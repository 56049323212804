import http from "@/api/http";
import logger from "@/store/logger/index";

import { SAVING } from "../general/types";
import { GET, UPDATE, ADD, DEL, RESET, DOWNLOAD } from "./types";
import { TOAST_ERROR, TOAST_SUCCESS } from "@/store/toast/types";
const ADD_TO = "ADD_TO";
const DELETING = "DELETING";
const REMOVE_FROM = "REMOVE_FROM";
const SET_STATUS = "SET_STATUS";
const CLEAR_STATUS = "CLEAR_STATUS";

const lookups = {
	project: { _ts: 0, life: 60 * 8 },
	contact: { _ts: 0, life: 60 * 8 },
	itemMaster: { _ts: 0, life: 60 * 8 },
	docTypes: { _ts: 0, life: 60 * 8 },
	accounts: { _ts: 0, life: 60 * 8 },
	countries: { _ts: 0, life: 60 * 8 },
};
const singles = {
	payableTotal: { hr: 1 },
	invoiceTotal: { hr: 1 },
	contractTotal: { hr: 1 },
};

const state = {
	invite: [],
	contact: [],
	project: [],
	payable: [],
	accounts: {},
	itemMaster: {},
	businessTypes: {},
	docTypes: {},

	accessCodes: {},
	states: {},
	lastNumber: {},
	template: {},
	types: {},
	nexoAccount: {},
	countries: {},
	budgeting: [],
	scheduling: [],
	projectSchedule: [],

	contract: [],
	receivedQuote: [],
	invoice: [],
	report: [],
	dailyLog: [],
	doc: [],
	cashIn: [],
	income: [],
	timesheet: [],
	invoiceSummary: [],
	payableSummary: [],
	cashInSummary: [],
	progressReport: [],
	feed: [],
	payableTotal: {},
	invoiceTotal: {},
	contractTotal: {},
	listloaded: {
		project: "",
		contact: "",
	},
	shareCustomer: [],
	user: [],
};

const reset = (state) => {
	state.invite = [];
	state.contact = [];
	state.project = [];
	state.payable = [];
	state.accounts = {};
	state.itemMaster = {};
	state.businessTypes = {};
	state.docTypes = {};
	state.states = {};
	state.accessCodes = {};
	state.lastNumber = {};
	state.template = {};
	state.types = {};
	state.budgeting = [];
	state.scheduling = [];
	state.projectSchedule = [];
	state.contract = [];
	state.receivedQuote = [];

	state.invoice = [];
	state.report = [];
	state.dailyLog = [];
	state.doc = [];
	state.cashIn = [];
	state.income = [];
	state.invoiceSummary = [];
	state.payableSummary = [];
	state.cashInSummary = [];
	state.progressReport = [];
	state.feed = [];
	state.payableTotal = {};
	state.invoiceTotal = {};
	state.contractTotal = {};
	state.nexoAccount = {};
	state.client = "";
	state.userClient = "";
	state.profileLoaded = false;
	state.user = [];
	state.shareCustomer = [];
	state.timesheet = [];
};

const getters = {
	//load once

	accessCodes: (state) => state.accessCodes,
	businessTypes: (state) => state.businessTypes,
	states: (state) => state.states,
	users: (state) => state.user,
	accounts: (state) => state.accounts,
	itemMaster: (state) => state.itemMaster,
	template: (state) => state.template,
	types: (state) => state.types,
	lastNumber: (state) => state.lastNumber,
	countries: (state) => state.countries,
	shareCustomers: (state) => state.shareCustomer,
	timesheets: (state) => state.timesheet,

	categories: (state) => (state.itemMaster ? state.itemMaster.categories : []),
	items: (state) => (state.itemMaster ? state.itemMaster.items : []),
	docTypeList: (state) => (state.docTypes ? state.docTypes.list : []),

	budgetings: (state) => state.budgeting || [],
	schedulings: (state) => state.scheduling || [],
	projectSchedules: (state) => state.projectSchedule || [],

	contacts: (state) => state.contact.filter((o) => o.entityName == "contact") || [],
	projects: (state) => state.project || [],
	invites: (state) => state.invite || [],
	feeds: (state) => state.feed || [],

	contracts: (state) => state.contract || [],
	receivedQuotes: (state) => state.receivedQuote || [],

	payables: (state) => state.payable || [],
	invoices: (state) => state.invoice || [],
	reports: (state) => state.report || [],
	docs: (state) => state.doc || [],
	cashIns: (state) => state.cashIn || [],
	incomes: (state) => state.income || [],
	progressReports: (state) => state.progressReport || [],

	dailyLogs: (state) => state.dailyLog || [],
	invoiceSummaries: (state) => state.invoiceSummary || [],
	payableSummaries: (state) => state.payableSummary || [],
	cashInSummaries: (state) => state.cashInSummary || [],

	payableTotal: (state) => state.payableTotal || {},
	invoiceTotal: (state) => state.invoiceTotal || {},
	contractTotal: (state) => state.contractTotal || {},
	nexoAccount: (state) => state.nexoAccount || {},

	dailyLogById: (state) => (id) => state.dailyLog.find((o) => o._id == id) || {},
	projectById: (state) => (id) => state.project.find((o) => o._id == id) || {},
	contactById: (state) => (id) => state.contact.find((o) => o._id == id) || {},
	contractById: (state) => (id) => state.contract.find((o) => o._id == id) || {},
	timesheetById: (state) => (id) => state.timesheet.find((o) => o._id == id) || {},

	payableById: (state) => (id) => state.payable.find((o) => o._id == id) || {},
	eceivableById: (state) => (id) => state.eceivable.find((o) => o._id == id) || {},
	reportById: (state) => (id) => state.report.find((o) => o._id == id) || {},
	budgetingById: (state) => (id) => state.budgeting.find((o) => o._id == id) || {},
	itemById: (state) => (id) => (state.itemMaster ? state.itemMaster.items.find((o) => o._id == id) || {} : {}),
	docById: (state) => (id) => state.doc.find((o) => o._id == id) || {},
	cashInById: (state) => (id) => state.cashIn.find((o) => o._id == id) || {},
	invoiceById: (state) => (id) => state.invoice.find((o) => o._id == id) || {},
	incomeById: (state) => (id) => state.income.find((o) => o._id == id) || {},
	accountById: (state) => (id) => (state.accounts.list ? state.accounts.list.find((o) => o._id == id) || {} : {}),
};
function freshInLocal(state, entityName) {
	let data = state[entityName];
	if (!data) return false;

	let loaded = 0;
	//check list for lookup entities
	let limit = 0;

	const isArray = Array.isArray(data);

	if (lookups[entityName]) {
		loaded = lookups[entityName]._ts;
		limit = lookups[entityName].life;
		const isfresh = Date.now() - loaded <= limit * 60 * 1000;
		if (isArray) return data.length && data.length > 1 && isfresh;
		else return data._id && isfresh;
	} else {
		if (singles[entityName]) {
			const hr = singles[entityName].hr || 0;
			return Date.now() - data.__loaded <= hr * 60 * 60 * 1000;
		}
	}
	return false;
}

const actions = {
	[GET]: ({ state, commit, dispatch }, { entityName, action, params, force, toast = true }) => {
		if (!force) {
			const fresh = freshInLocal(state, entityName);
			if (fresh) return new Promise((resolve) => resolve(fresh));
		}

		let url = `/${entityName}/${action || ""}`;
		return http
			.get(url, { params })
			.then((response) => {
				let data = response.data;
				commit(ADD_TO, { entityName, data, action });
				logger.info((Array.isArray(data) ? data.length : "1") + ` ${entityName}/s loaded `);
				return data;
			})
			.catch((e) => {
				if (toast) commit(TOAST_ERROR, e.response);
				throw e.response;
			})
			.finally(() => {});
	},

	[UPDATE]: ({ commit, dispatch }, { entityName, action, data, id, skey, toast = true }) => {
		if (!action) action = "update";
		const idpart = id || data._id ? `/${id || data._id}` : "";
		let url = `/${entityName}/${action}${idpart}`;
		if (skey) commit("SAVINGKEY", { skey, saving: true });
		else commit("SAVING", true);
		return http
			.put(url, data)
			.then((response) => {
				let data = response.data;
				commit(ADD_TO, { entityName, data });
				logger.info(` ${entityName} updated successfully!`);
				return data;
			})
			.catch((e) => {
				if (toast) commit(TOAST_ERROR, e.response);
				throw e.response;
			})
			.finally(() => {
				if (skey) commit("SAVINGKEY", { skey, saving: false });
				else commit("SAVING", false);
			});
	},

	[ADD]: ({ commit, dispatch }, { entityName, action, data }) => {
		if (!action) action = "add";
		let url = `/${entityName}/${action}`;
		commit(SAVING, true);
		return http
			.post(url, data)
			.then((response) => {
				let data = response.data;
				commit(ADD_TO, { entityName, data });
				logger.info(` ${entityName} added successfully!`);
				return data;
			})
			.catch((e) => {
				commit(TOAST_ERROR, e.response, { entityName, ADD });
				throw e.response;
			})
			.finally(() => {
				commit(SAVING, false);
			});
	},

	[DEL]: ({ commit, dispatch }, { entityName, action, model }) => {
		if (!action) action = "del";
		let url = `/${entityName}/${action}/${model._id}`;
		commit(DELETING, { data: model, started: true });
		return http
			.delete(url)
			.then((response) => {
				let data = response.data;
				logger.info(` ${entityName} deleted successfully!`);
				if (action.startsWith("list/del") || action.includes("/del")) {
					commit(ADD_TO, { entityName, data: model });
				} else commit(REMOVE_FROM, { entityName, data: model });
				return data;
			})
			.catch((e) => {
				commit(TOAST_ERROR, e.response);
				throw e.response;
			})
			.finally(() => {
				commit(DELETING, { data: model, completed: true });
			});
	},
	[DOWNLOAD]: ({ state, commit, dispatch }, { entityName, action }) => {
		let url = `/${entityName}/${action || ""}`;
		// commit(SAVING, true);
		return http({
			url,
			method: "GET",
			responseType: "arraybuffer", //"blob" // important
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
				const link = document.createElement("a");
				link.href = url;
				link.target = "_blank";
				link.download = "file.pdf";

				link.click();
				return url;
			})
			.catch((e) => {
				commit(TOAST_ERROR, e.response);
				throw e.response;
			})
			.finally(() => {
				// commit(SAVING, false);
			});
	},
};

const mutations = {
	[ADD_TO]: (state, { entityName, data, action }) => {
		if (!data) return;
		if (!Array.isArray(data) && !data._id) return;
		if (!Array.isArray(data) && !entityName) return;

		if (!state[entityName]) return;

		if (action == "list" || action == "one") {
			if (lookups[entityName]) lookups[entityName]._ts = Date.now();
		}

		if (!Array.isArray(state[entityName])) {
			setLoaded(data);
			state[entityName] = data;
		} else {
			if (Array.isArray(data)) {
				data.map((e) => {
					addOrReplace(state, e, entityName);
				});
			} else addOrReplace(state, data, entityName);
		}
	},
	[DELETING]: (state, { data, started, completed }) => {
		if (data && data.entityName) {
			let found = state[data.entityName].find((o) => o._id == data._id);
			if (found) {
				if (started) found.__status = "d";
				if (completed) data.__status = "";
			}
		} else if (data) {
			//attachment
			if (started) data.__status = "d";
			if (completed) data.__status = "";
		}
	},
	[REMOVE_FROM]: (state, { entityName, data }) => {
		if (!data) return;
		if (!Array.isArray(state[entityName])) {
			state[entityName] = "";
		} else {
			if (!Array.isArray(data)) {
				removeFromList(state[entityName], data);
			} else
				data.map((e) => {
					removeFromList(state[entityName], e);
				});
		}
	},
	[SET_STATUS]: (state, { entity, status }) => {
		if (!entity.entityName) return;
		const found = state[entity.entityName].find((o) => o._id == entity._id);
		if (found) found.__status = status;
	},
	[CLEAR_STATUS]: (state, entity) => {
		if (!entity.entityName) return;
		const found = state[entity.entityName].find((o) => o._id == entity._id);
		if (found) found.__status = "";
	},

	[RESET]: (state) => {
		reset(state);
	},
};

const log = {
	success: (txt) => {
		if (process.env.NODE_ENV !== "production") {
			console.log(txt);
		}
	},
};
export default {
	state,
	getters,
	actions,
	mutations,
};

function setLoaded(entity) {
	entity.__loaded = Date.now();
	entity.__status = "";
	if (entity.attachments && entity.attachments.length) {
		entity.attachments.map((a) => (a.__status = ""));
	}
}

function addOrReplace(state, entity, entityName) {
	const list = state[entityName];
	if (entity.entityName != entityName) return;
	if (!entity || !list) return;
	let found = list.find((o) => o._id == entity._id);
	setLoaded(entity);
	if (found) {
		let index = list.indexOf(found);
		list.splice(index, 1, entity);
	} else if (!entity.isDeleted) {
		if (entity.hasOwnProperty("sequence")) list.push(entity);
		else list.unshift(entity);
	}
}

function removeFromList(list, entity) {
	if (!entity || !list) return;
	let found = list.find((o) => o._id == entity._id);

	if (found) {
		let index = list.indexOf(found);
		list.splice(index, 1);
	}
}

// this.$store.dispatch("GET", { entityName: "nexo", action: "initdb" });
