import { render, staticRenderFns } from "./BudgetEdit.vue?vue&type=template&id=6ec5cb7e&scoped=true"
import script from "./BudgetEdit.vue?vue&type=script&lang=js"
export * from "./BudgetEdit.vue?vue&type=script&lang=js"
import style0 from "./BudgetEdit.vue?vue&type=style&index=0&id=6ec5cb7e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec5cb7e",
  null
  
)

export default component.exports