<template>
  <div
    class="d-flex align-items-center justify-content-between"
    :class="{current:project._id==project_id}"
    :key="project._id"
  >
    <div class="d-flex align-items-start">
      <svg-icon name="folder" class="mr-3" />
      <router-link :to="`/project/${project._id}`">
        <span>{{ project.name }}</span>
      </router-link>
    </div>
    <div v-if="isShared" class="text-success fw-600">Shared</div>
    <div v-else>
      <a-button
        :disabled="sharing"
        @click="sharePrject(project)"
        class="btn btn-outline-primary px-2"
      >
        <spinner :busy="true" :size="1" class="mr-1" v-if="sharing" />Share with Clinet
      </a-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";

export default {
  name: "shareProjectLine",
  mixins: [baseMixin, dataMixin, routeMixin],
  props: { project: Object },
  data() {
    return {
      sharing: false
    };
  },
  methods: {
    async sharePrject(project) {
      try {
        this.sharing = true;
        await this.update(
          "shareCustomer",
          { projectId: project._id },
          `share/customer/${this.customerId}`
        );
      } catch (error) {
      } finally {
        this.sharing = false;
      }
    }
  },
  computed: {
    customerId() {
      return this.project.customer._id;
    },
    shareCustomer() {
      return this.shareCustomers.find(o => o._id == this.customerId);
    },
    isShared() {
      return this.shareCustomer.projects
        .map(o => o._id)
        .includes(this.project._id);
    },
    ...mapGetters(["shareCustomers"])
  }
};
</script>