<template>
  <div class="border-0 rounded-5-top shadow-sm">
    <div class="px-3 py-2 text-center fs-11 bg-trans rounded-5-top">Invoice Summary</div>

    <table class="table bg-white">
      <tr>
        <td>Billed Invoices</td>
        <td class="text-right fs-11">{{ totalInvoiced| currency0 }}</td>
      </tr>
      <tr>
        <td>Received Payments</td>
        <td class="text-right fs-11">{{ totalPaid | currency0 }}</td>
      </tr>

      <tr class="g-total alert alert-warning">
        <td>Balance</td>
        <td class="text-right fs-11">{{ balance | currency0 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import invoiceListMixin from "../data/invoiceListMixin";
export default {
  name: "invoiceTotal",
  mixins: [invoiceListMixin],
  props: {
    list: Array,
    incomes: Array
  }
};
</script>
