<template>
	<a :href="`mailto:${email}`"> <i :class="icon" class="mr-1" v-if="!!icon"></i>{{ text || email }} </a>
</template>
<script>
export default {
	name: 'email-link',
	props: {
		email: String,
		text: String,
		icon: String,
	},
};
</script>
