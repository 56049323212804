<template>
  <card-layout nofooter back :state="state" class="bg-1">
    <div slot="header" class="d-flex">
      <div v-if="!nodata">
        <!-- <div v-if="isContarctMode">
          <add-button v-if="!noContract" @add="addContract">Add Subcontrator</add-button>
        </div>-->
        <!-- <div v-else>
          <add-button v-if="!noQuote" @add="addQuote">Add Received Quotation</add-button>
        </div>-->
      </div>
    </div>
    <div slot="title" class="d-flex">Contract Management</div>
    <div class="card-body" slot="data">
      <div class="form-group">
        <ul class="nav nav-tabs header-tabs">
          <li class="nav-item" v-for="item in viewsModes" :key="item.code">
            <a
              class="nav-link"
              :class="{'active':item.code==selectedMode}"
              @click="switchMode(item.code)"
            >
              {{item.name}}
              <span class="badge badge-pill badge-light">{{count(item.code)}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <SubContratcs ref="subContratcs" v-if="selectedMode=='c'" />
        <ReceivedQuotes ref="receivedQuotes" v-else />
      </div>
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import SubContratcs from "./SubContratcs";
import ReceivedQuotes from "./ReceivedQuotes";
export default {
  name: "contractManagement",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { SubContratcs, ReceivedQuotes },
  mounted() {
    this.load();
  },
  data() {
    return {
      selected: "",
      selectedMode: "c",
      viewsModes: [
        { code: "c", name: "Contracts" },
        { code: "q", name: "Received Quotations" }
      ]
    };
  },
  methods: {
    async load() {
      this.loading();
      this.loadByProject("contract", this.project_id);
      this.loadByProject("receivedQuote", this.project_id);
      this.loaded();
    },
    select(c) {
      this.selected = c._id;
    },
    addContract() {
      this.$refs.subContratcs.add();
    },
    addQuote() {
      this.$refs.receivedQuotes.add();
    },

    switchMode(mode) {
      this.selectedMode = mode;
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },
    contractList() {
      return this.contracts.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    quoteList() {
      return this.receivedQuotes.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    nodata() {
      return this.ready && !this.contractList.length && !this.quoteList.length;
    },
    noContract() {
      return this.ready && !this.contractList.length;
    },
    noQuote() {
      return this.ready && !this.quoteList.length;
    },
    isContarctMode() {
      return this.viewsModes[0].code == this.selectedMode;
    },

    count() {
      return mode =>
        mode == this.viewsModes[0].code
          ? this.contractList.length
          : this.quoteList.length;
    },
    ...mapGetters(["projectById", "contracts", "contactById", "receivedQuotes"])
  }
};
</script>
<style lang="scss" scoped>
.vh-100-m {
  min-height: calc(100vh - 14rem);
}
</style>