<template>
  <card-layout :title="isnew ? 'New Timesheet' : 'Edit Timesheet'" :state="state" class="bg-1">
    <div slot="header">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
    <div class="card-body" slot="data">
      <div class="row form-group">
        <div class="col-lg-7">
          <section class="form-group">
            <label>Project</label>
            <project-view :project="timesheet.project" class="form-control value" />
            <!-- <project-selector
              name="project"
              :show-general="true"
              ref="projectView"
              :project="timesheet.project"
              placeholder="Select project"
              @change="changeProject"
              :class="{ 'is-invalid': validated && $v.timesheet.project.$invalid}"
              class="form-control"
            />
            <div
              class="invalid-feedback"
              v-if="validated && $v.timesheet.project.$invalid"
            >Please select a project</div>-->
          </section>
          <section class="form-group">
            <label>Work Item</label>
            <item-selector
              name="title"
              ref="itemSelector"
              :item="timesheet.item"
              placeholder="Select item"
              @change="focusNext"
              :projectId="timesheet.project?timesheet.project._id:''"
              class="form-control"
            />
          </section>

          <section class="form-group row">
            <div class="col-lg-8">
              <label>Subcontractor / contact</label>
              <contact-selector
                name="payee"
                ref="contactSelector"
                :contact="timesheet.contact"
                placeholder="Select vendor / subcontractor"
                @change="focusNext"
                :class="{'is-invalid': validated && $v.timesheet.contact.$invalid }"
                class="form-control"
              />
              <div
                class="invalid-feedback"
                v-if="validated && $v.timesheet.contact.$invalid"
              >Please select a contact</div>
            </div>
            <div class="col-lg-4">
              <label for>Insider Or Subcontractor</label>
              <b-checkbox v-model="timesheet.insider">Insider(Employee)</b-checkbox>
            </div>
          </section>

          <div class="row form-group">
            <div class="col">
              <div>
                <label>Date</label>
                <date-input ref="datePicker" @change="focusNext" v-model="timesheet.date" />
              </div>
            </div>
            <div class="col form-group"></div>
          </div>

          <div class="row">
            <div class="col-lg-9">
              <time-input
                label="Start Time"
                v-model="timesheet.startTime"
                ref="startTime"
                class="d-inline-block"
                @close="onStartClose"
                :validated="validated"
              />
              <time-input
                label="End Time"
                v-model="timesheet.endTime"
                ref="endTime"
                class="d-inline-block ml-3"
                :validated="validated"
                @close="onEndClose"
              />
            </div>
            <div class="col-lg-3">
              <label for>Duration</label>
              <div
                class="form-control fs-11 bg-success-1 fw-600"
                :class="{'is-invalid':!durationValid && validated}"
              >{{duration | duration}}</div>
            </div>
          </div>

          <div class="form-group">
            <label for>Note</label>
            <textarea
              ref="noteInput"
              v-model="timesheet.note"
              class="form-control"
              placeholder="Any note ?"
              rows="3"
              maxlength="500"
              id="note"
            ></textarea>
          </div>

          <section>
            <b-checkbox v-model="timesheet.sharedCustomer" switch>Share with Client</b-checkbox>
          </section>
        </div>

        <div class="col-lg-5">
          <label>Attach Images / Files</label>
          <entity-attachments entityName="timesheet" :entity="timesheet" class="px-2" />
        </div>
      </div>
    </div>
    <div slot="footer">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import {
  required,
  numeric,
  minValue,
  maxValue,
  decimal
} from "vuelidate/lib/validators";

import { validationMixin } from "vuelidate";
import { calcDuration } from "@/helpers/date";
import { isValidTime } from "./components/timesheet";
const entityName = "timesheet";

export default {
  name: "timesheetEdit",
  mixins: [dataMixin, baseMixin, routeMixin, validationMixin],

  data() {
    return {
      timesheet: "",
      startClosed: false,
      endClosed: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      if (this.isnew) {
        this.timesheet = this.newTimesheet();

        this.loaded();
        this.$nextTick(() => this.focusNext());
      } else {
        await this.loadById(entityName, this.timesheet_id);
        this.init();
        this.loaded();
      }
    },
    newTimesheet() {
      let key = this.$route.query.type || 1;
      return {
        date: new Date(),
        project: {
          _id: this.project_id,
          name: this.project ? this.project.name : ""
        },
        contact: { _id: "", name: "" },
        item: { _id: "", name: "" },
        startTime: "",
        endTime: "",
        note: "",
        insider: false,
        attachments: []
      };
    },
    async init() {
      let timesheet = this.timesheetById(this.timesheet_id);
      this.timesheet = JSON.parse(JSON.stringify(timesheet));
    },

    onStartClose() {
      this.startClosed = true;
      this.focusNext();
    },
    onEndClose() {
      this.endClosed = true;
      this.focusNext();
    },
    focusNext() {
      this.$nextTick(() => {
        if (!this.timesheet.item._id) {
          this.$refs.itemSelector.show();
          return;
        }
        if (!this.timesheet.contact._id) {
          this.$refs.contactSelector.show();
          return;
        }
        if (!this.timesheet.startTime && !this.startClosed) {
          this.$refs.startTime.focus();
          return;
        }
        if (!this.timesheet.endTime && !this.endClosed) {
          this.$refs.endTime.focus();
          return;
        }
        if (!this.timesheet.note) {
          this.$refs.noteInput.focus();
          return;
        }
      });
    },

    async save() {
      this.validated = true;

      if (
        this.$v.$invalid ||
        !this.startTimeValid ||
        (!this.endTimeValid && !this.durationValid)
      )
        return;
      let timesheet = { ...this.timesheet, duration: this.duration };

      try {
        timesheet = this.isnew
          ? await this.add(entityName, timesheet)
          : await this.update(entityName, timesheet);
        this.$router.go(-1);
      } catch (error) {}
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  validations: {
    timesheet: {
      project: {
        _id: { required }
      },
      contact: {
        _id: { required }
      },

      date: { required },
      startTime: { required },
      endTime: { required }
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },
    isnew() {
      return this.timesheet_id == "new" || this.timesheet_id == "0";
    },
    startTimeValid() {
      return isValidTime(this.timesheet.startTime);
    },
    endTimeValid() {
      return isValidTime(this.timesheet.endTime);
    },
    durationValid() {
      return this.duration > 0;
    },

    duration() {
      return calcDuration(this.timesheet.startTime, this.timesheet.endTime);
    },

    ...mapGetters(["user", "projectById", "timesheetById"])
  }
};
</script> 