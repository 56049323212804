<template>
  <span class="mobile-hide">
    <img :src="logoUrl" height="30" class="mr-2" alt="nexobuild" v-if="logoUrl" />
    <router-link to="/settings/clientprofile">{{ clientName }}</router-link>
    <i class="icon-arrow-right mx-2 small"></i>
  </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "clientNmeInHeader",

  computed: {
    ...mapGetters(["clientName", "logoUrl"])
  }
};
</script>
