<template>
  <button class="btn mx-2 mobile-hide d-print-none" @click="print">
    <i class="icon-printer mr-2"></i>
    Print
  </button>
</template>

<script>
export default {
  name: "buttonPrint",
  methods: {
    print: function() {
      window.print();
    }
  }
};
</script>
