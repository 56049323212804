<template>
  <card-layout title="Payable" :state="state" back>
    <div slot="header">
      <div class="d-flex align-items-center">
        <button-share :entity="payable" />
        <button-edit @edit="edit" />
      </div>
    </div>
    <div class="card-body" slot="data">
      <div class="row" v-if="payable">
        <div class="col-lg-7">
          <div class="bg-2 p-3">
            <row label="Date">{{ payable.date | dateformat }}</row>
            <row label="Job/Project">
              <project-view :project="payable.project" />
            </row>
            <row label="Payee">
              <contact-view :contact="payable.payee" />
            </row>
            <row label="Item">
              <item-view :item="payable.item" />
            </row>
            <row label="Invoice Number">{{ payable.refNo }}</row>

            <div class="row">
              <div class="col-lg-6"></div>
              <div class="col-lg-6">
                <PayableAmount :payable="payable" />
              </div>
            </div>
            <row label="Notes" class="mt-2 mb-2">{{ payable.note }}</row>
            <div class="alert alert-info" v-if="payable.invoiceId">
              This expense has been Invoiced
              <router-link
                class="px-3"
                v-if="payable.invoiceId"
                :to="`/project/${payable.project._id}/invoice/${payable.invoiceId}`"
              >View Invoice</router-link>
            </div>
            <payments :payable="payable" />
          </div>
        </div>

        <div class="col-lg-5">
          <entity-attachments :entityName="'payable'" :entity="payable" />
        </div>
      </div>
    </div>
    <div slot="footer" class="d-flex justify-content-between">
      <last-modified :entity="payable" class="float-left" />
      <delete-confirm
        :entity="payable"
        fill
        @confirm="delExpense"
        confirmtext="Are you sure you want to delete this expense"
      />
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import newPayment from "./data/newPayment.js";
import InlinePaymet from "./components/InlinePaymet";
import TaxInput from "@/views/settings/tax/TaxInput";
import PayableAmount from "./PayableAmount";
import Payments from "./Payments";
import calc from "./data/calc.js";
import pages from "@/pages";
const entityName = pages.payables.entityName;

export default {
  name: "payableEdit",
  mixins: [dataMixin, baseMixin, routeMixin],
  components: {
    InlinePaymet,
    TaxInput,
    Payments,
    PayableAmount
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      this.loading();
      this.loaded();
      await this.loadById(entityName, this.payable_id);
    },

    edit(id) {
      this.$router.push(`/${entityName}/${this.payable_id}/edit`);
    },
    async delExpense() {
      await this.del(entityName, this.payable);
      this.$router.go(-1);
    }
  },
  computed: {
    payable() {
      return this.payableById(this.payable_id);
    },
    ...mapGetters(["payableById"]),
    total() {
      return calc.calcTotal(this.payable);
    }
  }
};
</script>
