<template>
	<div class="autocomplete search">
		<i class="la la-spinner la-spin" v-if="loading"></i>
		<i class="la la-search" v-show="isEmpty"></i>
		<span class="count" v-if="result.length > 0">({{ result.length }})</span>
		<i class="la la-times" v-show="isDirty && !loading" @click="reset"></i>
		<input
			:class="{ selected: !!selected }"
			ref="searchInput"
			class="form-control"
			type="text"
			v-model="keyword"
			v-debounce="delay"
			@change="onChange"
			@keydown.down="down"
			@keydown.up="up"
			@keydown.esc="reset"
			@keydown.enter="onEnter"
			@keydown.tab="onEnter"
			@focus="onFocus"
			:placeholder="placeholder"
		/>
		<div class="search-result p-1" ref="ulList" id="search-result" v-show="isOpen">
			<table class="table">
				<tr
					v-for="(item, i) in items"
					:key="i"
					ref="liList"
					:class="{ active: i === current }"
					@click="select(item)"
					@mouseover="mouseover(item)"
				>
					<td>
						{{ item.name }}
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	name: 'typeahead',
	props: {
		loading: Boolean,
		rowTemplate: String,
		placeholder: {
			type: String,
			default: 'Search',
		},
		delay: {
			type: Number,
			default: 300,
		},
		items: {
			type: Array,
			required: false,
			default: () => [],
		},
		minChars: {
			type: Number,
			default: 2,
		},
		isAsync: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			isOpen: false,
			filtered: [],
			keyword: '',
			current: -1,
			selected: '',
			previuse: '',
		};
	},
	methods: {
		onChange() {
			this.isOpen = true;
			this.selected = '';
			this.$emit('search', this.keyword);
			this.$emit('change', this.keyword);
		},
		down() {
			this.isOpen = true;
			if (this.current < this.result.length - 1) {
				this.current++;
			} else {
				this.current = -1;
			}
			this.scroll();
		},
		up() {
			if (this.current > 0) {
				this.current--;
			} else if (this.current === -1) {
				this.current = this.result.length - 1;
			} else {
				this.current = -1;
			}
			this.scroll();
		},
		scroll() {
			let el = this.$refs.liList[this.current];
			if (!el || !el.$el) return;
			const elH = el.$el.clientHeight;
			let totalH = this.$refs.ulList.clientHeight;
			this.$refs.ulList.scrollTop = elH * (this.current - totalH / elH + 2);
		},
		mouseover(item) {
			this.current = this.result.indexOf(item);
		},
		onFocus() {
			this.isOpen = true;
		},
		focus(keyword) {
			let el = this.$refs.searchInput;
			if (el) el.focus();
			this.isOpen = true;
			this.keyword = keyword;
		},
		reselect(item) {
			this.current = this.result.indexOf(item);
			this.keyword = item.name;
			this.isOpen = true;
			this.previuse = item;
			this.$refs.searchInput.select();
		},
		input() {
			this.selected = '';
		},
		onEnter() {
			if (this.result.length && this.current < 0) {
				this.current = 0;
			}
			let item = this.result[this.current];
			this.select(item);
			this.current = -1;
		},
		select(item) {
			if (!item) return;
			this.selected = item;
			this.previuse = '';
			this.isOpen = false;
			this.$emit('select', item);
			this.$refs.searchInput.blur();
		},
		reset() {
			this.keyword = '';
			this.isOpen = false;
		},
		handleClickOutside(evt) {
			if (!this.$el.contains(evt.target)) {
				this.isOpen = false;
				this.current = -1;
				if (!this.selected && this.previuse) {
					this.select(this.previuse);
				}
			}
		},
	},
	watch: {
		items() {
			if (this.current < 0 || (this.result.length > 0 && this.current > this.result.length - 1)) {
				this.current = 0;
			}
		},
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
	},
	destroyed() {
		document.removeEventListener('click', this.handleClickOutside);
	},
	computed: {
		hasItems() {
			return this.result.length > 0;
		},
		isEmpty() {
			return !this.keyword;
		},
		isDirty() {
			return !!this.keyword;
		},
		result() {
			return this.isAsync ? this.items : this.filtered;
		},
	},
};
</script>
<style lang="scss" scoped>
.autocomplete {
	position: relative;
}
.table {
	table-layout: fixed;
}
.search-result {
	padding: 0;
	margin: 0;
	// border: 1px solid #eeeeee;
	overflow-y: auto;
	width: 100%;
	position: absolute;
	z-index: 10;
	margin-top: 2px;
	min-width: 100%;
	background-color: #fff;
	// list-style: none;
	border-radius: 4px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	z-index: 1000;
	height: 32rem;
	overflow-x: hidden;
}
.result-row {
	list-style: none;
	text-align: left;
	padding: 4px 2px;
	cursor: pointer;
}
.result-row.is-active {
	background-color: #4caf50;
	color: white;
}
.count,
i {
	float: right;
	position: absolute;
	top: 0.8rem;
	right: 1rem;
	opacity: 0.4;
}
.count {
	top: 0.4rem;
	right: 2.5rem;
}
.search-result .active td {
	background-color: #03a9f4;
	color: white;
}
.search-result table tr td {
	padding: 0.25rem;
}
</style>
