import { mapGetters } from "vuex";

export const roles = {
	a: 10,
	f: 8,
	r: 7,
	c: 3,
	"": 0,
};
export const checkAccess = (myrole, role) => {
	return !role || roles[myrole] >= roles[role];
};

export const filterRoute = (route, myrole) => {
	return (
		!route.meta ||
		checkAccess(myrole, route.meta.accessRole) ||
		(route.meta.forceShow && route.meta.forceShow === myrole)
	);
};

export default {
	props: {
		accessRole: { type: String, default: "f" },
	},

	computed: {
		hasAccess() {
			return checkAccess(this.myrole, this.accessRole);
		},
		hideAction() {
			return this.isUserCustomer;
		},
		isUserCustomer() {
			return this.myrole == "c";
		},
		isAdmin() {
			return this.myrole == "a";
		},
		isReadonlyUser() {
			return this.myrole == "r";
		},
		...mapGetters(["myrole"]),
	},
};
