<template>
  <div>
    <highcharts :options="chartOptions" ref="hchart" />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import moment from "moment";
import { sortDate, todate } from "@/helpers/date";
export default {
  name: "clientInvoiceChart",
  components: { highcharts: Chart },
  mixins: [baseMixin],
  props: {
    invoices: Array,
    incomes: Array
  },
  data() {
    return {
      chartOptions: {
        chart: {
          //plotBackgroundColor: "#f7f5f8",
          plotBorderWidth: null,
          plotShadow: false,
          // type: "areaspline",
          height: 250
        },
        title: {
          text: ""
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: "%e. %b",
            year: "%b"
          }
        },
        yAxis: {
          //min: 0,
          title: {
            text: "Amount ($)"
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "{series.name}: <b>${point.y:,.0f}</b>"
        },

        series: [
          {
            type: "areaspline",
            name: "Balance",
            color: "#fee4b0", //"#a6e0dd",
            data: []
          },
          {
            type: "column",
            name: "Invoiced",
            color: "#3e97b5",
            data: []
          },

          {
            type: "column",
            name: "payment",
            color: "#4CAF50",
            data: []
          }
        ]
      }
    };
  },
  watch: {
    invoices: {
      immediate: true,
      handler() {
        this.updateData();
      }
    }
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      let balance = 0;
      let balanceData = [];
      let list = this.invoices.concat(this.incomes).sort(sortDate);
      list.map(o => {
        if (o.hasOwnProperty("invoiceTotal")) {
          balance += o.invoiceTotal;
        } else {
          balance -= o.amount;
        }
        balanceData.push({
          date: o.date,
          amount: balance
        });
      });

      this.chartOptions.series[1].data = this.invoices.map(o => {
        return [todate(o.date), o.invoiceTotal];
      });

      this.chartOptions.series[0].data = balanceData.map(o => {
        return [todate(o.date), o.amount];
      });
      this.chartOptions.series[2].data = this.incomes.map(o => {
        return [todate(o.date), o.amount];
      });
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped></style>
