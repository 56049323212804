<template>
  <div class="position-relative">
    <button class="btn small py-1 ml-3 text-primary" @click="show()">
      <i class="la la-plus"></i>
      <i class="icon-arrow-down mr-2"></i>
      Import From Excel
    </button>

    <popover :show="modalShow" align="r" @close="modalShow = false" :offset-y="4">
      <h6 class="mx-3 my-2">Import From Excel</h6>
      <div class="popup-content p-4 pt-5 bg-light">
        <div class="form-group">
          <div class="custom-file">
            <input
              type="file"
              ref="file"
              class="custom-file-input"
              id="inputGroupFile01"
              @change="handleFileUpload($event.target)"
              :class="{ disabled: !hasAccess, 'btn-sm': small, 'is-invalid': validated && !file}"
              :disabled="!hasAccess"
              accept=".xlsx"
            />

            <label
              class="custom-file-label"
              for="inputGroupFile01"
            >{{file?file.name: 'Select the Excel file'}}</label>
          </div>
        </div>

        <div class="form-group">
          <b-form-group label="How do you want to import?">
            <b-form-radio v-model="append" name="some-radios" value="1">
              <div class="fw-600">Merge</div>New items will be added to the end of existing items
            </b-form-radio>
            <b-form-radio v-model="append" name="some-radios" value="0">
              <div class="fw-600">Replace</div>The existing item master will be replaced with the new one
            </b-form-radio>
          </b-form-group>
          <div
            class="alert alert-danger"
            v-if="append==0"
          >This is not recomended if you have already used the existing items in budgeting or other entities</div>
        </div>
        <div class="clearfix pt-3 border-top">
          <save-cancel @save="importFile" savetext="Import" @cancel="cancel" />
        </div>
      </div>
    </popover>
  </div>
</template>

<script>
import accessMixin from "@/mixins/accessMixin";
import readXlsxFile from "read-excel-file";

export default {
  name: "buttonImport",
  mixins: [accessMixin],
  props: {
    title: {
      type: String,
      default: "Import"
    },

    small: Boolean
  },
  data() {
    return {
      modalShow: false,
      append: 1,
      file: "",
      validated: false
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
      this.$refs.file.value = "";
      this.file = "";
      this.append = 1;
      this.validated = false;
    },
    select(data) {
      this.hide();
      this.$emit("select", data);
    },
    cancel() {
      this.modalShow = false;
      this.file = "";
    },
    edit() {
      if (!this.hasAccess) return;
      this.$emit("import");
    },
    async handleFileUpload(e) {
      this.file = e.files[0];
    },
    importFile() {
      this.validated = true;
      if (!this.file) return;

      readXlsxFile(this.file).then(rows => {
        rows = rows.filter(row => row.some(cell => cell !== null));
        this.$emit("onImport", { rows, append: this.append });
        this.hide();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-content {
  width: 26rem;
}
</style>
