<template>
  <div>
    <highcharts :options="chartOptions" ref="hchart" />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import moment from "moment";
import { sortDate, todate } from "@/helpers/date";
import { mapGetters } from "vuex";
import { groupByContact, totalDuration } from "./timesheet";
import { toDuration } from "@/helpers/date";
const filterByContact = (list, contactId) => {
  if (!list) return 0;
  const contactList = list.filter(o => o.contact && o.contact._id == contactId);
  if (!contactList || !contactList.length) return 0;
  return totalDuration(contactList) / 60;
};
export default {
  name: "CashflowChart",
  components: { highcharts: Chart },
  mixins: [baseMixin, dataMixin, routeMixin],
  props: {
    list: Array,
    grouped: Array
  },
  data() {
    return {
      chartOptions: {
        chart: {
          plotBorderWidth: null,
          plotShadow: false,
          height: 420,
          type: "column"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: "Duration (hours)"
          }
        },
        tooltip: {
          formatter: function(point) {
            return `
            ${this.series.name} <br/> ${toDuration(this.y * 60)} hours
            `;
          }
        },

        series: []
      }
    };
  },
  watch: {
    list: {
      immediate: true,
      handler(value) {
        this.updateChart();
      }
    },
    grouped: {
      immediate: true,
      handler(value) {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      let chartdata = groupByContact(this.list).map((g, i) => {
        return {
          name: g.contact.name,
          data: this.grouped.map(o => filterByContact(o.list, g.contactId))
        };
      });
      const categories = this.grouped.map(o => o.key);

      this.chartOptions.series = chartdata;
      this.chartOptions.xAxis.categories = categories;
    }
  },
  computed: {}
};
</script> 
