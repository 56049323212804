<template>
  <div>
    <b-table
      hover
      responsive
      :items="list"
      :fields="fields"
      :sort-date.sync="sortDate"
      sort-icon-left
      :tbody-tr-class="rowClass"
    >
      <template v-slot:cell(date)="data">
        <router-link :to="`/payable/${data.item._id}`" class="d-d-inline-block no-wrap">
          {{
          data.value | dateformat
          }}
        </router-link>
      </template>

      <template v-slot:head()="data">
        <span class="c-pointer p-2">{{ data.label }}</span>
      </template>

      <template v-slot:cell(attachments.lenght)="data">
        <div class>
          <i
            v-if="data.item.sharedCustomer"
            class="icon-share small mr-2"
            v-tooltip="'Shared with client'"
          ></i>
          <attachment-icon :attachments="data.item.attachments" class="py-1" />
        </div>
      </template>
    </b-table>

    <!-- <div v-for="f in fields" :key="f.key">
      <input
        class="form-check-input"
        type="checkbox"
        :value="f.label"
        id="f.key"
        :checked="f.default"
        v-model="fields"
        @change="remove($index)"
      />
      <label class="form-check-label" for="defaultCheck1">{{ f.label }}</label>
    </div>-->
  </div>
</template>

<script>
import PayableTypeIcon from "../components/PayableTypeIcon";
import PaidAmount from "../components/PaidAmount";
import { subTotal } from "../data/calc";
import paymentMixin from "../data/paymentMixin.js";
import { dateformat } from "@/filters/dateformat";
import { truncate } from "@/filters/stringformat";
import { toCurrency } from "@/filters/currency";
import _ from "lodash";
export default {
  name: "payableTable",
  components: {
    PayableTypeIcon,
    PaidAmount
  },
  mixins: [paymentMixin],
  props: {
    list: Array
  },
  computed: {
    lineTotal() {
      return subTotal;
    },
    multiProject() {
      return this.projectIds.length > 1;
    },
    projectIds() {
      return _.values(_.groupBy(this.list.map(o => o.project._id)));
    }
  },
  data() {
    return {
      sortDate: true,
      fields: [
        {
          label: "Date",
          key: "date",
          sortable: true,
          default: true
        },
        {
          label: "Payee",
          key: "payee.name",
          sortable: true,
          formatter: value => truncate(value, 10),
          default: true
        },
        {
          label: "Project",
          key: "project.name",
          sortable: true,
          formatter: value => truncate(value, 10),
          default: true
        },
        {
          label: "Item",
          key: "title",
          sortable: true,
          formatter: value => truncate(value, 20),
          default: true
        },
        {
          label: "Invoice#",
          key: "refNo",
          sortable: true,
          formatter: value => truncate(value, 20),
          default: true
        },
        {
          label: "Amount",
          key: "amount",
          sortable: true,
          formatter: toCurrency,
          default: true
        },
        {
          // todo: replace GST from taxcode1
          label: "GST",
          key: "tax1.amount",
          sortable: true,
          formatter: toCurrency,
          default: true
        },
        {
          // todo: replace PST from taxcode1
          label: "PST",
          key: "tax2.amount",
          sortable: true,
          formatter: toCurrency,
          default: true
        },
        {
          label: "Paid Amount",
          key: "paidAmount",
          sortable: true,
          formatter: toCurrency,
          default: true
        },
        {
          // todo: fix this
          label: "",
          key: "attachments.lenght",
          sortable: true,
          default: true
        }
      ]
    };
  },
  methods: {
    rowClass(item, type) {
      return this.paymentStatus(item).bg;
    },
    remove($index) {
      // this.todos.splice(index, 1)
      this.$delete(this.fileds, index);
    }
  }
};
// ref https://bootstrap-vue.org/docs/components/table
</script>
