export default (list, keyword) => {
    if (list && keyword) {
        keyword = keyword.toLowerCase();
        return list.filter(
            o =>
            (o.contact &&
                o.contact.name &&
                o.contact.name.toLowerCase().includes(keyword)) ||
            (o.item && o.item.name && o.item.name.toLowerCase().includes(keyword))
        );
    }
    return list;
};