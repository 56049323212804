<template>
	<div>
		<div class="mt-3 mobile-show">
			<button
				class="btn-collapse btn fw-600 py-2 w-100 btn-light no-radius bg-l-gray"
				@click="collapse = !collapse"
			>
				<i class="mr-2 " :class="activeMenu.icon"></i>

				{{ activeMenu.name }}

				<i class="icon-arrow-down ml-3"></i>
			</button>

			<b-collapse id="menu-collapse" v-model="collapse" class="mobile-menu">
				<ul class="list-group list-group-flush  text-left  shadow ">
					<a
						class="list-group-item d-flex justify-content-between align-items-center  "
						v-for="menu in menus"
						:key="menu.path"
						@click="navigate(menu)"
					>
						<div class="d-flex align-items-center ">
							<i class="mr-3 fs-12" :class="menu.icon"></i>

							<div>
								<div>{{ menu.name }}</div>
								<div class="text-gray fw-300  small ">{{ menu.desc }}</div>
							</div>
						</div>
						<!-- <count-badge :count="count(menu.key)" /> -->
					</a>
				</ul>
			</b-collapse>
		</div>
		<ul class="list-group list-group-flush mt-5 text-left mobile-hide">
			<router-link
				class="list-group-item d-flex justify-content-between align-items-center border-0 "
				:class="{ active: isActive(menu) }"
				:to="`/${pageKey}/${entity.guid}${menu.path}`"
				v-for="menu in menus"
				:key="menu.path"
			>
				<div class="d-flex align-items-center ">
					<i class="mr-3 fs-12" :class="menu.icon"></i>
					<div>
						<div>{{ menu.name }}</div>
						<div class="text-gray fw-300  small ">{{ menu.desc }}</div>
					</div>
				</div>
				<!-- <count-badge :count="count(menu.key)" /> -->
			</router-link>
		</ul>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import routeMixin from '@/mixins/routeMixin';
import pages from '@/pages';

export default {
	name: 'subMenu',
	mixins: [routeMixin],
	data() {
		return {
			collapse: false,
			menus: [],
			pageKey: '',
			page: {},
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.pageKey = this.$route.path.split('/')[1];
			this.page = pages[this.pageKey];
			this.menus = pages[this.pageKey].menus;
		});
	},
	methods: {
		navigate(menu) {
			this.collapse = false;
			this.$router.push(`/${this.pageKey}/${this.entity.guid}${menu.path}`);
		},
	},
	computed: {
		activeMenu() {
			return this.menus.find(this.isActive) || {};
		},
		isActive() {
			return r => {
				return (
					r &&
					r.name &&
					this.$route &&
					this.$route.name &&
					r.name.toLowerCase() == this.$route.name.toLowerCase()
				);
			};
		},
		count() {
			return key => {
				if (key == 'reqSkills') return this.roleReqSkillsByRoleId(this.role._id).length;
				if (key == 'skill-skilledEmployees') return this.employeeSkillsBySkillId(this.skill._id).length;
				if (key == 'skill-reqTrainings')
					return this.skillReqTrainings.filter(o => o.skillId == this.skill._id).length;

				if (key == 'employeeSkills') return this.employeeSkillsByEmployeeId(this.employee._id).length;
				return 0;
			};
		},

		entity() {
			switch (this.pageKey) {
				case 'role':
					return this.roleByGuid(this.role_guid);
				case 'skill':
					return this.skillByGuid(this.skill_guid);
				case 'employee':
					return this.employeeByGuid(this.employee_guid);
				case 'training':
					return this.trainingByGuid(this.training_guid);
				default:
					break;
			}
		},
		...mapGetters(['roles', 'roleByGuid', 'skillByGuid', 'employeeByGuid', 'trainingByGuid']),
		keyGuid() {
			return `${this.pageKey}.guid`;
		},
	},
};
</script>
