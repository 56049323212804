<template>
  <div>
    <div class="side-layout">
      <div class="page-header py-3 px-4 d-flex justify-content-between">
        <h5 class="d-flex page-title">
          <client-name-in-header />
          <slot name="title"></slot>
        </h5>
        <div>
          <slot name="btns"></slot>
        </div>
      </div>
      <div class="full-screen" v-if="isFullScreen">
        <transition-page trans="slide">
          <router-view />
        </transition-page>
      </div>
      <div class="container-fluid" v-else>
        <div class="row">
          <div class="side col-xl-3 col-lg-4 col-md-4 px-0 over-header d-print-none">
            <slot name="side" class></slot>
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8">
            <div class="card-view card-transition container shadow-sm px-0 over-header">
              <slot></slot>
              <transition-page trans="slide">
                <router-view />
              </transition-page>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "sidelayout",

  mounted() {
    console.log("this.$route", this.$route);
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    isFullScreen() {
      return this.fullscreen[this.routeName];
    },
    ...mapGetters(["fullscreen"])
  }
};
</script>
<style lang="scss" scoped>
.full-screen {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 140px;
}
</style>
