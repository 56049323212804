<template>
  <div class="alert d-flex justify-content-between no-radius mb-0 py-2" :class="status.bg">
    <h5 class="my-0">Payments</h5>
    <span>
      <i class="icon mx-2 fs-12" :class="status.icon"></i>
      {{ status.desc }}
    </span>
  </div>
</template>
<script>
import paymentMixin from "../data/paymentMixin.js";
export default {
  name: "paymentStatus",
  mixins: [paymentMixin],
  props: {
    payable: Object
  },
  computed: {
    status() {
      return this.paymentStatus(this.payable);
    }
  }
};
</script>
