<template>
  <div class="w-100" v-if="ready">
    <LastNumber field="invoice" title="Invoice" :data="lastNumber.invoice" />
    <LastNumber field="contract" title="Contract" :data="lastNumber.contract" />
    <LastNumber field="quote" title="Quote" :data="lastNumber.quote" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import clientDataMixin from "@/mixins/clientDataMixin";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import LastNumber from "./LastNumber";
import { numeric, required, between } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
  name: "lastNumbers",
  mixins: [validationMixin, dataMixin, baseMixin],
  components: { LastNumber },
  data() {
    return {
      validated: true
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      await this.loadPerClient("lastNumber");
      this.loaded();
    }
  },
  validations: {
    model: {
      dateformat: {
        required
      }
    }
  },
  computed: {
    ...mapGetters(["clientSetting", "lastNumber"])
  }
};
</script>
