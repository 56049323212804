<template>
  <card-layout class="bg-1" back :title="isnew ? `Create Invoice`  : `Edit Invoice`" :state="state">
    <div slot="header">
      <save-cancel @save="save" @cancel="cancel" v-if="hasChanges" />
    </div>
    <div class="card-body" slot="data">
      <div class>
        <div class="row">
          <div class="col-lg-9">
            <!-- company header -->
            <div class="row form-group">
              <div class="col-lg-4">
                <header-logo @change="onHeaderLogoChanged" />
              </div>
              <div class="col-lg-8">
                <address-view
                  :address="invoice.sender.address"
                  v-if="invoice.sender"
                  @change="updateClientAddress"
                />
              </div>
            </div>
            <!-- bill/ship adderss -->
            <section class="form-group">
              <label>Invoice To / Client</label>
              <div>
                <contact-selector
                  name="Contributor/lender"
                  ref="contactSelector"
                  :contact="invoice.customer"
                  placeholder="Whom do you invoice to?"
                  @change="onCustomerChanged"
                  :class="{'is-invalid': validated && !invoice.customer._id}"
                  class="form-control"
                />
                <div
                  class="invalid-feedback"
                  v-if="validated && !invoice.customer._id"
                >Select contributor/lender</div>
              </div>
            </section>
            <div class="row form-group" v-if="invoice.customer">
              <div class="col">
                <label>Billing Address</label>
                <address-view
                  class="bg-2 px-3 py-2"
                  :address="invoice.billingAddress"
                  @change="updateContactAddress"
                />
              </div>
              <div class="col">
                <label>Shipping Address</label>
                <address-view
                  class="bg-2 px-3 py-2"
                  :address="invoice.shippingAddress"
                  @change="updateContactShippingAddress"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-3">
            <InvoiceNumber :invoice="invoice" class="form-group" />
            <section class="form-group">
              <label>Invoice Date</label>
              <date-input ref="datePicker" @change="focusNext" v-model="invoice.date" />
            </section>
            <section class="form-group">
              <label>Due date</label>
              <date-input @change="focusNext" v-model="invoice.dueDate" />
            </section>
            <section>
              <label>Attention</label>
              <div>
                <input class="form-control" v-model="invoice.attention" placeholder="Attention " />
              </div>
            </section>
          </div>
        </div>

        <!-- header table -->
        <div class="form-group">
          <section>
            <label for="paymentTerms">Payment Terms</label>
            <input
              id="paymentTerms"
              class="form-control"
              v-model="invoice.terms"
              placeholder="e.g. Net 30"
            />
          </section>
        </div>

        <!-- header line items -->
        <div class="form-group">
          <InvoiceItemTable
            :invoice="invoice"
            @openMngFee="openMngFee"
            @openReimbursement="openReimbursement"
            @edit="editItem"
            :isFixedPrice="isFixedPrice"
            :isCostPlus="isCostPlus"
          />
        </div>

        <section class="form-group">
          <label>Notes</label>
          <div>
            <textarea
              class="form-control"
              v-model="invoice.note"
              rows="3"
              placeholder="Enter notes... "
            ></textarea>
          </div>
        </section>
      </div>

      <div class="row">
        <div class="col-lg-7">
          <div>
            <input class="form-control" v-model="invoice.footer" placeholder="Footer notes... " />
          </div>
        </div>
        <div class="col-lg-5">
          <entity-attachments :entityName="'invoice'" :entity="invoice" />
        </div>
      </div>
    </div>
    <div slot="footer">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
    <transition-page trans="zoom" :full-page="false">
      <MngFeeEdit
        :invoice="invoice"
        v-if="mngFeeShow"
        @cancel="cancelMngFee"
        @done="onMngFeeUpdate"
      />
    </transition-page>
    <transition-page trans="zoom" :full-page="false">
      <ReimbursementEdit
        :invoice="invoice"
        v-if="reimbursementShow"
        @cancel="cancelReimbursement"
        @done="onReimbursementUpdate"
      />
    </transition-page>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import InvoiceItemTable from "./components/InvoiceItemTable";
import MngFeeEdit from "./components/MngFeeEdit";
import { validationMixin } from "vuelidate";
import codes from "./data/itemcodes";
import newInvoice from "./data/newInvoice";
import newAddress from "@/components/views/address/newAddress";
import contactUpdateMixin from "@/views/contact/data/contactUpdateMixin";
import contractMixin from "@/views/contract/data/contractMixin";
import invoiceMixin from "./data/invoiceMixin";
import ReimbursementEdit from "./components/ReimbursementEdit";
import InvoiceNumber from "./components/InvoiceNumber";
import _ from "lodash";
import {
  required,
  numeric,
  minValue,
  maxValue
} from "vuelidate/lib/validators";
const entityName = "invoice";
const addressOrNew = address =>
  _.isEmpty(address) ? { ...newAddress() } : address;

export default {
  name: "invoiceEdit",
  mixins: [
    dataMixin,
    baseMixin,
    routeMixin,
    validationMixin,
    contactUpdateMixin,
    invoiceMixin,
    contractMixin
  ],

  components: {
    InvoiceItemTable,
    MngFeeEdit,
    ReimbursementEdit,
    InvoiceNumber
  },

  data() {
    return {
      validated: false,
      mngFeeShow: false,
      reimbursementShow: false,
      invoice: {
        attachments: []
      },
      contact: ""
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.isnew) this.newModel();
      else this.load();
    },

    async load() {
      this.loading();
      await this.loadById(entityName, this.invoice_id);
      await this.loadProjectIfNeeded();
      this.invoice = JSON.parse(JSON.stringify(this.invoice_store));
      this.checkAddresses();
      if (this.invoice.sender) this.invoice.sender.logo = this.headerLogoUrl;
      this.loaded();
    },
    async loadProjectIfNeeded() {
      if (!this.project || !this.project.contract) {
        console.log("this.project", this.project);
        await this.loadById("project", this.project_id, true);
      }
    },
    checkAddresses() {
      this.invoice.billingAddress = addressOrNew(this.invoice.billingAddress);
      this.invoice.shippingAddress = addressOrNew(this.invoice.shippingAddress);
    },

    async setNextNumber(invoice) {
      invoice.number = await this.getData(entityName, "nextNumber");
    },
    async newModel() {
      this.invoice = newInvoice(this.project);
      await this.loadContactSetAddress(this.project.customer._id);
      await this.setNextNumber(this.invoice);

      this.$forceUpdate();
      this.focusDate();
      this.loaded();
    },

    onCustomerChanged(contact) {
      this.focusNext();
      this.loadContactSetAddress(contact._id);
    },

    openMngFee() {
      this.mngFeeShow = true;
    },
    cancelMngFee() {
      this.mngFeeShow = false;
    },
    onMngFeeUpdate(item) {
      this.mngFeeShow = false;
      this.saveAndRload();
    },
    openReimbursement() {
      this.reimbursementShow = true;
    },
    cancelReimbursement() {
      this.reimbursementShow = false;
    },
    onReimbursementUpdate() {
      this.reimbursementShow = false;
      this.saveAndRload();
    },
    editItem(item) {
      if (this.isMngFee(item)) {
        this.mngFeeShow = true;
      } else if (this.isReimbItem(item)) {
        this.reimbursementShow = true;
      }
    },

    onHeaderLogoChanged(url) {
      this.invoice.sender.logo = url;
    },

    updateClientAddress(address) {
      if (!address) return;
      this.updateClient({ address }, "profile/update");
    },

    async loadContactSetAddress(contactId) {
      let contact =
        this.contact || (await this.loadById("contact", contactId, true));

      if (contact) {
        const { _id, name, address, shippingAddress } = contact;
        this.invoice.customer = {
          _id,
          name
        };
        this.invoice.billingAddress = addressOrNew(contact.address);
        this.invoice.shippingAddress = addressOrNew(contact.shippingAddress);
      }
      this.contact = contact;
    },

    updateContactAddress(address) {
      this.updateBillingAddress(this.invoice.customer._id, address);
    },

    updateContactShippingAddress(address) {
      this.updateShippingAddress(this.invoice.customer._id, address);
    },

    cancel() {
      this.$router.go(-1);
    },

    focusDate() {
      setTimeout(() => {
        let input = this.$refs.datePicker;
        if (input) {
          input.focus();
        }
      }, 300);
    },

    focusNext() {
      setTimeout(() => {
        if (!this.invoice.customer._id) {
          this.$refs.contactSelector.show();
          return;
        }
        if (this.invoice.amount == 0) {
          this.$refs.amountInput.focus();
          return;
        }
      }, 200);
    },
    async saveAndRload() {
      await this.save(true);
    },
    async save(reload = false) {
      this.validated = true;
      if (this.$v.invalid) return;
      let model = { ...this.invoice };
      try {
        this.isnew
          ? await this.add(entityName, model)
          : await this.update(entityName, model);
        if (!reload) this.$router.go(-1);
        else this.load();
      } catch (error) {
        console.log("error :", error);
      }
    }
  },

  validations: {
    invoice: {
      customer: {
        _id: { required }
      },
      date: { required }
    }
  },
  computed: {
    type() {
      return this.invoice ? this.invoice.type || {} : {};
    },
    hasChanges() {
      return (
        JSON.stringify(this.invoice) !== JSON.stringify(this.invoice_store)
      );
    },

    isnew() {
      return this.$route.params.invoice_id == "new";
    },
    project() {
      return this.projectById(this.project_id);
    },
    invoice_store() {
      return this.invoiceById(this.invoice_id);
    },
    isCostPlus() {
      return this.isCostPlusProject(this.project);
    },
    isFixedPrice() {
      return this.isFixedPriceProject(this.project);
    },
    ...mapGetters([
      "projectById",
      "invoiceById",
      "clientProfile",
      "headerLogoUrl"
    ])
  }
};
</script>
