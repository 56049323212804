export default () => {
  return {
    date: new Date(),
    amount: 0,
    note: "",
    refNo: "",
    isRefund: false,
    attachments: [],
    paidOption: "n",
    account: { id: "", name: "" },
  };
};
