<template>
  <div class="bg-1 card shadow-sm border-0">
    <div
      class="alert alert-success border-0 py-3 d-flex justify-content-between align-items-center"
    >
      <div class="fs-11">Active Subscription</div>
      <!-- <button class="btn text-primary" @click="Upgrade">
        <i class="icon-pencil mr-2"></i> Upgrade
      </button>-->
    </div>
    <div class="px-5 py-3">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label>Type</label>
            <div class="form-control value fw-600">{{title }}</div>
          </div>
          <div class="form-group">
            <label>Maximum Number of Users</label>
            <div class="form-control value fw-600">{{subscription.maxUsers}}</div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label>Started</label>
            <div class="form-control value fw-600">{{subscription.starting |day}}</div>
          </div>
          <div class="form-group">
            <label>Valid Until</label>
            <div class="form-control value fw-600">{{nexoAccount.validUntil | day}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top p-3 px-5 bg-2">
      <button class="btn text-primary" @click="Upgrade" disabled>
        <i class="icon-pencil mr-2"></i> Upgrade
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
export default {
  name: "subscription",
  mixins: [dataMixin, baseMixin],
  methods: {
    Upgrade() {}
  },
  computed: {
    subscription() {
      return this.nexoAccount.subscription || {};
    },
    title() {
      switch (this.subscription.cycle) {
        case "m":
          return "Monthly";
        case "y":
          return "Annual";
      }
    },
    ...mapGetters(["nexoAccount"])
  }
};
</script>