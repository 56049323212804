import moment from "moment";

import { downloadAsCSV } from "@/helpers/csv";

export const exportcsv = (list) => {
  let today = moment().format("MMM-D-YYYY");
  let data = getExportData(list);
  downloadAsCSV({
    filename: "Payables" + "_" + today + ".csv",
    data,
    title: "Payables,Date: " + today,
  });
};

const getExportData = (list) => {
  let taxcode1 =
    list.filter((o) => o.tax1 && o.tax1.code).map((o) => o.tax1.code)[0] ||
    "Tax1";
  let taxcode2 =
    list.filter((o) => o.tax2 && o.tax2.code).map((o) => o.tax2.code)[0] ||
    "Tax2";

  return (
    list
      //.sort(sortData('no'))
      .map((p) => {
        return {
          Type: p.type.name,
          Date: moment(p.date).format("YYYY-MM-DD"),
          Project: p.project.name.replace(/#/g, "") || "",
          Payee: p.payee.name.replace(/#/g, "") || "",
          Item: p.item.name.replace(/#/g, "") || "",
          Amount: p.amount || "",
          [taxcode1]: p.tax1.amount,
          [taxcode2]: p.tax2.amount,
          Total: p.amount + p.tax1.amount + p.tax2.amount,
          Paid_Amount: p.paidAmount || "",
          Currency: p.exchange.currency,
          Invoice_No: p.refNo.replace(/#/g, "") || "",
          Note: p.note.replace(/#/g, "") || "",
        };
      })
  );
};
