<template>
  <card-layout :state="state" title="Subscription " back nofooter>
    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-3">
          <list-group :items="tabs" v-model="selected" @change="select" class="green" />
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-6"></div>
          </div>
          <div v-if="selected==1">
            <Subscription v-if="isActiveSubscription" />
            <Subscribe :nexoAccount="nexoAccount" v-else />
          </div>
          <BillingInfo v-if="selected==2" />
          <BillingHistory v-if="selected==3" />
        </div>
      </div>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import Subscription from "./Subscription";
import Subscribe from "./Subscribe";
import BillingHistory from "./BillingHistory";
import BillingInfo from "./BillingInfo";
import Pay from "./Pay";
import moment from "moment";
export default {
  name: "billing",
  mixins: [baseMixin, dataMixin],
  components: { Subscription, Subscribe, Pay, BillingHistory, BillingInfo },

  data() {
    return {
      tabs: [
        { code: 1, name: "Subscription" },
        { code: 2, name: "Billing Address / Contact" },
        { code: 3, name: "Billing History" }
      ],
      selected: 1,
      model: {}
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    async load() {
      this.loading();
      this.loadPerClient("nexoAccount", true);
      this.model = JSON.parse(JSON.stringify(this.nexoAccount));
      this.loaded();
    },

    select(t) {
      this.selected = t.code;
    }
  },
  computed: {
    ...mapGetters(["nexoAccount"]),
    isActiveSubscription() {
      return (
        this.nexoAccount &&
        this.nexoAccount.subscription &&
        this.nexoAccount.validUntil &&
        moment().isBefore(moment(this.nexoAccount.validUntil))
        //this.nexoAccount.isActive
      );
    }
  }
};
</script>