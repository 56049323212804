import { SAVING, DELETING, ADD_TO_RECENT, SAVINGKEY } from "./types";
const SET_FULL_SCREEN = "SET_FULL_SCREEN";
const state = {
	saving: false,
	savings: [],
	deleting: false,
	recent: {
		project: "",
		contact: "",
	},
	fullscreen: {
		scheduling: false,
	},
};

const getters = {
	saving: (state) => state.saving,
	deleting: (state) => state.deleting,
	recent: (state) => state.recent,
	fullscreen: (state) => state.fullscreen,
	savingByKey: (state) => (skey) => state.savings.find((o) => o.skey == skey),
};
const actions = {};

const mutations = {
	[SAVING]: (state, value) => {
		state.saving = value;
	},
	[SAVINGKEY]: (state, { skey, saving }) => {
		console.log("saving :", saving);
		const found = state.savings.find((o) => o.skey == skey);
		if (found) {
			found.saving = saving;
		} else {
			state.savings.push({ skey, saving });
		}
	},
	[ADD_TO_RECENT]: (state, data) => {
		if (data.entityName) state.recent[lcfirst(data.entityName)] = data;
	},
	[SET_FULL_SCREEN]: (state, value) => {
		state.fullscreen = { ...state.fullscreen, ...value };
	},
};

function lcfirst(string) {
	if (!string) return "";
	return string.charAt(0).toLowerCase() + string.slice(1);
}

export default {
	getters,
	state,
	actions,
	mutations,
};
