<template>
  <button
    class="btn position-relative attach-col d-inline-block text-primary px-1"
    @click="open"
    v-if="hasAttachment"
    ref="lookupRef"
    v-b-tooltip="'Attachments'"
  >
    <i class="icon-paper-clip small text-primary"></i>
    <small class="badge badge-pill fw-300 text-2">{{ attachments.length }}</small>
    <AttachmentsView
      ref="attachmentsView"
      v-if="isOpen"
      @close="isOpen=false"
      :attachments="attachments"
    />
  </button>
</template>
<script>
import AttachmentsView from "./AttachmentsView";
export default {
  name: "attachemntIcon",
  components: { AttachmentsView },
  props: {
    attachments: Array
  },
  data() {
    return { isOpen: false };
  },
  methods: {
    open() {
      this.isOpen = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.attachmentsView.open(this.$refs.lookupRef);
        }, 100);
      });
    }
  },
  computed: {
    hasAttachment() {
      return this.attachments && this.attachments.length;
    }
  }
};
</script>
<style lang="scss" scoped>
.badge {
  position: absolute;
  top: 0;
  right: 0px;
  // background-color: #00000013;
}
.attach-col {
  /* width: 2rem; */
}
</style>
