<template>
  <center-layout>
    <div slot="title"></div>

    <div class="min-vh-100 pt-3">
      <div class="text-center d-flex flex-column bg-1 p-5 shadow-sm align-items-center">
        <div>
          <avatar :username="user.displayName" :src="user.photoURL" />
        </div>
        <h5>{{user.displayName}}</h5>
        <p>
          We sent you an email to
          <strong>{{user.email}}</strong>. Please check and verify your email.
        </p>
        <button class="btn btn-success px-5" @click="refresh">Refresh</button>
      </div>
    </div>
  </center-layout>
</template>
<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";

export default {
  mixins: [dataMixin, baseMixin],
  name: "emailVerify",

  methods: {},
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    refresh() {
      this.$router.go();
    }
  }
};
</script>