<template>
  <card-layout title="Edit Contact" :state="state" class="bg-1">
    <div slot="header">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
    <div class="card-body px-5" slot="data">
      <div class="form-group">
        <div class="form-group">
          <label for>Name</label>
          <input
            class="form-control fs-12"
            type="text"
            v-model="contact.name"
            :class="{'is-invalid': validated && $v.contact.name.$invalid }"
          />
          <div
            class="invalid-feedback"
            v-if="validated && $v.contact.name.$invalid"
          >Please enter the name</div>
        </div>

        <section class="form-group">
          <label for="type">Business Type</label>
          <BusinessTypeSelect ref="businessTypeSelect" :client="contact" :validated="validated" />
        </section>
      </div>
      <div class="row form-group">
        <div class="col-lg-2 text-2">
          <i class="icon-phone mr-2"></i> Phone
        </div>
        <div class="col-lg-10">
          <phone-input v-model="contact.phone" class="w-25" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-2 text-2">
          <i class="icon-home mr-2"></i> Website
        </div>
        <div class="col-lg-10">
          <input class="form-control" v-model="contact.website" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-2 text-2">
          <i class="icon-location-pin mr-2"></i> Address
        </div>
        <div class="col-lg-10">
          <AddressInput :address="contact.address" class="bg-2 p-3" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-lg-2 text-2">
          <i>#</i> Tax Number
        </div>
        <div class="col-lg-10">
          <input class="form-control" type="text" v-model="contact.taxNo" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-2 text-2">
          <i>#</i> WCB Number
        </div>
        <div class="col-lg-10">
          <input class="form-control" type="text" v-model="contact.wcbNo" />
        </div>
      </div>
    </div>
    <div slot="footer">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import routeMixin from "@/mixins/routeMixin";
import BusinessTypeSelect from "@/views/businessType/BusinessTypeSelect.vue";
import AddressInput from "@/components/views/address/AddressInput.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

const entityName = "contact";
import newAddress from "@/components/views/address/newAddress";

export default {
  name: "contactInfo",
  mixins: [dataMixin, baseMixin, routeMixin, validationMixin],
  components: { AddressInput, BusinessTypeSelect },

  data() {
    return {
      validated: false,
      contact: {
        address: { ...newAddress() },
        people: []
      }
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadById(entityName, this.contact_id);
      this.init();
      this.loaded();
    },

    init() {
      const conatctStore = this.contactById(this.contact_id);
      let contact = JSON.parse(JSON.stringify(conatctStore));
      if (JSON.stringify(contact.address) === JSON.stringify({})) {
        contact.address = { ...newAddress() };
      }
      this.contact = contact;
    },
    async save() {
      this.validated = true;
      if (this.$v.$invalid) return;
      let contact = { ...this.contact };
      await this.update(entityName, contact);
      this.$router.go(-1);
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  validations: {
    contact: {
      name: { required, maxLength: maxLength(50) }
    }
  },
  computed: {
    ...mapGetters(["contactById"])
  }
};
</script>
