<template>
  <div>
    <div class="alert alert-info">
      <div class="row">
        <div class="col-lg-4">
          <label for>Total Cost :</label>
          <div class="form-control bg-trans fs-12">{{totalBudget | currency}}</div>
        </div>
        <div class="col-lg-4">
          <div class="mb-1">
            <label for>Default Markup :</label>
            <percent-input v-model="budgeting.defaultMarkup" class="w-50" />
          </div>
          <div>
            <button class="btn btn-sm btn-outline-primary" @click="updateMarkup">Update</button>
          </div>
        </div>

        <div class="col-lg-4">
          <label for>Total Price :</label>
          <div class="form-control bg-trans fs-12">{{totalPrice | currency}}</div>
        </div>
      </div>
    </div>

    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Item</th>
          <th scope="col" class="w-7">Cost</th>
          <th scope="col" class="w-6 no-wrap">Markup</th>
          <th scope="col" class="w-7">Price</th>
          <th scope="col" class="w-4">Notes</th>
          <th scope="col" class="w-3"></th>
        </tr>
      </thead>
      <tbody v-for="c in selectedCategories" v-bind:key="c._id">
        <tr class="bg-1">
          <td></td>
          <td class="fs-11">{{ c.name }}</td>
          <td class="strong">{{ categoryBudget(c._id) | currency }}</td>
          <td></td>
          <td class="strong">{{ categoryPrice(c._id) | currency }}</td>
          <td colspan="2"></td>
        </tr>
        <tr v-for="b in getItemsforCategory(c._id)" v-bind:key="b._id">
          <td>{{ b.code }}</td>
          <td>{{ b.name }}</td>
          <td>
            <amount-input
              @change="costChange(b)"
              v-model="b.budget"
              placeholder="Cost"
              inputclass="font-weight-md px-0"
            />
          </td>
          <td>
            <percent-input
              v-model="b.markup"
              @change="markupChanged(b)"
              placeholder="Markup"
              :class="{'negeive-error':b.markup<0}"
              inputclass="font-weight-md px-0"
            />
          </td>
          <td>
            <amount-input
              @change="priceChanged(b)"
              v-model="b.price"
              tabindex="-1"
              placeholder="Price"
              inputclass="font-weight-md px-0"
            />
          </td>

          <td>
            <i class="icon-speech text-gray" v-if="b.note" v-tooltip="b.note"></i>
            <i class="icon-paper-clip" v-if="b.attachments.length"></i>
          </td>
          <td>
            <!-- <button @click="$emit('edit', $event.target,b)" class="btn btn-sm" tabindex="-1">
              <i class="icon-options mr-1 text-primary"></i>
            </button>-->
            <button @click="$emit('edit',b)" class="btn btn-sm" tabindex="-1">
              <i class="icon-arrow-right mr-1 text-primary"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import routeMixin from "@/mixins/routeMixin.js";
import budgetMixin from "./data/budgetMixin.js";
import budgetItemMixin from "./data/budgetItemMixin.js";
const entityName = "budgeting";
export default {
  name: "budgetTableFixedPrice",
  mixins: [budgetItemMixin, budgetMixin],
  props: {
    budgeting: Object,
    selectedCategories: Array
  },
  methods: {
    updateMarkup() {
      let m = this.budgeting.defaultMarkup;
      this.budgeting.budgetItems.map(b => {
        b.markup = m;
        this.markupChanged(b);
      });
      this.$emit("update");
    }
  },

  computed: {
    isLockedBudget() {
      return this.budgeting && this.budgeting.isLockedBudget;
    }
  }
};
</script>

<style scoped>
.table tbody tr th,
.table tbody tr td {
  padding: 0.4em;
}

.table tbody tr th {
  font-weight: 500;
}

.table tbody + tbody {
  border-top: 2px solid #bfbfbf;
}
.budget-input {
  max-width: 7rem !important;
}
.h-100- {
  min-height: calc(100vh - 18.5rem);
}
</style>
