import Vue from "vue";

//buttons
import AddButton from "./buttons/AddButton.vue";
Vue.component("add-button", AddButton);

import AButton from "./buttons/AButton.vue";
Vue.component("a-button", AButton);

import ButtonPreview from "./buttons/ButtonPreview";
Vue.component("button-preview", ButtonPreview);
//buttons
import AddInList from "./buttons/AddInList.vue";
Vue.component("add-in-list", AddInList);

import BackButton from "./buttons/BackButton.vue";
Vue.component("back-button", BackButton);

import CloseButton from "./buttons/CloseButton";
Vue.component("close-button", CloseButton);

import ExportButton from "./buttons/ExportButton.vue";
Vue.component("export-button", ExportButton);

import PrintButton from "./buttons/PrintButton.vue";
Vue.component("print-button", PrintButton);

import SwitchToggle from "./buttons/SwitchToggle.vue";
Vue.component("switch-toggle", SwitchToggle);

import ButtonEdit from "./buttons/ButtonEdit.vue";
Vue.component("button-edit", ButtonEdit);

import ButtonImport from "./buttons/ButtonImport.vue";
Vue.component("button-import", ButtonImport);

import ToggleButtons from "./buttons/ToggleButtons.vue";
Vue.component("toggle-buttons", ToggleButtons);

import Check from "./buttons/Check.vue";
Vue.component("check", Check);

import DeleteConfirm from "./buttons/DeleteConfirm.vue";
Vue.component("delete-confirm", DeleteConfirm);

//form
import SearchInput from "./form/SearchInput.vue";
Vue.component("search-input", SearchInput);

import DateInput from "./form/DateInput.vue";
Vue.component("date-input", DateInput);

import TimeInput from "./form/TimeInput.vue";
Vue.component("time-input", TimeInput);

import NumberInput from "./form/NumberInput.vue";
Vue.component("number-input", NumberInput);

import PercentInput from "./form/PercentInput.vue";
Vue.component("percent-input", PercentInput);

import FormSelect from "./form/FormSelect.vue";
Vue.component("form-select", FormSelect);

import TypeSelect from "./form/TypeSelect.vue";
Vue.component("type-select", TypeSelect);

import SaveCancel from "./form/SaveCancel.vue";
Vue.component("save-cancel", SaveCancel);

import UnSavedChanges from "./form/UnSavedChanges.vue";
Vue.component("un-saved-changes", UnSavedChanges);

import Typeahead from "./form/Typeahead.vue";
Vue.component("typeahead", Typeahead);

import AmountInput from "./form/AmountInput.vue";
Vue.component("amount-input", AmountInput);

import PhoneInput from "./form/PhoneInput.vue";
Vue.component("phone-input", PhoneInput);

import PhoneCountryInput from "./form/PhoneCountryInput.vue";
Vue.component("phone-country-input", PhoneCountryInput);

import Row from "./form/Row.vue";
Vue.component("row", Row);

import IconField from "./form/IconField.vue";
Vue.component("icon-field", IconField);

import CheckCircle from "./form/CheckCircle.vue";
Vue.component("check-circle", CheckCircle);

import PhoneNumber from "./form/PhoneNumber.vue";
Vue.component("phone-number", PhoneNumber);

import LastModified from "./form/LastModified.vue";
Vue.component("last-modified", LastModified);

import EmailLink from "./form/EmailLink.vue";
Vue.component("email-link", EmailLink);

import EntityFooter from "./form/EntityFooter.vue";
Vue.component("entity-footer", EntityFooter);

// import AddressInput from "./views/address/AddressInput.vue";
// Vue.component("address-input", AddressInput);
//views
import NoData from "./views/NoData.vue";
Vue.component("no-data", NoData);

import Spinner from "./views/Spinner.vue";
Vue.component("spinner", Spinner);

import LogoView from "./views/LogoView.vue";
Vue.component("logo-view", LogoView);

import HeaderLogo from "./views/HeaderLogo.vue";
Vue.component("header-logo", HeaderLogo);

import CountBadge from "./views/CountBadge.vue";
Vue.component("count-badge", CountBadge);

import SubMenu from "./views/SubMenu.vue";
Vue.component("sub-menu", SubMenu);

import IconAvatar from "./views/IconAvatar.vue";
Vue.component("icon-avatar", IconAvatar);

import Popover from "./views/Popover.vue";
Vue.component("popover", Popover);

import NavItem from "./views/NavItem.vue";
Vue.component("nav-item", NavItem);

import AddressView from "./views/address/AddressView.vue";
Vue.component("address-view", AddressView);

import HeaderView from "./views/HeaderView.vue";
Vue.component("header-view", HeaderView);

import ProgressBar from "./views/ProgressBar.vue";
Vue.component("progress-bar", ProgressBar);

import ButtonShare from "../views/share/ButtonShare";
Vue.component("button-share", ButtonShare);

import User from "./views/User.vue";
Vue.component("user", User);

import Tabs from "./views/Tabs.vue";
Vue.component("tabs", Tabs);

import You from "./views/You.vue";
Vue.component("you", You);

import ListGroup from "./views/ListGroup.vue";
Vue.component("list-group", ListGroup);
//layouts
import TransitionPage from "./layout/TransitionPage.vue";
Vue.component("transition-page", TransitionPage);

import BackLayer from "./layout/BackLayer.vue";
Vue.component("back-layer", BackLayer);

import SideLayout from "./layout/SideLayout.vue";
Vue.component("side-layout", SideLayout);

import ListLayout from "./layout/ListLayout.vue";
Vue.component("list-layout", ListLayout);

import CenterLayout from "./layout/CenterLayout.vue";
Vue.component("center-layout", CenterLayout);

import ContainerLayout from "./layout/ContainerLayout.vue";
Vue.component("container-layout", ContainerLayout);

import LookupLayout from "./layout/LookupLayout.vue";
Vue.component("lookup-layout", LookupLayout);

import CardLayout from "./layout/CardLayout.vue";
Vue.component("card-layout", CardLayout);

import ListPlaceHolder from "./layout/ListPlaceHolder.vue";
Vue.component("list-placeholder", ListPlaceHolder);

import InlineEdit from "./layout/InlineEdit.vue";
Vue.component("inline-edit", InlineEdit);
//from views

import ClientNameInHeader from "./layout/ClientNameInHeader";
Vue.component("client-name-in-header", ClientNameInHeader);

import ContactSelector from "../views/contact/components/ContactSelector.vue";
Vue.component("contact-selector", ContactSelector);

import ContractSelector from "../views/contract/components/ContractSelector.vue";
Vue.component("contract-selector", ContractSelector);

import UserView from "../views/client/user/UserView.vue";
Vue.component("user-view", UserView);

import ContactAvatar from "../views/contact/components/ContactAvatar.vue";
Vue.component("contact-avatar", ContactAvatar);

import ContactView from "../views/contact/components/ContactView.vue";
Vue.component("contact-view", ContactView);

import ProjectSelector from "../views/project/components/ProjectSelector.vue";
Vue.component("project-selector", ProjectSelector);

import ProjectView from "../views/project/components/ProjectView.vue";
Vue.component("project-view", ProjectView);

import ItemSelector from "../views/itemmaster/components/ItemSelector.vue";
Vue.component("item-selector", ItemSelector);

import ItemView from "../views/itemmaster/components/ItemView.vue";
Vue.component("item-view", ItemView);

import AccountSelector from "../views/account/components/AccountSelector.vue";
Vue.component("account-selector", AccountSelector);

import EntityAttachments from "../views/attachments/EntityAttachments.vue";
Vue.component("entity-attachments", EntityAttachments);

import AttachmentIcon from "../views/attachments/AttachmentIcon.vue";
Vue.component("attachment-icon", AttachmentIcon);

//icons
import Icon from "./icons/Icon.vue";
Vue.component("icon", Icon);

import SvgIcon from "./icons/SvgIcon.vue";
Vue.component("svg-icon", SvgIcon);

import FileType from "./icons/FileType";
Vue.component("file-type", FileType);
