<template>
  <div class="d-inline-block">
    <div v-if="contact">
      <span class="d-flex flex" v-if="noNavigate">
        <contact-avatar :size="30" :contact="contact" class="mr-3" />
        <div class>
          <div>{{ contact.name || 'unknown' }}</div>
          <div class="small" v-if="showdesc">{{ contact.description }}</div>
        </div>
      </span>
      <router-link :to="`/contact/${contact._id}`" class="d-flex align-items-center" v-else>
        <contact-avatar :size="30" :contact="contact" class="mr-3" />
        <div class>
          <div>{{ contact.name || 'unknown' }}</div>
          <div class="small" v-if="showdesc">{{ contact.description }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-view",
  mixins: [],
  props: {
    contact: Object,
    noNavigate: Boolean,
    showdesc: Boolean
  }
};
</script>

<style lang="scss" scoped>
.contact-avatar {
  margin-top: -0.2rem;
}
.contact-icon {
  font-size: 1.2rem;
}
</style>
