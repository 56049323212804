import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log("App is being served from cache by a service worker.");
		},
		registered(registration) {
			console.log("Service worker has been registered.");
			// console.log("Checking for updates every 10 minutes.");

			// Start check every 10 minutes for an update
			// setInterval(() => {
			// 	registration.update();
			// }, 600000);

			// Let ourselves know the serviceworker has been registerd. Used for the PWS insignia in DEVELOPMENT mode
			const evt = new CustomEvent("swRegistered");
			document.dispatchEvent(evt);
		},
		cached() {
			console.log("Content has been cached for offline use.");
		},
		updatefound() {
			console.log("New content is downloading.");
		},
		updated(registration) {
			console.log("New content is available.");
			// Fire event - listed to in App.vue for bottom popup
			caches.keys().then(function(names) {
				for (let name of names) caches.delete(name);
			});
			const evt = new CustomEvent("swUpdated", { detail: registration });

			document.dispatchEvent(evt);
		},
		offline() {
			console.log("No internet connection found. App is running in offline mode.");
		},
		error(error) {
			console.error("Error during service worker registration:", error);
		},
	});
}
