export const templateData = {
	methods: {
		async loadTemplate(force) {
			return await this.$store.dispatch("GET", {
				entityName: "template",
				force: force
			});
		},
		async updateTemplate(template) {
			return await this.$store.dispatch("UPDATE", {
				entityName: "template",
				data: template
			});
		}
	},
	computed: {
		//terms,scopeOfWorks,notes,exclusions
	}
};
