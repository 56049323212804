<template>
	<div>
		<div class="back-layer" v-if="edit"></div>
	</div>
</template>
<script>
export default {
	name: 'back-drop',
	computed: {
		edit() {
			return this.$route.path.split('/').pop() == 'edit';
		},
	},
};
</script>
<style lang="scss" scoped>
.back-layer {
	z-index: 0;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	top: 56px;
	background-color: rgba(0, 0, 0, 0.1);
}
</style>
