<template>
  <div class="row mb-2" :class="{ missing: tax && !tax.percent }">
    <div class="col-lg-2">
      <label for>{{name}}</label>
    </div>

    <div class="col-lg-10" v-if="tax">
      <inline-edit ref="inlineEdit" :invalid="$v.$invalid" @edit="edit" @save="save" :skey="skey">
        <div class="form-control value">
          <span class="tax-code fw-600">{{ tax.code }}</span>
          <span class="ml-3 fw-600">{{ tax.percent }} %</span>
        </div>

        <div slot="edit">
          <div class="row">
            <div class="col">
              <label for>Code</label>
              <input
                type="text"
                class="form-control"
                ref="firstInput"
                v-model="model.code"
                :class="{ 'is-invalid': $v.model.code.$invalid }"
                placeholder="Tax Code"
              />
              <div class="invalid-feedback">Please enter the tax code</div>
            </div>
            <div class="col">
              <label for>Percent</label>
              <input
                type="text"
                class="form-control w-75 d-inline-block"
                v-model.number="model.percent"
                placeholder="Percent"
                :class="{ 'is-invalid': $v.model.percent.$invalid }"
              /> %
              <div class="invalid-feedback">Please enter a valid amount between 0-99</div>
            </div>
          </div>
        </div>
      </inline-edit>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { numeric, required, between } from "vuelidate/lib/validators";
import clientDataMixin from "@/mixins/clientDataMixin";
export default {
  name: "taxSetup",
  mixins: [validationMixin, clientDataMixin],
  props: {
    tax: Object,
    name: String,
    field: String
  },
  data() {
    return {
      model: {},
      isEdit: false,
      validated: true,
      busy: false
    };
  },
  validations: {
    model: {
      code: {
        required
      },
      percent: {
        required,
        between: between(0, 99)
      }
    }
  },
  methods: {
    edit() {
      this.model = JSON.parse(JSON.stringify(this.tax));
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.firstInput.focus();
      });
    },
    async save() {
      this.busy = true;
      if (this.$v.$invalid) return;
      try {
        await this.updateSetting({ [this.field]: this.model }, this.skey);
        this.busy = false;
        this.$emit("update", this.model);
        this.$refs.inlineEdit.close();
      } catch (error) {
        console.log("error", error);
      }
    }
  },
  computed: {
    skey() {
      return `TaxSetup${this.field}`;
    }
  }
};
</script>
