<template>
	<side-layout>
		<span slot="title">
			<router-link to="/payables">{{ page.name }}</router-link>
			<i class="icon-arrow-right mx-2 small"></i>
			{{ payable.title }}</span
		>

		<div slot="side"></div>
	</side-layout>
</template>

<script>
import pages from '@/pages';
import routeMixin from '@/mixins/routeMixin.js';
import { mapGetters } from 'vuex';

export default {
	name: 'payableNav',
	mixins: [routeMixin],
	data() {
		return {
			page: pages.payables,
		};
	},
	computed: {
		payable() {
			return this.payableById(this.payable_id);
		},
		...mapGetters(['payableById']),
	},
};
</script>
