<template>
  <div class="p-3">
    <div class="fs-600 mb-1 px-2">Subcontracts</div>
    <div class="bg-2 p-2 rounded-10 form-group" v-if="!task.contacts.length">
      <div class="text-1 fs-11 text-center">No Subcontract</div>
    </div>
    <div class="form-group">
      <div class="list-list-group-flush green">
        <div
          class="list-group-item mb-1 py-2 px-3 rounded-10 shadow-sm list-group-item-action d-flex justify-content-between bg-success-1"
          v-for="contact in  task.contacts"
          :key="contact._id"
        >
          <div class="d-flex align-items-center">
            <contact-avatar :contact="contact" :size="32" class="mr-3" />
            <div>{{ contact.name }}</div>
          </div>
          <button class="btn text-danger" @click="remove(contact)">
            <i class="icon-trash"></i>
          </button>
        </div>
      </div>
    </div>

    <div v-if="itemContracts.length">
      <div class="px-3 text-2">Select from Contracts</div>
      <div class="list-list-group-flush green mb-1">
        <div
          class="list-group-item py-1 px-3 list-group-item-action d-flex justify-content-between border-bottom"
          v-for="c in  itemContracts"
          :key="c._id"
        >
          <div class="d-flex align-items-center">
            <contact-avatar :contact="c.contact" :size="32" class="mr-3" />
            <div>{{ c.contact.name }}</div>
          </div>
          <button class="btn text-success d-flex align-items-start" @click="add(c.contact)">
            <i class="icon-plus fs-15 mr-2"></i> Add
          </button>
        </div>
      </div>
    </div>
    <div class="border px-3 py-2 rounded-5">
      <contact-selector
        :contact="selected"
        placeholder="Select from Contacts"
        @change="add"
        class="w-100 pr-3 my-1"
      />
    </div>
  </div>
</template>
<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import { mapGetters } from "vuex";
export default {
  name: "subs",
  mixins: [baseMixin, dataMixin],
  props: {
    task: Object,
    scheduling: Object
  },
  data() {
    return {
      selected: {}
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loadByProject("contract", this.projectId);
    },
    remove(contact) {
      let i = this.task.contacts.map(o => o._id).indexOf(contact._id);
      if (i >= 0) {
        this.task.contacts.splice(i, 1);
      }
    },
    add(contact) {
      this.selected = {};
      if (this.task.contacts.find(o => o._id == contact._id)) return;
      const { _id, name } = contact;
      this.task.contacts.push({ _id, name });
    }
  },
  computed: {
    itemContracts() {
      return (
        this.contracts.filter(
          o =>
            o.project &&
            o.project._id == this.projectId &&
            o.item &&
            // &&
            // o.item._id == this.task.itemId
            o.contact &&
            !this.task.contacts.map(o => o._id).includes(o.contact._id)
        ) || []
      );
    },
    projectId() {
      return this.scheduling.projectId;
    },
    ...mapGetters(["contracts"])
  }
};
</script>