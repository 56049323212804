<template>
  <div>
    <section class="mb-1">
      <select
        id="type"
        :class="{ 'is-invalid': validatedp && !businessTypeValid }"
        class="form-control custom-select"
        v-model="client.businessTypeId"
        @change="onChange($event.target.value)"
      >
        <option value="-1" disabled selected>Select business type</option>

        <option v-for="item in list" :key="item._id" :value="item._id">{{ item.name }}</option>
      </select>
      <div class="invalid-feedback">Please select a business type</div>
    </section>

    <section class="mb-1" v-if="isOther">
      <div>
        <input
          class="form-control"
          :class="{ 'is-invalid': validatedp && !companyDescValid }"
          id="desc"
          ref="descriptionInput"
          type="text"
          placeholder="Enter your business type"
          @change="$emit('onDescChanged', $event.target.value)"
          v-model="client.description"
        />
        <div class="invalid-feedback">Please enter the company description</div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import { toTitleCase } from "@/helpers/text";
const entityName = "businessTypes";
export default {
  name: "businessTypeSelect",
  mixins: [baseMixin, dataMixin],
  props: {
    client: Object,
    validatedp: Boolean,
    required: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      placeDescription: ""
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      if (!this.list.length) await this.loadNexo(entityName);
      this.loaded();
    },
    onChange(id) {
      setTimeout(() => {
        if (this.businessType && this.businessType.code != 0) {
          this.client.description = this.businessType.name;
          this.client.businessTypeId = id;
        } else {
          if (this.$refs.descriptionInput) this.$refs.descriptionInput.select();
        }
      }, 300);
    },
    selectType(types) {
      if (types && types.includes("general_contractor")) {
        let businessType = this.list.find(o => o.id == 220);
        if (businessType) {
          this.client.description = businessType.name;
          this.client.businessTypeId = businessType._id;
        }
      } else {
        let excl = ["point_of_interest", "establishment"];
        let type = types.find(o => !excl.includes(o));
        if (type) {
          let other = this.list.find(o => o.code == 0);
          if (other) {
            this.client.businessTypeId = other._id;
            let description = toTitleCase(type);
            this.client.description = description;
            this.placeDescription = description;
          }
        } else {
          this.client.description = "";
          this.placeDescription = "";
          this.client.businessTypeId = "";
        }
      }
      this.$forceUpdate();
    }
  },
  computed: {
    businessTypeId() {
      return this.client.businessTypeId;
    },
    companyDescValid() {
      return this.client.description && this.client.description.length > 2;
    },
    businessTypeValid() {
      return this.client.businessTypeId && this.client.businessTypeId != -1;
    },
    isOther() {
      return this.businessType && this.businessType.code == 0;
    },
    ...mapGetters(["businessTypes"]),
    list() {
      return this.businessTypes.list || [];
    },
    businessType() {
      return this.list.find(o => o._id == this.client.businessTypeId) || {};
    }
  }
};
</script>
