
<template>
  <div class="mng-fee bg-1">
    <div class="bg-2 p-3 d-flex justify-content-between">
      <span class="fs-15">Management Fee Calculation for Billable Expenses</span>
      <save-cancel savetext="Update" @save="save" @cancel="cancel" />
    </div>
    <div class="p-3" v-if="this.ready">
      <div class="d-flex justify-content-between pr-3 mb-2">
        <div>
          Selected Expenses
          <span>({{selecteds.length}})</span>
          <button class="btn ml-5 text-primary" @click="selectAll">Select All</button>
        </div>

        <div>
          Selecteds Total :
          <span class="fs-11 fw-600 no-wrap">{{total | currency0}}</span>
        </div>
      </div>

      <div class="table-container">
        <spinner :busy="state.loading" v-if="state.loading" />
        <table class="table table-hover bg-white shadow-sm" v-else>
          <thead>
            <tr>
              <th scope="col" class="w-4">Select</th>
              <th scope="col" class="w-3">Type</th>
              <th scope="col" class="w-6 text-center no-wrap">Date</th>
              <th scope="col">Payee</th>
              <th scope="col">Title</th>
              <th scope="col" class="w-7">Reference #</th>
              <th scope="col" class="text-right">Total Amount</th>
              <th scope="col" class="text-right">Payment</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="p in list"
              v-bind:key="p._id"
              :class="{checked:p.checked, 'no-mng-fee':p.noMngFee}"
            >
              <td class="pr-1">
                <div class="d-flex justify-content-between align-items-center">
                  <i
                    class="icon-ban fs-14 pt-1 text-secondary"
                    v-if="p.noMngFee"
                    v-tooltip="'This expense is flagged to not be inclued in management fee'"
                  ></i>
                  <check v-model="p.checked" @change="onChange" v-else />
                  <!-- <button @click="mak"
                    class="btn ml-2 py-0 px-2 d-flex align-items-center"
                    v-tooltip="'Do not include this expense in management fee'"
                  >
                    <i class="icon-minus fs-14"></i>
                  
                  </button>-->
                </div>
              </td>
              <td>
                <PayableTypeName :type="p.type" />
              </td>
              <td class="date no-wrap">
                <router-link :to="`/payable/${p._id}`">{{p.date | dateformat}}</router-link>
              </td>

              <td>{{ p.payee && p.payee.name }}</td>
              <td>{{ p.title }}</td>
              <td>{{ p.refNo }}</td>

              <td class="text-right">{{ (p.amount + p.tax1.amount + p.tax2.amount) | currency }}</td>
              <td class="text-right">
                <PaidAmount :payable="p" class="mt-1" />
              </td>

              <td>
                <attachment-icon :attachments="p.attachments" class="py-1" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="bg-2 p-3 mt-3 border form-group">
        <section class="form-group row">
          <div class="col-lg-6">
            <label for>Item</label>
            <input
              type="text"
              v-model="item.description"
              class="form-control"
              placeholder="Management fee line text"
            />
          </div>
          <div class="col-lg-6">
            <div class="row d-flex">
              <div class="col">
                <label>Total Expenses</label>
                <div class="form-control value fw-600">{{total | currency}}</div>
              </div>
              <span class="pt-4 mt-2">X</span>

              <div class="col">
                <div>
                  <label>Mng Fee Percent</label>
                  <input
                    type="text"
                    class="form-control d-inline-block w-50"
                    v-model="percent"
                    @change="onChange"
                  /> %
                </div>
              </div>
              <span class="pt-4 mt-2">=</span>

              <div class="col">
                <label>Management Fee</label>
                <div class="form-control value fw-600">{{total*percent/100 | currency}}</div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <label>Notes</label>
          <div>
            <textarea
              class="form-control"
              v-model="item.note"
              rows="2"
              placeholder="Enter notes... "
            ></textarea>
          </div>
        </section>
      </div>

      <div class>
        <save-cancel savetext="Update" @save="save" @cancel="cancel" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import codes from "../data/itemcodes";
import invoiceMixin from "../data/invoiceMixin";
import taxMixin from "@/views/settings/data/taxMixin";
import PaidAmount from "@/views/payable/components/PaidAmount";
import PayableTypeName from "@/views/payable/components/PayableTypeName";

export default {
  name: "mngFeeEdit",
  mixins: [dataMixin, baseMixin, routeMixin, invoiceMixin, taxMixin],
  components: { PaidAmount, PayableTypeName },
  props: {
    invoice: Object
  },
  data() {
    return {
      list: [],
      percent: 0,
      item: {}
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadByProject("payable", this.project_id, true);
      await this.setPercent();
      this.populate();
      this.selectExistings();
      this.loaded();
    },
    async setPercent() {
      let project = this.projectById(this.project_id);
      if (!project || !project.contract) {
        project = await this.loadById("project", this.project_id, true);
      }
      if (project && project.contract && project.contract.contractType) {
        this.percent = project.contract.contractType.percent;
      }
    },
    populate() {
      //filter payables that not invoiced or on this invoice
      let list = this.payables.filter(
        o =>
          o.project._id == this.project_id &&
          (!o.invoiceId || o.invoiceId == this.invoice._id)
      );

      list = JSON.parse(JSON.stringify(list));
      //this needs to be here to make it observable
      list.map(o => (o.checked = false));
      this.list = list;

      if (this.mngFeeItem) {
        this.item = JSON.parse(JSON.stringify(this.mngFeeItem));
      } else {
        this.item = this.newItem();
      }
    },
    selectExistings() {
      if (!this.item.expenses) return;
      let ids = this.item.expenses.map(o => o._id);
      this.list.map(o => (o.checked = ids.includes(o._id)));
    },
    newItem() {
      return {
        description: "Management Fee",
        note: "",
        qty: 1,
        unitPrice: 0,
        tax1: this.newTax(1),
        tax2: this.newTax(2),
        code: codes.MNG_FEE,
        expenses: []
      };
    },
    selectAll() {
      if (!this.list.length) return;
      let checked = this.list[0].checked;
      this.list.filter(o => !o.noMngFee).map(o => (o.checked = !checked));
      this.onChange();
    },
    onChange() {},
    save() {
      let item = this.item;
      item.unitPrice = this.mngFee;
      //set selected items
      item.expenses = this.selecteds;

      let i = this.invoice.items.indexOf(this.mngFeeItem);
      if (i >= 0) {
        this.invoice.items.splice(i, 1);
        this.invoice.items[i] = item;
        //
      } else {
        this.invoice.items.push(item);
      }
      this.$emit("done", item);
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  computed: {
    selecteds() {
      return this.list.filter(o => o.checked) || [];
    },
    mngFee() {
      return (this.total * this.percent) / 100;
    },
    total() {
      return this.selecteds
        .map(
          o =>
            o.amount +
            (o.tax1 ? o.tax1.amount : 0) +
            (o.tax2 ? o.tax2.amount : 0)
        )

        .reduce((a, b) => a + b, 0);
    },

    ...mapGetters(["payables", "projectById"])
  }
};
</script>

<style lang="scss" scoped>
.table-container {
  max-height: 40vh;
  min-height: 20rem;
}
.mng-fee {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.table tr.no-mng-fee td {
  background-color: #e8e8e8;
  opacity: 0.5;
}
.table tr.checked td {
  background-color: #eff7ef !important;
}
</style>