<template>
  <div class="mytoast">
    <div
      class="alert alert-dismissible fade show"
      :class="{
				'alert-danger': toast.type == 'error',
				'alert-success': toast.type == 'success',
				'alert-warning': toast.type == 'warning',
			}"
      role="alert"
      v-if="visible"
    >
      <strong class="alert-heading">{{ toast.title }}</strong>
      {{ toast.message }}
      <button
        @click="hide"
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "toastMessage",
  data() {
    return {
      visible: false,
      duration: 10000
    };
  },

  watch: {
    message: {
      immediate: true,
      handler() {
        if (!this.message) {
          this.hide();
        } else {
          this.show();
          setTimeout(() => {
            this.hide();
          }, this.duration);
        }
      }
    }
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    }
  },
  computed: {
    toast() {
      return this.$store.getters.toast;
    },
    message() {
      return this.toast.message;
    }
  }
};
</script>

<style lang="scss" scoped>
.b-toast {
  z-index: 100;
  position: absolute;
  top: 0;
}
.mytoast {
  position: absolute;
  opacity: 0.95;
  margin: auto;
  z-index: 9999;
  top: 0.3rem;
  left: 0;
  right: 0;
  width: auto;
  max-width: 27rem;
  .alert {
    padding: 1rem;
  }
}
</style>
