<template>
  <div class="card form-group" v-if="!verifiedTaxCurrency">
    <div class="card-header text-muted d-flex justify-content-between">
      Verify Your Company's Tax and Currency
      <button
        v-if="validTaxCurrency"
        @click="taxVerified"
        class="btn btn-sm btn-default text-primary"
        v-tooltip="'Skip if all looks good ?'"
      >Looks Good /Skip</button>
    </div>
    <div class="card-body">
      <tax-setup :tax="clientSetting.tax1" name="Tax 1" field="tax1" class="mx-3" />
      <tax-setup :tax="clientSetting.tax2" name="Tax 2" field="tax2" class="mx-3" />
      <div class="mx-3 px-3">
        <div class="row">
          <div class="col-lg-2">
            <label for>Currency</label>
          </div>
          <div class="col-lg-10">
            <currency-setup />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CurrencySetup from "@/views/settings/CurrencySetup";
import TaxSetup from "@/views/settings/TaxSetup";
import clientDataMixin from "@/mixins/clientDataMixin";
import dataMixin from "@/mixins/dataMixin";
export default {
  name: "initialSetup",
  mixins: [dataMixin, clientDataMixin],
  components: {
    TaxSetup,
    CurrencySetup
  },
  methods: {
    taxVerified() {
      this.updateSetting({ taxVerified: true, currencyVerified: true });
    }
  },
  computed: {
    taxPercent1_missing() {
      return !this.clientSetting.tax1;
    },
    taxPercent2_missing() {
      return !this.clientSetting.tax2;
    },

    currencyCode_missing() {
      return !this.clientSetting.currencyCode;
    },

    verifiedTaxCurrency() {
      return (
        this.clientSetting.taxVerified && this.clientSetting.currencyVerified
      );
    },
    taxValid() {
      if (!this.clientSetting) return false;
      let tax = this.clientSetting.tax1;
      return tax && tax.code && tax.percent;
    },
    currencyValid() {
      if (!this.clientSetting) return false;
      return this.clientSetting.currencyCode && this.clientSetting.currencyName;
    },
    validTaxCurrency() {
      return this.taxValid && this.currencyValid;
    },
    ...mapGetters([
      "clientSetting",
      "clientProfile",
      "clientName",
      "client",
      "logoUrl",
      "cid"
    ])
  }
};
</script>
  
