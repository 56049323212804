<template>
  <div class="d-flex flex-column align-items-center">
    <div class="position-relative">
      <img class="rounded-circle img-fluid user-img" v-if="value" :src="value" alt="Avatar" />

      <div v-if="!value" class="avatar text-center p-4 rounded-circle">
        <i class="icon-user rounded-circle"></i>
      </div>
      <div class="pt-1 btn-cam rounded-circle bg-1 shadow-sm" v-if="!readonly">
        <button class="btn" @click.stop.prevent="handleFileUpload">
          <i class="icon-camera fs-15"></i>
        </button>
      </div>
    </div>
    <b-modal
      v-if="!readonly"
      ref="uploadModal"
      title="Upload Image"
      hide-footer
      @hide="showUploadModal = false"
    >
      <CropImage
        v-if="showUploadModal"
        @cancel="closeModal"
        @done="done"
        :isavatar="true"
        :round="true"
        :showCropper="true"
        :public="true"
        accessRole
      />
    </b-modal>
  </div>
</template>
<script>
import CropImage from "@/views/attachments/CropImage.vue";
// import { UPDATE_CLIENT } from '@/store/actions/auth.actions';

export default {
  name: "UserAvatar",
  components: { CropImage },
  props: {
    value: String,
    readonly: Boolean
  },
  data() {
    return {
      showUploadModal: false
    };
  },
  methods: {
    handleFileUpload(e) {
      this.showUploadModal = true;
      this.$refs.uploadModal.show();
    },
    closeModal() {
      this.$refs.uploadModal.hide();
      this.showUploadModal = false;
    },
    done(url) {
      this.$emit("input", url);
      this.$emit("change", url);
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
img.user-img {
  max-width: 9rem;
  max-height: 9rem;
}
.avatar {
  max-width: 9rem;
  max-height: 9rem;
  background-color: #ccc;
  color: #999;
  i {
    font-size: 5rem;
    color: #eff0ec;
  }
}
.btn-cam {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
