<template>
	<span class="d-flex">
		<i v-if="icon" class="icon " :class="icon" :style="iconStyle"></i>
		<svg
			v-if="!icon && svg"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			:width="size"
			:height="size"
			viewBox="0 0 50 50"
			version="1.1"
		>
			<g :fill="color" :class="{ hover: hover }">
				<slot />
				<component :is="IconComponent"></component>
			</g>
		</svg>
	</span>
</template>

<script>
export default {
	name: 'svgIcon',
	data() {
		return {
			iconStyle: {
				'font-size': `${this.size}px `,
				color: this.color,
			},
		};
	},
	props: {
		svg: {
			type: String,
			default: 'box',
		},
		icon: String,

		size: {
			type: [Number, String],
			default: 28,
		},
		color: {
			type: String,
			default: 'rgba(255, 255, 255, 0.6)',
		},
		hover: {
			type: Boolean,
			default: false,
		},
		colorHover: {
			type: String,
			default: 'rgba(255, 255, 255, 0.9)',
		},
	},
	computed: {
		IconComponent() {
			return () => import(`./${this.svg}`);
		},
	},
};
</script>
<style lang="scss" scoped>
svg {
	display: inline-block;
	vertical-align: baseline;
}
path {
	stroke: none;
	fill-rule: evenodd;
	transition: ease-in 0.2s;
}
svg:hover g.hover {
	fill: rgba(255, 255, 255, 0.9);
}
</style>
