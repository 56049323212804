<template>
  <progress-bar :percent="value*100" :variant="variant" />
</template>
<script>
export default {
  name: "budgetProgress",
  props: {
    value: Number
  },
  computed: {
    isOver() {
      return this.value > 1;
    },
    variant() {
      return this.isOver ? "danger" : "success";
    }
  }
};
</script>