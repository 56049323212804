<template>
  <b-modal ref="modal" size="lg" title="Add Workspace" no-close-on-esc hide-footer>
    <create-client ref="createClient" v-if="show" @done="onAdd" />
  </b-modal>
</template>

<script>
import data from "@/mixins/dataMixin.js";
import modal from "@/mixins/modalMixin.js";
import CreateClient from "./CreateClient";
export default {
  mixins: [modal],
  name: "addClient",
  components: { CreateClient },
  methods: {
    onAdd(profile) {
      this.$emit("done", profile);
    }
  }
};
</script>
