<template>
  <div>
    <div class="bg-1 p-3 form-group" v-if="address">
      <h5 class="mb-3 pb-2 border-bottom">Billing Address</h5>
      <address-view @change="addressChange" :address="address" showBtnText btn-text="Edit Address" />
    </div>

    <div class="bg-1 p-3">
      <h5 class="mb-3 pb-2 border-bottom">Billing Contact</h5>
      <div class="d-flex justify-content-between" v-if="contact">
        <div class="d-flex align-items-center">
          <avatar :username="contact.name" :size="42" />
          <span class="ml-2">{{contact.name}}</span>
          <you :uid="contact.uid" />
        </div>

        <button class="btn text-primary" @click="selectContact">
          <i class="icon-arrow-down fs-09 mr-1"></i>
          Change Billing Contact
        </button>
        <SelectUser ref="selectUser" @select="userSelected" :roles="['a']" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import SelectUser from "@/views/settings/user/SelectUser";
export default {
  name: "billingInfo",
  mixins: [dataMixin, baseMixin],
  components: { SelectUser },
  mounted() {
    this.load();
  },
  data() {
    return {
      address: "",
      contact: ""
    };
  },
  methods: {
    async load() {
      this.loading();
      this.loadPerClient("nexoAccount", true);
      let address = this.nexoAccount.billingAddress || {};
      let contact = this.nexoAccount.billingContact || {};
      this.address = JSON.parse(JSON.stringify(address));
      this.contact = JSON.parse(JSON.stringify(contact));

      this.loaded();
    },
    async addressChange(address) {
      try {
        await this.update("nexoAccount", { address }, "update/info");
      } catch (error) {
        console.log("error :", error);
      }
    },
    selectContact(e) {
      this.$refs.selectUser.open(e.target);
    },
    async userSelected(user) {
      try {
        const contact = {
          uid: user.ui,
          name: user.name,
          userId: user._id
        };
        await this.update("nexoAccount", { contact }, "update/info");
        this.contact = contact;
      } catch (error) {
        console.log("error :", error);
      }
    }
  },
  computed: {
    ...mapGetters(["nexoAccount", "me"])
  }
};
</script>