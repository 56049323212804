<template>
  <div :class="show?'fill':''" v-if="!hideAction">
    <button
      class="btn mx-2 text-danger"
      :class="{ disabled: !hasAccess  }"
      :disabled="!hasAccess"
      @click="showConfirm"
      v-if="!show && !isDeleting"
    >
      <i class="icon-trash mr-2"></i>
      {{ title }}
    </button>

    <div
      class="d-flex alert alert-danger confirm py-3 align-items-center justify-content-between"
      v-if="show"
    >
      <div class="w-100">
        <div>{{ confirmtext }} ?</div>
        <div class="pb-3 fs-12" v-if="name">{{name}}</div>
      </div>
      <div class="d-flex">
        <button
          @click="del"
          class="btn btn-danger mx-3 no-wrap d-inline-block"
          :class="{ disabled: !hasAccess  }"
          :disabled="!hasAccess"
        >{{title}}</button>

        <button class="btn ml-2" @click="cancel">Cancel</button>
      </div>
    </div>
    <div
      class="confirm d-flex alert alert-danger confirm py-3 align-items-center fill"
      v-if="isDeleting"
    >
      <spinner :busy="true" />Deleting...
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import data from "@/mixins/dataMixin";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "buttonDelete",
  mixins: [data, accessMixin],
  props: {
    disabled: Boolean,
    fill: { type: Boolean, default: true },
    confirmtext: {
      type: String,
      default: " Delete this Item"
    },
    title: {
      type: String,
      default: "Delete"
    },
    name: String,
    entityName: String,
    entity: Object,
    custom: Boolean
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    del() {
      this.$emit("confirm");
      return;
    },
    cancel() {
      this.show = false;
      this.$emit("show", false);
    },
    showConfirm() {
      this.show = true;
      this.$emit("show", true);
    }
  },
  computed: {
    isDeleting() {
      return (this.entity && this.entity.__status == "d") || this.deleting;
    },
    ...mapGetters(["deleting"])
  }
};
</script>

<style lang="scss" scoped>
.confirm.alert {
  margin: 0 !important;
}
.confirm.alert .btn {
  min-width: 6rem;
}

// .left {
//   position: absolute;
//   left: 1rem;
// }

.fill {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
