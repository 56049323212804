const ROUND_TO = 2;

export const round = (amount, decimals = 2) => {
	const p = Math.pow(10, decimals);
	return Math.round(amount * p) / p;
};

export const calcPercent = (amount, total) => {
	const percent = total == 0 ? 0 : (parseFloat(amount) / parseFloat(total)) * 100;
	return round(percent);
};
