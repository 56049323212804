<template>
  <div>
    <input
      v-if="!readonly"
      :class="{ 'is-invalid': error }"
      ref="numInput"
      type="text"
      class="form-control"
      @change="$emit('change', $event.target.value)"
      @keypress="isNumber($event)"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      :tabindex="tabindex"
    />

    <small class="form-text text-muted" v-if="help">{{ help }}</small>
    <small class="form-text invalid-feedback" v-if="error">{{ error }}</small>
    <span class="read-only" v-if="readonly">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "numberInput",
  props: {
    value: {
      validator: prop => prop === null || !isNaN(prop),
      default: 0,
      required: true
    },
    label: String,
    placeholder: String,
    help: String,
    error: String,
    readonly: Boolean,
    tabindex: [String, Number]
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    focus() {
      this.$refs.numInput.select();
    }
  }
};
</script>

<style></style>
