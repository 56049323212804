<template>
  <list-layout :nodata="nodata" :state="state" :page="page">
    <div slot="title">Invoices</div>
    <span slot="btns">
      <export-button @click.native="exportData" />
      <print-button class="mr-5"></print-button>
    </span>
    <span slot="top-add">
      <!-- <add-button @add="toAddInvoice">Add Invoice</add-button> -->
    </span>

    <div slot="left">
      <ReportFilters @change="change" />
      <!-- <Invoices-total :list="list" v-if="hasFilter" class="mt-3" /> -->
    </div>
    <div slot="main">
      <InvoiceTable :list="list" />
    </div>
    <no-data
      slot="nodata"
      :svg="page.svg"
      title="No Invoice"
      subtitle="You don't have any Invoices! To add an invoice go to project dashboard"
    >
      <!-- <add-button @add="toAddPayable">Add Payable</add-button> -->
    </no-data>
  </list-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import data from "@/mixins/dataMixin.js";
// import InvoicesTotal from "./components/InvoicesTotal";
import ReportFilters from "@/components/filters/ReportFilters";
import pages from "@/pages";
import InvoiceTable from "./components/InvoiceTable";
import { exportcsv } from "./data/export";
const entityName = "invoice";

export default {
  name: "Invoices",
  mixins: [baseMixin, data],
  components: {
    InvoiceTable,
    // InvoicesTotal,
    ReportFilters
  },
  data() {
    return {
      page: pages.invoices,
      list: [],
      hasFilter: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadRecent(entityName, { count: 5 });
      this.loaded();
      this.populate();
    },

    populate() {
      this.list = this.invoices;
    },
    async change(params) {
      this.hasFilter = Object.keys(params).length;
      if (!this.hasFilter) return this.populate();
      var result = await this.filterData(entityName, params, true);
      this.list = result;
      this.loaded();
    },

    toAddInvoice() {
      this.$router.push(`/Invoice/new/edit?type=0`);
    },
    exportData() {
      exportcsv(this.list);
    }
  },
  computed: {
    nodata() {
      return this.ready && !this.invoices.length;
    },
    ...mapGetters(["invoices"])
  }
};
</script>
