<template>
  <card-layout :state="state" :nodata="nodata" title="Budgeting" nofooter back>
    <div slot="header" class="d-flex">
      <save-cancel @save="save" @cancel="cancel" v-if="hasChanges" />
      <div v-if="!hasChanges   && !nodata">
        <div class="d-flex align-items-center">
          <button
            class="btn btn-success ml-3"
            @click="showSetup"
            :disabled="!hasAccess"
            v-if="!hideAction"
          >
            <i class="icon-list px-2"></i>
            {{isLockedBudget?'Add Items': 'Setup Items'}}
          </button>
          <div>
            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <i class="icon-options-vertical"></i>
                <span class="sr-only">Setting</span>
              </template>
              <b-dropdown-item @click="toBudgetSetting">Budget Setting</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body" slot="data">
      <div>
        <div class="row">
          <div class="col-md-4">
            <div class="h-100 bg-2 pb-3">
              <ItemCategorySelect
                @select="select"
                :selected-items="this.budgetItems"
                :show-all="false"
              />
            </div>
          </div>
          <div class="col-md-8">
            <BudgetTableFixedPrice
              v-if="isFixedPrice"
              :selectedCategories="selectedCategories"
              :budgeting="budgeting"
              @edit="edit"
              @change="change"
              @update="save"
            />
            <BudgetTableCostPlus
              v-if="isCostPlus"
              :budgeting="budgeting"
              :selectedCategories="selectedCategories"
              @edit="edit"
              @change="change"
              @update="save"
            />
          </div>
        </div>
        <!-- <BudgetItemEdit ref="budgetItemEdit" @update="onUpdate" :isFixedPrice="isFixedPrice" /> -->
      </div>
    </div>
    <ItemSetup
      ref="budgetItemSetup"
      :projectId="project_id"
      type="budgeting"
      @done="onSetupDone"
      :pageTitle="isLockedBudget?'Add Items': 'Setup Items'"
    />
    <CopyFromProject
      ref="copyFromProject"
      :projectId="project_id"
      type="budgeting"
      @done="onSetupDone"
    />
    <div class="text-center p-5" v-if="nodata">
      <h3 class="mb-3 text-muted text-capitalize">No Budgeting</h3>
      <p class="text-center text-muted">
        You don't have budget for this project yet
        <br />You can copy from previous projects or setup a new one
      </p>
      <div class="row" v-if="!hideAction">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <div class="mt-4">
            <button class="btn btn-success w-100" @click="showSetup">
              <i class="la la-plus"></i>
              Setup Task Breakdown
            </button>
          </div>

          <div class="mt-3">
            <button class="btn btn-outline-primary w-100" @click="showCopyfrom">
              <i class="la la-plus"></i>
              Copy from Exiting Projects
            </button>
          </div>
        </div>
      </div>
    </div>
    <un-saved-changes ref="unsavedChanges" />
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ItemCategorySelect from "@/views/itemmaster/components/ItemCategorySelect.vue";
import ItemSetup from "./ItemSetup.vue";
// import BudgetItemEdit from "./BudgetItemEdit";
import budgetMixin from "./data/budgetMixin.js";
import BudgetTableFixedPrice from "./BudgetTableFixedPrice";
import BudgetTableCostPlus from "./BudgetTableCostPlus";
import CopyFromProject from "./CopyFromProject";
const entityName = "budgeting";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "budgettig",
  components: {
    ItemCategorySelect,
    ItemSetup,
    // BudgetItemEdit,
    BudgetTableFixedPrice,
    BudgetTableCostPlus,
    CopyFromProject
  },
  mixins: [baseMixin, dataMixin, routeMixin, budgetMixin, accessMixin],
  data() {
    return {
      selectedCategories: [],
      setupModelShow: false,
      copyFromModelShow: false,
      budgeting: {}
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(force) {
      this.loading();
      if (!this.budgeting) {
        await this.loadByAction(entityName, `project/${this.project_id}`);
      }
      if (!this.itemMaster) {
        await this.loadPerClient("itemMaster");
      }
      this.init();
      this.loaded();
      this.select({ id: 0 });
      this.$forceUpdate();
    },
    init() {
      if (!this.projectBudgeting) return;
      let budgeting = JSON.parse(JSON.stringify(this.projectBudgeting));

      budgeting.budgetItems.map(b =>
        b.cost
          ? (b.markup = b.price / b.cost)
          : (b.markup = budgeting.defaultMarkup)
      );
      budgeting.budgetItems.map(o => (o.__u = false));

      this.budgeting = budgeting;
    },
    cancel() {
      this.init();
    },
    showSetup() {
      this.$refs.budgetItemSetup.open();
    },
    showCopyfrom() {
      this.$refs.copyFromProject.open();
    },
    toBudgetSetting() {
      this.$router.push(`/project/${this.project_id}/budgetSetting`);
    },

    toView() {
      this.$router.push(`/project/${this.project_id}/budgetItem`);
    },
    edit(item) {
      //this.$refs.budgetItemEdit.open(el, item);
      const route = this.budgeting.isLockedBudget ? "revise" : "edit";
      this.$router.push(
        `/project/${this.project_id}/budgeting/${this.budgeting._id}/${route}/${item._id}`
      );
    },

    onUpdate(item) {
      this.change(item);
      this.save();
    },
    change(item) {
      item.__u = this.checkChanges(item);
    },
    // revise(item) {
    //   this.$router.push(
    //     `/project/${this.project_id}/budgeting/${this.budgeting._id}/revise/${item._id}`
    //   );
    // },
    checkChanges(item) {
      const original = this.projectBudgeting.budgetItems.find(
        o => o._id == item._id
      );
      return JSON.stringify(original) != item;
    },
    async save() {
      const items = this.budgeting.budgetItems.filter(o => o.__u);
      const data = {
        items,
        other: {
          defaultMarkup: this.budgeting.defaultMarkup
        }
      };
      try {
        await this.update(
          entityName,
          data,

          `update/${this.budgeting._id}`
        );
        this.init();
      } catch (error) {
        console.log("error", error);
      }
    },

    onSetupDone() {
      this.init();
      this.select({ id: 0 });
    },
    async onTypeChangeDone() {
      try {
        await this.update(
          "budgeting",
          { other: { type: this.budgeting.type } },
          "update",
          this.budgeting._id
        );
        this.init();
      } catch (error) {}
    }
  },
  computed: {
    hasChanges() {
      return (
        this.ready &&
        this.budgeting &&
        this.budgeting.budgetItems &&
        this.budgeting.budgetItems.some(o => o.__u)
      );
    },
    nodata() {
      return this.state.loaded && !this.projectBudgeting;
    },
    projectBudgeting() {
      return this.budgetings.find(o => o.projectId == this.project_id);
    },
    all() {
      return this.projectBudgeting ? this.projectBudgeting.budgetItems : [];
    },
    isLockedBudget() {
      return this.projectBudgeting && this.projectBudgeting.isLockedBudget;
    },

    contractType() {
      return this.projectBudgeting && this.projectBudgeting.type;
    },
    isFixedPrice() {
      return this.contractType && this.contractType == "f";
    },
    isCostPlus() {
      return this.contractType && this.contractType == "c";
    },
    ...mapGetters(["categories", "items", "budgetings"])
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges) {
      this.$refs.unsavedChanges.open({ saveFunc: this.save, to, from });
      return next(false);
    } else {
      next();
    }
  }
};
</script>

<style scoped>
.table tbody tr th,
.table tbody tr td {
  padding: 0.4em;
}

.table tbody tr th {
  font-weight: 500;
}

.table tbody + tbody {
  border-top: 2px solid #bfbfbf;
}
.budget-input {
  max-width: 7rem !important;
}
.h-100- {
  min-height: calc(100vh - 18.5rem);
}
</style>
