import moment from "moment";

import { downloadAsCSV } from "@/helpers/csv";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const exportcsv = (list) => {
  let today = moment().format("MMM-D-YYYY");
  let data = getExportData(list);
  downloadAsCSV({
    filename: "Invoices" + "_" + today + ".csv",
    data: data,
    title: "Invoices,Date: " + today,
    date: today,
  });
};

const getExportData = (list) => {
  let taxcode1 =
    list.filter((o) => o.tax1 && o.tax1.code).map((o) => o.tax1.Code)[0] ||
    "Tax1";
  let taxcode2 =
    list.filter((o) => o.tax2 && o.tax2.code).map((o) => o.tax2.Code)[0] ||
    "Tax2";

  return (
    list
      //.sort(sortData('no'))
      .map((p) => {
        return {
          Number: p.number || "",
          Revision: p.rev || "",
          Project: p.project.name || "",
          Customer: p.customer.name || "",
          Date: moment(p.date).format("YYYY-MM-DD") || "", //2018-09-19T07,00,00 TODO try to foramat date befor export use map and moment function to format
          Due_Date: moment(p.dueDate).format("YYYY-MM-DD") || "",
          Subtotal: p.subTotal || "", //Thrdv
          [taxcode1]: p.tax1.amount || "", //0 TODO use first or default for heading
          [taxcode2]: p.tax2.amount || "", //0 TODO use first or default for heading
          Total: p.invoiceTotal || "", //9000
          // Currency: p.currency, //CAD
          Ref: p.refNo || "", //null
          //Note: p.note || "" //null
          Terms: p.terms || "",
          Attention: p.attention || "",
        };
      })
  );
};
