import baseMixin from "@/mixins/baseMixin";
import data from "@/mixins/dataMixin";

export default {
	mixins: [baseMixin, data],
	props: { container: [String, HTMLElement] },
	data() {
		return {
			title: "Jobs",
			list: [],
			selected: [],
			keyword: "",
			addmode: false,
			isOpen: false,
			target: null,
		};
	},

	mounted() {
		this.load();
	},

	methods: {
		async load() {
			this.loading();
			await this.loadList(this.entityName);
			this.loaded();
			this.filterAll();
		},

		search(keyword) {
			this.keyword = keyword;
			this.filterAll();
		},

		select(p) {
			if (this.isSelected(p._id)) return;
			else this.$emit("select", p);
		},

		isSelected(id) {
			if (!this.selecteds) return false;
			return this.selecteds.indexOf(id) >= 0;
		},
		cancelAdd() {
			this.addmode = false;
		},

		addNew() {
			this.addmode = true;
			this.$nextTick(() => {
				setTimeout(() => {
					if (this.$refs.newForm) {
						this.$refs.newForm.init(this.keyword);
						this.$refs.newForm.focus();
					}
				}, 100);
			});
		},
		onAdded(entity) {
			this.addmode = false;
			this.select(entity);
		},
		open(el) {
			this.isOpen = false;
			this.target = el;
			this.$nextTick(() => {
				this.isOpen = true;
			});
		},
	},
};
