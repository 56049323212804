<template>
  <transition>
    <div :class="size">
      <button
        v-if="hasAccess"
        class="btn mr-3 btn-success save-btn d-inline-block"
        type="submit"
        ref="save"
        :class="{ disabled: isSaving, 'btn-sm': small }"
        :disabled="!!isSaving"
        @click="$emit('save')"
      >
        <div class="d-flex align-items-center justify-content-around">
          <spinner :busy="isSaving" v-if="isSaving" size="1" />
          <i v-if="!isSaving && !noicon" class="icon-cloud-upload mx-1"></i>
          <span class="ml-2">{{ savetext }}</span>
          <i v-if="saveIcon2" :class="saveIcon2" class="ml-2"></i>
        </div>
      </button>
      <button v-else class="btn mr-3 btn-success save-btn d-inline-block disabled" disabled>
        <div class="d-flex align-items-center justify-content-around">
          <i class="icon-lock mr-3"></i>
          {{ savetext }}
        </div>
      </button>

      <button
        ref="cancel"
        class="btn cancel-btn d-inline-block"
        v-if="!nocancel"
        :class="{ disabled: isSaving, 'btn-sm': small }"
        :disabled="!!isSaving"
        @click="$emit('cancel')"
      >
        <i class="icon-action-undo mr-2" v-if="!noicon"></i>
        {{ canceltext }}
      </button>
    </div>
    <!-- <div class="px-3 alert alert-danger m-0 w-100 text-center" v-else>
      <i class="icon-exclamation mr-2"></i>Access denied
    </div>-->
  </transition>
</template>
<script>
import { mapGetters } from "vuex";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "saveCancel",
  mixins: [accessMixin],
  props: {
    skey: String,
    savetext: {
      type: String,
      default: "Save"
    },
    canceltext: {
      type: String,
      default: "Cancel"
    },
    noicon: {
      type: Boolean
    },
    nocancel: {
      type: Boolean,
      default: false
    },
    saveIcon2: String,
    size: {
      default: "md",
      type: String
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    focusSave() {
      this.$refs.save.focus();
    },
    focusCancel() {
      this.$refs.cancel.focus();
    }
  },
  computed: {
    ...mapGetters(["hasChanges", "saving", "savingByKey"]),

    isSaving() {
      return this.skey
        ? this.savingByKey(this.skey)
          ? this.savingByKey(this.skey).saving
          : false
        : this.saving;
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-btn {
  color: #555;
}
.save-btn {
  background-color: #72c02c;
}
.lg .cancel-btn,
.lg .save-btn,
.lg .ok-btn {
  min-width: 8rem;
}
</style>
