<template>
  <card-layout :state="state" title="Company Profile" nofooter back>
    <div slot="header" class="d-flex"></div>
    <!-- <div class="card-header content-header text-center"> 
      <button-edit class="float-right" :allow="true" title="Edit" @edit="edit" />
    </div>-->

    <div class="card-body px-5" slot="data">
      <div class="row">
        <div class="col-lg-7">
          <section class="row form-group-2 bg-1 py-2">
            <label class="col-lg-3">Name</label>
            <div class="col-lg-9">
              <inline-edit ref="nameEdit" @save="updateName" @edit="editName">
                <div>
                  <h4>{{ client.name }}</h4>
                  <div>{{ client.description }}</div>
                </div>

                <div slot="edit">
                  <div>
                    <input type="text" class="form-control fs-12 mb-1" v-model="name" />
                    <BusinessTypeSelect :client="businessType" />
                  </div>
                </div>
              </inline-edit>
            </div>
          </section>

          <section class="row form-group-2 bg-1 py-2">
            <label class="col-lg-3">Letterhead Logo</label>
            <div class="col-lg-9">
              <header-logo />
              <p class="text-2">This logo will be shown on the header of the documents like invoice</p>
            </div>
          </section>
          <section class="row form-group-2 bg-1 py-2">
            <label class="col-lg-3">Address</label>
            <div class="col-lg-9">
              <address-view :address="{...clientProfile.address}" @change="onAddressUpdated" />
            </div>
          </section>

          <section class="row form-group-2 bg-1 py-2">
            <label class="col-lg-3">Phone Number</label>
            <div class="col-lg-9">
              <inline-edit ref="phoneEdit" @save="updatePhone" @edit="editPhone">
                <phone-number v-model="clientProfile.phone" class="form-control value" />
                <div slot="edit">
                  <phone-input ref="phoneInput" type="text" class="form-control" v-model="phone" />
                </div>
              </inline-edit>
            </div>
          </section>
        </div>
      </div>
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import BusinessTypeSelect from "@/views/businessType/BusinessTypeSelect.vue";
import clientDataMixin from "@/mixins/clientDataMixin";

export default {
  name: "CompanyProfile",
  mixins: [baseMixin, dataMixin, clientDataMixin],
  components: { BusinessTypeSelect },
  mounted() {
    this.loaded();
  },
  data() {
    return {
      phone: "",
      name: "",
      businessType: {
        description: "",
        businessTypeId: ""
      }
    };
  },
  methods: {
    editPhone() {
      this.phone = this.clientProfile.phone;
    },

    async updatePhone() {
      await this.updateProfile({ phone: this.phone });
      this.$refs.phoneEdit.close();
    },
    editName() {
      this.name = this.client.name;
      this.businessType = {
        description: this.client.description,
        businessTypeId: this.client.businessTypeId
      };
    },
    async updateName() {
      await this.updateClient({
        name: this.name,
        description: this.businessType.description,
        businessTypeId: this.businessType.businessTypeId
      });
      this.$refs.nameEdit.cancel();
    },
    onAddressUpdated(address) {
      this.updateProfile({ address });
    },
    edit() {
      this.$router.replace(`/settings/clientprofile/edit`);
    }
  },
  computed: mapGetters(["logoUrl", "clientProfile", "client"])
};
</script>
