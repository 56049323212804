<template>
  <div>
    <div class="w-100">
      <div v-if="editMode" ref="editdiv" class="bg-trans-2 p-2 px-3 rounded-5 shadow-sm mb-2">
        <slot name="edit"></slot>
        <div class="d-flex justify-content-end pt-3">
          <save-cancel
            @save="$emit('save')"
            :accessRole="accessRole"
            @cancel="cancel"
            small
            v-if="!invalid"
            :skey="skey"
          />
        </div>
      </div>

      <div v-else class="d-flex justify-content-between">
        <slot></slot>
        <a @click="edit" class="ml-3 px-2 py-2" v-if="!readonly && hasAccess">
          <i class="icon-pencil"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "inlineEdit",
  mixins: [accessMixin],
  props: {
    invalid: Boolean,
    skey: String,
    readonly: Boolean
  },
  data() {
    return {
      editMode: false
    };
  },
  // watch: {
  //   busy: {
  //     immediate: true,
  //     handler() {
  //       setTimeout(() => {
  //         this.editMode = this.busy;
  //       }, 100);
  //     }
  //   }
  // },
  methods: {
    edit() {
      this.$emit("edit");
      this.editMode = true;
      setTimeout(() => {
        let el = this.$refs.editdiv.getElementsByTagName("input")[0];
        if (el) el.focus();
      }, 200);
    },

    cancel() {
      this.editMode = false;
    },
    close() {
      this.editMode = false;
    }
  }
  // computed: {
  //   ...mapGetters(["saving"])
  // }
};
</script>