<template>
  <div>
    <div class="d-flex flex-wrap py-0">
      <div v-for="c in list.slice(0,count)" :key="c._id">
        <router-link :to="`/contact/${c._id}`" class="d-flex align-items-center mb-1">
          <contact-avatar :contact="c" :size="32" />
          <div class="text-truncate text-2 ml-2 small">{{c.name}}</div>
        </router-link>
      </div>
      <avatar
        :username="more"
        :size="40"
        background-color="gray"
        color="white"
        v-if="list.length>count"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "subsSummary",
  props: {
    list: Array
  },
  data() {
    return {
      count: 4
    };
  },
  computed: {
    more() {
      return "+ " + (this.list.length - this.count); //!this.list.length  ? "" : "+ " + this.list.length - this.count;
    },
    hasData() {
      return this.list.length;
    }
  }
};
</script>
 