<template>
  <div v-if="invoice" class="row">
    <div class="col-sm-10">
      <label class="no-wrap">Invoice #</label>
      <input
        class="form-control fs-11"
        v-model="invoice.number"
        placeholder="Invoice Number "
        @change="checkNumber"
        :class="{ 'is-invalid': !isValid }"
      />
      <div class="invalid-feedback" v-if=" !isValid">This invoice number already exist</div>
    </div>
    <div class="col-sm-2">
      <spinner :busy="busy" :size="1" class="px-1 pt-4" />
    </div>
  </div>
</template> 
<script>
import dataMixin from "@/mixins/dataMixin.js";

export default {
  name: "invoiceNumber",
  props: { invoice: Object },
  mixins: [dataMixin],
  data() {
    return {
      isValid: true,
      busy: false
    };
  },
  methods: {
    async checkNumber() {
      console.log("this.invoice.number", this.invoice.number);
      this.busy = true;
      try {
        this.isValid = await this.getData(
          "invoice",
          `${this.invoice._id || "0"}/isNumberValid/${this.invoice.number}`
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>