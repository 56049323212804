<template>
  <b-popover
    v-if="isOpen"
    trigger="manual"
    offset="5"
    :show.sync="isOpen"
    ref="popover"
    custom-class="country-panel"
    placement="auto"
    :target="target"
    :auto-hide="false"
    no-fade
  >
    <template v-slot:title>
      <div class="d-flex justify-content-between align-items-center pl-1 pr-0">
        <!-- <input type="text" class="form-control" placeholder="Search Country" /> -->
        Select Country Code
        <button
          class="btn close p-2 px-1 ml-3"
          @click="cancel"
          aria-label="Close"
        >
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </button>
      </div>
    </template>
    <div class="text-left" v-if="isOpen">
      <div class="list-group list-group-flush">
        <div
          @click="onSelect(c)"
          class="list-group-item list-group-item-action py-2 c-pointer"
          v-for="c in list"
          :key="c.isoCode"
        >
          <span class="img-w d-inline-block mr-2">
            <img :src="src(c.isoCode)" class="img-flag" alt="nexobuild-flags" />
          </span>
          <span class="mr-2">+{{ c.countryCode }}</span>
          <span class="text-muted">{{ c.country }}</span>
        </div>
      </div>
    </div>
  </b-popover>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin.js";

export default {
  name: "CountrySelect",
  mixins: [dataMixin],
  props: {},
  data() {
    return {
      isOpen: false,
      target: "",
      country: "",
    };
  },
  methods: {
    open(el, country) {
      this.isOpen = false;
      this.target = el;
      setTimeout(() => {
        this.isOpen = true;
      }, 100);
    },
    cancel() {
      this.isOpen = false;
    },
    onSelect(c) {
      this.$emit("change", c.countryCode);
      this.isOpen = false;
    },
  },
  computed: {
    src() {
      return (code) =>
        `https://firebasestorage.googleapis.com/v0/b/nexo-app.appspot.com/o/public%2Fflags%2F${code}.png?alt=media&token=445109f6-a2e6-4ffd-b3cc-8a5fb28ca42a`;
    },
    list() {
      return this.countries ? this.countries.list : [];
    },
    ...mapGetters(["countries"]),
  },
};
</script>
<style lang="scss">
.country-panel {
  width: 20rem;
}
@media (max-width: 768px) {
  .country-panel {
    min-width: 100%;
  }
}

.popover-body {
  padding: 0 !important;
  background-color: rgb(247, 247, 247);
  color: #212529;
}
.popover {
  background: #f9f9f9;
  border: 1px solid rgb(224, 224, 224) !important;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss" scoped>
.list-group {
  max-height: 22rem !important;
  overflow-y: auto;
}
.confirm {
  position: absolute;
  left: 0;
  right: 0;
}
.img-w {
  width: 40px;
}
.img-flag {
  height: 16px;
}
</style>
