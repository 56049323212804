<template>
	<div>
		<div class="card-header text-center clearfix">
			<h5 class="d-inline-block">Templates</h5>
			<spinner :busy="state.loading" class="text-center  ml-3" size="1.5" />
			<back-button class="pull-left" />
			<button class="btn btn-success float-right" @click="add">
				<i class="la la-plus"></i>Add {{ type.name }} Template
			</button>
		</div>

		<div class="card-body py-3">
			<div class="row">
				<div class="col-lg-3">
					<TemplateTypes @select="onTypeSelect" />
				</div>
				<div class="col-lg-9">
					<div class="" v-if="!nodata">
						<table class="table table-hover thead-bottom-border header-md   ">
							<thead>
								<tr>
									<th scope="col" class="w-1"></th>
									<th scope="col">Name</th>
									<th scope="col">Content</th>
									<th scope="col" class="w-1"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(template, i) in list" v-bind:key="template._id">
									<td class="w-1">{{ i + 1 }}</td>
									<td>
										{{ template.name }}
									</td>
									<td>
										{{ template.text }}
									</td>
									<td class="w-1">
										<a @click="edit(template)" class="text-primary"
											><i class="icon-pencil"></i
										></a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<no-data :entity="type.name" icon="icon-grid" v-if="nodata" />

					<!-- <button class="btn text-primary  " @click="add"><i class="la la-plus"></i>Add {{ type.name }} Template</button> -->
				</div>
			</div>
		</div>

		<b-modal ref="editModal" title="Edit Template" hide-footer>
			<TemplateEdit
				:template="selectedForEdit"
				v-if="editModalVisible"
				@cancel="hideEditModal"
				@done="doneEdit"
				@hide="editModalVisible = false"
			/>
		</b-modal>
	</div>
</template>

<script>
import TemplateEdit from './TemplateEdit';
import TemplateTypes from './TemplateTypes';
import baseMixin from '@/mixins/baseMixin';
import { templateData } from './data';
import { mapGetters } from 'vuex';
export default {
	name: 'templates',
	props: {
		selecteds: Array,
	},
	components: { TemplateEdit, TemplateTypes },
	mixins: [baseMixin, templateData],
	data() {
		return {
			type: { id: 'S' },
			selected: [],
			editModalVisible: false,
			selectedForEdit: '',
		};
	},

	mounted() {
		this.load();
	},

	methods: {
		async load() {
			this.loading();
			await this.loadTemplate(true);
			this.loaded();
		},
		onTypeSelect(type) {
			this.type = type;
		},
		edit(template) {
			this.editModalVisible = false;
			this.selectedForEdit = { ...template };
			this.editModalVisible = true;
			this.$refs.editModal.show();
		},
		add() {
			this.edit({ type: this.type.id, name: `${this.type.name}  ${this.list.length + 1}` });
		},
		doneEdit() {
			console.log('done');
			this.hideEditModal();
		},
		hideEditModal() {
			this.editModalVisible = false;
			this.$refs.editModal.hide();
		},
	},

	computed: {
		...mapGetters(['template']),
		// list() {
		// 	return this.templates.filter(o => o.type == this.type._id);
		// },
		list() {
			return [];
		},
		nodata() {
			return this.state.loaded; //&& this.list.length == 0;
		},
	},
};
</script>

<style lang="css" scoped></style>
