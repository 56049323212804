import { round } from "@/utils/calc";
export default {
	methods: {
		costChange(b) {
			if (!b.markup || b.markup == 0) b.markup = this.defaultMarkup;
			b.price = round(b.budget * (1 + b.markup / 100));
			this.$emit("change", b);
			this.$forceUpdate();
		},
		priceChanged(b) {
			if (b.budget) b.markup = (b.price / b.budget - 1) * 100;
			else b.budget = round(b.price / (1 + b.markup / 100));

			this.$emit("change", b);
			this.$forceUpdate();
		},
		markupChanged(b) {
			b.price = round(b.budget * (1 + b.markup / 100));
			this.$emit("change", b);
			this.$forceUpdate();
		},
		calcMatrkup(b) {
			if (!b.budget) return 0;
			b.markup = round((parseFloat(b.price) / parseFloat(b.budget) - 1) * 100);
			return b.markup;
		},
	},
};
