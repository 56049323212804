<template>
  <inline-edit
    :invalid="$v.$invalid"
    @edit="edit"
    @save="save"
    ref="inlineEdit"
    :skey="skey"
    readonly
  >
    <!-- <span class="field-name">Currency</span> -->
    <div class="form-control value">
      <span class="tax-code fw-600">{{ clientSetting.currencyCode }}</span>
      <span class="ml-3 fw-600">{{ clientSetting.currencySymbol }}</span>
    </div>
    <div slot="edit">
      <div class="row">
        <div class="col">
          <label for>Code</label>
          <input
            type="text"
            class="form-control"
            ref="firstInput"
            v-model="model.currencyCode"
            :class="{ 'is-invalid': $v.model.currencyCode.$invalid }"
            placeholder="CurrencyCode"
          />
          <div class="invalid-feedback">Please enter the tax code</div>
        </div>
        <div class="col">
          <label for>Symbol</label>
          <input
            type="text"
            class="form-control"
            v-model.number="model.currencySymbol"
            placeholder="$"
            :class="{ 'is-invalid': $v.model.currencySymbol.$invalid }"
          />
          <div class="invalid-feedback">Please currency Symbol</div>
        </div>
      </div>
    </div>
  </inline-edit>
</template>
<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { numeric, required, between } from "vuelidate/lib/validators";
import clientDataMixin from "@/mixins/clientDataMixin";
export default {
  name: "taxSetup",
  mixins: [validationMixin, clientDataMixin],
  props: {
    name: String
  },
  data() {
    return {
      model: { currencyCode: "", currencySymbol: "" },
      validated: true,
      skey: "currencySetup"
    };
  },
  validations: {
    model: {
      currencyCode: {
        required
      },
      currencySymbol: {
        required
      }
    }
  },
  methods: {
    edit() {
      this.model = {
        currencyCode: this.clientSetting.currencyCode,
        currencySymbol: this.clientSetting.currencySymbol
      };
    },
    async save() {
      if (this.$v.$invalid) return;
      try {
        await this.updateSetting({ ...this.model }, this.skey);
        this.$refs.inlineEdit.close();
        this.$emit("done", this.model);
      } catch (error) {
        console.log("error", error);
      }
    }
  },
  computed: {
    ...mapGetters(["clientSetting"])
  }
};
</script>
