<template>
  <small class="text-0 ml-2 px-2 bg-info-1 badge-pill badge-success" v-if="me.uid== uid">you</small>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    uid: String
  },
  name: "you",
  computed: {
    ...mapGetters(["me"])
  }
};
</script>