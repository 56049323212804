<template>
  <div class="list-group list-group-flush" :class="className">
    <div
      v-for="t in items"
      :key="t.code"
      :class="{ active: value == t.code }"
      @click="$emit('change',t)"
      class="list-group-item d-flex align-items-center c-pointer border list-group-item-action rounded-5 shadow-sm mb-2"
    >
      <div class="d-flex align-items-center">{{ t.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "items",

  props: {
    items: Array,
    value: [String, Number],
    className: String
  }
};
</script>

  