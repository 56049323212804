<template>
  <div v-if="sharable && !hideAction">
    <b-checkbox v-model="value" v-if="checkbox" switch @change="checkShare">Share with Client</b-checkbox>
    <button
      v-else
      class="btn mr-3 d-flex align-items-center"
      @click="checkShare"
      ref="btn"
      :disabled="busy || !hasAccess"
      :class="{  disabled:busy || !hasAccess, 'shared fw-600':!!isShared}"
    >
      <spinner :busy="busy" :size="1" v-if="busy" class="mr-2" />
      <i class="icon-share mr-2" v-else></i>
      <span v-if="isShared" class="d-flex align-items-center">Shared</span>
      <span v-else>Share with Client</span>

      <slot></slot>
    </button>
  </div>
</template> 
<script>
import dataMixin from "@/mixins/dataMixin.js";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "buttonShare",
  mixins: [dataMixin, accessMixin],
  props: {
    entity: Object,
    checkbox: Boolean
  },
  data() {
    return { busy: false, value: false };
  },
  mounted() {
    this.value = this.entity ? this.entity.sharedCustomer : false;
  },
  methods: {
    open(options) {
      const el = this.$refs.btn;
      this.$refs.shareCustomerModal.open(el, this.entity, options);
    },
    checkShare() {
      this.toggleShare();
    },
    async toggleShare() {
      try {
        //console.log('object', object)
        this.busy = true;
        let result = await this.$store.dispatch("UPDATE", {
          entityName: this.entity.entityName,
          data: {},
          action: `toggleShare/customer/${this.entity._id}`,
          toast: false
        });
      } catch (response) {
        if (response.data && response.data == "CUSTOMER_NOT_A_USER") {
          this.open({ notUser: true });
        }
        console.log("response", response);
      } finally {
        this.busy = false;
      }
    }
  },
  computed: {
    sharable() {
      return this.entity && this.entity.entityName && this.entity._id;
    },
    isShared() {
      return this.entity.sharedCustomer;
    }
  }
};
</script> 
<style lang="scss" scoped>
.shared {
  color: green !important;
}
</style>