<template>
	<div :class="classNames">
		<input
			ref="searchinput"
			class="form-control search no-print "
			type="search"
			:placeholder="placeholder"
			v-debounce="500"
			v-bind:value="value"
			v-on:input="$emit('input', $event.target.value)"
			v-on:keyup.enter="onchange($event.target.value)"
			@change="onchange($event.target.value)"
		/>
	</div>
</template>

<script>
export default {
	props: {
		placeholder: {
			type: String,
			default: 'Search',
		},
		isfocus: Boolean,
		value: String,
		classNames: {
			type: String,
			default: 'd-block ',
		},
		loading: Boolean,
	},
	mounted() {
		if (this.isfocus) {
			setTimeout(() => {
				let input = this.$refs.searchinput;
				if (input) input.focus();
			}, 300);
		}
	},
	methods: {
		onchange(value) {
			this.$emit('change', value);
		},
	},
};
</script>
<style lang="scss" scoped>
// .search {
// 	min-width: 15rem;
// }
</style>
