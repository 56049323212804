<template>
  <div class="border bg-light p-3" autocomplete="off">
    <div class="px-3">
      <section class="form-group">
        <label>Account Name</label>
        <input
          ref="nameInput"
          :class="{ 'is-invalid': validated && !valid }"
          class="form-control"
          v-model="account.name"
          placeholder="e.g. Visa 2333"
        />
        <div class="invalid-feedback" v-if="validated && !valid">Please eneter the title</div>
      </section>
      <section class="form-group">
        <label>Description</label>
        <textarea
          class="form-control"
          v-model="account.description"
          placeholder="Account description"
        />
      </section>
    </div>
    <div class="modal-footer save-footer mt-3">
      <save-cancel @save="save" savetext="Add" @cancel="$emit('cancel')" class="float-right"></save-cancel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
const entityName = "accounts";
import { replace } from "@/utils/array.js";
export default {
  name: "accountEdit",
  mixins: [dataMixin, baseMixin],
  data() {
    return {
      validated: false,
      account: { id: 0, name: "", description: "" }
    };
  },

  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.nameInput.focus();
      });
    },
    init(name) {
      this.account.name = name;
    },
    mounted() {
      this.focus();
    },
    async save() {
      this.validated = true;
      if (!this.valid) return;
      try {
        let saved = await this.listAdd(entityName, this.account);
        let account = saved.list.find(o => o.name == this.account.name);
        this.$emit("done", account);
      } catch (error) {}
    }
  },
  computed: {
    valid() {
      return this.account.name && this.account.name.length < 129;
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
