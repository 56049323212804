import Vue from "vue";
import store from "../store";
import numeral from "numeral";

let currency = {
	cultureName: "",
	currencyCode: "",
	currencySymbol: "",
};

const getCurrency = () => {
	if (!currency.currencyCode) {
		const clientSetting = store.getters.clientSetting;
		const { cultureName, currencyCode, currencySymbol } = clientSetting;
		currency = { cultureName: cultureName, currencyCode, currencySymbol };
	}

	return currency;
};

export const toCurrency = (number) => {
	if (!number) return "0";

	let currency = getCurrency();

	return currency.currencyCode && currency.cultureName
		? new Intl.NumberFormat(currency.cultureName, {
				style: "currency",
				currency: currency.currencyCode,
				currencyDisplay: "symbol",
		  }).format(number)
		: new Intl.NumberFormat().format(number);
};
export const toCurrency0 = (number) => {
	if (!number) return "0";
	return toCurrency(number).replace(/\D00(?=\D*$)/, "");
};

Vue.filter("currency0", function(number) {
	return toCurrency0(number);
});

Vue.filter("currency", function(number) {
	return toCurrency(number);
});

// (100.123454879)
// 	.toLocaleString("en-US", {
// 		style: "currency",
// 		currency: "USD",
// 	})
// 	.split(".")[1].length;
