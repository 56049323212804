<template>
  <div>
    <div class="input-group">
      <input
        v-if="!readonly"
        :class="{ 'is-invalid': error }"
        ref="numInput"
        type="text"
        class="form-control border-right-0 px-2 text-right"
        @change="$emit('change', $event.target.value)"
        @keypress="isNumber($event)"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
      />
      <div class="input-group-append">
        <span class="input-group-text px-2 border-left-0">%</span>
      </div>

      <!-- <small class="form-text text-muted" v-if="help">{{ help }}</small>
      <small class="form-text invalid-feedback" v-if="error">{{ error }}</small>
      <span class="read-only" v-if="readonly">{{ value }}</span>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "percentInput",
  props: {
    value: {
      validator: prop =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      default: 0,
      required: true
    },
    label: String,
    placeholder: String,
    help: String,
    error: String,
    readonly: Boolean
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    focus() {
      this.$refs.numInput.select();
    }
  }
};
</script>
<style lang="scss" scoped>
.negetive {
  input {
    color: red;
  }
}
</style>