<template>
  <div class="d-inline-block entity-view h-auto">
    <a
      ref="lookupRef"
      @click="select"
      class="d-flex justify-content-between align-items-center"
      :class="{ 'action-link': !contract._id }"
      v-if="contract"
    >
      <div class="d-flex align-items-center" :class="{ 'has-value': hasValue }" v-if="hasValue">
        <contact-avatar :contact="contract.contact" class="mr-3 ml-2" :size="30" />
        <div>
          <div class="fs-11">{{ contract.contact.name }}</div>
          <div v-if="!hideItem">{{ contract.item.name }}</div>
        </div>
      </div>

      <span v-else>{{ placeholder }}</span>
      <i class="icon-arrow-down float-right align-middle mt-1 ml-2"></i>
    </a>

    <select-contract @select="changed" v-if="modalShow" :projectId="projectId" ref="modalSelect" />
  </div>
</template>

<script>
import SelectContract from "./SelectContract";
import data from "@/mixins/dataMixin.js";
export default {
  name: "ContractSelector",
  components: { SelectContract },
  mixins: [data],
  props: {
    contract: Object,
    placeholder: String,
    projectId: String,
    hideItem: Boolean,

    showicon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    select() {
      this.show();
    },
    changed(selected) {
      this.modalShow = false;
      const { item, project, contact, _id, title } = selected;

      this.contract._id = _id;
      this.contract.title = title;
      this.contract.contact = { ...contact };
      this.contract.project = { ...project };
      this.contract.item = { ...item };

      this.$emit("change", selected);
    },
    show() {
      this.modalShow = true;
      this.$nextTick(() => {
        this.$refs.modalSelect.open(this.$refs.lookupRef);
      });
    }
  },
  computed: {
    contract_store() {
      return this.contract && this.contract._id
        ? this.getContract(this.contract._id)
        : {};
    },
    hasValue() {
      return (
        (this.contract && this.contract._id) ||
        (this.contract.contact && this.contract.contact._id)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.contract-avatar {
  margin-top: -0.2rem;
}
.contract-icon {
  font-size: 1.2rem;
}
</style>
