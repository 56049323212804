<template>
  <center-layout>
    <card-layout class="bg-1 shadow-sm" :state="state" title="Feeds" nofooter>
      <div class="px-1">
        <table class="table table-hover thead-bottom-border header-md">
          <thead>
            <tr>
              <th scope="col" class="w-10">User</th>
              <th scope="col" class="w-8">When</th>
              <th scope="col" class="w-8">Change</th>
              <th scope="col" class="w-6 no-wrap">Description</th>
              <th scope="col">Detail</th>
              <th scope="col" class="w-20"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="feed in feedsSorted" :key="feed._id">
              <td class=" no-wrap">
                <user-view :user="feed.user" />
              </td>
              <td class=" no-wrap">{{feed.date |since}}</td>
              <td class=" no-wrap">{{feed.action}}</td>
              <td class=" no-wrap">{{feed.ename}}</td>
              <td>
                <div v-if="feed.project">
                  <!-- <i class="icon-folder mr-1 text-2"></i> -->
                  {{feed.project.name}}
                </div>
                <div>{{feed.title || '--'}}</div>
              </td>
              <td>
                <div class="d-flex flex-auto flex-wrap justify-content-end">
                  <file-thumb
                    class="mx-1 file-thumb"
                    :size="60"
                    :file="file"
                    v-for="file in feed.attachments"
                    :key="file._id"
                    readonly
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="text-center" v-if="this.feeds.length && hasMode">
          <button class="btn text-primary my-1" @click="loadMore">Load more</button>
        </div>
      </div>
    </card-layout>
  </center-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import FileThumb from "@/views/attachments/FileThumb";
import dataMixin from "@/mixins/dataMixin.js";
import moment from "moment";
const size = 10;
export default {
  name: "feeds",
  mixins: [dataMixin, baseMixin],
  components: { FileThumb },
  data() {
    return {
      busy: false,
      hasMode: true
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      await this.loadByAction("feed", `list?size=${size}`);
      this.loaded();
    },
    async loadMore() {
      try {
        if (this.busy) return;
        this.busy = true;
        let last = this.feedsSorted[this.feedsSorted.length - 1];
        let result = await this.loadByAction(
          "feed",
          `list?from=${last._id}&size=${size}`
        );
        if (!result.length) {
          this.hasMode = false;
        }
      } catch (error) {
        console.log("error :", error);
      } finally {
        this.busy = false;
      }
    }
  },
  computed: {
    feedsSorted() {
      return [...this.feeds].sort((a, b) =>
        moment(b.date).diff(moment(a.date))
      );
    },
    to() {
      return feed =>
        feed.project
          ? `project/${feed.project._id}/${feed.ename}/${feed.eid}`
          : `${feed.ename}/${feed.eid}`;
    },
    ...mapGetters(["feeds"])
  }
};
</script>

<style lang="scss" scoped>
.file-thumb {
  max-width: 9rem;
}
</style>