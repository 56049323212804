<template>
  <div>
    <b-modal
      ref="budgetItemSetup"
      size="lg"
      :title="formtitle"
      hide-footer
      @hide="showModal = false"
    >
      <div v-if="showModal">
        <div class="form-group" v-if="isScheduling">
          <input
            ref="titleInput"
            type="text"
            class="form-control fs-12"
            placeholder="Item"
            v-model="title"
          />
        </div>

        <div class="row">
          <div class="col-md-4">
            <search-input
              v-model="keyword"
              class="mx-2"
              @change="search"
              :placeholder="'Search item'"
            />
            <ItemCategorySelect
              ref="itemCategorySelect"
              @select="selectCategory"
              :selected-items="selectedItems"
            />
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <toggle-buttons :items="viewModes" @select="switchView" :value="viewMode" />
              <save-cancel
                savetext="Done"
                @save="save"
                @cancel="$emit('cancel')"
                :nocancel="true"
                class="float-right"
              ></save-cancel>
            </div>
            <div class="items">
              <table class="table table-hover thead-bottom-border header-md">
                <tbody v-for="c in selectedCategories" v-bind:key="c._id">
                  <tr class="category" v-if="getItemsforCategory(c._id).length > 0">
                    <th colspan="3" class="px-3">
                      <div class="d-flex align-items-center justify-content-between">
                        <span class="font-size-11 font-weight-4">{{ c.name }}</span>

                        <span class="float-right">
                          <button class="btn mr-3" @click="checkAll(c._id)">Select All</button>
                          ({{ getItemsforCategory(c._id).length }})
                        </span>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                  <tr
                    v-for="i in getItemsforCategory(c._id)"
                    v-bind:key="i._id"
                    :class="{ 'checked-bg': i.checked }"
                  >
                    <td class="select-icon">
                      <check @change="checkItem(i)" v-model="i.checked" :disabled="i.disabled" />
                    </td>
                    <th class="no">
                      <div class="c-pointer" @click="checkItem(i)">{{ i.code }}</div>
                    </th>
                    <td class="item-name">
                      <div class="c-pointer" @click="checkItem(i)">{{ i.name }}</div>
                    </td>
                    <td class="pr-3 text-right">
                      <div v-if="budgetItem(i)">
                        <span
                          class="bg-2 text-primary fw-300 badge fs-1 rounded-circle"
                          v-tooltip="'Budgeted Item'"
                        >$</span>
                        <!-- {{budgetItem(i).budget |currency0}} -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import searchItem from "@/views/itemmaster/data/search";
import ItemCategorySelect from "@/views/itemmaster/components/ItemCategorySelect.vue";

import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";

export default {
  name: "itemSetup",
  components: { ItemCategorySelect },
  props: {
    projectId: String,
    type: String,
    pageTitle: String
  },
  mixins: [baseMixin, dataMixin, routeMixin],
  data() {
    return {
      selectedCategory: 0,
      selectedCategories: [],
      title: "",
      list: [],
      all: [],
      allCategories: [],
      viewModes: [
        { text: "All", code: "a" },
        { text: "Selected", code: "s" }
      ],
      viewMode: "a",
      keyword: "",
      showModal: false,
      schedulingId: ""
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadPerClient("itemMaster");
      await this.loadBudgetingIfNot();
      this.loaded();
    },
    async loadBudgetingIfNot() {
      if (this.projectId && !this.budgeting) {
        await this.loadByAction("budgeting", `project/${this.projectId}`);
      }
    },

    checkItem(item) {
      item.checked = !item.checked;
    },
    setupScheule({ scheduling, title }) {
      this.schedulingId = scheduling ? scheduling._id : "";
      this.title = title ? title : this.scheduling.title;
      this.open();
      setTimeout(() => {
        this.init();
        this.$refs.titleInput.focus();
      }, 300);
    },
    open() {
      this.showModal = true;
      //this.budgeting = budgeting;
      this.$refs.budgetItemSetup.show();
      this.init();
    },
    close() {
      this.showModal = false;
      this.$refs.budgetItemSetup.hide();
    },

    init() {
      let items = JSON.parse(JSON.stringify(this.items));
      items.map(o => (o.checked = false));
      items.filter(this.isItemSelected).map(o => (o.checked = true));

      this.disabledItemsIfLockedBudegt(items);

      this.all = [...items];
      this.populate(this.all);
    },

    disabledItemsIfLockedBudegt(items) {
      if (this.budgeting && this.budgeting.isLockedBudget)
        items.filter(this.isItemSelected).map(o => (o.disabled = true));
    },

    isItemSelected(item) {
      const data = this.isScheduling
        ? this.scheduling
          ? this.scheduling.tasks
          : []
        : this.budgeting
        ? this.budgeting.budgetItems
        : [];

      if (!data) return false;

      return data.map(o => o.itemId).includes(item._id);
    },

    populate(items) {
      this.list = items;
      this.selectedCategories = this.categories.filter(
        c => items.map(o => o.categoryId).indexOf(c._id) >= 0
      );
    },

    selectCategory(c) {
      this.keyword = "";
      this.viewMode == "a";
      if (c._id != 0) {
        this.selectedCategories = [c];
      } else {
        this.populate(this.all);
      }
    },

    switchView(mode) {
      this.viewMode = mode;
      if (mode == "a") {
        this.populate(this.all);
      } else {
        this.populate(this.all.filter(o => o.checked));
        this.$refs.itemCategorySelect.clearSelected();
      }
    },

    search(keyword) {
      if (!keyword) {
        this.populate(this.all);
        return;
      }
      const items = searchItem(this.all, keyword);
      this.populate(items);
    },

    checkAll(categoryId) {
      let val = this.all
        .filter(o => o.categoryId == categoryId)
        .some(o => !o.checked);
      this.all
        .filter(o => o.categoryId == categoryId)
        .map(o => (o.checked = val));
    },
    async save() {
      const items = this.all.filter(o => o.checked);
      let inserted = "";
      if (this.isScheduling) {
        inserted = await this.update(
          "scheduling",
          { title: this.title, items },
          `setup/${this.projectId}/${this.schedulingId || "0"}`
        );
      } else {
        inserted = await this.update(
          "budgeting",
          { items },
          `setup/${this.projectId}`
        );
      }
      this.$emit("done", inserted);
      this.close();
    }
  },
  computed: {
    formtitle() {
      return (
        this.pageTitle ||
        (this.isScheduling ? "Setup Scheduling Tasks" : "Setup Budget")
      );
    },
    isScheduling() {
      return this.type == "scheduling";
    },
    getItemsforCategory() {
      return catId => this.list.filter(o => o.categoryId == catId) || [];
    },
    budgetItem() {
      return item =>
        this.budgeting
          ? this.budgeting.budgetItems.find(o => o.itemId == item._id)
          : null;
    },
    budgeting() {
      return this.budgetings.find(o => o.projectId == this.projectId);
    },
    scheduling() {
      return this.schedulings.find(o => o._id == this.schedulingId) || {};
    },
    selectedItems() {
      return this.all.filter(o => o.checked);
    },
    ...mapGetters([
      "categories",
      "items",
      "budgetings",
      "schedulings",
      "itemMaster"
    ])
  }
};
</script>

<style scoped>
.table tbody tr.category {
  background-color: #e9ecef;
}

.table tbody tr th,
.table tbody tr td {
  padding: 0.4em;
}
.table tbody tr th {
  font-weight: 500;
}
.table tbody + tbody {
  border-top: 2px solid #bfbfbf;
}
.table tr th.no {
  width: 3rem;
  color: #777;
}
.table tr td.select-icon {
  width: 3rem;
}
.items {
  max-height: 52rem;
  overflow-y: auto;
}
.item-name {
  color: #777;
}
.check {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.cats {
  max-height: 63rem;
  overflow-y: auto;
}
.checked-bg .no,
.checked-bg .item-name {
  color: inherit !important;
}

.checked-bg {
  background-color: #e8f5e9;
  font-weight: 500 !important;
}
.all {
  border-bottom: 3px solid #e9ecef !important;
  border-top: 3px solid #e9ecef !important;
}
</style>
