import firebaseUpload from "../helpers/firebaseUpload";
import { isImage, readImage } from "../helpers/file-helper";
import { mapGetters } from "vuex";
import { uuidv4, getFilExt } from "../helpers/file-helper";
export const fileUploadMixin = {
	data() {
		return {
			list: [],
			busy: false
			//unsavedMetaFiles: [],
		};
	},
	methods: {
		handleFileUpload(e, imageonly) {
			let files = [...e.files];
			if (imageonly) {
				files = files.filter(f => isImage(f.name));
			}
			this.handleFiles(files);
		},

		async handleFiles(files) {
			this.busy = true;
			let models = files.map(f => this.newFileModel(f));
			this.list.unshift(...models);

			for (const model of models) {
				await readImage(model.file).then(async img => {
					model.img = img;
					await firebaseUpload(model).then(url => {
						this.remove(model);
						model.url = url;
						model.progress = 100;
						model.done = true;
						this.$emit("uploaded", model);
					});
				});
			}
			this.$emit("done", this.list);
			this.busy = false;
		},

		remove(model) {
			let found = this.list.find(o => o.url == model.url);
			if (found) {
				let index = this.list.indexOf(found);
				this.list.splice(index, 1);
			}
		},
		saveAll() {
			//DO IT IN STORE
			// this.unsavedMetaFiles.forEach(model => {
			//     this.addMetaFile(model);
			// });
		},

		newFileModel(file) {
			return {
				file,
				name: file.name,
				entityName: this.entityName,
				projectId: this.projectId,
				docType: this.docType,
				url: "",
				progress: 1,
				done: false,
				error: "",
				filePath: `${this.getfolder()}/${uuidv4()}.${getFilExt(file.name)}`,
				meta: this.getMeta(),
				img: ""
			};
		},
		getfolder() {
			let p = this.projectId ? `p/${this.projectId}/` : "";
			let t = this.docType && this.docType._id ? `t/${this.docType.code}/` : "";
			return `clients/${this.client._id}/${p}${t}${this.entityName}`;
		},
		getMeta() {
			return {
				entityName: this.entityName,
				pid: this.projectId
			};
		}
	},

	computed: {
		inprogress() {
			return f => f.progress > 0 && !f.done && !f.error;
		},
		isImage() {
			return name => isImage(name);
		},
		...mapGetters([ "client"])
	}
};
