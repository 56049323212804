<template>
  <ul class="text-gray mb-0" v-if="!hasInvoice">
    <li>Client invoicing</li>
    <li>Payment tracking</li>
  </ul>
  <div class="d-flex justify-content-between mt-2 pr-4" v-else>
    <div class="text-center">
      <div class="fs-14 text-2 no-wrap">{{invoiceSummary.invoice.total | currency0}}</div>
      <div class="text-secondary small">Invoiced</div>
    </div>
    <div class="text-center">
      <div class="fs-14 text-2 no-wrap">{{invoiceSummary.income.total | currency0}}</div>
      <div class="text-secondary small">Paid</div>
    </div>
    <div class="text-center">
      <div
        class="fs-14 text-2 no-wrap"
      >{{invoiceSummary.invoice.total-invoiceSummary.income.total | currency0}}</div>
      <div class="text-secondary small">Balance</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import accessMixin from "@/mixins/accessMixin";
export default {
  name: "invoiceSummary",
  mixins: [baseMixin, dataMixin, routeMixin, accessMixin],
  methods: {
    load() {
      if (this.isUserCustomer) return;
      this.loadById("invoiceSummary", this.project_id, false,false);
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    invoiceSummary() {
      return this.invoiceSummaries.find(o => o._id == this.project_id);
    },
    hasInvoice() {
      if (!this.invoiceSummary) return false;
      return (
        this.invoiceSummary.invoice.total || this.invoiceSummary.income.total
      );
    },
    ...mapGetters(["invoiceSummaries"])
  }
};
</script>
 