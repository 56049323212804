import axios from "axios";
import config from "@/config/config";

const FIND_ADDRESS = "FIND_ADDRESS";
const GET_COUNTRY = "GET_COUNTRY";
const FIND_LOCATION = "FIND_LOCATION";
const FIND_LOCATION_ADDRESS = "FIND_LOCATION_ADDRESS";
const API_KEY = "AIzaSyD80KWR1f78qtr114msEfIBr-NbAhC4cm8";
const MAP_API = "AIzaSyDQS0R_BLWfInfRUOj3pr6xUuSEKoVgq2c";
var instance = axios.create({
	timeout: 1000,
});
let instancePlace = axios.create({
	timeout: 1000,
});
const state = {
	address: {},
	location: {},
};

const getters = {
	address: (state) => state.address,
	location: (state) => state.location,
};

export const getPhotoRefByPlaceId = async (placeId) => {
	//placeId = "ChIJNx0DNYdxhlQRqoFBUpC9B0k";
	if (!placeId) return "";
	const base = `https://maps.googleapis.com/maps/api/place/details/json`;
	const url = `${base}?placeid=${placeId}&fields=photos&key=${MAP_API}`;
	try {
		console.log("url", url);

		const resp = await instancePlace.get(url);
		console.log("result", resp);
		if (resp.result && resp.result.photos) return resp.result.photos[0].photo_reference;
		return null;
	} catch (error) {
		console.log("error1", error);
		return null;
	}
};
export const getPhotoUrl = (photo_reference) => {
	const base = `https://maps.googleapis.com/maps/api/place/photo`;
	return `${base}?maxwidth=400&photoreference=${photo_reference}&key=${API_KEY}`;
};
const actions = {
	[GET_COUNTRY]: async ({ commit, dispatch, state }) => {
		let country = "";
		if (state.address && state.address.country) {
			country = state.address.country;
		} else {
			let str = localStorage.getItem("address");
			if (str) {
				let address = JSON.parse(str);
				if (address) {
					country = address.country;
					commit("setAddress", address);
				}
			}
		}
		if (country) {
			return new Promise((resolve) => resolve(country));
		} else {
			await dispatch(FIND_LOCATION);
			let address = await dispatch(FIND_ADDRESS);
			return address ? address.country : "";
		}
	},

	[FIND_LOCATION]: async ({ commit, state }) => {
		// if (state.location) return new Promise(res => res(state.location));
		let url = `${config.googleapis.location}?key=${API_KEY}`;
		try {
			const response = await instance.post(url);
			let location = response.data.location;
			commit("setLocaton", location);

			return location;
		} catch (e) {
			console.log(e);
		}
	},
	[FIND_LOCATION_ADDRESS]: async ({ dispatch, state }) => {
		const location = await dispatch(FIND_LOCATION);
		return await dispatch(FIND_ADDRESS, location);
	},

	[FIND_ADDRESS]: async ({ commit }, location) => {
		// if (!location.lat || !location.lng) return new Promise((r, j) => j());
		let url = `${config.googleapis.address}?latlng=${location.lat},${location.lng}&key=${API_KEY}`;
		return instance.get(url).then((response) => {
			//xmlRequest("GET", url).then(response => {
			let place = response.data;
			// console.log(place);
			let c = place.results.find((o) => o.types.indexOf("country") >= 0);
			let country = c ? c.address_components[0] : "";
			let locality = place.results.find((o) => o.types.indexOf("locality") >= 0);
			let state = place.results.find((o) => o.types.indexOf("administrative_area_level_1") >= 0);
			if (Array.isArray(state.address_components)) {
				state = state.address_components[0];
			}
			// console.log(state);
			//"administrative_area_level_1", "political"
			let city = locality ? locality.address_components[0] : "";
			let address = {
				country: {
					code: country ? country.short_name : "",
					name: country ? country.long_name : "",
				},
				state: {
					code: state ? state.short_name : "",
					name: state ? state.long_name : "",
				},
				formatted_address: locality ? locality.formatted_address : "",
				city: city ? city.long_name : "",
				location: location,
			};
			commit("setAddress", address);
			return address;
		});
	},
};

const mutations = {
	setLocaton: (state, data) => {
		state.location = { ...data };
	},
	setAddress: (state, address) => {
		state.address = address;
		localStorage.setItem("address", JSON.stringify(address));
	},
};

function xmlRequest(method, url) {
	return new Promise(function(resolve, reject) {
		let xhr = new XMLHttpRequest();
		xhr.open(method, url);
		xhr.onload = function() {
			if (this.status >= 200 && this.status < 300) {
				resolve(JSON.parse(xhr.response));
			} else {
				reject({
					status: this.status,
					statusText: xhr.statusText,
				});
			}
		};
		xhr.onerror = function() {
			reject({
				status: this.status,
				statusText: xhr.statusText,
			});
		};
		xhr.send();
	});
}
export default {
	state,
	getters,
	actions,
	mutations,
};
