<template>
  <div v-if="scheduling">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col" class="w-2"></th>
          <th scope="col" class="w-18">Task Name</th>
          <th scope="col" class="w-8 nowrap">Start</th>
          <th scope="col" class="w-8 nowrap">End</th>
          <th scope="col" class="w-12">Progress</th>
          <th scope="col" class="w-5 px-1 text-center">
            Duration
            <small>days</small>
          </th>
          <th>Dependencies</th>
          <th>Subcontractors</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(task, i) in scheduling.tasks" :key="task._id">
          <td class="py-1 text-left">{{i+1}}</td>
          <td class="py-0 px-0">
            <a class="btn text-primary text-left" @click="edit(task,$event.target)">
              <div class="d-flex align-items-center justify-content-start">
                <i class="icon-pencil mr-2"></i>
                {{task.name}}
              </div>
            </a>
          </td>
          <td>{{task.start | day}}</td>
          <td>{{task.end| day}}</td>
          <td class="py-1">
            <small class="text-1">{{progreesStatus(task.progress ) }}</small>
            <progress-bar :percent="task.progress" :variant="progressVariant(task.progress)" />
          </td>
          <td>{{duration(task ) }}</td>
          <td>{{dependenciesIndexes(task)}}</td>
          <td>
            <SubsList :list="task.contacts" />
          </td>
        </tr>
      </tbody>
    </table>
    <TaskEdit ref="taskEditModal" :scheduling="scheduling" v-if="showTaskEdit" @update="onUpdate" />
  </div>
</template>
<script>
import scheduleMixin from "./data/scheduleMixin";
import TaskEdit from "./components/TaskEdit";
import SubsList from "./components/SubsList";
export default {
  name: "scheduleList",
  mixins: [scheduleMixin],
  components: { TaskEdit, SubsList },
  props: {
    scheduling: Object
  },
  data() {
    return {
      showTaskEdit: false
    };
  },
  methods: {
    edit(task, el) {
      this.showTaskEdit = true;
      this.$nextTick(() => {
        this.$refs.taskEditModal.open(el, task);
      });
    },
    onUpdate(task) {
      this.$emit("update", task);
    }
  }
};
</script>