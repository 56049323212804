<template>
  <div class="back-drop" @click="$emit('close')" v-if="isOpen">
    <b-popover
      v-if="isOpen"
      trigger="manual"
      offset="5"
      :show.sync="isOpen"
      ref="popover"
      custom-class="lookup-panel"
      placement="auto"
      :target="target"
      :auto-hide="false"
      :container="container"
      boundary-padding="80,0,0,0"
    >
      <div class="select-lookup">
        <div class="select-header">
          <button type="button" aria-label="Close" class="btn p-2 close" @click="$emit('close')">×</button>
          <slot name="search" v-if="!addmode"></slot>
        </div>
        <div class="data-view" :style="style">
          <spinner :busy="state.loading" class="bg-white d-block text-center" />
          <slot name="add" v-if="addmode"></slot>
          <slot name="list" v-show="!addmode"></slot>
        </div>
      </div>
    </b-popover>
  </div>
</template>

      

<script>
export default {
  name: "lookupLayout",
  props: {
    state: Object,
    addmode: Boolean,
    width: {
      type: Number,
      default: 24
    },

    height: {
      type: Number,
      default: 25
    },
    container: [String, HTMLElement],
    isOpen: { type: Boolean, default: false },
    target: [HTMLElement, String]
  },

  computed: {
    style() {
      let width = this.addmode ? "33rem" : `${this.width}rem`;
      let height = this.addmode ? "" : `${this.height}rem`;
      return { minWidth: width, height };
    }
  }
};
</script>

<style lang="scss" scoped>
.select-header {
  padding: 1rem 1.5rem 1rem 1rem;
  .close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
}
.select-lookup .selected {
  border-left: 2px solid green;
}
.select-lookup .data-view {
  overflow-y: auto;
  overflow-x: hidden;
}
.select-lookup {
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  // margin-bottom: 1rem;
  //   padding-bottom: 1rem;
}
</style>
<style lang="scss">
.back-drop {
  background-color: transparent;
  // opacity: 0.1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
</style>
