<template>
  <card-layout :state="state" title="User Profile" nofooter back>
    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-4">
          <user-avatar
            v-model="user.photoURL"
            :readonly="false"
            @change="onImageChange"
            class="mr-3"
          />
        </div>
        <div class="col-lg-8">
          <section class="form-group">
            <label for="fullName">Phone Number</label>
            <h4 class="form-group form-control value">
              <phone-number v-model="user.phoneNumber" :mask="mask" />
            </h4>
          </section>

          <section class="form-group">
            <label for="email">Email Address</label>
            <div class="form-control value fw-600" v-if="user.email">
              {{ user.email }}
            </div>
          </section>

          <section class="form-group">
            <label for="fullName">Your Name</label>
            <inline-edit ref="nameEdit" @save="updateName" @edit="editName">
              <div class="form-control value fs-11">{{ user.displayName }}</div>
              <div slot="edit">
                <div>
                  <input
                    class="form-control font-weight-5"
                    :class="{
                      'is-invalid': validated && $v.model.displayName.$invalid,
                    }"
                    id="fullName"
                    ref="fullNameInput"
                    type="text"
                    autocomplete="given-name"
                    placeholder="First and Last name"
                    v-model="model.displayName"
                  />
                  <div class="invalid-feedback">Please enter your name</div>
                </div>
              </div>
            </inline-edit>
          </section>
        </div>
      </div>
      <div class="alert alert-danger" v-if="error">{{ error }}</div>
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import UserAvatar from "./UserAvatar.vue";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "updateUserInfo",
  components: {
    UserAvatar,
  },
  mixins: [baseMixin, validationMixin],
  data() {
    return {
      model: {
        _id: "",
        phoneNumber: "",
        displayName: "",
        email: "",
        photoURL: "",
      },
      mask: "(+#) ###-###-####",
      validated: false,
      error: "",
    };
    saving: false;
  },
  validations: {
    model: {
      phoneNumber: {
        required,
      },
      displayName: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      let profile = await this.$store.dispatch("GET_PROFILE");
      this.model = { ...this.user };
      this.loaded();
    },
    updateName() {
      this.save();
    },
    editName() {
      this.model = { ...this.user };
    },
    onImageChange(url) {
      this.model.photoURL = url;
      this.save();
    },
    async save() {
      this.validated = true;
      this.error = "";
      if (this.$v.$invalid) return;

      try {
        this.saving = true;
        const data = { ...this.model };
        if (!data.photoURL) data.photoURL = "";
        data.oldEmail = this.user.email;
        await this.$store.dispatch("UPDATE_USER", data);
        this.$refs.nameEdit.cancel();
      } catch (e) {
        let error = e.data ? e.data.message : e;
        if (error == "TOO_SHORT") error = "Invalid Phone number!";
        this.error = error;
      } finally {
        this.saving = false;
      }
    },
  },
  computed: {
    isValid() {
      return this.emailValid && this.nameValid && this.validNumber;
    },
    ...mapGetters(["isAuthenticated", "user"]),
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 80rem;
}

.save-btn {
  width: 8rem;
}
</style>
