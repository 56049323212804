<template>
  <div class="p-3 px-4">
    <section class="form-group">
      <input class="form-control fs-11" v-model="task.name" placeholder="Task Name" />
    </section>
    <div class="row form-group">
      <div class="col">
        <label for>Start</label>
        <date-input v-model="task.start" @change="startChanged" />
      </div>
      <div class="col">
        <label for>End</label>
        <date-input v-model="task.end" @change="endChanged" />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-lg-6">
        <label for>Progress</label>

        <div class="d-flex">
          <div class="input-group w-50">
            <input
              type="text"
              v-model="task.progress"
              class="form-control text-center d-inline-block"
            />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">%</span>
            </div>
          </div>
          <div class="btn-group ml-3">
            <button class="btn border px-3" @click="decreaseProgrees">-</button>
            <button class="btn border px-3" @click="increaseProgrees">+</button>
          </div>
        </div>
        <progress-bar
          :percent="task.progress"
          class="my-2"
          :variant="progressVariant(task.progress)"
        />
      </div>
      <div class="col-lg-6">
        <label for>
          Duration
          (
          <span class="text-2 small fw-600">Days</span>)
        </label>
        <div class="d-flex">
          <button class="btn text-primary fs-15 py-0 mx-1" @click="minus()">
            <i class="icon-minus"></i>
          </button>
          <div class="text-center">
            <input
              type="text"
              @change="handleDurationChange"
              v-model="durationInput"
              class="form-control text-center"
            />
          </div>
          <button class="btn text-primary fs-15 py-0 mx-1" @click="plus()">
            <i class="icon-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <section class="form-group">
      <label for="budgetNote">Task Notes</label>
      <textarea
        id="budgetNote"
        class="form-control"
        v-model="task.note"
        placeholder="Notes for this task"
      />
    </section>
  </div>
</template>
<script>
import scheduleMixin from "../data/scheduleMixin";
import { dayDiff } from "@/helpers/date";

import moment from "moment";

export default {
  mixins: [scheduleMixin],
  name: "taskInfo",
  props: {
    scheduling: Object,
    task: Object
  },
  data() {
    return {
      durationInput: 0
    };
  },
  mounted() {
    this.updateDuration();
  },
  methods: {
    plus() {
      this.task.end = moment(this.task.end)
        .add(1, "day")
        .toDate();
    },
    minus() {
      if (moment(this.task.end).diff(moment(this.task.star)) < 1) return;
      this.task.end = moment(this.task.end)
        .add(-1, "day")
        .toDate();
    },
    handleDurationChange() {
      this.setEndDate(this.task, this.durationInput);
    },
    updateDuration() {
      this.durationInput = dayDiff(this.task.start, this.task.end);
    },
    startChanged() {
      setTimeout(() => {
        if (!this.task.start) {
          this.task.start = new Date();
        }
        this.makeSureEnd(this.task);
      }, 300);
    },
    endChanged() {
      if (!this.task.end) {
        this.task.end = new Date();
      }
      setTimeout(() => {
        this.makeSureStart(this.task);
      }, 300);
    },
    increaseProgrees() {
      this.task.progress = this.getProgrees(true);
    },
    decreaseProgrees() {
      this.task.progress = this.getProgrees(false);
    },
    getProgrees(increase) {
      const progress = this.task.progress;
      if (increase) {
        if (progress < 25) return 25;
        if (progress < 50) return 50;
        if (progress < 75) return 75;
        if (progress < 100) return 100;
        return 100;
      } else {
        if (progress >= 100) return 75;
        if (progress >= 75) return 50;
        if (progress >= 50) return 25;
        if (progress >= 25) return 0;
        return 0;
      }
    }
  },
  watch: {
    "task.start": {
      immediate: true,
      handler() {
        this.updateDuration();
      }
    },
    "task.end": {
      immediate: true,
      handler() {
        this.updateDuration();
      }
    }
  }
};
</script>