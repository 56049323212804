
<template>
  <Fragment>
    <td :class="{'fw-600':head}" v-if="column.visible" class="nowrap">
      <div v-if="column.isText">{{item[column.field]}}</div>
      <div v-if="column.isPercent">{{item[column.field] | percent}}</div>
      <div v-if="!column.isPercent && !column.isText">{{item[column.field] | currency}}</div>
    </td>
  </Fragment>
</template>

<script>
import numeral from "numeral";
import { Fragment } from "vue-fragment";
export default {
  name: "budgetTd",
  components: { Fragment },
  props: {
    column: Object,
    item: Object,
    head: Boolean
  }
};
</script>

 

 