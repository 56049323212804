export default {
	methods: {
		async add(entityName, entity) {
			return await this.$store.dispatch("ADD", { entityName, data: entity });
		},
		async loadList(entityName, force, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action: "list", force, toast });
		},
		async loadPerClient(entityName, force, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action: "one", force, toast });
		},
		async loadNexo(entityName, force, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action: "", force, toast });
		},
		async loadById(entityName, id, force, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action: `id/${id}`, force, toast });
		},
		async loadByAction(entityName, action, force, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action, force, toast });
		},
		async loadByProject(entityName, projectId, force, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action: `project/${projectId}`, force, toast });
		},
		async loadRecent(entityName, params, force, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action: "list", params, force, toast });
		},
		async update(entityName, data, action = "update", id = "", skey = "") {
			return await this.$store.dispatch("UPDATE", { entityName, data, action, id, skey });
		},
		async updateClient(data, action = "update", skey = "") {
			return await this.$store.dispatch("UPDATE_CLIENT", { data: data, action: action }, skey);
		},

		async download(entityName, action) {
			return await this.$store.dispatch("DOWNLOAD", { entityName, action: action });
		},

		async listAdd(entityName, entity, action = "list/add") {
			return await this.update(entityName, entity, action);
		},
		async listUpdate(entityName, entity, action = "list/update") {
			return await this.update(entityName, entity, action);
		},
		async listDelete(entityName, entity, action = "list/del") {
			return await this.$store.dispatch("DEL", {
				entityName,
				action,
				model: entity,
			});
		},
		async del(entityName, entity) {
			return await this.$store.dispatch("DEL", {
				entityName,
				action: "del",
				model: entity,
			});
		},
		async filterData(entityName, params, force) {
			return await this.$store.dispatch("GET", {
				entityName,
				action: "filter",
				params,
				force,
			});
		},
		async reloadProfile() {
			await this.$store.dispatch("GET_PROFILE");
		},
		async getData(entityName, action, toast = true) {
			return await this.$store.dispatch("GET", { entityName, action, force: true, toast });
		},
	},
};
