<template>
  <div>
    <div class="p-3 px-5">
      <div>
        <div class="row form-group">
          <div class="col-lg-6"></div>
          <div class="col-lg-6 d-flex justify-content-end">
            <button-edit @edit="edit" />
          </div>
        </div>
        <div class="border-bottom form-group pb-3">
          <h4>{{ contact.name }}</h4>
          <div class="fs-11">{{ contact.description }}</div>
        </div>
        <div class="row form-group">
          <label class="col-lg-2 text-2">
            <i class="icon-phone mr-2"></i> Phone
          </label>
          <div class="col-lg-7">
            <div class="form-control value">
              <a v-if="contact.phone" :href="`tel:+${contact.phone}`">
                <phone-number v-model="contact.phone" />
              </a>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-lg-2 text-2">
            <i class="icon-location-pin mr-2"></i> Address
          </label>
          <div class="col-lg-7">
            <div v-if="contact.address && contact.address.text">
              <address class="m-0">{{contact.address.text }}</address>
              <a target="_blank" :href="mapUrl" class="small">
                <i class="icon-map mr-1"></i>
                Show in map
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-lg-2 text-2">
            <i class="icon-home mr-2"></i> Website
          </label>
          <div class="col-lg-7">
            <a :href="contact.website" target="_blank" rel="noopener noreferrer">{{contact.website}}</a>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for>Tax Number</label>
              <div class="form-control value">{{contact.taxNo}}</div>
            </div>
            <div>
              <label for>WCB Number</label>
              <div class="form-control value">{{contact.wcbNo}}</div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="col-lg-2 text-2">
          <label for>
            <i class="icon-people mr-2"></i>Persons
          </label>
        </div>
        <div class="col-lg-6">
          <People :contact="contact" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import routeMixin from "@/mixins/routeMixin";
import People from "./components/People";
import PersonEdit from "./components/PersonEdit";
const entityName = "contact";

export default {
  name: "contactInfo",
  mixins: [dataMixin, baseMixin, routeMixin],
  components: { People, PersonEdit },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadById(entityName, this.contact_id, true);
      this.loaded();
    },
    edit() {
      this.$router.push(`/contact/${this.contact_id}/edit`);
    }
  },
  computed: {
    contact() {
      return this.contactById(this.contact_id);
    },
    ...mapGetters(["contactById"]),
    mapUrl() {
      return this.contact.address
        ? `https://www.google.com/maps/?q=${this.contact.address.name},${this.contact.address.text}`
        : "";
    }
  }
};
</script>
