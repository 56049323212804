import itemcodes from "./itemcodes";
export default {
	methods: {
		isMngFee(item) {
			return item.code == itemcodes.MNG_FEE;
		},
		isReimbItem(item) {
			return item.code == itemcodes.REIMBURSEMENT;
		}
	},
	computed: {
		subTotal() {
			return this.invoice.items ? this.invoice.items.map(o => o.qty * o.unitPrice).reduce((a, b) => a + b, 0) : 0;
		},
		totalTax() {
			return this.invoice.items
				? this.invoice.items
						.map(o => (o.tax1 ? o.tax1.amount : 0) + (o.tax2 ? o.tax2.amount : 0))
						.reduce((a, b) => a + b, 0)
				: 0;
		},
		invoiceTotal() {
			return this.subTotal + this.totalTax;
		},
		balanceDue() {
			return this.invoiceTotal + this.invoice.prevBalance;
		},
		mngFeeItem() {
			return this.invoice.items.find(o => o.code == itemcodes.MNG_FEE);
		},
		reimbItem() {
			return this.invoice.items.find(o => o.code == itemcodes.REIMBURSEMENT);
		},
		isCustom() {
			return item => this.isMngFee(item) || this.isReimbItem(item);
		}
	}
};
