<template>
  <div>
    <label for="card-element">Pay by Credit or Debit Card</label>
    <card
      class="stripe-card form-group bg-1 rounded-5 p-3 border shadow-sm"
      :class="{ complete }"
      :stripe="apikey"
      :options="stripeOptions"
      @change="complete = $event.complete"
    />

    <button
      class="btn btn-success btn-block fs-11 shadow"
      :class="{ disabled:busy}"
      @click="pay"
      :disabled="!complete || busy"
    >
      <spinner :busy="busy" :size="1.5" />
      Pay ${{subscription.amount}}
    </button>
  </div>
</template>

    


<script>
import { Card, createToken } from "vue-stripe-elements-plus";
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
export default {
  name: "stribePay",
  mixins: [dataMixin, baseMixin],
  props: { subscription: Object },
  components: { Card },
  data() {
    return {
      complete: false,
      busy: false,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      apikey: process.env.VUE_APP_STRIPE_API_KEY
    };
  },

  methods: {
    async pay() {
      try {
        const tokenData = await createToken();
        let model = {
          token: tokenData.token,
          ...this.subscription
        };

        this.busy = true;
        await this.update("nexoAccount", model, "pay");
      } catch (error) {
        console.log("error :", error);
      } finally {
        this.busy = false;
      }
      //createToken().then(data => console.log(data.token));
      //error There was an issue with your payment. Please make sure the credit card you provided is correct.!
    }
  }
};
</script>

 
<style>
.stripe-card {
}
.stripe-card.complete {
  border-color: green;
}
</style> 