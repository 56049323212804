<template>
  <div>
    <img :src="src" alt />
  </div>
</template>
<script>
import { getPhotoUrl } from "@/store/google";

export default {
  name: "contactImage",
  props: {
    contact: Object
  },
  computed: {
    hasImage() {
      return (
        this.contact &&
        this.contact.gplace &&
        this.contact.gplace.photo_reference
      );
    },
    src() {
      return this.hasImage
        ? getPhotoUrl(this.contact.gplace.photo_reference)
        : "";
    }
  }
};
</script>