<template>
  <div class="d-inline-block">
    <div v-if="isCompany" class="text-center">
      <avatar
        :username="contact.name"
        :src="contact.logoUrl"
        v-if="contact.name && contact.logoUrl"
      />
      <svg-icon name="contact" :color="color" :size="size" />
    </div>
    <div v-else>
      <avatar :username="contact.name" :src="person.imageUrl" :size="size" v-if="contact.name" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "contactAvatar",
  props: {
    contact: Object,
    size: {
      type: Number,
      default: 40
    },
    color: {
      type: String,
      default: "#5c6bc0"
    }
  },

  computed: {
    isCompany() {
      return false;
    },
    company() {
      return this.contact.company || {};
    },
    person() {
      return this.contact.person || {};
    }
    // contact__() {
    // 	return this.contactById(this.contact._id);
    // },
    // ...mapGetters(['contactById']),
  }
};
</script>
