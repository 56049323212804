<template>
  <div class="px-3 pt-3">
    <h4 class="form-group px-2">
      Activities
      <spinner :busy="state.loading" :size="1.5" />
    </h4>
    <no-data
      custom
      :page="{icon:'icon-feed', name:'activity found!'}"
      v-if="nodata"
      class="p-5 m-5"
    />

    <WhereUsedLayout title="Subcontacts:" :data="list.contacts">
      <div class="list-group list-group-flush">
        <div
          class="list-group-item bg-1 d-flex justify-content-between"
          v-for="contact in list.contacts"
          :key="contact._id"
        >
          <div class="d-flex align-items-center">
            <svg-icon name="folder" class="mr-3" />
            <div class>
              <div class>{{contact.project.name}}</div>
              <div class="text-2">{{contact.item.name}}</div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="text-2">
              <div class="text-right small">{{contact.date | dateformat}}</div>
              <div class="text-right">{{contact.amount | currency}}</div>
            </div>
            <!-- <a class="ml-3 btn-link">
              <i class="icon icon-arrow-right"></i>
            </a>-->
          </div>
        </div>
      </div>
    </WhereUsedLayout>

    <WhereUsedLayout title="Payment" :data="list.payables">
      <div class="list-group list-group-flush">
        <div
          class="list-group-item bg-1 d-flex justify-content-between"
          v-for="payable in list.payables"
          :key="payable._id"
        >
          <div class="d-flex align-items-center">
            <svg-icon name="folder" class="mr-3" />
            <div class>
              <div class>{{payable.project.name}}</div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="text-2">
              <div class="text-right" v-tooltip="'Total payments'">{{payable.paidAmount | currency}}</div>
            </div>
            <!-- <a class="ml-3 btn-link">
              <i class="icon icon-arrow-right"></i>
            </a>-->
          </div>
        </div>
      </div>
    </WhereUsedLayout>

    <WhereUsedLayout title="Client of the Projects" :data="list.projects">
      <div class="list-group list-group-flush">
        <div
          class="list-group-item bg-1 d-flex justify-content-between"
          v-for="project in list.projects"
          :key="project._id"
        >
          <div class="d-flex align-items-center">
            <svg-icon name="folder" class="mr-3" />
            <div class>
              <div class>{{project.name}}</div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <!-- <a class="ml-3 btn-link">
              <i class="icon icon-arrow-right"></i>
            </a>-->
          </div>
        </div>
      </div>
    </WhereUsedLayout>

    <WhereUsedLayout title="Invoices" :data="list.invoices">
      <div class="list-group list-group-flush">
        <div
          class="list-group-item bg-1 d-flex justify-content-between"
          v-for="inv in list.invoices"
          :key="inv._id"
        >
          <div class="d-flex align-items-center">
            <svg-icon name="folder" class="mr-3" />
            <div class>
              <div class>{{inv.project.name}}</div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="text-2">
              <div
                class="text-right"
                v-tooltip="'Amount Invoiced'"
              >{{inv.invoicedAmount | currency}}</div>
              <div class="text-right" v-tooltip="'Amount Received'">
                <i class="icon-arrow-down-circle small mr-1"></i>
                {{inv.receivedAmount | currency}}
              </div>
            </div>
            <!-- <a class="ml-3 btn-link">
              <i class="icon icon-arrow-right"></i>
            </a>-->
          </div>
        </div>
      </div>
    </WhereUsedLayout>

    <WhereUsedLayout title="Invested in projects" :data="list.cashIns">
      <div class="list-group list-group-flush">
        <div
          class="list-group-item bg-1 d-flex justify-content-between"
          v-for="s in list.cashIns"
          :key="s._id"
        >
          <div class="d-flex align-items-center">
            <svg-icon name="folder" class="mr-3" />
            <div class>
              <div class>{{s.project.name}}</div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="text-2">
              <div
                class="text-right"
                v-tooltip="'Total amount invested on this project'"
              >{{s.amount | currency}}</div>
            </div>
            <!-- <a class="ml-3 btn-link">
              <i class="icon icon-arrow-right"></i>
            </a>-->
          </div>
        </div>
      </div>
    </WhereUsedLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import routeMixin from "@/mixins/routeMixin";
import WhereUsedLayout from "./components/WhereUsedLayout";
const entityName = "contact";

export default {
  name: "whereUsed",
  mixins: [dataMixin, baseMixin, routeMixin],
  components: { WhereUsedLayout },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      let result = await this.loadByAction(
        entityName,
        `whereused/${this.contact_id}`,
        true
      );
      console.log("result :", result);
      this.list = result;
      this.loaded();
    }
  },
  computed: {
    contact() {
      return this.contactById(this.contact_id);
    },
    nodata() {
      return (
        this.ready &&
        !this.list.contacts.length &&
        !this.list.projects.length &&
        !this.list.invoices.length &&
        !this.list.payables.length &&
        !this.list.cashIns.length
      );
    },
    ...mapGetters(["contactById"])
  }
};
</script>
