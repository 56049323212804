export default (list, keyword) => {
    if (list && keyword) {
        keyword = keyword.toLowerCase();
        return list.filter(
            o =>
            (o.name && o.name.toLowerCase().includes(keyword)) ||
            (o.description && o.description.toLowerCase().includes(keyword))
        );
    }
    return list;
};