<template>
	<div>
		<div class="page-header"></div>
		<div class="container detail-over  ">
			<div class="card shadow pb-3 mb-5">
				<div class="card-header d-flex  justify-content-center">
					<h5>
						Create or Join to a Company
					</h5>
					<spinner :busy="state.loading" v-if="state.loading" />
				</div>

				<div v-if="loaded">
					<div class="p-3">
						<ul class="nav nav-tabs nav-fill nav-pills   ">
							<li class="nav-item">
								<a class="nav-link " @click="switchTab(false)" :class="{ active: !join }"
									>Create a new Company
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link " @click="switchTab(true)" :class="{ active: join }"
									>Join Company by Invitation</a
								>
							</li>
						</ul>
					</div>

					<create-client ref="clientInfo" v-show="!join" class="join" />
					<join-client v-show="join" class="join" :invites="invites" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CreateClient from './CreateClient';
import JoinClient from './JoinClient';
import baseMixin from '@/mixins/baseMixin';
import dataMixin from '@/mixins/dataMixin';
import { mapGetters } from 'vuex';

export default {
	name: 'initClient',
	mixins: [baseMixin, dataMixin],
	components: {
		CreateClient,
		JoinClient,
	},
	data() {
		return {
			join: false,
		};
	},
	mounted() {
		this.load();
	},
	methods: {
		async load() {
			this.loading();

			await this.loadList('invite', true);
			if (this.invites.length) {
				this.join = true;
			}
			this.loaded();
		},
		switchTab(value) {
			this.join = value;
		},
		done() {
			this.$refs.clientInfo.done();
		},
	},
	computed: {
		...mapGetters(['invites']),
	},
};
</script>

<style lang="scss" scoped>
.container {
	max-width: 38rem;
}

.detail-over {
	margin-top: -5rem;
}
.nav-link {
	min-width: 15rem;
}
.nav-pills .nav-link {
	border-radius: 0.75rem 0.75rem 0 0;
}
.nav-pills .nav-link.active {
	background-color: #00bcd4;
}

.nav-pills .nav-link.active:hover {
	color: var(--light) !important;
}
.join {
	min-height: 18rem;
}
</style>
