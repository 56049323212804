<template>
  <card-layout nofooter :state="state" class="bg-2" back>
    <div slot="header" class="d-flex">
      <add-button @add="add">Add Payable</add-button>
    </div>
    <div slot="title">Project Expenses</div>

    <div class="card-body" slot="data">
      <div class="row form-group">
        <div class="col-lg-4">
          <PayablesTotal :list="list" title="Project Total Expenses" />
        </div>
        <div class="col-lg-2"></div>
        <div class="col-lg-6 h-100"></div>
      </div>

      <div class="rounded-5-top shadow-sm form-group">
        <div
          class="fs-11 px-3 py-2 rounded-5-top bg-trans d-flex justify-content-between align-items-center"
        >
          <div>Recent Expenses ({{list.length}})</div>
          <button class="btn btn-sm text-primary" @click="showAll" v-if="list.length">Show All</button>
        </div>
        <PayableTable :list="listRecent" />
      </div>
      <PayablePayeeChart :list="list" class="form-group" />
      <PayableTypeChart :list="list" class="form-group" />
      <ExpenseChart :list="list" class="form-group" />
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import PayableTable from "@/views/payable/components/PayableTable.vue";
import PayablesTotal from "@/views/payable/components/PayablesTotal.vue";
import ContactPayablesChart from "@/views/payable/components/ContactPayablesChart.vue";
import PayableTypeChart from "./components/PayableTypeChart";
import PayablePayeeChart from "./components/PayablePayeeChart";
import ExpenseChart from "./components/ExpenseChart";
import { sortDate } from "@/helpers/date";
const entityName = "payable";
export default {
  name: "dailyLogs",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: {
    PayableTable,
    PayablesTotal,
    ContactPayablesChart,
    PayableTypeChart,
    PayablePayeeChart,
    ExpenseChart
  },
  data() {
    return {
      recentCount: 5
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    add() {
      this.$router.push(`payable/new/edit`);
    },
    async load() {
      this.loading();
      await this.loadByProject(entityName, this.project_id);
      this.loaded();
    },
    showAll() {
      this.$store.commit("SET_SELECTED", {
        key: "projectId",
        value: this.project_id
      });
      this.$router.push(`/payables`);
    }
  },

  computed: {
    summary() {
      return {
        amount: this.list.map(o => o.amount).reduce((a, b) => a + b, 0)
      };
    },
    list() {
      return this.payables.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    listRecent() {
      return this.list.sort(sortDate, false).slice(0, this.recentCount);
    },
    nodata() {
      return this.ready && !this.list.length;
    },

    ...mapGetters(["payables"])
  }
};
</script>
