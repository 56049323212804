<template>
  <div class="text-center position-relative logo-container">
    <figure class="figure rounded-5 border p-3" v-if="readonly">
      <img class="img-fluid logo" v-if="value" :src="value" alt="Avatar" />
    </figure>

    <button class="btn" @click="openModal" v-else>
      <div class="text-center d-flex flex-column align-items-center">
        <figure class="figure rounded-5 border p-1" v-if="value">
          <img class="img-fluid logo" v-if="value" :src="value" alt="Avatar" />
        </figure>

        <div v-if="!value" class="icon-container text-center p-3 rounded">
          <i class="icon-camera"></i>
        </div>

        <i v-else class="icon-camera fs-15 m-auto change-icon text-2"></i>
      </div>
    </button>

    <b-modal
      v-if="!readonly"
      ref="uploadModal"
      title="Upload Image"
      hide-footer
      @hide="showUploadModal = false"
    >
      <CropImage
        v-if="showUploadModal"
        @cancel="closeModal"
        @done="done"
        :isavatar="true"
        :round="false"
        :folder="folder"
      />
    </b-modal>
  </div>
</template>
<script>
import CropImage from "@/views/attachments/CropImage.vue";

export default {
  name: "logoUploadView",
  components: { CropImage },
  props: {
    value: String,
    readonly: Boolean,
    folder: String
  },
  data() {
    return {
      showUploadModal: false
    };
  },
  methods: {
    openModal() {
      if (this.readonly) return;
      this.showUploadModal = true;
      this.$refs.uploadModal.show();
    },
    closeModal() {
      this.$refs.uploadModal.hide();
      this.showUploadModal = false;
    },
    done(url) {
      this.$emit("change", url);
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
img.logo {
  max-width: 10rem;
  max-height: 10rem;
}
.logo-container {
  min-width: 10rem;
  min-height: 10rem;
}
.change-icon {
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
  // display: none;
}

// .logo-container:hover {
//   .change-icon    {
//     display: inline;
//   }
// }
.icon-container {
  max-width: 10rem;
  max-height: 10rem;
  background-color: #e9ecef;

  i {
    font-size: 7rem;
    color: #bdbfc1;
  }
}
</style>
