<template>
  <div>
    <div v-if="readonly || !hasAccess">
      <figure class="figure rounded-5 p-0 text-left m-0">
        <img class="img-fluid logo" v-if="headerLogoUrl" :src="headerLogoUrl" alt="Logo" />
      </figure>
    </div>
    <div v-else class="logo-container clearfix">
      <button class="btn p-0" @click="openModal ">
        <div class="text-center d-flex flex-column align-items-center">
          <figure class="figure m-0" v-if="headerLogoUrl">
            <img
              class="img-fluid img-thumbnail logo"
              v-if="headerLogoUrl"
              :src="headerLogoUrl"
              alt="Logo"
            />
            <!-- <small class="text-1">click to upload</small> -->
          </figure>
          <div v-if="!headerLogoUrl" class="d-flex p-3 flex-center flex-column text-2">
            <i class="icon-cloud-upload fs-2"></i>
            Upload letterhead logo
          </div>
        </div>
      </button>

      <b-modal ref="uploadModal" title="Upload Image" hide-footer @hide="showUploadModal = false">
        <CropImage
          v-if="showUploadModal"
          @cancel="closeModal"
          @done="done"
          :isavatar="true"
          :round="false"
          :folder="folder"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import CropImage from "@/views/attachments/CropImage.vue";
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin.js";
import accessMixin from "@/mixins/accessMixin.js";
export default {
  name: "logoUploadView",
  mixins: [dataMixin, accessMixin],
  components: { CropImage },
  data() {
    return {
      accessRole: "f"
    };
  },
  props: {
    value: String,
    readonly: Boolean
  },
  data() {
    return {
      showUploadModal: false
    };
  },
  methods: {
    openModal() {
      this.showUploadModal = true;
      this.$refs.uploadModal.show();
    },
    closeModal() {
      this.$refs.uploadModal.hide();
      this.showUploadModal = false;
    },
    done(url) {
      this.updateClient({ headerLogoUrl: url }, "setting/update");

      this.closeModal();
      this.$emit("change", url);
    }
  },
  computed: {
    folder() {
      return `/clients/${this.cid}/logo`;
    },
    ...mapGetters(["clientSetting", "headerLogoUrl", "cid"])
  }
};
</script>

<style lang="scss" scoped>
img.img-fluid {
  max-height: 5.8rem;
}

.logo-container {
  height: 6rem;
}
.header-logo {
  max-height: 6rem;
}
.btn-hover {
  position: absolute;
  margin: auto;
  top: 0.5rem;
  left: 1.5rem;
  opacity: 0;
}
.logo-container:hover .btn-hover {
  display: block;
  opacity: 0.9;
  transition: opacity 0.5s ease-in;
}
</style>
