<template>
  <div>
    <PleaseSearch
      types="establishment"
      ref="searchPlace"
      placeholder="Search business"
      class="px-3"
      @select="onSelect"
      @blur="onSearchBur"
    />
    <div class="px-3 py-1 px-3 pt-3 bg-1" autocomplete="off">
      <div class="row">
        <div class="col-lg-6">
          <div class="d-flex flex-fill">
            <div class="mt-4 mr-3" v-if="!small">
              <img :src="contact.gplace.icon" alt height="40" width="40" v-if="contact.gplace.icon" />
              <svg-icon name="company" color="gray" :size="40" v-else />
            </div>
            <div class="form-group w-100">
              <div class>
                <label for="companyName">Company Name</label>
                <div class="form-group">
                  <input
                    type="text"
                    ref="companyName"
                    id="companyName"
                    autofocus
                    autocomplete="off"
                    v-model="contact.companyName"
                    :class="{ 'is-invalid': validated && !valid }"
                    placeholder="Company name"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="validated && !valid"
                  >Please contact/company name!</div>
                </div>
                <section class="form-group">
                  <label for="type">Business Type</label>
                  <BusinessTypeSelect
                    ref="businessTypeSelect"
                    :client="contact"
                    :validated="validated"
                  />
                </section>
                <div>
                  <input
                    type="text"
                    class="form-control mb-3"
                    placeholder="Website"
                    v-model="contact.website"
                  />
                  <img
                    :src="contact.gplace.image"
                    height="300"
                    class="place-img img-thumbnail p-0 border-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class>
            <div class="form-group">
              <div class="d-flex flex-fill">
                <div class="p-2 mt-2" v-if="!small">
                  <i class="icon-user fs-3 text-1 mr-3"></i>
                </div>
                <div class="w-100">
                  <div class="form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      ref="firstInput"
                      v-model="person.name"
                      :class="{ 'is-invalid': validated && !valid }"
                      placeholder="e.g. John Smith"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      class="form-control"
                      type="email"
                      autocomplete="off"
                      placeholder="Contact's email address"
                      v-model="person.email"
                      :class="{ 'is-invalid': validated && !emailValid }"
                    />
                  </div>
                  <hr />
                  <div>
                    <label>Phone Number</label>
                    <input
                      class="form-control"
                      type="text"
                      ref="phone"
                      autocomplete="off"
                      v-model="contact.phone"
                      placeholder="000-000-0000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-2 p-3" v-if="contact.address.text">{{contact.address.text}}</div>

    <div class="p-3 modal-footer save-footer" :class="{'m-0 ':!inmodal}">
      <save-cancel savetext="Add " @save="save" @cancel="cancel" class="float-right"></save-cancel>
    </div>
  </div>
</template>

<script>
import { isValidEmail } from "@/validations";
import BusinessTypeSelect from "@/views/businessType/BusinessTypeSelect.vue";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import pages from "@/pages";
import PleaseSearch from "@/components/form/place/PleaseSearch.vue";
import { getPhotoRefByPlaceId } from "@/store/google";
import { mapGetters } from "vuex";
const entityName = "contact";
const getImageRef = url => {
  if (!url) return "";
  url = url.split("?")[1];
  return url ? url.split("&")[0] : "";
};
export default {
  name: "addContact",
  mixins: [baseMixin, dataMixin],
  components: { BusinessTypeSelect, PleaseSearch },
  props: { small: Boolean, inmodal: Boolean },
  data() {
    return {
      contact: {
        gplace: { image: "", icon: "" },
        company: {},
        people: [{}],
        address: {},
        companyName: "",
        businessTypeId: ""
      },
      searched: false,
      mask: "(+#) ###-###-####",
      validated: false
    };
  },
  methods: {
    init() {
      this.focus();
    },
    onSelect(place) {
      let contact = {
        name: place.name,
        businessTypeId: 0,
        description: "",
        companyName: place.name,
        gplace: { ...place.g },
        address: { ...place.address },

        phone: place.phone,
        website: place.website,
        people: [
          {
            name: "",
            email: "",
            phone: ""
          }
        ]
      };
      //contact.gplace.imageRef = getImageRef(place.g.image);
      //contact.gplace.photo_reference=;;;

      this.contact = contact;
      console.log("gplace", contact.gplace);
      this.$refs.firstInput.focus();

      setTimeout(() => {
        this.$refs.businessTypeSelect.selectType(place.types);
      }, 200);

      this.searched = true;
    },
    onSearchBur(keyword) {
      setTimeout(() => {
        if (!this.contact.companyName) this.contact.companyName = keyword;
      }, 500);
      this.$forceUpdate();
    },
    reset() {
      this.contact = {
        name: "",
        description: "",
        businessTypeId: "",
        companyName: "",
        phone: "",
        website: "",
        people: [
          {
            name: "",
            email: "",
            phone: ""
          }
        ],
        gplace: {
          icon: "",
          image: ""
        },
        address: {}
      };

      this.validated = false;
    },

    focus() {
      setTimeout(() => {
        this.$refs.searchPlace.focus();
      }, 300);
    },

    async save() {
      this.validated = true;
      if (!this.valid) return;
      let contact = { ...this.contact };
      try {
        if (contact.companyName) {
          contact.name = contact.companyName;
        } else {
          contact.name = contact.people[0].name;
        }
        if (contact.name.length > 45) {
          contact.name = contact.name.substring(0, 45);
        }

        await this.findPhotoRef(contact.gplace);
        let added = await this.add(entityName, contact);

        this.$emit("done", added);
        //
      } catch (error) {
        console.log("error :", error);
      }
    },
    async findPhotoRef(gplace) {
      if (gplace) {
        gplace.photo_reference = await getPhotoRefByPlaceId(gplace.place_id);
      }
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  async mounted() {
    // let x = await getPhotoRefByPlaceId();
    // console.log("x", x);
  },
  computed: {
    person() {
      return this.contact.people[0];
    },
    valid() {
      return this.validCompany || this.validperson;
    },
    validperson() {
      return (
        this.person &&
        !!this.person.name &&
        (!this.person.email || this.emailValid)
      );
    },
    validCompany() {
      return !!this.contact.companyName;
    },
    emailValid() {
      return !this.person.email || isValidEmail(this.person.email);
    },
    ...mapGetters(["cid"])
  }
};
</script>
<style lang="scss" scoped>
.place-img {
  max-height: 200px;
}
</style>
