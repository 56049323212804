<template>
  <div v-if="!hideAction">
    <div class="dropbox" novalidate>
      <input
        tabindex="-1"
        type="file"
        multiple
        id="file"
        ref="file"
        @change="handleFileUpload($event.target)"
        :accept="accept"
        class="input-file h-100"
      />
      <div class="text-center">
        <i class="icon-cloud-upload d-block fs-3"></i>
        <span class="text-gray small drag">{{ note }}</span>
      </div>
    </div>

    <div class="row mt-3" v-if="list.length">
      <div v-for="(f, index) in list" v-bind:key="index" class="col-lg-4">
        <div class="position-relative mb-3">
          <file-thumb :file="f" />
          <progress-bar
            :percent="f.progress"
            :variant="'sucess'"
            v-if="inprogress(f)"
            class="prgs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fileUploadMixin } from "./mixins/fileUploadMixin.js";
import FileThumb from "./FileThumb";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "fileUpload",
  mixins: [fileUploadMixin, accessMixin],

  components: { FileThumb },
  props: {
    entityName: String,
    projectId: String,
    note: {
      type: String,
      default: "Drag file(s) here or click to browse and upload "
    },
    accept: {
      type: String,
      default: "*"
    },
    docType: Object
  }
};
</script>

<style lang="scss" scopped>
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -0.2rem;

  color: dimgray;
  padding: 0.5rem;
  min-height: 4rem;
  position: relative;
  cursor: pointer;
}

.dropbox:hover {
  background: rgb(239, 240, 236);
}

.dropbox:-moz-drag-over {
  border: 2px dashed blueviolet;
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
}
.dropbox .drag {
  line-height: 1rem;
}

.in-progress {
  opacity: 0.5;
}
.prgs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
