<template>
  <ul class="nav nav-justified bg-1 tabs">
    <a
      class="nav-item nav-link border-bottom fs-09"
      v-for="t in items"
      :key="t.code"
      @click="$emit('change',t)"
      :class="{active:value==t.code}"
    >{{t.name}}</a>
  </ul>
</template>

<script>
export default {
  name: "tabs",

  props: {
    items: Array,
    value: [String, Number]
  }
};
</script>

 

<style lang="scss"   >
/* .s-nav-bg {
  background-color: rgba(0, 0, 0, 0.04);
} */

.tabs {
  .nav-item.nav-link {
    color: #555 !important;
  }

  .nav-item.nav-link.active {
    font-weight: 600;
  }

  .nav-item.nav-link.active {
    /* background-color: green;
    color: white !important; */
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #00bcd4 !important;
  }
}
</style>