<template>
  <div v-if="lastChange" c>
    <div class="d-flex justify-content-between align-items-end" v-if="simple">
      <user-view :user="lastChange.by" />
      <small>{{ lastChange.date | since }}</small>
    </div>
    <div class="d-flex flex-column" v-else>
      <div class="text-muted small last-modified mr-3">Last Modified</div>

      <div class="d-flex align-items-center justify-content-between">
        <span class="small">
          <user-view :user="lastChange.by" />
        </span>

        <span class="mx-2 small">
          <i class="icon-calendar mr-1"></i>
          <span class="date no-wrap">{{ lastChange.date | timeformat }}</span>
        </span>

        <button class="mx-2 btn btn-sm" @click="openChangeLogs" v-if="isAdmin">
          <i class="icon-clock mr-2"></i>
          Change Logs
        </button>
      </div>
    </div>
    <ChangeLogs
      :entity="entity"
      ref="changeLogs"
      v-if="showChangeLogs && !hideAction"
      @close="closeChangeLogs"
    />
  </div>
</template>

<script>
import ChangeLogs from "./ChangeLogs";
import accessMixin from "@/mixins/accessMixin";
import { mapGetters } from "vuex";

export default {
  props: {
    entity: Object,
    simple: Boolean
  },
  mixins: [accessMixin],
  components: { ChangeLogs },
  data() {
    return {
      showChangeLogs: false
    };
  },
  methods: {
    openChangeLogs() {
      if (this.showChangeLogs) {
        this.closeChangeLogs();
        return;
      }
      this.showChangeLogs = true;
      this.$nextTick(() => {
        this.$refs.changeLogs.open();
      });
    },
    closeChangeLogs() {
      this.showChangeLogs = false;
    }
  },
  computed: {
    lastChange() {
      return this.entity ? this.entity.lastChange : {};
    }
  }
};
</script>

<style lang="scss" scoped>
.last-modified {
  margin-top: -0.5rem;
}
</style>
