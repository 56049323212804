import { render, staticRenderFns } from "./ShareCustomer.vue?vue&type=template&id=340a95a8&scoped=true"
import script from "./ShareCustomer.vue?vue&type=script&lang=js"
export * from "./ShareCustomer.vue?vue&type=script&lang=js"
import style0 from "./ShareCustomer.vue?vue&type=style&index=0&id=340a95a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340a95a8",
  null
  
)

export default component.exports