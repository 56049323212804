<template>
  <div>
    <table class="table table-hover bg-white mb-0 table-bordered thead-md">
      <thead>
        <tr>
          <th scope="col" class="w-10">Contact</th>
          <th scope="col" class="w-5">Total (hr)</th>
          <th scope="col" v-for="g in grouped" :key="g.key">{{g.key}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="c in contacts" v-bind:key="c.contactId">
          <td>
            <contact-view :contact="c.contact" />
          </td>
          <td class="bg-2 fs-12">{{getTotalContact(c.contactId) | duration}}</td>
          <td
            scope="col"
            v-for="(g,i) in grouped"
            :key="g.key"
          >{{getTotal(c.contactId,i) | duration}}</td>
        </tr>
        <tr class="bg-2">
          <td class="text-right">TOTAL (hr)</td>
          <td class="strong fs-12">{{total | duration}}</td>
          <td
            scope="col"
            class="fs-12"
            v-for="(g,i) in grouped"
            :key="g.key"
          >{{getTotalIndex(i) |duration}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment, { duration } from "moment";
import _ from "lodash";
import { groupByContact, totalDuration } from "./timesheet";

export default {
  name: "timeSheetTable",
  props: {
    grouped: Array,
    list: Array,
    groupBy: String
  },
  methods: {
    findContact(id) {
      const contact = this.list.find(o => o.contact && o.contact._id == id);
      return contact || {};
    }
  },
  computed: {
    contacts() {
      return groupByContact(this.list);
    },
    getTotal() {
      return (contactId, index) => {
        const list =
          this.grouped[index].list.filter(
            o => o.contact && o.contact._id == contactId
          ) || [];
        return totalDuration(list);
      };
    },
    getTotalIndex() {
      return index => {
        const list = this.grouped[index].list || [];
        return totalDuration(list);
      };
    },
    getTotalContact() {
      return contactId => {
        const list =
          this.list.filter(o => o.contact && o.contact._id == contactId) || [];
        return totalDuration(list);
      };
    },
    total() {
      return totalDuration(this.list);
    }
  }
};
</script>

 