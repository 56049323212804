<template>
  <card-layout back :title="type.name" :state="state">
    <div slot="header">
      <div class="d-flex align-items-center">
        <button-share :entity="cashIn" />
        <button-edit @edit="edit" />
      </div>
    </div>
    <div class="card-body" slot="data">
      <section class="form-group bg-2 p-2 px-3">
        <div class="fs-11">{{cashIn.type.name}}</div>
      </section>

      <div class="row">
        <div class="col-lg-7">
          <div class="bg-1 p-3">
            <section class="form-group">
              <label>Date</label>
              <div class="form-control value">{{cashIn.date | dateformat}}</div>
            </section>

            <section class="form-group">
              <label>Contributor/lender</label>
              <div class="pl-3 form-control value">
                <contact-view :contact="cashIn.contact" />
              </div>
            </section>

            <section class="form-group">
              <div class="row">
                <div class="col">
                  <label>Amount</label>
                  <div class="form-control value">{{cashIn.amount | currency}}</div>
                </div>
                <div class="col">
                  <label>
                    Interest rate
                    <small>(If applicable)</small>
                  </label>
                  <div class="form-control value">{{cashIn.interest }} %</div>
                </div>
              </div>
            </section>

            <section>
              <label>Notes</label>
              <p class="form-control value min-h-note">{{cashIn.note}}</p>
            </section>
          </div>
        </div>

        <div class="col-lg-5">
          <entity-attachments :entityName="'cashIn'" :entity="cashIn" />
        </div>
      </div>
    </div>
    <last-modified :entity="cashIn" class="float-left" slot="footer" />
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";

const entityName = "cashIn";

export default {
  name: "cashInEdit",
  mixins: [dataMixin, baseMixin, routeMixin],
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadById(entityName, this.cashIn_id);
      this.loaded();
    },
    edit() {
      this.$router.push(
        `/project/${this.project_id}/cashin/${this.cashIn_id}/edit`
      );
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  computed: {
    type() {
      return this.cashIn ? this.cashIn.type || {} : {};
    },
    isnew() {
      return this.$route.params.cashIn_id == "new";
    },
    project() {
      return this.projectById(this.project_id);
    },
    cashIn() {
      return this.cashInById(this.cashIn_id);
    },
    ...mapGetters(["projectById", "cashInById"])
  }
};
</script>
