<template>
  <div>
    <highcharts :options="chartOptions" ref="hchart" />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import moment from "moment";
import { sortDate, todate } from "@/helpers/date";
import { mapGetters } from "vuex";

export default {
  name: "CashflowChart",
  components: { highcharts: Chart },
  mixins: [baseMixin, dataMixin, routeMixin],
  props: {
    list: [Array]
  },
  data() {
    return {
      chartOptions: {
        chart: {
          plotBorderWidth: null,
          plotShadow: false,
          height: 420
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: "datetime",
          crosshair: true,
          dateTimeLabelFormats: {
            day: "%d %b %Y" //ex- 01 Jan 2016
          }
          //maxZoom: 14 * 24 * 3600000
        },
        yAxis: {
          title: {
            text: "Amount ($)"
          }
        },
        tooltip: {
          pointFormat: "{series.name}: <b>${point.y:,.0f}</b>"
        },

        series: [
          {
            type: "areaspline",
            name: "Balance",
            color: "#c6e6c7",
            data: []
          },
          {
            type: "column",
            name: "Cash Inflow",
            color: "#4CAF50",
            data: []
          },
          {
            type: "column",
            name: "Cash Outflow",
            color: "#f45b5b",
            data: []
          }
        ]
      }
    };
  },
  watch: {
    list: {
      immediate: true,
      handler(value) {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      let cashIns = (payments = _(this.list)
        .groupBy(o => moment(o.date).format("YYYYMMDD"))
        .map((value, key) => ({
          key,
          date: todate(value[0].date),
          amount: value.map(o => o.amount).reduce((a, b) => a + b, 0)
        }))
        .orderBy(o => o.date)
        .value()
        .map(o => [o.date, o.amount]));

      let payments = _(this.expenses)
        .groupBy(o => moment(o.date).format("YYYYMMDD"))
        .map((value, key) => ({
          key,
          date: todate(value[0].date),
          amount: value.map(o => o.paidAmount || 0).reduce((a, b) => a + b, 0)
        }))
        .orderBy(o => o.date)
        .value()
        .map(o => [o.date, -1 * o.amount]);

      let balance = [];
      let combined = _([...cashIns, ...payments])
        .orderBy(o => o[0])
        .value();
      let amount = 0;
      combined.map(b => {
        amount += b[1];
        balance.push([b[0], amount]);
      });
      this.chartOptions.series[0].data = balance;
      this.chartOptions.series[1].data = cashIns;
      this.chartOptions.series[2].data = payments;
    },

    change(start, end) {
      this.loadPayable(start, end);
      this.loadReceivable(start, end);
    }
  },
  computed: {
    expenses() {
      return this.payables.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    ...mapGetters(["payables"])
  }
};
</script> 
