<template>
  <card-layout back :title="isnew ? `Add ${type.name}`  : `Edit ${type.name}`" :state="state">
    <div slot="header">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-7">
          <div class="bg-1 p-3">
            <section class="form-group">
              <b-checkbox v-model="cashIn.sharedCustomer" switch size="lg">Share with Client</b-checkbox>
            </section>

            <section class="form-group">
              <label>Type</label>
              <type-select :types="types" v-model="cashIn.type" />
            </section>

            <section class="form-group">
              <label>Date</label>
              <date-input ref="datePicker" @change="focusNext" v-model="cashIn.date" />
            </section>

            <section class="form-group">
              <label>Contributor/lender</label>
              <div>
                <contact-selector
                  name="Contributor/lender"
                  ref="contactSelector"
                  :contact="cashIn.contact"
                  placeholder="Select lender"
                  @change="focusNext"
                  :class="{'is-invalid': validated && !cashIn.contact._id}"
                  class="form-control"
                />
                <div
                  class="invalid-feedback"
                  v-if="validated && !cashIn.contact._id"
                >Select contributor/lender</div>
              </div>
            </section>

            <section class="bg-trans p-3 form-group">
              <div class="row">
                <div class="col">
                  <label>Amount</label>
                  <div>
                    <amount-input
                      ref="amountInput"
                      @change="focusNext"
                      v-model="cashIn.amount"
                      placeholder="Amount"
                      class="font-weight-md"
                      :class="{ 'is-invalid': validated &&  $v.cashIn.amount.$invalid}"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validated &&  $v.cashIn.amount.$invalid"
                    >Please enter the amount</div>
                  </div>
                </div>
                <div class="col">
                  <label>
                    Interest rate
                    <small>(If applicable)</small>
                  </label>
                  <div>
                    <div class="input-group mb-3 w-50">
                      <input
                        @change="focusNext"
                        v-model.number="cashIn.interest"
                        placeholder="Amount before tax"
                        class="form-control"
                        :class="{ 'is-invalid': validated &&  $v.cashIn.interest.$invalid}"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                    </div>

                    <div
                      class="invalid-feedback"
                      v-if="validated && $v.cashIn.interest.$invalid"
                    >Please enter the amount</div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <label>Notes</label>
              <div>
                <textarea
                  class="form-control"
                  v-model="cashIn.note"
                  rows="5"
                  placeholder="Enter notes... "
                ></textarea>
              </div>
            </section>
          </div>
        </div>

        <div class="col-lg-5">
          <entity-attachments :entityName="'cashIn'" :entity="cashIn" />
        </div>
      </div>
    </div>
    <div slot="footer">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import types from "./data/cashInTypes";

import {
  required,
  numeric,
  minValue,
  maxValue
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
const entityName = "cashIn";

export default {
  name: "cashInEdit",
  mixins: [dataMixin, baseMixin, routeMixin, validationMixin],
  data() {
    return {
      validated: false,
      cashIn: {
        attachments: []
      },
      types
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.isnew) this.newModel();
      else this.load();
    },
    async load() {
      this.loading();
      await this.loadById(entityName, this.cashIn_id);
      this.cashIn = JSON.parse(JSON.stringify(this.cashIn_store));
      this.loaded();
    },

    newModel() {
      let key = this.$route.query.type;
      this.cashIn = {
        type: { key: key, name: types[key] },
        date: new Date(),
        attachments: [],
        contact: {
          _id: "",
          name: ""
        },
        project: { _id: this.project_id, name: this.project.name },
        amount: 0,
        interest: 0,
        note: ""
      };
      this.focusDate();
      this.loaded();
    },

    async save() {
      this.validated = true;
      if (this.$v.invalid) return;
      let model = { ...this.cashIn };

      this.isnew
        ? await this.add(entityName, model)
        : await this.update(entityName, model);

      this.$router.go(-1);
    },

    cancel() {
      this.$router.go(-1);
    },

    focusDate() {
      setTimeout(() => {
        let input = this.$refs.datePicker;
        if (input) {
          input.focus();
        }
      }, 300);
    },

    focusNext() {
      setTimeout(() => {
        if (!this.cashIn.contact._id) {
          this.$refs.contactSelector.show();
          return;
        }
        if (this.cashIn.amount == 0) {
          this.$refs.amountInput.focus();
          return;
        }
      }, 200);
    }
  },
  validations: {
    cashIn: {
      contact: {
        _id: { required }
      },
      date: { required },
      amount: { required, numeric, minValue: minValue(100) },
      interest: { numeric, maxValue: maxValue(99) }
    }
  },
  computed: {
    type() {
      return this.cashIn ? this.cashIn.type || {} : {};
    },
    isnew() {
      return this.$route.params.cashIn_id == "new";
    },
    project() {
      return this.projectById(this.project_id);
    },
    cashIn_store() {
      return this.cashInById(this.cashIn_id);
    },
    ...mapGetters(["projectById", "cashInById"])
  }
};
</script>
