<template>
  <header class="topbar">
    <nav
      class="navbar top-navbar navbar-expand-md navbar-dark fixed-top"
      :class="devClass()"
    >
      <div class="collapse navbar-collapse navbar-main">
        <div class="navbar-header px-3 navbar-brand">
          <span class="d-flex align-align-items-start mt-1">
            <img :src="nexoLogo" height="46" class="logo" alt="Nexobuild" />
            <span class="nexo-name">Nexobuild</span>
          </span>
        </div>
        <!-- <h1 class="text-white">{{myrole}}</h1> -->
        <ul class="navbar-nav mr-auto main-nav" v-if="showMenu">
          <li
            class="nav-item"
            v-for="route in routes"
            :key="route.name"
            :class="{ active: isActive(route) }"
          >
            <router-link
              :to="route.path"
              class="nav-link px-4 nav-menu d-flex flex-column align-items-center"
            >
              <!-- <i :class="route.icon"></i> -->
              <icon :icon="route.icon" :svg="route.svg"></icon>
              <small style="font-size:0.8rem">{{ route.name }}</small>
            </router-link>
          </li>
        </ul>

        <ul class="navbar-nav my-lg-0 user-drop-down ml-auto">
          <b-nav-item-dropdown text="Lang" right no-caret v-if="ready">
            <template slot="button-content">
              <avatar :username="userName" :src="photoURL" class="mx-3" />
              <!-- <img
								:src="photoURL"
								width="46px"
								:alt="displayName"
								class="d-inline-block align-top rounded-circle"
              />-->
              <!-- <span class="mt-2 d-inline-block ml-2 mr-3"
								>{{ displayName }}
								<i class="icon-arrow-down"></i>
              </span>-->
            </template>
            <b-dropdown-item class="user-menu" @click="toUserSetting">
              <div class="d-flex flex align-items-center">
                <avatar :username="userName" :src="photoURL" class="mr-3" />
                <div>
                  <div class="fs-12">{{ userName | capital }}</div>
                  <div>Profile</div>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-divider />

            <b-dropdown-item class="user-menu mt-3">
              <div class="d-flex align-items-center" @click="toSetting">
                <logo-view :url="client.logoUrl" :size="40" class="mr-3" />
                <div class="fs-12">{{ clientName }}</div>
              </div>
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item class="user-menu " @click="switchClient">
              <i class="icon-shuffle mr-3"></i>Switch Workspace
            </b-dropdown-item>
            <b-dropdown-item
              class="user-menu "
              @click="switchToDemp"
              v-if="!isDemo"
            >
              <i class="icon-arrow-right-circle mr-3"></i>View Demo
              Workspace<spinner :busy="switching" v-if="switching" size="1" />
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item
              class="user-menu mt-3"
              href="mailto:info@nexobuild.com"
              >Send Feedback</b-dropdown-item
            >
            <b-dropdown-item
              href="https://nexobuild.com/terms.html"
              target="_blank"
              >Terms Of Service</b-dropdown-item
            >
            <b-dropdown-item
              href="https://nexobuild.com/privacy.html"
              target="_blank"
              >Privacy Policy</b-dropdown-item
            >

            <b-dropdown-divider />

            <b-dropdown-item class="user-menu" @click.prevent="logout">
              <i class="icon-logout mr-3"></i>
              Sign Out
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </ul>
      </div>

      <div class="nav-mobile w-100 px-4">
        <ul
          class="navbar-nav d-flex justify-content-between align-items-center"
        >
          <li class="nav-item">
            <a
              class="nav-link c-pointer nav-menu"
              @click="sideopen = !sideopen"
            >
              <i class="icon-menu"></i>
            </a>
          </li>
          <li>
            <div class="navbar-header navbar-brand mx-0 bg-transparent">
              <span class="d-flex align-align-items-start mt-1">
                <img :src="nexoLogo" height="46" class="logo" alt="Nexobuid" />

                <span class="nexo-name">Nexobuild</span>
              </span>
            </div>
          </li>
          <li class="nav-item">
            <img
              :src="photoURL"
              width="40px"
              :alt="userName"
              class="d-inline-block align-top rounded-circle"
            />
          </li>
        </ul>
      </div>
    </nav>

    <div
      class="back-layer"
      v-if="sideopen"
      :class="{ show: sideopen }"
      @click="sideopen = false"
    ></div>
    <div class="side-menu pt-2" :class="{ open: sideopen }">
      <ul class="list-group list-group-flush">
        <li
          v-for="route in routes"
          :key="route.name"
          class="list-group-item border-0 bg-none"
        >
          <a @click="navigate(route)" class="nav-link d-flex py-0">
            <!-- <i :class="route.icon" class="pr-3"></i> -->
            <icon :icon="route.icon" :svg="route.svg" class="mr-3"></icon>
            {{ route.name }}
          </a>
        </li>
        <li>
          <hr />
        </li>
        <li class="list-group-item border-0 bg-none">
          <a @click="toSetting" class="nav-link d-flex py-0">
            <logo-view :url="client.logoUrl" :size="36" class="mr-3" />
            <div class="text-left">
              <div>{{ clientName }}</div>
            </div>
          </a>
        </li>
        <li class="list-group-item border-0 bg-none">
          <a @click="switchClient" class="nav-link ">
            Switch Workspace
          </a>
        </li>
        <li class="list-group-item border-0 bg-none">
          <a @click="toUserSetting" class="nav-link d-flex py-0">
            <div class="d-flex flex align-items-center">
              <avatar
                :username="userName"
                :src="photoURL"
                class="mr-3"
                :size="36"
              />
              <div class="text-left">
                <div>{{ userName | capital }}</div>
                <div class="small">Profile</div>
              </div>
            </div>
          </a>
        </li>

        <!-- <li class="list-group-item border-0 bg-none">
          <a
            href="https://nexobuild.com/terms.html"
            target="_blank"
            class="nav-link d-flex py-0"
          >Terms Of Service</a>
        </li>
        <li class="list-group-item border-0 bg-none">
          <a
            href="mailto:info@nexobuild.com"
            target="_blank"
            class="nav-link d-flex py-0"
          >Send Feedback</a>
        </li>

        <li class="list-group-item border-0 bg-none">
          <a
            href="https://nexobuild.com/privacy.html"
            target="_blank"
            class="nav-link d-flex py-0"
          >Privacy Policy</a>
        </li>-->
        <li>
          <hr />
        </li>

        <li class="list-group-item border-0 bg-none">
          <a @click.prevent="logout" class="nav-link d-flex py-0">
            <i class="icon-logout mr-3"></i>
            Sign Out
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import navMixin from "@/mixins/navMixin";
import { SIGN_OUT } from "@/auth/login/store/types";
import { navbars } from "@/pages";
import { filterRoute } from "@/mixins/accessMixin";

export default {
  name: "navbar",
  mixins: [navMixin],
  data() {
    return {
      routes: [],
      sideopen: false,
      nexoLogo: "/img/logo/nexobuild.png",
      switching: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setRoutes();
    });
  },
  watch: {
    myrole: {
      immediate: true,
      handler() {
        this.setRoutes();
      }
    },
    emailVerified: {
      immediate: true,
      handler() {
        this.setRoutes();
      }
    }
  },

  methods: {
    login() {
      this.$router.push({ path: "/login" });
    },
    setRoutes() {
      this.routes = this.myrole
        ? navbars.filter(o => filterRoute(o, this.myrole))
        : [];
    },
    logout() {
      this.$store.dispatch(SIGN_OUT);
      // this.$router.push({ path: "/" });
      this.sideopen = false;
    },
    navigate(route) {
      this.$router.push(route);
      this.sideopen = false;
    },
    switchClient() {
      const path = "/switchClient";
      if (this.$route.path !== path) this.$router.push(path);
      this.sideopen = false;
    },
    toUserSetting() {
      const path = "/settings/userProfile";
      if (this.$route.path !== path) this.$router.push(path);
      this.sideopen = false;
    },
    toSetting() {
      const path = "/settings";
      if (this.$route.path !== path) this.$router.push(path);
      this.sideopen = false;
    },
    devClass() {
      return process.env.NODE_ENV === "development" ? "dev-mode" : "";
    },
    async switchToDemp() {
      if (this.switching) return;
      this.switching = true;
      try {
        await this.$store.dispatch("SWITCH_TO_DEMO");
        this.$router.push("/projects");
      } catch (error) {
        console.log("error", error);
      } finally {
        this.switching = false;
      }
    }
  },

  computed: {
    ready() {
      return this.user && this.user.uid && this.profileLoaded;
    },
    showMenu() {
      return this.ready && !this.noClient;
    },
    emailVerified() {
      return this.user && this.user.emailVerified;
    },
    userName() {
      return (
        this.displayName ||
        (this.user ? this.user.phoneNumber : "-") ||
        "user-name"
      );
    },

    ...mapGetters([
      "user",
      "isAuthenticated",
      "displayName",
      "photoURL",
      "profileLoaded",
      "noClient",
      "clientName",
      "client",
      "myrole",
      "isDemo"
    ])
  }
};
</script>
<style lang="scss" scoped>
// .nav-menu {
// 	font-size: 1.5rem;
// }
$h: 70px;
// .topbar {
// 	width: 100%;
// 	position: fixed;
// 	z-index: 5;
// }

.topbar .top-navbar {
  padding: 0;
}

.topbar .top-navbar .navbar-header {
  background: rgba(0, 0, 0, 0.1);
  line-height: 65px;
  min-width: 10rem;
  height: $h;
  flex-shrink: 0;
}
.navbar-brand {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(230, 245, 251, 0.25) !important;
}
.nexo-name {
  color: #fafafa;
  font-weight: 600;
}
.logo {
  max-width: 10rem;
  opacity: 0.9;
}
.dropdown-toggle::after {
  display: none !important;
}

@media (min-width: 768px) {
  .nav-mobile {
    display: none;
  }
}
.nav-mobile .navbar-nav {
  flex-direction: row;
}
@media (min-width: 768px) {
  .side-menu,
  .back-layer {
    display: none;
  }
}
@media (max-width: 768px) {
  .side-menu {
    z-index: 9999;
    max-width: 16rem;
    height: 100%;
    width: 30rem;
    position: fixed;
    top: $h;
    left: 0rem;
    background-color: #464642;
    overflow-x: hidden;
    padding-top: 20px;

    transform: translateX(-100%);
    transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .side-menu {
    .nav-link {
      color: rgba(255, 255, 255, 0.6);
    }
    .nav-link:hover {
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .side-menu.open {
    opacity: 1;
    transform: translateX(0);
  }

  .back-layer {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
  .back-layer.show {
    filter: alpha(opacity=50);
    opacity: 0.2;
  }
}
.navbar-main {
  max-width: 100% !important;
}
.main-nav {
  flex-wrap: nowrap;
  overflow-x: auto;
}

// .user-menu:hover a {
// 	color: red !important;
// }
</style>
