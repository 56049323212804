<template>
  <div class="position-relative">
    <!-- <span class="table-count text-muted small" v-if="list && list.length > 0"> ({{ list.length }}) </span> -->

    <table class="table table-hover bg-white thead-md">
      <thead>
        <tr>
          <th scope="col" class="pl-3 w-6">Date</th>
          <th scope="col" class="w-10">Notes</th>
          <th scope="col" class="w-10">RefNo</th>
          <th scope="col" class="text-right w-7">Amount</th>
          <th class="w-4"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in list" v-bind:key="p._id">
          <td class="date no-wrap">{{p.date |dateformat}}</td>
          <td>
            <p class="w-100 text-gray">{{p.note}}</p>
          </td>
          <td class="text-truncate text-gray">{{p.refNo}}</td>
          <td class="text-right">{{p.amount |currency}}</td>
          <td class="text-right">
            <router-link class="btn-link px-3 text-decoration-none" :to="`income/${p._id}/edit`">
              <i class="icon-arrow-right"></i>
            </router-link>
          </td>
        </tr>
        <tr class="bg-1">
          <td></td>
          <td></td>
          <td class="text-right">Total Paid</td>
          <td class="text-right fw-600">{{total | currency}}</td>
          <td class="text-right"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import invoiceListMixin from "../data/invoiceListMixin";
export default {
  name: "invoiceTable",
  mixins: [invoiceListMixin],
  props: {
    list: Array
  },
  computed: {
    total() {
      return this.list.map(o => o.amount).reduce((a, b) => a + b, 0);
    }
  }
};
</script>
