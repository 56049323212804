 
<template>
  <select
    class="form-control text-center custom-select"
    ref="select"
    @change="change($event.target.value)"
    v-model="value.key"
    v-on:input="input($event.target.value)"
  >
    <!-- <option>{{placeholder}}</option> -->
    <option
      v-for="s of options"
      :key="s.key"
      :value="s.key"
      :disabled="s.disabled"
      :class="{'disabled':s.disabled}"
    >{{s.name}}</option>
  </select>
</template>

<script>
import { mapGetters } from "vuex";

import { toArray } from "@/utils/array.js";
export default {
  name: "typeSelect",

  props: {
    placeholder: {
      type: String,
      default: "Select a Type"
    },
    types: Object,
    entity: Object,
    value: Object
  },
  data() {
    return {
      options: [],
      selected: ""
    };
  },
  mounted() {
    this.$nextTick(_ => {
      this.options = toArray(this.types);
    });
    this.$nextTick(() => {
      this.focus();
    });
  },

  methods: {
    change(key) {
      let type = { key, name: this.types[key] };
      this.$emit("change", type);
    },
    input(key) {
      let type = { key, name: this.types[key] };
      this.$emit("input", type);
      console.log("thiskey :", key);
    },

    focus() {
      this.$refs.select.focus();
    }
  }
};
</script>

 