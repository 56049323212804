import Vue from "vue";
import Vuex from "vuex";
import auth from "../auth/login/store";
import client from "../auth/client/store";
import toast from "./toast";
import general from "./general";
import google from "./google";
import data from "./data";
import appstate from "./appstate";
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
	modules: {
		auth,
		client,
		toast,
		general,
		google,
		data,
		appstate,
	},
	strict: debug,
});
