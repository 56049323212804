<template>
  <button class="btn mx-2 mobile-hide d-print-none" @click="$emit('click')">
    <i class="icon-cloud-download mr-2"></i>
    Export
  </button>
</template>

<script>
export default {
  name: "buttonExport",
  methods: {}
};
</script>
