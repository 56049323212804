<template>
  <section>
    <fieldset class="rounded shadow-sm m-0">
      <label>
        <span>Name</span>
        <input
          class="field form-control"
          name="name"
          ref="addressName"
          v-model="address.name"
          @change="$emit('change')"
          placeholder="Name on the Address"
          required
        />
      </label>
      <label>
        <span>Address Line 1</span>
        <input
          name="email"
          @change="$emit('change')"
          v-model="address.street"
          class="field form-control"
          placeholder="e.g. 185 Berry Street Suite 550"
          required
        />
      </label>
      <label>
        <span>Address Line 2</span>
        <input
          name="address"
          @change="$emit('change')"
          v-model="address.route"
          class="field form-control"
          placeholder="Address line 2"
        />
      </label>
      <label>
        <span>City</span>
        <input
          name="city"
          @change="$emit('change')"
          v-model="address.city"
          class="field form-control"
          placeholder="City"
        />
      </label>
      <label class="state">
        <span>State</span>
        <input
          name="state"
          @change="$emit('change')"
          v-model="address.state"
          class="field form-control"
          placeholder="State"
        />
      </label>
      <label class="zip">
        <span>ZIP/Postal Code</span>
        <input
          name="postal_code"
          @change="$emit('change')"
          v-model="address.postalCode"
          class="field form-control"
          placeholder="Zip/Postal Code"
        />
      </label>
      <label class="select">
        <span>Country</span>
        <input
          name="country"
          @change="$emit('change')"
          v-model="address.country"
          class="field form-control"
          placeholder="USA"
        />
      </label>
    </fieldset>
  </section>
</template>

<script>
export default {
  name: "addressForm",
  props: {
    address: Object
  }
};
</script>

<style lang="scss"  scoped >
section {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
  margin: 0;
  padding: 0;

  font-size: 15px;
  line-height: 1.4em;
}
fieldset {
  margin-bottom: 20px;
  background: #fff;
  border: none;
}
fieldset label:not(:last-child) {
  border-bottom: 1px solid #f0f5fa;
}
fieldset label {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 42px;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
fieldset label span {
  min-width: 125px;
  padding: 0 15px;
  text-align: right;
}
.field {
  flex: 1;
  padding: 0 15px;
  background: transparent;
  font-weight: 400;
  color: #31325f;
  outline: none;
  cursor: text;
}
input {
  flex: 1;
  border-style: none;
  outline: none;
  color: #313b3f;
}

fieldset .select::after {
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  /* background-image: url(/images/dropdown.svg); */
  pointer-events: none;
}
fieldset label.state {
  display: inline-flex;
  width: 40%;
}
fieldset label.zip {
  display: inline-flex;
  width: 60%;
  /* padding-right: 60px; */
}
</style>