<template>
  <b-modal
    ref="modal"
    size="lg"
    :title="isnew?'New Quote':'Edit Quote'"
    no-close-on-esc
    hide-footer
  >
    <div class v-if="show && quote">
      <div class="p-3">
        <div class="row">
          <div class="col-lg-8">
            <div>
              <section class="form-group">
                <label>Subcontractor</label>
                <div>
                  <contact-selector
                    :contact="quote.contact"
                    name="company"
                    ref="contactSelctor"
                    :class="{ 'is-invalid': validated && !quote.contact._id }"
                    placeholder="Select subcontractor"
                    @change="contactChanged"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="validated && !quote.contact._id"
                  >Select a Contact</div>
                </div>
              </section>

              <section class="form-group">
                <label>Work Item</label>
                <div>
                  <item-selector
                    name="title"
                    ref="itemSelector"
                    :item="quote.item"
                    placeholder="Select work item"
                    :class="{
								'is-invalid': validated && !quote.item._id,
							}"
                    @change="itemChanged"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="validated && !quote.item._id"
                  >Please select a item</div>
                </div>
              </section>

              <section class="form-group row">
                <div class="col-lg-6">
                  <div class="float-right">
                    <label>Date Received</label>
                    <date-input ref="datePicker" @change="dateChanged" v-model="quote.date" />
                  </div>
                </div>
              </section>

              <section class="row">
                <div class="col-lg-6 form-group">
                  <label>Quote Amount </label>
                  <amount-input
                    v-model="quote.amount"
                    ref="amountInput"
                    tabindex="-1"
                    placeholder="Amount"
                    inputclass="font-weight-md px-0"
                  />
                </div>
              </section>
            </div>
            <section class="form-group">
              <b-checkbox v-model="quote.accepted" switch>Award Contract</b-checkbox>
            </section>

            <section class="form-group">
              <label>Quotation notes</label>
              <div>
                <textarea
                  class="form-control"
                  v-model="quote.note"
                  placeholder="note"
                  ref="noteInput"
                  rows="5"
                ></textarea>
              </div>
            </section>
          </div>
          <div class="col-lg-4">
            <entity-attachments
              :entityName="'receivedQuote'"
              :entity="quote"
              note="Upload quotation file here"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer save-footer mt-3">
        <save-cancel @save="save" @cancel="cancel"></save-cancel>
      </div>
    </div>
  </b-modal>
</template>

<script>
import data from "@/mixins/dataMixin.js";
import modal from "@/mixins/modalMixin.js";
import dataMixin from "@/mixins/dataMixin";
import routeMixin from "@/mixins/routeMixin.js";
export default {
  name: "receivedQuoteEdit",
  mixins: [data, modal, dataMixin, routeMixin],
  props: { project: Object },

  data() {
    return {
      title: "Add Quote",
      quote: "",
      validated: false
    };
  },

  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.quote = this.newQuote();
      this.validated = false;
    },
    newQuote() {
      return {
        date: new Date(),
        amount: 0,
        note: "",
        accepted: false,
        contact: { _id: "" },
        item: { _id: "" },
        project: {
          _id: this.project._id,
          name: this.project.name
        },
        attachments: []
      };
    },
    open(quote) {
      const newquote = this.newQuote();
      if (!quote) this.quote = newquote;
      else {
        if (!quote._id) {
          this.quote = {
            ...newquote,
            item: quote.item,
            project: quote.project
          };
        } else {
          this.quote = JSON.parse(JSON.stringify(quote));
        }
      }
      this.$refs.modal.show();
      this.show = true;
      setTimeout(() => {
        this.focusNext();
      }, 300);
    },
    dateChanged(val) {
      this.focusNext();
    },
    focus() {
      this.$refs.contactSelctor.show();
    },
    itemChanged() {
      this.focusNext();
    },
    contactChanged() {
      this.focusNext();
    },
    focusNext() {
      setTimeout(() => {
        if (!this.quote.contact._id) {
          this.$refs.contactSelctor.show();
          return;
        }
        if (!this.quote.item._id) {
          this.$refs.itemSelector.show();
          return;
        }
        if (!this.quote.amount) {
          this.$refs.amountInput.focus();
          return;
        }
      }, 200);
    },

    async save() {
      this.validated = true;
      if (!this.valid) return;
      try {
        const quote = this.isnew
          ? await this.addQuote()
          : await this.updateQuote();
        this.reset();
        this.$emit("done", quote);
        this.close();
      } catch (error) {
        console.log("error", error);
      }
    },
    async addQuote() {
      const model = { ...this.quote, title: this.quote.item.name };
      return await this.add("receivedQuote", model);
    },
    async updateQuote() {
      return await this.update("receivedQuote", this.quote);
    }
  },
  computed: {
    valid() {
      return (
        this.quote.contact && this.quote.contact._id && this.quote.item._id
      );
    },
    isnew() {
      return !this.quote._id;
    }
  }
};
</script>
