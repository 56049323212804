<template>
  <div class="d-flex justify-content-between align-items-center">
    <last-modified :entity="entity" v-if="!showDel" />
    <delete-confirm
      @show="toggle"
      :entity="entity"
      fill
      @confirm="del"
      :confirmtext="confirmtext"
      :name="entity.name"
    />
  </div>
</template>
<script>
export default {
  name: "entityFooter",
  props: {
    entity: Object,
    confirmtext: String
  },
  data() {
    return {
      showDel: false
    };
  },
  methods: {
    toggle(show) {
      this.showDel = show;
    },
    del() {
      this.$emit("del");
    }
  }
};
</script>