<template>
  <card-layout nofooter back :state="state" class="bg-1">
    <div slot="header" class="d-flex">
      <!-- <add-button @add="add">Add {{ selected.name }}</add-button> -->
    </div>
    <div slot="title" class="d-flex">Project Gallary</div>
    <div class="card-body" slot="data">
      <SetDefaultShare
        :projectId="project_id"
        entityName="gallery"
        v-model="sharedCustomerDefault"
      />
      <file-upload
        :projectId="project_id"
        :docType="docType"
        entityName="doc"
        @uploaded="uploaded"
        accept="image/*"
        class="mb-3"
      />
      <div v-for="d in dayGroups" :key="d.day">
        <div class="px-3 py-2 bg-trans rounded-5-top fs-11 mb-1">{{d.day}}</div>

        <div class="row">
          <div class="col-lg-4" v-for="file in d.files" :key="file._id">
            <file-thumb :file="file" class="mb-3" @del="deleteFile" :shareable="true" />
          </div>
        </div>
      </div>
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import FileThumb from "@/views/attachments/FileThumb";
import FileUpload from "@/views/attachments/FileUpload";
import SetDefaultShare from "@/views/share/SetDefaultShare";
import _ from "lodash";
import moment from "moment";

export default {
  name: "projectGallery",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { FileThumb, FileUpload, SetDefaultShare },
  data() {
    return {
      selected: "",
      docType: {
        code: "g",
        name: "Gallary",
        _id: "g"
      },
      sharedCustomerDefault: false
    };
  },
  mounted() {
    this.selected = {};
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      this.loadByProject("doc", this.project_id);
      this.loaded();
    },
    select(type) {
      this.selected = type;
    },

    async uploaded(docModel) {
      const { name, uploadUrl, docType, projectId } = docModel;
      let model = {
        name,
        projectId,
        url: uploadUrl,
        typeId: this.docType._id,
        sharedCustomer: this.sharedCustomerDefault
      };
      await this.add("doc", model);
    },
    async deleteFile(file) {
      await this.del("doc", file);
      this.$forceUpdate();
    }
  },
  computed: {
    dayGroups() {
      return _(this.files)
        .groupBy(f => moment(f.date).format("YYYY/MM/DD"))
        .map((value, key) => ({ day: key, files: value }))
        .value();
    },
    files() {
      return this.docs.filter(
        o => o.projectId == this.project_id && o.typeId == this.docType._id
      );
    },
    ...mapGetters(["docTypeList", "docs"])
  }
};
</script>
