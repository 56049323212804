<template>
  <card-layout :state="state" nofooter back title="Revise Budget">
    <div slot="header" class="d-flex">
      <save-cancel @save="save" @cancel="cancel" v-if="hasChanges" />
    </div>

    <div class="card-body" v-if="budgetItem">
      <div class="row form-group">
        <div class="col-lg-4">
          <div class="bg-1 p-3 h-100">
            <h4>{{ category.name }}</h4>
            <label class="mt-3 text-gray">Item</label> of work
            <div class="fs-14">{{ budgetItem.name }}</div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="bg-1 rounded-5 shadow-sm p-3">
            <div class="row">
              <div class="col-lg-4">
                <section class="form-group">
                  <label>Current Budget</label>
                  <span class="form-control value d-block fs-12">{{ budgetItem.budget | currency }}</span>
                </section>

                <section class="form-group">
                  <label for="newBudegt">New Budget</label>
                  <amount-input
                    ref="firstInput"
                    class="fs-12 font-weight-md zero-h"
                    id="newBudegt"
                    v-model="history.amount"
                  />
                </section>
              </div>
              <div class="col-lg-8">
                <label>Supporting Documents</label>
                <entity-attachments
                  tabindex="-1"
                  entityName="budgeting"
                  :entity="budgeting"
                  :child="budgetItem"
                  childName="budgetItems"
                />
              </div>
            </div>
            <section>
              <label for="note">Revise Notes</label>
              <div>
                <textarea
                  id="note"
                  class="form-control"
                  placeholder="Reason for revising the budget?"
                  rows="3"
                  v-model="history.note"
                ></textarea>
              </div>
            </section>
          </div>
        </div>
      </div>
      <BudgetItemQuotes
        :budgetItemId="budgetItem._id"
        :budgetingId="budgeting._id"
        class="form-group"
        v-if="budgetItem"
      />
      <budget-history :item="budgetItem" v-if="this.budgetItem" />
    </div>
  </card-layout>
</template>

<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import routeMixin from "@/mixins/routeMixin.js";
import BudgetHistory from "./BudgetHistory";
import BudgetItemQuotes from "./BudgetItemQuotes";

import { mapGetters } from "vuex";

export default {
  name: "reviseBudget",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { BudgetHistory, BudgetItemQuotes },
  data() {
    return {
      history: {
        amount: 0,
        note: ""
      }
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.budgetItem) this.init();
      else this.load();
    }, 500);
  },
  methods: {
    init() {
      this.loading();
      this.history.amount = this.budgetItem.budget;
      this.loaded();
      setTimeout(() => {
        this.$refs.firstInput.focus();
      }, 300);
    },
    load() {},
    async save() {
      let model = this.history;

      try {
        await this.update(
          "budgeting",
          model,
          `revise/${this.budgetItem_id}`,
          this.budgeting_id
        );
      } catch (error) {}
    },
    cancel() {
      this.toBudgets();
    },
    toBudgets() {
      this.$router.push(`/project/${this.budgetItem.projectId}/budgetting`);
    }
  },
  computed: {
    category() {
      return (
        this.categories.find(o => o._id == this.budgetItem.categoryId) || {}
      );
    },
    budgetItem() {
      let budgeting = this.budgetingById(this.budgeting_id);
      return budgeting
        ? budgeting.budgetItems.find(o => o._id == this.budgetItem_id)
        : "";
    },
    budgeting() {
      return this.budgetingById(this.budgeting_id);
    },

    hasChanges() {
      return (
        !this.budgetItem.budget ||
        this.budgetItem.budget != this.history.amount ||
        this.history.note != this.budgetItem.note
      );
    },

    ...mapGetters(["budgetingById", "categories"])
  }
};
</script>

<style lang="scss" scoped>
.form-control.value {
  width: 10rem;
}
</style>
