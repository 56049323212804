<template>
  <table class="table bg-light m-0">
    <tr>
      <td>Amount</td>
      <td class="text-right">{{ payable.amount | currency }}</td>
    </tr>
    <tr v-if="payable.tax1">
      <td>{{ payable.tax1.code }}</td>
      <td class="text-right">{{ payable.tax1.amount | currency }}</td>
    </tr>
    <tr v-if="payable.tax2">
      <td>{{ payable.tax2.code }}</td>
      <td class="text-right">{{ payable.tax2.amount | currency }}</td>
    </tr>
    <tr v-if="payable.refund" class="bg-warning-2">
      <td class="fs-11">Refund</td>
      <td class="text-right fs-11 fw-600">{{ payable.refund | currency }}</td>
    </tr>
    <tr v-if="total != payable.amount">
      <td class="fs-11">Total</td>
      <td class="text-right fs-11 fw-600">{{ payable.total | currency }}</td>
    </tr>
  </table>
</template>
<script>
import calc from "./data/calc.js";

export default {
  name: "payableAmount",
  props: {
    payable: Object
  },
  computed: {
    total() {
      return calc.calcTotal(this.payable);
    }
  }
};
</script>