<template>
  <div class="rounded-5 border-0 form-group bg-2">
    <div
      class="card-header min-h-auto d-flex justify-content-between align-items-center"
    >
      <h5 class="m-0">Project Information</h5>
      <button-edit @edit="$emit('edit')" small />
    </div>
    <div class="card-body">
      <div class="form-group">
        <label for>Name</label>
        <div class="form-control value">{{ project.name }}</div>
      </div>
      <div class="form-group">
        <label for>Client</label>
        <div class="form-control value">
          <contact-view :contact="project.customer" />
        </div>
      </div>
      <div class="form-group">
        <label>Address</label>
        <div class="form-control value">
          <address-view
            :address="project.address"
            readonly
            :show-country="false"
          />
        </div>
      </div>
      <div class="form-group">
        <label>Description</label>
        <div class="form-control value">
          {{ project.description }}
          <br />
        </div>
      </div>
      <div class="form-group">
        <label>Gross Floor Area</label>
        <div class="form-control value">
          {{ project.sqf }}
          <span>ft<sup>2</sup></span>
        </div>
      </div>

      <!-- <div class="form-group">
        <label>Project Status</label>
        <div class="form-control value">
          <ProjectStatus :project="project" current />
          <br />
        </div>
      </div>-->
    </div>
  </div>
</template>
<script>
import ProjectStatus from "./ProjectStatus";
export default {
  name: "porjectDetail",
  components: { ProjectStatus },
  props: {
    project: Object
  }
};
</script>
