 
<template>
  <select
    class="form-control text-center custom-select"
    ref="select"
    @change="change($event.target.value)"
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
  >
    <option
      v-for="s of options"
      v-bind:key="s[id]"
      v-bind:value="s[id]"
      :disabled="s.disabled"
      :class="{'disabled':s.disabled}"
    >{{s[name]}}</option>
  </select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "formSelect",

  props: {
    value: [String, Number],
    options: Array,
    id: { String, default: "code" },
    name: { String, default: "name" }
  },

  methods: {
    change(value) {
      this.$emit("change", value);
    },

    focus() {
      this.$refs.select.focus();
    }
  }
};
</script>

 