import store from "@/store";
import newAddress from "@/components/views/address/newAddress";

export default project => {
	const client = store.getters.client;
	const clientSetting = store.getters.clientSetting;
	const clientProfile = store.getters.clientProfile;
	const headerLogoUrl = store.getters.headerLogoUrl;
	let address = { ...clientProfile.address } || new address();
	if (!address.name) address.name = client.name;

	return {
		number: "",
		rev: 1,
		date: new Date(),
		dueDate: null,
		sender: {
			logo: headerLogoUrl,
			address: address
		},
		customer: {
			_id: "",
			name: ""
		},
		project: { _id: project._id, name: project.name },
		shippingAddress: newAddress(),
		billingAddress: newAddress(),

		terms: "",
		footer: "",
		note: "",
		refNo: "",

		items: [],
		subTotal: 0,
		prevBalance: 0,
		tax1: {
			code: clientSetting.tax1 ? clientSetting.tax1.code : "",
			amount: 0,
			percent: clientSetting.tax1 ? clientSetting.tax1.percent : 0
		},
		tax2: {
			code: clientSetting.tax2 ? clientSetting.tax2.code : "",
			amount: 0,
			percent: clientSetting.tax2 ? clientSetting.tax2.percent : 0
		},
		invoiceTotal: Number,

		amount: 0,
		attachments: []
	};
};
