<template>
	<!-- <div class="d-inline-block">
		<i class="icon la la-spinner la-spin" :style="{ fontSize: `${size}rem` }"></i>
	</div> -->
	<div class="d-inline-block">
		<b-spinner
			type="grow"
			variant="warning"
			label="Spinning"
			v-if="busy"
			:style="`width: ${size}rem; height: ${size}rem;`"
		></b-spinner>
	</div>
</template>
<script>
export default {
	name: 'spinner',
	props: {
		busy: Boolean,
		size: {
			default: '2',
			type: [String, Number],
		},
	},
};
</script>

<style lang="scss" scoped>
// .icon {
// 	color: #0000009c;
// 	font-size: 1.2em;
// }

// .spinner-continer {
// 	// width: 28px;
// 	display: inline-block;
// }
// .spinner {
// 	display: inline-block;
// 	//   margin: auto;
// 	border: 2px solid #ffffffb6;
// 	border-radius: 50%;
// 	border-top: 2px solid rgba(0, 132, 255, 0.5);
// 	//   border-bottom: 2px solid blue;
// 	width: 15px;
// 	height: 15px;
// 	-webkit-animation: spin 1.1s linear infinite;
// 	animation: spin 1.1s linear infinite;
// }

// @-webkit-keyframes spin {
// 	0% {
// 		-webkit-transform: rotate(0deg);
// 	}
// 	100% {
// 		-webkit-transform: rotate(360deg);
// 	}
// }

// @keyframes spin {
// 	0% {
// 		transform: rotate(0deg);
// 	}
// 	100% {
// 		transform: rotate(360deg);
// 	}
// }
</style>
