<template>
  <div>
    <input
      ref="autocomplete"
      :placeholder="placeholder"
      class="form-control search form-group"
      @focus="onFocus"
      @blur="onBlur"
      type="search"
      autocomplete="false"
      v-model="keyword"
    />
  </div>
</template> 
 
<script>
// const CITIES_TYPE = ["locality", "administrative_area_level_3"];
// const REGIONS_TYPE = [
//   "locality",
//   "sublocality",
//   "postal_code",
//   "country",
//   "administrative_area_level_1",
//   "administrative_area_level_2"
// ];
const ADDRESS_COMPONENTS = {
  street_number: { short_name: "street" },
  route: { long_name: "route" },
  locality: { long_name: "city" },
  administrative_area_level_1: { short_name: "state" },
  postal_code: { short_name: "postalCode" },
  country: { long_name: "country", short_name: "countryCode" }
};
export default {
  name: "pleaseSearch",
  props: {
    inputclass: String,
    types: {
      type: String,
      default: "address" //establishment, locality, country
    },
    placeholder: { type: String, default: "Search address or business name" },

    enableGeolocation: {
      type: Boolean,
      default: true
    },
    geolocationOptions: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      place: {},
      keyword: "",
      autocomplete: "",
      countryFilter: "",
      geolocation: {
        /**
         * Google Geocoder Objet
         * @type {Geocoder}
         * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
         */
        geocoder: null,
        /**
         * Filled after geolocate result
         * @type {Coordinates}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
         */
        loc: null,
        /**
         * Filled after geolocate result
         * @type {Position}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
         */
        position: null
      }
    };
  },
  mounted() {
    const options = {};
    if (this.types) {
      options.types = [this.types];
    }
    if (this.countryFilter) {
      options.componentRestrictions = {
        country: this.countryFilter
      };
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      options
    );
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    this.focus();
  },

  watch: {
    countryFilter: function(newVal, oldVal) {
      this.autocomplete.setComponentRestrictions({
        country: this.countryFilter === null ? [] : this.countryFilter
      });
    }
  },

  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    onFocus() {
      this.biasAutocompleteLocation();
      this.$emit("focus");
    },

    onBlur() {
      this.$emit("blur", this.keyword);
    },

    biasAutocompleteLocation() {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          let circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    /**
     * Update location based on navigator geolocation
     */
    geolocate() {
      this.updateGeolocation((geolocation, position) => {
        this.updateCoordinates(geolocation);
      });
    },

    /**
     * Update internal location from navigator geolocation
     * @param  {Function} (geolocation, position)
     */
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        let options = {};
        if (this.geolocationOptions)
          Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition(
          position => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.geolocation.loc = geolocation;
            this.geolocation.position = position;
            if (callback) callback(geolocation, position);
          },
          err => {
            this.$emit("error", "Cannot get Coordinates from navigator", err);
          },
          options
        );
      }
    },

    /**
     * Update internal location from navigator geolocation
     * @param  {Function} (geolocation, position)
     */
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        let options = {};
        if (this.geolocationOptions)
          Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition(
          position => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.geolocation.loc = geolocation;
            this.geolocation.position = position;
            if (callback) callback(geolocation, position);
          },
          err => {
            this.$emit("error", "Cannot get Coordinates from navigator", err);
          },
          options
        );
      }
    },

    format() {
      const {
        street,
        route,
        city,
        state,
        postalCode,
        countryCode
      } = this.place;

      return `${street} ${route},${city}, ${state} ${postalCode}, ${countryCode}`;
    },
    onPlaceChanged() {
      let result = this.autocomplete.getPlace();
      let place = {
        address: {}
      };
      if (result.address_components !== undefined) {
        result.address_components.map(c => {
          if (c.types) {
            let field = ADDRESS_COMPONENTS[c.types[0]];
            if (field) {
              if (field["short_name"])
                place.address[field["short_name"]] = c["short_name"];
              if (field["long_name"])
                place.address[field["long_name"]] = c["long_name"];
              //this.place.state=c.administrative_area_level_1
            }
          }
        });

        place.address.text = result.formatted_address;
        place.types = result.types;

        place.phone = result.formatted_phone_number;
        place.g = {
          id: result.id,
          place_id: result.place_id,
          image:
            result.photos && result.photos.length
              ? result.photos[0].getUrl({ maxWidth: 480, maxHeight: 480 })
              : "",
          types: result.types,
          icon: result.icon
        };

        place.address.location = result.geometry.location;
        place.website = result.website;

        if (result.name && !result.name.startsWith(place.address.street)) {
          place.name = result.name;
        }

        place.address.name = place.name;
        this.$forceUpdate();
        this.$refs.autocomplete.value = "";
        this.place = place;
        this.$emit("select", place);
      }
    }
  },
  beforeDestroy() {
    //remove extra element
    [...document.getElementsByClassName("pac-container")].map(d => {
      d.parentNode.removeChild(d);
    });
    google.maps.event.clearInstanceListeners(this.autocomplete);
  }
};
</script>
