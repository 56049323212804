<template>
  <card-layout :state="state" title="Invite User" back :noheader="modal" v-if="hasAccess">
    <div slot="header" class="d-flex">
      <save-cancel
        savetext="Invite "
        :accessRole="accessRole"
        @save="invite"
        @cancel="cancel"
        v-if="!$v.$invalid"
      />
    </div>
    <div class="card-body px-5" slot="data">
      <p class="fs-12 mb-4">Invite colleagues / partners to join {{ client.name }}</p>
      <hr />
      <div class="clearfix" v-if="result">
        <div class="alert alert-success">
          {{ result }}
          <div v-if="!model.role">
            <hr />
            {{ inviteeName }} will not see any information until access is granted!
            <div class="mt-3 text-center">
              <button class="btn btn-success px-5" @click="toGrantAccess">Grant Access</button>
            </div>
          </div>
        </div>

        <div class="mt-5 text-center mb-3">
          <button class="btn btn-outline-primary ml-2 px-5" @click="reset">Invite Another User</button>
        </div>
      </div>

      <div v-else>
        <div class="row form-group">
          <div class="col-lg-6">
            <label for="name">Invite By Email</label>
            <div>
              <input
                class="form-control d-inline"
                type="text"
                name="email"
                ref="firstInput"
                autocomplete="off"
                v-model="model.email"
                @change="getUser($event.target.value)"
                placeholder="Enter the email address"
                :class="{ 'is-invalid': validated && $v.model.email.$invalid }"
              />
              <div class="invalid-feedback">Please enter a valid email</div>
            </div>
          </div>
          <div class="col-lg-4" v-if="user">
            <div class="d-flex align-items-center mt-3">
              <avatar :username="user.displayName" :src="user.photoURL" />
              <span class="font-size-12 ml-3">{{ user.displayName }}</span>
            </div>
          </div>

          <div class="col-lg-6" v-else>
            <label for="name">Name</label>
            <div>
              <input
                class="form-control d-inline"
                type="text"
                id="name"
                name="name"
                ref="name"
                autocomplete="off"
                v-model="model.name"
                placeholder="Enter the name of the person"
              />
            </div>
          </div>

          <div class="col-lg-4"></div>
        </div>
        <div class="row form-group"></div>
        <div class="form-group p-3 bg-light border shadow-sm rounded-5">
          <AccessTypes :name="inviteeName" v-model="model.role" />
        </div>

        <div>
          <label>Notes</label>
          <textarea rows="3" class="form-control" v-model="model.message"></textarea>
        </div>
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
      </div>
    </div>
    <div slot="footer" v-if="!result">
      <save-cancel
        savetext="Invite "
        :accessRole="accessRole"
        @save="invite"
        @cancel="cancel"
        v-if="!$v.$invalid"
      />
    </div>
  </card-layout>
</template>

<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AccessTypes from "@/views/settings/access/AccessTypes.vue";
import { checkAccess } from "@/mixins/accessMixin";
export default {
  name: "invite",
  mixins: [baseMixin, dataMixin, validationMixin],
  components: { AccessTypes },
  props: {
    modal: Boolean
  },

  data() {
    return {
      validated: false,
      searchingUser: false,
      user: "",
      sent: false,
      model: {
        email: "",
        name: "",
        message: "",
        role: "r"
      },
      result: "",
      error: "",
      accessRole: "a"
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.firstInput.focus();
    }, 300);
    this.loaded();
  },
  methods: {
    reset() {
      this.model = {
        email: "",
        name: "",
        message: "",
        role: ""
      };
      this.validated = false;
      this.user = "";
      this.result = "";
    },
    async invite() {
      this.error = "";
      if (this.$v.$invalid) return;
      const data = {
        email: this.model.email,
        name: this.user ? this.user.displayName : this.model.name,
        phone: this.user ? this.user.phone : "",
        role: this.model.role,
        message: this.model.message
      };

      try {
        this.result = await this.add("invite", data);
        this.$router.push("/settings/useraccess/0");
      } catch (e) {
        this.error = e.data;
      }
    },

    async getUser(email) {
      this.validated = true;
      this.user = "";
      if (this.$v.model.email.$invalid) return;
      try {
        this.searchingUser = true;
        this.user = await this.loadByAction(
          "user",
          `email/${email}`,
          true,
          false
        );
        console.log("this.user", this.user);
      } catch (e) {
        console.log("error", e);
      } finally {
        this.searchingUser = false;
      }
    },

    toGrantAccess() {
      this.$router.push(`/settings/useraccess/${this.result.clientUserId}`);
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  validations: {
    model: {
      email: {
        required,
        email
      }
    }
  },
  computed: {
    inviteeName() {
      return this.user ? this.user.displayName : "";
    },
    hasAccess() {
      return checkAccess(this.myrole, this.accessRole);
    },
    ...mapGetters(["client", "myrole"])
  }
};
</script>

<style lang="scss">
.code {
  font-weight: 600;
  width: 4em;
}
.number {
  width: 70% !important;
  font-weight: 600;
}
</style>
