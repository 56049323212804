<template>
  <div
    class="d-flex justify-content-between px-3 form-group entity-view"
    :class="{ 'rounded-5 bg-success-1  py-2 shadow-sm': isSelected }"
  >
    <div v-if="!isSelected" class="d-flex justify-content-between w-100">
      <div class="d-flex">
        <svg-icon name="filter" color="gray" :size="20" class="mr-3" />
        <a class="action-link" @click="toggle">
          <span class>{{current || 'Filter Date'}}</span>
        </a>
      </div>
      <i class="icon-arrow-down float-right align-middle mt-1 ml-2 mr-3"></i>
    </div>

    <div class="d-flex justify-content-between w-100" v-else>
      <a class="action-link" @click="toggle">
        <div class="d-flex">
          <i class="icon-calendar mr-3 fs-15 mt-1"></i>
          <div>
            <div class="fs">{{current || 'Filter Date'}}</div>
            <div class="text-center slected-dates text-primary" v-if="selected && !isRange">
              <span>{{dates.start | format_ll}}</span>
              <span v-if="!selected.day">- {{dates.end | format_ll}}</span>
            </div>
          </div>
        </div>
      </a>
      <button @click="clear" type="button" class="btn close" v-if="isSelected">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="d-flex">
      <div class="position-relative">
        <!-- <div class="mb-2 bg-light pl-3 pr-4" v-if="isRange">
          <button
            @click="closeDateRange"
            type="button"
            class="close close-1 pull-right position-absolute"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <section class="form-group">
            <label>Start</label>
            <section class="form-group">
              <date-inpt v-model="dates.start" ref="startPicker" @change="startDateChanged" />
            </section>
          </section>
          <section class="form-group">
            <label>End</label>
            <section class="form-group">
              <date-inpt v-model="dates.end" ref="startPicker" @change="endDateChanged" />
            </section>
          </section>
        </div>-->

        <div class="mb-3" v-if="!isRange"></div>
        <!-- <div class="mb-2" v-if="!isRange">
          
        </div>-->

        <div class="card card-body popover d-flex pt-3" v-if="show && !isRange">
          <button
            @click="close"
            type="button"
            class="close pull-right position-absolute"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="list-group list-group-flush min-w">
            <div
              class="list-group-item list-group-list-group-item-action c-pointer border-0 py-2 w-100"
              @click="select(b)"
              v-for="b in dateButtons"
              v-bind:key="b.value"
            >{{b.text}}</div>
          </div>
          <!-- <button class="btn mr-3 mb-1 btn-sm bg-transparent" v-if="selected" @click="clear()">
            <i class="la la-close"></i>
            Clear Date Fileter
          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "dateRange",
  props: {
    value: Object
  },
  data() {
    return {
      dateInputProps: { class: "form-control date-input small" },
      dates: {
        start: new Date(),
        end: new Date()
      },
      selected: "",
      show: false,
      dateButtons: [
        {
          value: "0",
          text: "Today",
          offset: [0, 0, "days"],
          active: "",
          day: true
        },
        {
          value: "d",
          text: "Yesterday",
          offset: [-1, -1, "days"],
          active: "",
          day: true
        },
        {
          value: "7d",
          text: "Last Week",
          offset: [-7, 0, "days"],
          active: "",
          day: false
        },
        {
          value: "m",
          text: "Last Month",
          offset: [-1, 0, "months"],
          active: "",
          day: false
        },
        {
          value: "4m",
          text: "Last Quarter",
          offset: [-4, 0, "months"],
          active: "",
          day: false
        },
        {
          value: "y",
          text: "Last Year",
          offset: [-1, 0, "years"],
          active: "",
          day: false
        }
        // {
        //   value: "f",
        //   text: "Fiscal Year",
        //   offset: [-7, 0, "days"],
        //   active: "",
        //   day: false
        // }
        // {
        //   value: "o",
        //   text: "Date Range",
        //   offset: [10, 10, "days"],
        //   active: "",
        //   day: false
        // }
      ]
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    closeDateRange() {
      this.clear();
    },
    select(b) {
      if (b.disabled) return;
      this.selected = b;
      this.dateButtons.map(btn => (btn.active = btn.value == b.value));
      if (b.value == "o") {
        this.pickStart();
        return;
      }

      if (!b.offset) {
        return;
      }
      setTimeout(() => {
        this.close();
      }, 200);
      let today = new Date();
      this.dates.start = moment(today)
        .add(b.offset[0], b.offset[2])
        .toDate();
      this.dates.end = moment(today)
        .add(b.offset[1], b.offset[2])
        .toDate();
      this.onchange();
    },
    pickStart() {
      setTimeout(() => {
        let input = this.$refs.startPicker.$el.children[0];
        if (input) {
          input.focus();
        }
      }, 300);
    },
    pickEnd() {
      setTimeout(() => {
        let input = this.$refs.endPicker.$el.children[0];
        if (input) {
          input.focus();
        }
      }, 300);
    },
    startDateChanged(val) {
      this.pickEnd();
      this.dates.start = val.date;
      this.onchange();
    },
    endDateChanged(val) {
      this.dates.end = val.date;
      this.onchange();
    },
    clear() {
      this.$emit("change", {});
      this.selected = "";
      setTimeout(() => {
        this.close();
      }, 100);
    },
    onchange() {
      this.$emit("change", {
        start: moment(this.dates.start).format(this.dateFormat),
        end: moment(this.dates.end).format(this.dateFormat),
        dateType: this.selected.value
      });
    }
  },
  computed: {
    current() {
      return this.selected ? this.selected.text : "";
    },
    isRange() {
      return this.selected && this.selected.value == "o";
    },
    isSelected() {
      return this.selected;
    },
    ...mapGetters(["dateFormat"])
  }
};
</script>

<style scoped>
.popover {
  position: absolute;
  top: 2.5rem;
  left: 0;
  margin: auto;
}
.slected-dates {
  /* margin-top: -0.2rem; */
}
.close {
  right: 0.5rem;
  top: 0.5rem;
}
.close-1 {
  top: 0.25rem;
}

i.la {
  font-size: 1.2em;
  vertical-align: middle;
}
label {
  margin-bottom: 0.25rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
.min-w {
  width: 10rem;
}
</style>