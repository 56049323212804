<template>
	<div class="popover-wrapper " :style="popStyle">
		<div class="push popover-content " ref="popover" :class="className" :style="style">
			<div v-if="show">
				<div>
					<slot name="header"></slot>
				</div>
				<button @click="close" type="button" class="close pull-right" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<main>
					<slot></slot>
				</main>
				<footer>
					<slot name="footer"></slot>
				</footer>
			</div>
		</div>

		<div v-if="show" class="backdrop" @click="close"></div>
	</div>
</template>

<script>
export default {
	props: {
		show: Boolean,
		align: {
			type: String,
			default: 'c', // center, left
		},

		offsetY: {
			type: Number,
			default: 2,
		},
		offsetX: {
			type: Number,
			default: 1,
		},
	},

	data() {
		return {
			style: 'right:0',
		};
	},

	methods: {
		close() {
			this.$emit('close');
		},

		setStyle() {
			let elem = this.$refs.popover;
			if (elem && this.show) {
				let w = elem.offsetWidth;

				if (this.align == 'l') {
					this.style = `left:  -${0.5}rem`;
				} else if (this.align == 'r') {
					this.style = `right: -5rem`;
				} else {
					this.style = `left: -${w / 2}px`;
				}
			}
		},
	},

	updated() {
		this.setStyle();
	},

	computed: {
		popStyle() {
			return { top: `${this.offsetY}rem` };
		},
		className: function() {
			return {
				show: this.show,
				left: this.align == 'l',
				right: this.align == 'r',
				center: this.align == 'c',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
$back: #eee;
.popover-wrapper {
	position: relative;
	// display: inline-block;
	float: left;
}

.popover-content {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	transform: translate(0, 10px);
	background-color: $back;
	// padding-top: 0.5rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	width: auto;
	// top: 4rem;
	border-radius: 0.2rem;
}

.popover-content:before {
	position: absolute;
	z-index: -1;
	content: '';
	top: -8px;
	border-style: solid;
	border-width: 0 10px 10px 10px;
	border-color: transparent transparent $back transparent;
	transition-duration: 0.3s;
	transition-property: transform;
}

.popover-content.left:before {
	left: 2rem;
}

.popover-content.right:before {
	right: 1rem;
}

.popover-content.center:before {
	right: calc(50% - 1rem);
}

.popover-wrapper .popover-content.show {
	z-index: 9999;
	opacity: 1;
	visibility: visible;
	transform: translate(0, -20px);
	transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.close {
	position: absolute;
	right: 0.75rem;
	top: 0.25rem;
	font-weight: 300;
	font-size: 2rem;
}

.backdrop {
	background-color: transparent;
	// opacity: 0.1;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9990;
}
</style>
