<template>
  <div>
    <table class="table table-hover bg-white mb-0 thead-md shadow" v-if="list && list.length">
      <thead>
        <tr>
          <th scope="col" class="pl-3 w-8">Date</th>
          <!-- <th scope="col" class="w-7">project</th> -->
          <th scope="col" class="w-7">Contact</th>
          <th scope="col" class="w-7">Title</th>
          <th scope="col" class="w-6">Start</th>
          <th scope="col" class="w-6">End</th>
          <th scope="col" class="w-5">Duration</th>
          <th scope="col">Notes</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="t in  list" v-bind:key="t._id">
          <td class="date no-wrap">
            <router-link :to="`timesheet/${t._id}/edit`">{{t.date | dateformat}}</router-link>
          </td>
          <!-- <td>{{t.project.name}}</td> -->
          <td>
            <contact-view :contact="t.contact" />
          </td>
          <td>{{t.item.name}}</td>
          <td>{{t.startTime}}</td>
          <td>{{t.endTime}}</td>
          <td>
            <span class="badge badge-info fs-1">{{t.duration | duration}}</span>
          </td>
          <td class="text-secondary">{{t.note}}</td>

          <td>
            <div class="d-flex align-items-center">
              <i
                v-if="t.sharedCustomer"
                class="icon-share small mr-2"
                v-tooltip="'Shared with client'"
              ></i>
              <attachment-icon :attachments="t.attachments" class="py-1" />
            </div>
          </td>
        </tr>
        <tr class="bg-2">
          <td class="text-right" colspan="5">Total:</td>
          <td colspan="2">
            <span class="fs-12">{{total | duration}}</span>
            <small class="text-muted ml-2">hr</small>
          </td>
          <td colspan="1"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "timeSheetTable",
  props: {
    list: Array
  },
  computed: {
    total() {
      return this.list
        ? this.list.map(o => o.duration || 0).reduce((a, b) => a + b)
        : 0;
    }
  }
};
</script>

 