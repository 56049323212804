<template>
  <div>
    <table class="table table-hover thead-md pad-md bg-1 m-0 bg-white" v-if="list.length">
      <thead>
        <tr>
          <th scope="col" class="w-10">Company</th>
          <th scope="col" class="w-7">Date</th>
          <th scope="col" class="w-12">Item</th>
          <th scope="col" class="w-8">Quote Amount</th>
          <th scope="col" class="w-5 no-wrap">Awarded</th>
          <th scope="col">Notes</th>
          <th scope="col" class="w-3"></th>
          <th scope="col" class="w-4"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="quote in list" v-bind:key="quote.id">
          <td class="py-2 w-5">
            <contact-view :contact="quote.contact" />
          </td>
          <td class="w-4">{{ quote.date | dateformat }}</td>
          <td class="w-4">{{ quote.item.name }}</td>
          <td>{{ quote.amount | currency }}</td>

          <td>
            <b-checkbox v-model="quote.accepted" switch disabled />
          </td>

          <td>
            {{ quote.note }}
            <div>
              <div
                class="confirm-del border alert alert-danger py-2"
                v-if="quote.__status == 'confirm-delete'"
              >
                <div class="mb-2">Delete this quote ?</div>
                <button @click="delQuote(quote)" class="btn btn-danger btn-sm mx-2 px-3">Delete</button>
                <button class="btn ml-2 btn-sm px-3" @click="cancelDel(quote)">Cancel</button>
              </div>
              <div
                class="confirm-del border alert alert-danger py-2 d-flex"
                v-if="quote.__status == 'deleting'"
              >
                <spinner :busy="true" :size="1.5" class="mr-2" />Deleting...
              </div>
            </div>
          </td>
          <td>
            <attachment-icon :attachments="quote.attachments" class="py-1" />
          </td>
          <td>
            <b-dropdown variant="light" toggle-class="text-decoration-none" no-caret right>
              <template v-slot:button-content>
                <i class="icon-options-vertical"></i>
                <span class="sr-only">Setting</span>
              </template>
              <b-dropdown-item @click="$emit('edit',quote)">Edit</b-dropdown-item>
              <b-dropdown-item @click="toContract(quote)">Convert to Contract</b-dropdown-item>
              <b-dd-divider />
              <b-dropdown-item @click="confirmDel(quote)" class="text-danger">
                <div class="text-danger">Delete</div>
              </b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import accessMixin from "@/mixins/routeMixin.js";
const entityName = "receivedQuote";

export default {
  name: "receivedQuoteTable",
  mixins: [accessMixin, baseMixin, dataMixin, routeMixin],
  components: {},
  props: {
    list: Array
  },
  methods: {
    async toContract(quote) {
      let contract = await this.update(
        entityName,
        { _id: quote._id },
        `toContract`
      );
      console.log(contract);
      this.$router.push(
        `/project/${contract.project._id}/contract/${contract._id}/edit`
      );
    },
    confirmDel(quote) {
      this.$store.commit("SET_STATUS", {
        entity: quote,
        status: "confirm-delete"
      });
    },
    async delQuote(quote) {
      await this.del(entityName, quote);
      this.$emit("done");
    },
    cancelDel(quote) {
      this.$store.commit("CLEAR_STATUS", quote);
    }
  },
  computed: {
    nodata() {
      return this.ready && !this.list.length;
    }
  }
};
</script>
 