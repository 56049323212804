import { GET_PROFILE, ADD_USER, CREATE_CLIENT, UPDATE_CLIENT, SWITCH_CLIENT, SWITCH_TO_DEMO } from "./types";
import { TOAST_ERROR, TOAST_SUCCESS } from "@/store/toast/types";
import logger from "@/store/logger/index";
import router from "@/router";
import http from "@/api/http";
import { setToken } from "../../firebaseAuth";

//holds current cid in local storage, when referesh uses from local storage
let currentcid = localStorage.getItem("currentcid");
if (currentcid) http.defaults.headers.common["x_cid"] = currentcid;

const setCidHeader = (cid) => {
	localStorage.setItem("currentcid", cid);
	http.defaults.headers.common["x_cid"] = cid;
};

const state = {
	client: "",
	userClient: "",
	profileLoaded: false,
	demoCid: "",
};

const getters = {
	client: (state) => state.client,
	cid: (state) => (state.client ? state.client._id : ""),
	clientSetting: (state) => state.client && state.client.setting,
	clientProfile: (state) => state.client && state.client.profile,
	dateFormat: (state) => (state.client && state.client.setting ? state.client.setting.dateFormat : "L"),
	logoUrl: (state) => state.client && state.client.logoUrl,
	headerLogoUrl: (state) => state.client && state.client.setting && state.client.setting.headerLogoUrl,
	profileLoaded: (state) => state.profileLoaded,
	noClient: (state) => !state.client || !state.client.name,
	clientName: (state) => state.client && state.client.name,
	myrole: (state) => state.userClient && state.userClient.role,
	isAdmin: (state) => state.userClient && state.userClient.role == "a",
	isReadOnly: (state) => state.userClient && state.userClient.role == "r",
	isFullAccess: (state) => state.userClient && state.userClient.role == "f",
	isDemo: (state) => (state.client ? state.demoCid == state.client._id : false),
	demoCid: (state) => state.demoCid,
};

const actions = {
	[SWITCH_CLIENT]: ({ state, commit, dispatch }, cid) => {
		setCidHeader(cid);
		commit("RESET");
		return dispatch(GET_PROFILE);
	},
	[SWITCH_TO_DEMO]: ({ state, commit, dispatch }) => {
		return http
			.get("/user/joinToDemo")
			.then((resp) => {
				const { cid } = resp.data;
				return dispatch(SWITCH_CLIENT, cid);
			})
			.catch((e) => {
				commit(TOAST_ERROR, e.response);
				throw e.response;
			});
	},
	[GET_PROFILE]: ({ state, commit, dispatch }) => {
		let url = `/user/profile`;

		return http
			.get(url)
			.then((response) => {
				let data = response.data;
				if (!data && response.status == 204) {
					commit(GET_PROFILE, {});
				} else {
					commit(GET_PROFILE, data);
				}
				return data;
			})
			.catch((e) => {
				commit("TOAST_ERROR", e, {});
			});
	},

	[CREATE_CLIENT]: ({ state, commit, dispatch }, data) => {
		commit("SAVING", true);
		return http
			.post("/user/createClient", data)
			.then((resp) => {
				//invoke new token
				return setToken().then((o) => {
					commit("RESET");
					commit(GET_PROFILE, resp.data);
					return resp.data;
				});
			})
			.catch((e) => {
				commit(TOAST_ERROR, e.response);
				throw e.response;
			})
			.finally(() => {
				commit("SAVING", false);
			});
	},
	[UPDATE_CLIENT]: ({ commit, dispatch }, { action, data, skey }) => {
		if (!action) action = "update";
		let url = `/client/${action}`;
		if (skey) commit("SAVINGKEY", { skey, saving: true });
		else commit("SAVING", true);
		return http
			.put(url, data)
			.then((response) => {
				let data = response.data;
				commit(UPDATE_CLIENT, data);
				logger.info(`client updated successfully!`);
				return data;
			})
			.catch((e) => {
				commit(TOAST_ERROR, e.response);
				throw e.response;
			})
			.finally(() => {
				if (skey) commit("SAVINGKEY", { skey, saving: false });
				else commit("SAVING", false);
			});
	},
};

const mutations = {
	[GET_PROFILE]: (state, profile) => {
		state.profileLoaded = true;
		if (profile.notExist) {
			state.client = "";
			state.demoCid = "";
			state.userClient = "";
			setCidHeader("");
		} else {
			const client = profile ? profile.client : {};
			state.client = client;
			state.userClient = profile ? profile.me : "";
			state.demoCid = profile.demoCid;
			setCidHeader(client._id);
		}
	},
	[UPDATE_CLIENT]: (state, profile) => {
		state.client = profile ? profile.client : "";
		state.userClient = profile ? profile.me : "";
	},
};

export default {
	getters,
	state,
	actions,
	mutations,
};
