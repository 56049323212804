<template>
  <div class="row form-group">
    <div class="col-lg-7">
      <div class="row">
        <div class="col-lg-6 form-group">
          <label>Select Contractor</label>
          <div>
            <contract-selector
              name="title"
              ref="contractView"
              :contract="contract"
              :projectId="project_id"
              placeholder="Select contractor"
              @change="onContactSelect"
              class="form-control"
              hide-item
            />
          </div>
        </div>

        <div class="col-lg-6 form-group">
          <label>Select Work Item</label>
          <item-selector
            name="title"
            ref="itemSelector"
            :item="dailyLog.item||{}"
            placeholder="Select item"
            @change="itemChanged"
            class="form-control"
          />
        </div>
      </div>

      <div class="form-group">
        <time-input label="Start Time" v-model="dailyLog.startTime" class="d-inline-block" />
        <time-input label="End Time" v-model="dailyLog.endTime" class="d-inline-block ml-3" />
      </div>
      <div class="row form-group">
        <div class="col-lg-4">
          <label>Number of People</label>
          <input class="form-control" placeholder="# people working" v-model="dailyLog.noOfPeople" />
        </div>
      </div>

      <div class></div>

      <div class="form-group">
        <user-view :user="user" class="mb-2" v-if="!dailyLog._id" />
        <last-modified :entity="dailyLog" simple class="pb-1 px-2" v-else />
        <textarea
          ref="noteInput"
          v-model="dailyLog.note"
          class="form-control"
          placeholder="Describe today's events"
          rows="5"
          maxlength="500"
          :class="{ 'is-invalid': validated && !dailyLog.note }"
          id="note"
        ></textarea>
        <div class="invalid-feedback" v-if="validated && !dailyLog.note">Please enter daily log</div>
      </div>
      <section>
        <b-checkbox v-model="dailyLog.sharedCustomer" switch>Share with Client</b-checkbox>
      </section>
    </div>

    <div class="col-lg-5">
      <label>Attach Images / Files</label>
      <entity-attachments entityName="dailyLog" :entity="dailyLog" class="px-2" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import routeMixin from "@/mixins/routeMixin.js";
export default {
  name: "logEdit",
  mixins: [routeMixin],
  props: {
    dailyLog: Object,
    validated: Boolean
  },
  data() {
    return {
      contract: {
        item: {},
        contact: {}
      }
    };
  },
  mounted() {
    this.$nextTick(_ => {
      const { project, item, contact } = this.dailyLog;
      this.contract = { item, contact, project };
    });
  },
  methods: {
    itemChanged() {
      this.focusNext();
    },
    onContactSelect(contract) {
      this.dailyLog.contact = { ...contract.contact };
      this.dailyLog.item = { ...contract.item };
      setTimeout(() => {
        this.focusNext();
      }, 100);
    },
    focusNext() {
      if (!this.dailyLog.item._id) {
        this.$refs.itemSelector.show();
        return;
      }
      if (!this.dailyLog.note) {
        this.$refs.noteInput.focus();
      }
    }
  },
  computed: {
    ...mapGetters(["user"])
  }
};
</script>

<style>
</style>