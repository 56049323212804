<template>
  <center-layout>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header">
            <h5 class="text-center">User Profile</h5>
          </div>
          <div class="card-body px-5 py-4">
            <div>
              <div class="row">
                <div class="col-lg-6">
                  <user-avatar
                    v-model="model.photoURL"
                    :readonly="false"
                    @change="onImageChange"
                    class="mr-3"
                  />
                </div>
                <div class="col-lg-6">
                  <section class="form-group">
                    <label for="fullName">Phone Number</label>

                    <h4
                      class="form-group form-control value"
                      v-if="user.phoneNumber"
                    >
                      <phone-number v-model="model.phoneNumber" :mask="mask" />
                    </h4>
                    <phone-country-input
                      v-model="phone"
                      @change="onPhoneChange"
                      ref="phone"
                      :validated="validated"
                      v-else
                    />
                  </section>

                  <section class="form-group">
                    <label for="email">Email Address</label>
                    <div class="form-control value fw-600" v-if="user.email">
                      {{ user.email }}
                    </div>
                    <div v-else>
                      <input
                        class="form-control"
                        id="email"
                        placeholder="Enter your email"
                        :class="{
                          'is-invalid': validated && $v.model.email.$invalid,
                        }"
                        type="text"
                        autocomplete="email"
                        v-model="model.email"
                      />
                      <div class="invalid-feedback">
                        Please enter a valid email
                      </div>
                    </div>
                  </section>

                  <section class="form-group">
                    <label for="fullName">Your Name</label>
                    <div>
                      <input
                        class="form-control font-weight-5"
                        :class="{
                          'is-invalid':
                            validated && $v.model.displayName.$invalid,
                        }"
                        id="fullName"
                        ref="fullNameInput"
                        type="text"
                        autocomplete="given-name"
                        placeholder="First and Last name"
                        v-model="model.displayName"
                      />
                      <div class="invalid-feedback">Please enter your name</div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
          </div>
          <div class="card-footer px-4">
            <save-cancel
              @save="save"
              class="float-right"
              :nocancel="true"
              accessRole
              savetext="Next"
              noicon
              save-icon2="icon-arrow-right"
              size="lg"
            />
          </div>
        </div>
      </div>
    </div>
  </center-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import UserAvatar from "./UserAvatar.vue";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
export default {
  name: "updateUserInfo",
  components: {
    UserAvatar,
  },
  mixins: [baseMixin, validationMixin],
  data() {
    return {
      model: {
        _id: "",
        phoneNumber: "",
        displayName: "",
        email: "",
        photoURL: "",
      },
      phone: {
        code: "+1",
        number: "",
      },
      mask: "(+#) ###-###-####",

      validated: false,
      error: "",
    };
    saving: false;
  },
  validations: {
    model: {
      phoneNumber: {
        required,
      },
      displayName: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },

  mounted() {
    this.init();
    this.$refs.fullNameInput.focus();
  },
  methods: {
    init() {
      this.load();
    },
    async load() {
      this.loading();
      let profile = await this.$store.dispatch("GET_PROFILE");
      this.model = { ...this.user };
      this.loaded();
    },
    onPhoneChange(phone) {
      this.model.phoneNumber = `${phone.code}${phone.number}`;
    },
    onImageChange(url) {
      this.model.photoURL = url;
    },
    async save() {
      this.validated = true;
      this.error = "";
      if (this.$v.$invalid || this.saving) return;
      try {
        this.saving = true;
        let data = { ...this.model };
        if (!data.photoURL) data.photoURL = "";
        data.oldEmail = this.user.email;
        await this.$store.dispatch("UPDATE_USER", data);
      } catch (e) {
        if (!e) {
          window.location.reload(true);
          return;
        }
        let error = e.data ? e.data.message : e;
        if (error == "TOO_SHORT") {
          error = "Invalid Phone number!";
          this.$refs.phone.focus();
        }
        this.error = error;
      } finally {
        this.saving = false;
      }
    },
  },
  computed: {
    isValid() {
      return this.emailValid && this.nameValid && this.validNumber;
    },
    emailUpdate() {
      return this.model.email != this.user.email;
    },
    ...mapGetters(["isAuthenticated", "user"]),
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 80rem;
}

.save-btn {
  width: 8rem;
}
</style>
