import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./registerServiceWorker";
import "./prototypes";
import "./components";
import "./directives";
import "./filters";

import "vue2-timepicker/dist/VueTimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "bootstrap-css-only/css/bootstrap.css";
import "./assets/scss/site.scss";

// import "node_modules/bootstrap/scss/bootstrap";
// import "node_modules/bootstrap-vue/src/index.scss";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./registerServiceWorker";

import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);

import Avatar from "vue-avatar";
Vue.component("avatar", Avatar);

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
Vue.use(VueCroppie);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import VCalendar from "v-calendar";
import { isAppInitialized } from "./auth/login/store";
// import "v-calendar/lib/v-calendar.min.css";

Vue.use(VCalendar, {
  firstDayOfWeek: 2,
});

console.log("process.env.NODE_ENV", process.env.NODE_ENV);
console.log("process.env.VUE_APP_API_URL", process.env.VUE_APP_API_URL);

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  await isAppInitialized;
  return next();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
