<template>
  <Fragment>
    <label for="amount" v-if="label">{{ label }}</label>
    <div v-if="readonly" ref="readonly" class="form-control value px-2 text-right">{{ amount }}</div>
    <input
      v-else
      :class="{ 'bg-zero': value == 0 && !zeroOk,   inputclass } "
      type="text"
      class="form-control text-right"
      aria-describedby="emailHelp"
      :placeholder="placeholder"
      :tabindex="tabindex"
      @blur="onBlurHandler"
      @input="onInputHandler"
      @focus="onFocusHandler"
      @change="$emit('change', $event.target.value)"
      ref="numeric"
      v-model="amount"
    />
  </Fragment>
</template>

<script>
import numeral from "numeral";
import { Fragment } from "vue-fragment";
export default {
  name: "amountInput",
  components: { Fragment },
  props: {
    label: String,
    currency: { type: String, default: "", required: false },
    stringfromat: { required: false, type: String, default: "$00,00.00" },
    zeroOk: Boolean,
    /**
     * Maximum value allowed.
     */
    tabindex: [String, Number],

    selectOnFocus: {
      type: Boolean,
      default: true
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
      required: false
    },
    /**
     * Minimum value allowed.
     */
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
      required: false
    },
    /**
     * Enable/Disable minus value.
     */
    minus: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Input placeholder.
     */
    placeholder: {
      type: String,
      default: "",
      required: false
    },
    /**
     * Value when the input is empty
     */
    emptyValue: {
      type: [Number, String],

      default: "",
      required: false
    },
    /**
     * Number of decimals.
     * Decimals symbol are the opposite of separator symbol.
     */
    precision: {
      type: Number,
      default: 0,
      required: false
    },
    /**
     * Thousand separator type.
     * Separator props accept either . or , (default).
     */
    separator: {
      type: String,
      default: ",",
      required: false
    },
    /**
     * Forced thousand separator.
     * Accepts any string.
     */
    thousandSeparator: {
      default: undefined,
      required: false,
      type: String
    },
    /**
     * Forced decimal separator.
     * Accepts any string.
     */
    decimalSeparator: {
      default: undefined,
      required: false,
      type: String
    },
    /**
     * The output type used for v-model.
     * It can either be String or Number (default).
     */
    outputType: {
      required: false,
      type: String,
      default: "Number"
    },
    /**
     * v-model value.
     */
    value: {
      //type: [Number, String],
      validator: prop =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      default: 0,
      required: true
    },
    /**
     * Hide input and show value in text only.
     */
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Class for the span tag when readonly props is true.
     */
    readonlyClass: {
      type: String,
      default: "form-control value",
      required: false
    },
    /**
     * Position of currency symbol
     * Symbol position props accept either 'suffix' or 'prefix' (default).
     */
    currencySymbolPosition: {
      type: String,
      default: "prefix",
      required: false
    },
    inputclass: String
  },
  data: () => ({
    amount: ""
  }),
  computed: {
    /**
     * Number type of formatted value.
     * @return {Number}
     */
    amountNumber() {
      return this.unformat(this.amount);
    },
    /**
     * Number type of value props.
     * @return {Number}
     */
    valueNumber() {
      return this.unformat(this.value);
    },
    /**
     * Define decimal separator based on separator props.
     * @return {String} '.' or ','
     */
    decimalSeparatorSymbol() {
      if (typeof this.decimalSeparator !== "undefined")
        return this.decimalSeparator;
      if (this.separator === ",") return ".";
      return ",";
    },
    /**
     * Define thousand separator based on separator props.
     * @return {String} '.' or ','
     */
    thousandSeparatorSymbol() {
      if (typeof this.thousandSeparator !== "undefined")
        return this.thousandSeparator;
      if (this.separator === ".") return ".";
      if (this.separator === "space") return " ";
      return ",";
    },
    /**
     * Define format position for currency symbol and value.
     * @return {String} format
     */
    symbolPosition() {
      if (!this.currency) return "%v";
      return this.currencySymbolPosition === "suffix" ? "%v %s" : "%s %v";
    }
  },
  watch: {
    /**
     * Watch for value change from other input with same v-model.
     * @param {Number} newValue
     */
    valueNumber(newValue) {
      if (this.$refs.numeric !== document.activeElement) {
        this.amount = this.format(newValue);
      }
    },
    /**
     * When readonly is true, replace the span tag class.
     * @param {Boolean} newValue
     * @param {Boolean} oldValue
     */
    readonly(newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        this.$nextTick(() => {
          this.$refs.readonly.className = this.readonlyClass;
        });
      }
    },
    /**
     * Immediately reflect separator changes
     */
    separator() {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },
    /**
     * Immediately reflect currency changes
     */
    currency() {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },
    /**
     * Immediately reflect precision changes
     */
    precision() {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    },
    stringfromat() {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    }
  },
  mounted() {
    // Set default value props when placeholder undefined.
    if (!this.placeholder) {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
      // In case of delayed props value.
    }
    // setTimeout(() => {
    // 	this.process(this.valueNumber);
    // 	this.amount = this.format(this.valueNumber);
    // }, 500);
    this.$nextTick(o => {
      this.process(this.valueNumber);
      this.amount = this.format(this.valueNumber);
    });
    // Set read-only span element's class
    if (this.readonly) this.$refs.readonly.className = this.readonlyClass;
  },
  methods: {
    /**
     * Handle blur event.
     * @param {Object} e
     */
    onBlurHandler(e) {
      this.$emit("blur", e);
      this.amount = this.format(this.valueNumber);
    },
    /**
     * Handle focus event.
     * @param {Object} e
     */
    focus() {
      if (this.$refs.numeric) this.$refs.numeric.focus();
    },
    onFocusHandler(e) {
      this.$emit("focus", e);
      if (this.valueNumber === 0) {
        this.amount = null;
      } else {
        this.amount = numeral(this.valueNumber).value();
        if (this.selectOnFocus) {
          setTimeout(() => {
            if (this.$refs.numeric) this.$refs.numeric.select();
          }, 100);
        }

        // this.amount = accounting.formatMoney(this.valueNumber, {
        //   symbol: "",
        //   format: "%v",
        //   thousand: "",
        //   decimal: this.decimalSeparatorSymbol,
        //   precision: Number(this.precision)
        // });
      }
    },
    /**
     * Handle input event.
     */
    onInputHandler() {
      this.process(this.amountNumber);
    },
    /**
     * Validate value before update the component.
     * @param {Number} value
     */
    process(value) {
      if (value >= this.max) this.update(this.max);
      if (value <= this.min) this.update(this.min);
      if (value > this.min && value < this.max) this.update(value);
      if (!this.minus && value < 0)
        this.min >= 0 ? this.update(this.min) : this.update(0);
    },
    /**
     * Update parent component model value.
     * @param {Number} value
     */
    update(value) {
      // const fixedValue = accounting.toFixed(value, this.precision);
      // const output =
      //   this.outputType.toLowerCase() === "string"
      // ? fixedValue
      // : Number(fixedValue);
      // this.valueNumber
      const output = numeral(value).value();
      this.$emit("input", output);
    },
    /**
     * Format value using symbol and separator.
     * @param {Number} value
     * @return {String}
     */
    format(value) {
      return numeral(value).format(this.stringfromat);
      // return accounting.formatMoney(value, {
      //   symbol: this.currency,
      //   format: this.symbolPosition,
      //   precision: Number(this.precision),
      //   decimal: this.decimalSeparatorSymbol,
      //   thousand: this.thousandSeparatorSymbol
      // });
    },
    /**
     * Remove symbol and separator.
     * @param {Number} value
     * @return {Number}
     */
    unformat(value) {
      return numeral(value).value();
      // const toUnformat =
      //   typeof value === "string" && value === "" ? this.emptyValue : value;
      // return accounting.unformat(toUnformat, this.decimalSeparatorSymbol);
    }
  }
};
</script>

<style lang="scss" scoped>
// .form-group {
//   input.form-control {
//     width: 10rem;
//   }
// }
.bg-zero {
  background-color: rgba(255, 255, 248, 1);
  color: #ff5722;
}
</style>
