<template>
  <div class="card rounded-0">
    <!-- <div class="py-2 px-3 fs-12 bg-2">{{task.name}}</div> -->
    <div class="px-3 fw-600 pt-2 d-flex justify-content-between align-items-center py-1">
      Dependencies ({{task.dependencies.length}})
      <!-- <add-button link class="py-1">Add Dependency</add-button> -->
    </div>

    <div class="card-body overflow-auto p-3 border-top">
      <!-- <div class="list-group list-group-flush">
          <div
            v-for="id in task.dependencies"
            :key="id"
            class="list-group-item text-0 bg-success-1 border-0 d-flex justify-content-between px-3 py-2 rounded-5 mb-1"
          >
            <div class="d-flex align-items-center">
              <i class="icon-arrow-right-circle mr-2 text-2 fs-12"></i>
              {{getTask(id).name}}
            </div>
            <a @click="addRemove(id)" class="text-danger" v-tooltip="'Remove this Dependency'">
              <i class="icon-trash"></i>
            </a>
          </div>
      </div>-->

      <div class="list-group list-group-flush">
        <div
          @click="addRemove(task._id)"
          v-for="task in list"
          :key="task._id"
          :class="{ 'bg-success-1':isDependent(task) }"
          class="list-group-item text-0 border-0 c-pointer d-flex justify-content-between px-2 py-2 list-group-item-action mb-1"
        >
          <div class="d-flex align-items-center">
            <svg-icon
              name="circleChecked"
              class="mr-2"
              :size="20"
              color="green"
              v-if="isDependent(task)"
            />
            <svg-icon name="circle" class="mr-2" :size="20" v-else />
            {{task.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import scheduleMixin from "../data/scheduleMixin";
export default {
  name: "selectDependency",
  mixins: [scheduleMixin],
  props: {
    task: Object,
    scheduling: Object
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let children = this.findPath(this.task);
      let list = this.scheduling.tasks.filter(
        o => !children.includes(o._id) && o._id != this.task._id
      );
      this.list = list;
      this.sortBySelected();
    },
    findPath(task, list) {
      if (!list) list = [];
      let dependons = this.scheduling.tasks.filter(
        o => o.dependencies && o.dependencies.includes(task._id)
      );
      if (dependons.length) {
        dependons.map(t => {
          list.push(t._id);
          this.findPath(t, list);
        });
      }
      return list;
    },
    findChild(task, list) {
      if (!list) list = [];
      if (task.dependencies && task.dependencies.length) {
        task.dependencies.map(id => {
          let childTask = this.scheduling.tasks.find(o => o._id == id);
          if (childTask) {
            list.push(id);
            this.findChild(childTask, list);
          }
        });
      }
      return list;
    },

    addRemove(id) {
      const dependencies = this.task.dependencies;
      let task = this.scheduling.tasks.find(o => o._id == id);
      const i = dependencies.indexOf(id);
      if (i >= 0) {
        dependencies.splice(i, 1);
      } else {
        dependencies.push(id);
      }
      this.sortBySelected();
    },
    sortBySelected() {
      this.list.sort((a, b) => this.isSelected(b) - this.isSelected(a));
    },
    isSelected(t) {
      return this.task.dependencies.includes(t._id) ? 1 : 0;
    }
  },
  computed: {
    isDependent() {
      return task => this.task.dependencies.includes(task._id);
    }
  }
};
</script>
<style lang="scss" scoped>
.card-body {
  height: 18.5rem !important;
}
</style>