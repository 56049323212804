<template>
  <div v-if="scheduling">
    <full-calendar
      :events="demoEvents"
      @eventClick="eventClick"
      locale="en"
      class="p-0 m-auto"
      @dayClick="dayClick"
    />
  </div>
</template>
<script>
import scheduleMixin from "./data/scheduleMixin";
import fullCalendar from "vue-fullcalendar";

export default {
  name: "scheduleCalendar",
  mixins: [scheduleMixin],
  props: {
    scheduling: Object
  },
  components: { fullCalendar },

  methods: {
    dayClick(day, e) {
      console.log("day :", day);
      console.log("jsEvent :", e);
    },
    eventClick(event, jsEvent, pos) {
      console.log("eventClick", event, jsEvent, pos);
    }
  },
  computed: {
    demoEvents() {
      return this.scheduling.tasks.map(t => {
        return {
          start: t.start,
          end: t.end,
          title: t.name
        };
      });
    }
  }
};
</script>