<template>
  <b-popover
    v-if="isOpen"
    trigger="manual"
    offset="5"
    :show.sync="isOpen"
    ref="popover"
    custom-class="expense-panel"
    placement="auto"
    :target="target"
    :auto-hide="false"
    no-fade
  >
    <template v-slot:title>
      <div class="d-flex justify-content-between align-items-center pl-3 pr-0">
        List of expenses for the management fee
        <close-button @click="cancel" />
      </div>
    </template>
    <div class="text-left" v-if="isOpen">
      <table class="table table-hover bg-white mb-0">
        <thead>
          <tr>
            <th scope="col" class="w-7 text-center no-wrap">Date</th>
            <th scope="col">Payee</th>
            <th scope="col">Item</th>
            <th scope="col" class="w-7">Reference #</th>
            <th scope="col" class="text-right">Total Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in expenses" v-bind:key="p._id">
            <td>
              <router-link :to="`/payable/${p._id}`">{{p.date | dateformat}}</router-link>
            </td>
            <td>{{ p.payee && p.payee.name }}</td>
            <td>{{ p.title }}</td>
            <td>{{ p.refNo }}</td>

            <td
              class="text-right"
            >{{ (p.amount + (p.tax1?p.tax1.amount:0) + (p.tax2?p.tax2.amount:0)) | currency }}</td>
          </tr>
          <tr class="bg-1">
            <td colspan="3"></td>
            <td class="bg-1 text-right">Total</td>
            <td class="bg-1 text-right">{{total | currency}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-popover>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "invoiceExpense",
  data() {
    return {
      isOpen: false,
      target: "",
      expenses: ""
    };
  },
  methods: {
    open(el, item) {
      this.isOpen = false;
      this.target = el;
      this.expenses = item.expenses;
      setTimeout(() => {
        this.isOpen = true;
      }, 100);
    },
    cancel() {
      this.isOpen = false;
    },
    lineTotal(p) {
      return (
        p.amount + (p.tax1 ? p.tax1.amount : 0) + (p.tax2 ? p.tax2.amount : 0)
      );
    }
  },
  computed: {
    total() {
      return this.expenses.map(this.lineTotal).reduce((a, b) => a + b, 0);
    }
  }
};
</script>
 <style lang="scss">
.expense-panel {
  min-width: 46rem;
}
</style>
 