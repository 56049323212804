<template>
  <b-popover
    trigger="manual"
    offset="5"
    :show.sync="isOpen"
    ref="popover"
    custom-class="item-panel"
    placement="auto"
    :target="target"
    :auto-hide="false"
  >
    <template v-slot:title>
      <div class="p-2">
        <button class="btn close" @click="cancel" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </button>
        {{task.name}}
      </div>
    </template>

    <div class="text-left" v-if="isOpen">
      <tabs :items="tabs" v-model="selectedTab" @change="selectTab" />

      <div class="task-edit-body">
        <TaskInfo :scheduling="scheduling" :task="task" v-if="selectedTab=='t'" />
        <SelectDependency :task="task" :scheduling="scheduling" v-if="selectedTab=='d'" />
        <Successors :task="task" :scheduling="scheduling" v-if="selectedTab=='c'" />
        <Subs :task="task" :scheduling="scheduling" v-if="selectedTab=='s'" />
      </div>

      <div class="card-footer clearfix">
        <delete-confirm
          v-if="!isnew && selectedTab=='t'"
          class="d-inline-block position-absolute"
          :class="{ confirm: deleteMode }"
          @confirm="del"
          entityName="itemMaster"
          :custom="true"
          @onConfirmed="del"
          confirmtext="Are you sure you want to delete this item"
        />
        <save-cancel savetext="Update" noicon @save="update" @cancel="cancel" class="float-right" />
      </div>
    </div>
  </b-popover>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin.js";
import SelectDependency from "./SelectDependency";
import scheduleMixin from "../data/scheduleMixin";
import Successors from "./Successors";
import TaskInfo from "./TaskInfo";
import Subs from "./Subs";
import moment from "moment";
export default {
  name: "taskEdit",
  mixins: [dataMixin, scheduleMixin],
  components: { SelectDependency, TaskInfo, Successors, Subs },
  props: {
    scheduling: Object
  },
  data() {
    return {
      task: {},
      originalTask: "",
      selectedTab: "t",
      tabs: [
        { code: "t", name: "Task" },
        { code: "d", name: "Dependencies" },
        { code: "c", name: "Successors" },
        { code: "s", name: "Subs" }
      ],
      validated: false,
      deleteMode: false,
      isOpen: false,
      target: "",
      bar: "",
      showDependency: false
    };
  },
  methods: {
    open(el, task, bar) {
      this.isOpen = false;
      this.bar = bar;
      this.target = el;
      this.originalTask = task;
      this.task = JSON.parse(JSON.stringify(task));
      setTimeout(() => {
        this.isOpen = true;
      }, 100);
    },
    selectTab(t) {
      this.selectedTab = t.code;
    },
    cancel() {
      this.isOpen = false;
      this.showDependency = false;
    },
    focus() {
      if (!this.task.name) {
        setTimeout(() => {
          this.$refs.itemNameInput.focus();
        }, 500);
      }
    },
    selectDependencies() {
      this.showDependency = true;
    },
    onDelModeChange(mode) {
      this.deleteMode = mode;
    },

    async del() {
      await this.delItem(this.task);
      this.$emit("done");
    },
    async update() {
      this.validated = true;
      if (!this.valid) return;
      const dependencyChanged =
        this.task.dependencies != this.originalTask.dependencies;

      Object.assign(this.originalTask, this.task);

      if (dependencyChanged) {
        this.adjustStartDateFromDepencies(this.originalTask);
      }
      this.sortWithNext(this.originalTask);

      this.isOpen = false;
      this.$emit("update", this.originalTask);
    },
    checkDependenceis(task, dependencyChanged) {
      if (dependencyChanged && task.dependencies && task.dependencies.length) {
        //tasks that this is depend of them
        dependentTasks = this.scheduling.tasks.filter(t =>
          task.dependencies.includes(t._id)
        );
        const moments = dependentTasks.map(o => moment(o.start));
        let maxDate = moment.max(moments);
        let diff = maxDate.diff(moment(task.start));

        if (diff > 0) {
          task.start = maxDate.toDate();
          task.end = moment(task.end)
            .add(diff, "days")
            .toDate();
        }
      }
    }
  },
  computed: {
    ...mapGetters(["categories"]),

    valid() {
      return this.task.name && this.task.start && this.task.end;
    },
    isnew() {
      return !this.task._id;
    }
  }
};
</script>
<style lang="scss">
.item-panel {
  min-width: 30rem;
}
.popover-body {
  padding: 0 !important;
  background-color: rgb(247, 247, 247);
  color: #212529;
}
.popover {
  background: #f9f9f9;
  border: 1px solid rgb(224, 224, 224) !important;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss" scoped>
/* .sel-dep {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0rem;
  top: 3.2rem;
  z-index: 1;
} */
.task-edit-body {
  height: 22rem !important;
  width: 34rem;
}
</style>
