<template>
  <div class="mb-3">
    <div class="card-body px-3">
      <div class="px-5">
        <section class="form-group pt-1">
          <label for="companyName">Company Name</label>
          <div>
            <input
              class="form-control font-weight-5"
              :class="{ 'is-invalid': validated && !companyNameValid }"
              id="companyName"
              ref="companyNameInput"
              type="text"
              placeholder="Enter your business name"
              v-model="client.name"
            />
            <div class="invalid-feedback">Please enter your company name</div>
          </div>
        </section>
        <section class="form-group">
          <label for="type">Business Type</label>
          <BusinessTypeSelect :client="client" :validatedp="validated" :required="true" />
        </section>
      </div>
    </div>
    <div class="bg-transparent border-0 card-footer text-center">
      <save-cancel @save="create" accessRole savetext="Create!" nocancel noicon size="lg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import BusinessTypeSelect from "@/views/businessType/BusinessTypeSelect.vue";
export default {
  name: "clientProfile",
  components: { BusinessTypeSelect },
  mixins: [baseMixin],
  data() {
    return {
      client: {
        businessTypeId: -1,
        name: "",
        description: ""
      },
      selectedBusinessType: "",
      validated: false
    };
  },
  mounted() {
    this.init();
    setTimeout(() => {
      this.$refs.companyNameInput.focus();
    }, 300);
  },
  methods: {
    init() {
      this.getLocation();
    },
    getLocation() {
      return this.$store.dispatch("FIND_LOCATION_ADDRESS");
    },
    async create() {
      this.validated = true;
      if (!this.isValid) return;
      try {
        let address = { ...this.address };
        if (this.address.country) {
          address.country = this.address.country.name;
          address.countryCode = this.address.country.code;
        }
        if (this.address.state) {
          address.state = this.address.state.code;
          address.stateCode = this.address.state.code;
        }
        let data = { ...this.client, address };
        const profile = await this.$store.dispatch("CREATE_CLIENT", data);
        this.$emit("done", profile);
      } catch (error) {
        console.log("error :", error);
      }
    }
  },
  computed: {
    ...mapGetters(["businessTypes", "address", "clientProfile", "user"]),
    isValid() {
      return (
        this.companyNameValid &&
        this.businessTypeValid &&
        this.client.description
      );
    },
    companyNameValid() {
      return this.client.name && this.client.name.length > 2;
    },

    businessTypeValid() {
      return this.client.businessTypeId;
    }
  }
};
</script>
<style lang="scss" scoped></style>
