<template>
  <div class="rounded-5-top">
    <payment-status :payable="payable" class="rounded-5-top border" />
    <table class="table table-hover bg-white h-sm shadow-sm mb-2" v-if="payments.length">
      <thead>
        <tr>
          <th scope="col" class="w-6 no-wrap">Date</th>
          <th scope="col" class="w-6">Account</th>
          <th scope="col">Ref#</th>
          <th scope="col">Notes</th>
          <th scope="col" class="text-right w-7">Amount</th>
          <th scope="col" class="w-2"></th>
          <th scope="col" class="w-2"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in payments" v-bind:key="p._id">
          <td class="no-wrap">{{ p.date | dateformat }}</td>
          <td class="text-2">{{ p.account.name }}</td>
          <td class="text-2">{{ p.refNo }}</td>

          <td class="small text-2">
            <small class="bage badge-warning badge-pill" v-if="p.isReimbursable">Reimbursable</small>
            <div>
              <router-link
                v-if="p.invoiceId"
                :to="`/project/${payable.project._id}/invoice/${p.invoiceId}`"
              >Invoiced</router-link>
            </div>
            <small class="bage badge-danger badge-pill" v-if="p.isRefund">Refund</small>
            <div class="overflow-auto">{{p.note}}</div>
          </td>

          <td class="text-right">{{ p.amount | currency }}</td>

          <td>
            <attachment-icon :attachments="p.attachments" />
          </td>

          <td class="py-1">
            <a-button @click="edit(p)" link>
              <i class="icon-pencil"></i>
            </a-button>
          </td>
        </tr>

        <tr class="total bg-1" v-if="payments.length > 1">
          <td class="text-right" colspan="4">
            <span class="pull-right">Total Paid</span>
          </td>
          <td class="fw-600 text-right">{{ total | currency }}</td>
          <td colspan="2"></td>
        </tr>
        <tr class="balance border-bottom bg-1">
          <td class="text-right" colspan="4">
            <span class="pull-right">Balance</span>
          </td>
          <td class="fw-600 text-scondary text-right">{{ balance | currency }}</td>
          <td colspan="2"></td>
        </tr>
      </tbody>
    </table>
    <div v-if="!payments.length" class="text-center form-group bg-1">
      <div class="text-light-gray fs-12 p-3">No Payments!</div>
    </div>
    <div v-if="!readonly">
      <div class="row">
        <div class="col"></div>

        <div class="col">
          <div v-if="hasBalance" class="my-2">
            <add-button @add="add" block>
              Add
              {{ payments.length > 1 ? ' Another ' : '' }}
              Payment
            </add-button>
          </div>
          <div class="my-2">
            <a-button @click="refund" class="btn-outline-secondary w-100">
              <i class="icon-action-undo mr-2"></i> Refund Payment
            </a-button>
          </div>
        </div>
      </div>
    </div>

    <payment-edit ref="paymentEdit" :payable="payable" @done="$emit('done')" />
  </div>
</template>

<script>
import PaymentEdit from "./PaymentEdit";
import newPayment from "./data/newPayment.js";
import PaymentStatus from "./components/PaymentStatus";
import calc from "./data/calc.js";
import accessMixin from "@/mixins/accessMixin";

export default {
  components: { PaymentEdit, PaymentStatus },
  mixins: [accessMixin],
  props: {
    payable: Object,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalShow: false,
      selectedId: 0,
      isRefund: false
    };
  },
  methods: {
    edit(p) {
      this.$refs.paymentEdit.open(p);
    },
    add() {
      this.$refs.paymentEdit.open();
    },
    refund() {
      let payment = newPayment();
      payment.isRefund = true;
      this.edit(payment);
    }
  },
  computed: {
    total() {
      if (!this.payments) return 0;
      return calc.totalPaid(this.payable);
    },
    balance() {
      return calc.balance(this.payable);
    },
    hasBalance() {
      return this.balance > 0;
    },
    canRefund() {
      return this.payments.some(o => !o.isRefund);
    },
    payments() {
      return this.payable ? this.payable.payments || [] : [];
    }
  }
};
</script>

<style lang="scss" scoped>
tr.total {
  background-color: #f2f2f2 !important;
}
// tr.balance {
// 	.amount {
// 		color: blue;
// 	}
// }
</style>
