<template>
  <b-modal ref="modal" size="lg" :title="title" no-close-on-esc hide-footer>
    <div class="py-2" v-if="show">
      <div class="px-4">
        <section class="row form-group">
          <label class="col-sm-3 col-form-label">Project Name</label>
          <div class="col-sm-9">
            <input
              type="text"
              ref="firstInput"
              v-model="project.name"
              @blur="focusContact"
              :class="{ 'is-invalid': validated && !project.name }"
              placeholder="e.g. Adam's cabinet"
              class="form-control"
            />
            <div
              class="invalid-feedback"
              v-if="validated && !project.name"
            >Please enter project name</div>
          </div>
        </section>

        <section class="form-group row">
          <label class="col-sm-3 col-form-label">Client</label>
          <div class="col-sm-9">
            <contact-selector
              :contact="project.customer"
              name="company"
              ref="contactSelctor"
              :class="{ 'is-invalid': validated && !project.customer._id }"
              placeholder="Select client"
              @change="contactChanged"
              class="form-control"
            />
            <div class="invalid-feedback" v-if="validated && !project.customer._id">Select a Contact</div>
          </div>
        </section>

        <!-- <section class="row form-group">
          <label class="col-sm-3 col-form-label">Project Location</label>
          <div class="col-sm-9">
            <input
              type="text"
              ref="projectAddress"
              v-model="project.address.text"
              placeholder="e.g. 8281 East 16th St."
              class="form-control"
            />
          </div>
        </section>-->

        <section class="row form-group">
          <label class="col-sm-3 col-form-label">Description</label>
          <div class="col-sm-9">
            <textarea class="form-control" v-model="project.description" ref="noteInput" rows="5"></textarea>
          </div>
        </section>
      </div>

      <div class="modal-footer save-footer mt-3">
        <save-cancel @save="save" @cancel="cancel"></save-cancel>
      </div>
    </div>
  </b-modal>
</template>
<script>
import data from "@/mixins/dataMixin.js";
import modal from "@/mixins/modalMixin.js";
import newProject from "./data/new.js";
export default {
  name: "newProject",
  mixins: [data, modal],
  data() {
    return {
      title: "Add Project",
      project: {},
      validated: false
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.project = newProject();
      this.validated = false;
    },
    contactChanged(company) {
      setTimeout(() => {
        if (this.$refs.projectAddress) this.$refs.projectAddress.focus();
      }, 300);
    },
    focusContact() {
      if (!this.project.customer._id && this.project.name)
        this.$refs.contactSelctor.show();
    },
    focusAddress() {
      if (!this.project.address) {
        setTimeout(() => {
          this.$refs.projectAddress.focus();
        }, 200);
      }
    },
    async save() {
      this.validated = true;
      if (!this.valid()) return;
      try {
        const project = await this.add("project", this.project);
        this.reset();
        this.$emit("done", this.project);
        this.close();
        this.$router.push(`/project/${project._id}`);
      } catch (error) {}
    },

    valid() {
      return this.project && this.project.customer._id && this.project.name;
    }
  }
};
</script>
