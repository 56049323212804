<template>
  <div v-if="hasAccess">
    <div class="mb-2">
      <b-checkbox
        v-model="share"
        @input="input"
        @change="toggle"
        switch
        size="lg"
      >Share with Customer by Default</b-checkbox>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import accessMixin from "@/mixins/accessMixin";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
export default {
  name: "setDefaultShare",
  props: {
    projectId: String,
    entityName: String,
    value: { Boolean, default: false }
  },
  mixins: [baseMixin, dataMixin, accessMixin],
  data() {
    return {
      saving: false,
      share: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (this.hasAccess && !this.shareCustomer && this.customerId) {
        await this.loadById("shareCustomer", this.customerId);
      }
      this.setShare();
    },
    setShare() {
      this.share = this.getDefault();
    },
    getDefault() {
      if (!this.shareCustomer) return false;
      let projectShare = this.shareCustomer.projects.find(
        o => o._id == this.projectId
      );
      if (!projectShare) return false;
      return projectShare.shareByDefault[this.entityName];
    },
    input(value) {
      this.$emit("input", value);
    },
    async toggle(shareDefault) {
      if (this.saving) return;
      let data = {
        entityName: this.entityName,
        projectId: this.projectId,
        shareDefault
      };
      try {
        this.saving = true;
        await this.update(
          "shareCustomer",
          data,
          `update/setDefault/${this.customerId}`
        );

        // this.setShare();
      } catch (error) {
      } finally {
        this.saving = false;
      }
    }
  },
  computed: {
    customerId() {
      return this.project ? this.project.customer._id : "";
    },
    project() {
      return this.projects.find(o => o._id == this.projectId);
    },
    shareCustomer() {
      return this.shareCustomers.find(o => o._id == this.customerId);
    },
    ...mapGetters(["projects", "shareCustomers"])
  }
};
</script>