<template>
  <card-layout back :title="isnew ? `Add Payment`  : `Edit Payment`" :state="state">
    <div slot="header">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-7">
          <div class="bg-1 p-4">
            <!-- <section class="form-group">
              <label>Type</label>
              <type-select :types="types" v-model="income.type" />
            </section>-->

            <section class="form-group">
              <label>Date</label>
              <date-input ref="dateInput" @change="focusNext" v-model="income.date" />
            </section>

            <section class="form-group">
              <label>Paid By</label>
              <div>
                <contact-selector
                  name="customer"
                  ref="contactSelector"
                  :contact="income.customer"
                  placeholder="Select client"
                  @change="focusNext"
                  :class="{'is-invalid': validated && !income.customer._id}"
                  class="form-control"
                />
                <div
                  class="invalid-feedback"
                  v-if="validated && !income.customer._id"
                >Select contributor/lender</div>
              </div>
            </section>

            <section class="bg-trans p-3 form-group">
              <div class="row">
                <div class="col">
                  <label>Amount</label>
                  <div>
                    <amount-input
                      ref="amountInput"
                      @change="focusNext"
                      v-model="income.amount"
                      placeholder="Paid amount"
                      class="font-weight-md"
                      :class="{ 'is-invalid': validated && $v.income.amount.$invalid}"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="validated && $v.income.amount.$invalid"
                    >Please enter the amount</div>
                  </div>
                </div>
                <div class="col"></div>
              </div>
            </section>
            <section class="form-group">
              <label>Reference No</label>
              <div>
                <input class="form-control" v-model="income.refNo" placeholder="Enter notes... " />
              </div>
            </section>
            <section>
              <label>Notes</label>
              <div>
                <textarea
                  class="form-control"
                  v-model="income.note"
                  rows="4"
                  placeholder="Enter notes... "
                ></textarea>
              </div>
            </section>
          </div>
          <div class="alert alert-danger" v-if="error">{{error}}</div>
        </div>

        <div class="col-lg-5">
          <entity-attachments :entityName="'income'" :entity="income" />
        </div>
      </div>
    </div>
    <div slot="footer">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import types from "./data/incomeTypes";

import {
  required,
  numeric,
  minValue,
  maxValue
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
const entityName = "income";

export default {
  name: "incomeEdit",
  mixins: [dataMixin, baseMixin, routeMixin, validationMixin],
  data() {
    return {
      validated: true,
      error: "",
      income: {
        attachments: [],
        customer: {}
      },
      types
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.isnew) this.newModel();
      else this.load();
    },
    async load() {
      this.loading();
      await this.loadById(entityName, this.income_id);
      this.income = JSON.parse(JSON.stringify(this.income_store));
      this.loaded();
    },

    newModel() {
      let key = this.$route.query.type;
      this.income = {
        type: { key: key, name: types[key] },
        date: new Date(),
        attachments: [],
        customer: { ...this.project.customer },
        project: { _id: this.project_id, name: this.project.name },
        amount: 0,
        interest: 0,
        note: ""
      };
      this.focusFirst();
      this.loaded();
    },

    async save() {
      this.validated = true;
      if (this.$v.invalid) return;
      let model = { ...this.income };
      try {
        this.isnew
          ? await this.add(entityName, model)
          : await this.update(entityName, model);

        this.$router.go(-1);
      } catch (error) {
        console.log("error :", error);
      }
    },

    cancel() {
      this.$router.go(-1);
    },

    focusFirst() {
      setTimeout(() => {
        let input = this.$refs.dateInput;
        if (input) input.focus();
      }, 300);
    },

    focusNext() {
      setTimeout(() => {
        if (!this.income.customer._id) {
          this.$refs.contactSelector.show();
          return;
        }
        if (this.income.amount == 0) {
          this.$refs.amountInput.focus();
          return;
        }
      }, 200);
    }
  },
  validations: {
    income: {
      customer: {
        _id: { required }
      },
      project: {
        _id: { required }
      },
      date: { required },
      amount: { required, numeric, minValue: minValue(1) }
    }
  },
  computed: {
    type() {
      return this.income ? this.income.type || {} : {};
    },
    isnew() {
      return this.$route.params.income_id == "new";
    },
    project() {
      return this.projectById(this.project_id);
    },
    income_store() {
      return this.incomeById(this.income_id);
    },
    ...mapGetters(["projectById", "incomeById"])
  }
};
</script>
