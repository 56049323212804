<template>
  <div class="border-0 bg-2 rounded-5">
    <div
      class="card-header min-h-auto d-flex justify-content-between align-items-center"
    >
      <h5 class="m-0">Agreement with Client</h5>
      <button-edit @edit="$emit('edit')" small />
    </div>

    <div class="card-body">
      <ContractTypeView :contract="contract" :is-projcet-contract="true" />
      <div class="form-group">
        <label for>Terms</label>
        <div class="form-control value">{{ contract.terms }}</div>
      </div>
      <div class="form-group">
        <label for>Scope</label>
        <p class="form-control value">{{ contract.scope }}</p>
      </div>

      <div class="form-group">
        <label for>Exclusions</label>
        <div class="form-control value">{{ contract.exclusions }}</div>
      </div>
      <div class="form-group">
        <label for>Contract file /Attachements</label>
        <div class="form-control value">
          <entity-attachments
            readonly
            :entity="contract"
            entityName="project"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ContractTypeView from "@/views/contract/components/ContractTypeView";
export default {
  name: "projectContract",
  components: { ContractTypeView },
  props: {
    project: Object,
  },
  computed: {
    contract() {
      return this.project ? this.project.contract || {} : {};
    },
  },
};
</script>
