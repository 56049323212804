<template>
  <div class="card project-card border-0 shadow-sm mb-3">
    <router-link :to="`/project/${project._id}/`">
      <img class="card-img-top p-img img-responsive" :src="imageUrl" alt="project" />
    </router-link>
    <div class="card-body py-2">
      <h5 class="card-title mb-0">{{ project.name }}</h5>
      <div class="card-text">{{ project.customer.name }}</div>
      <div class="text-secondary text-truncate fs-09">{{ project.address && project.address.text }}</div>
    </div>
    <!-- <div class="card-footer">
			<small class="text-muted">Some info</small>
    </div>-->
  </div>
</template>
<script>
export default {
  name: "projectCard",
  props: {
    project: Object
  },
  data() {
    return {
      image: "img/placeholders/blank_const_job.png"
    };
  },
  computed: {
    imageUrl() {
      return this.project.imageUrl || this.image;
    }
  }
};
</script>

<style lang="scss" scoped>
.project-card {
  background-color: white;
  .p-img {
    height: 15rem;
    object-fit: cover;
  }
  .card-body {
    height: 5.6rem;
    background-color: #eceff1;
  }
}
</style>
