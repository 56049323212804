<template>
  <div>
    <file-upload
      :projectId="projectId"
      :entityName="entityName"
      v-if="!readonly "
      @uploaded="uploaded"
      :note="note"
    />

    <div class="row files" v-if="entity">
      <div
        class="col"
        :class="attachments.length>1?'col-6':'col-12'"
        v-for="file in attachments"
        v-bind:key="file._id"
      >
        <div class="card file mb-4">
          <file-thumb :file="file" @del="deleteFile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isImage } from "./helpers/file-helper";
import FileUpload from "./FileUpload";
import FileThumb from "./FileThumb";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import { fileMixin } from "./mixins/fileMixin";
import accessMixin from "@/mixins/accessMixin";
import { getDownloadURL, getStorage, ref  } from "firebase/storage";

export default {
  name: "entityAttachments",
  components: { FileUpload, FileThumb },
  mixins: [baseMixin, dataMixin, fileMixin, accessMixin],
  props: {
    entityName: String,
    entity: Object,
    child: Object,
    childName: String,
    title: {
      type: String,
      default: "Documents"
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    note: String
  },
  mounted() {
    //this.load();
  },
  methods: {
    async uploaded(model) {
      const data = {
        name: model.name,
        url: model.url
      };

      if (this.child && this.childName) {
        await this.updateChild(data);
      } else {
        await this.updateEntity(data);
      }

      this.childOrEntity.attachments.unshift(data);
    },
    async updateEntity(data) {
      if (!this.entity._id) return;
      await this.update(
        this.entityName,
        [data],
        `attachments/add/${this.entity._id}`
      );
    },
    async updateChild(data) {
      console.log(`this.child`, this.child)
      if (!this.child._id) return;

      await this.update(
        this.entityName,
        [data],
        `attachments/add/${this.entity._id}/${this.childName}/${this.child._id}`
      );
    },

    load() {
      this.entity.attachments.map(f => {
        const url = f.url.split("?")[0];
        const storageRefFromURL = ref(getStorage(), url);
        getDownloadURL(storageRefFromURL)
          .then(function(src) {
            console.log("src", src);
          })
          .catch(function(error) {
            console.log("error", error);
          });
      });
    },
    async deleteFile(file) {
      await this.delAttachment(file);
      this.$forceUpdate();
    }
  },

  computed: {
    isImage() {
      return name => isImage(name);
    },
    projectId() {
      return this.entity && this.entity.project ? this.entity.project._id : "";
    },
    attachments() {
      return this.childOrEntity ? this.childOrEntity.attachments : [];
    },
    childOrEntity() {
      return this.child || this.entity;
    }
  }
};
</script>

<style lang="scss" scoped>
.files {
  min-height: 1rem;
}
.file.card {
  position: relative;
}

.card-header {
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  background-color: #fff8e1;
}
.card-header.file-action {
  opacity: 0;
}
.file.card:hover .card-header.file-action {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  opacity: 0.98;
}
</style>
