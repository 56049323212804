<template>
    <highcharts :options="chartOptions" ref="hchart"></highcharts>
</template>

<script>
import { Chart } from "highcharts-vue";
import moment from 'moment'
export default {
    name: "salesmanChart",

    components: {
        highcharts: Chart
    }, 
    props: {
        list: Array
    }, 
    data() {
        return {
            chartOptions: {
                chart: {
                    plotBackgroundColor: "#f7f5f8",
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "line",
                    height:360
                },
                title: {
                    text: ""
                },
                xAxis: {
                    categories: [ 
                    ],
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "Amount ($)"
                    }
                },
                tooltip: {
                    pointFormat: "{series.name}: <b>${point.y:,.0f}</b>"
                },    

                series: [
                    {
                        name: "Budget",
                        color: '#4CAF50',
                        data: [ 
                        ]
                    } 
                ]
            }
        };
    },

    mounted() {
        // let c = this.$refs.hchart;
        this.updateChart();
    },

    watch: {
        list(newValue, oldValue) {
            this.updateChart();
        }
    },

    methods: {
        updateChart() { 
             this.chartOptions.series[0].data = this.list.map(o=>o.amount);
             this.chartOptions.xAxis.categories = this.list.map(o=>moment(o.dateModified).format('YYYY-MM-DD'))
             
        }
    }, 
};
</script>
 