<template>
  <div>
    <div class="card-body d-flex p-3 mb-0">
      <div class="mr-3">
        <avatar :src="contact.logoUrl" :username="contact.name" :size="75" />
      </div>

      <div class="text-truncate">
        <div class="font-size-11 text-truncate font-weight-5 mt-1">
          <router-link :to="`/contact/${contact._id}`">{{ contact.name }}</router-link>
        </div>
        <div v-if="contact.person.phone">
          <phone-number v-model="contact.person.phone" link />
        </div>
      </div>
    </div>

    <div class="footer-contact px-3 py-2 border-top">
      <email-link :email="contact.person.email" :text="contact.person.email" class="px-2" />
    </div>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
export default {
  name: "Person",
  props: {
    contact: Object
  },
  computed: {
    person() {
      return contact.people[0];
    },
    logo() {
      if (this.contact.logoUrl) return this.contact.logoUrl;
      if (this.contact.isperson) return "img/blank/blank_user.png";
      else return "img/blank/blank_contact.png";
    }
  }
};
</script>
