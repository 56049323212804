export function isImage(name) {
	return /\.(jpe?g|png|gif|svg)$/i.test(name);
}
export function isPdf(name) {
	return /\.(pdf)$/i.test(name);
}
export function isDoc(name) {
	return /\.(txt|doc|docx)$/i.test(name);
}
export function isExcel(name) {
	return /\.(exls|csv|xls|xlsx)$/i.test(name);
}

export function getFilExt(filename) {
	return filename.split(".").pop();
}

export function uuidv4() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function readImage(file) {
	return new Promise((resolve, reject) => {
		if (!isImage(file.name)) {
			resolve("");
		}
		const fReader = new FileReader();

		fReader.onload = () => {
			resolve(fReader.result);
		};

		fReader.readAsDataURL(file);
	});
}

export function b64toBlob(b64Data, sliceSize) {
	var block = b64Data.split(";");
	// Get the content type of the image
	var contentType = block[0].split(":")[1]; // In this case "image/gif"
	// get the real base64 content of the file
	var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

	contentType = contentType || "";
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(realData);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, {
		type: contentType,
	});
	return blob;
}

// export function getImage(file) {
//     return new Promise((resolve, reject) => {
//         const fReader = new FileReader();
//         //const img = document.createElement('img');

//         fReader.onload = () => {
//             // img.src = fReader.result;
//             // let b64 = {
//             //     id: getBase64Image(img),
//             //     originalName: 'x.name',
//             //     fileName: 'x.name',
//             //     url: img
//             // }
//             // resolve(b64);
//             resolve(fReader.result)
//         }

//         fReader.readAsDataURL(file);
//     })
// }

// function getBase64Image(img) {
//     const canvas = document.createElement('canvas');
//     canvas.width = img.width;
//     canvas.height = img.height;

//     const ctx = canvas.getContext('2d');
//     ctx.drawImage(img, 0, 0);

//     const dataURL = canvas.toDataURL('image/png');

//     return dataURL;
// }

// function guid() {
//     return "ss-s-s-s-sss".replace(/s/g, s4);
// }

// function s4() {
//     return Math.floor((1 + Math.random()) * 0x10000)
//         .toString(16)
//         .substring(1);
// }

// function randomName() {
//     var text = "";
//     var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

//     for (var i = 0; i < 9; i++)
//         text += possible.charAt(Math.floor(Math.random() * possible.length));

//     return text;
// }
