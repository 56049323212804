<template>
  <div class="border-0 rounded-5-top">
    <div class="fs-11 bg-trans rounded-5-top shadow-sm">
      <div class="float-right d-inline font-weight-bold text-truncate p-2">{{ title }}</div>
      <div class="d-inline font-italic p-2 small">{{ count }} Records</div>
      <table class="table bg-white mb-0">
        <tr>
          <td>Amount</td>
          <td class="text-right fs-11">{{ total | currency}}</td>
        </tr>
        <tr>
          <td>{{ taxCode1 }}</td>
          <td class="text-right fs-11">{{ taxValue1 | currency }}</td>
        </tr>
        <tr v-if="taxCode2 || taxValue2">
          <td>{{ taxCode2 }}</td>
          <td class="text-right fs-11">{{ taxValue2 | currency }}</td>
        </tr>
        <tr class="bg-primary-2">
          <td>Total Refund</td>
          <td class="text-right fw-600 fs-11">{{ totalRefund | currency }}</td>
        </tr>
        <tr class="g-total bg-trans">
          <td>Total</td>
          <td class="text-right fw-600 fs-11">{{ totalTotal | currency }}</td>
        </tr>
      </table>
    </div>

    <div>
      <table class="table mt-2 m-0">
        <tr class="bg-success-2">
          <td class="fs-11">Paid</td>
          <td class="text-right fs-11">{{paid | currency}}</td>
        </tr>
        <tr class="bg-warning-2">
          <td class="fs-11">Unpaid</td>
          <td class="text-right fs-11">{{unpaid | currency}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    title: {
      type: String,
      default: "Total"
    }
  },
  computed: {
    count() {
      return this.list.length;
    },
    total() {
      return this.list.map(o => o.amount).reduce((a, b) => a + b, 0);
    },
    paid() {
      return (
        this.list
          // .map(o => o.payments)
          // .map(o => o.amount)
          .map(o => o.paidAmount)
          .reduce((a, b) => a + b, 0)
      );
    },
    unpaid() {
      return this.totalTotal - this.paid;
    },
    totalTotal() {
      return this.list.map(o => o.total).reduce((a, b) => a + b, 0);
    },
    taxValue1() {
      return this.list
        .filter(o => o.tax1)
        .map(o => o.tax1.amount)
        .reduce((a, b) => a + b, 0);
    },
    taxValue2() {
      return this.list
        .filter(o => o.tax2)
        .map(o => o.tax2.amount)
        .reduce((a, b) => a + b, 0);
    },
    taxCode1() {
      return this.list
        .filter(o => o.tax1 && !!o.tax1.code)
        .map(o => o.tax1.code)[0];
    },
    taxCode2() {
      return this.list
        .filter(o => o.tax2 && !!o.tax2.code)
        .map(o => o.tax2.code)[0];
    },
    totalRefund() {
      return this.list.map(o => o.refund).reduce((a, b) => a + b, 0);
    }
  }
};
</script>
