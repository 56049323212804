<template>
  <center-layout>
    <div class="card shadow-sm">
      <div class="card-header">
        <h5 class="text-center mt-1">Switch Workspace</h5>
      </div>
      <div class="card-body" v-if="!state.loading">
        <h5 class="px-3 mb-3">Your Workspaces</h5>
        <ul class="list-group">
          <li
            class="list-group-item d-flex align-items-end justify-content-between"
            :class="{ 'bg-active': isActive(client._id) }"
            v-for="client in list"
            v-bind:key="client._id"
          >
            <div class="d-flex">
              <logo-view :size="50" :url="client.logoUrl" class="mr-3 d-inline-block" />
              <div>
                <div class="fs-12">{{ client.name }}</div>
                <small class="text-gray">{{ client.description }}</small>
              </div>
            </div>
            <div class="align-self-center">
              <spinner :busy="true" v-if="switching && switchingId == client._id" />
              <span
                class="badge badge-success badge-pill ml-4 px-4 py-2"
                v-if="isActive(client._id)"
              >Current</span>
              <button
                @click="switchTo(client)"
                v-else
                class="btn btn-outline-primary ml-4 px-4 py-1"
                :disabled="switching"
                :class="{ disabled: switching }"
              >Switch To</button>
            </div>
          </li>
          <a
            class="list-group-item d-flex align-items-center border border-top-0 text-primary py-4"
            @click="add"
          >
            <svg-icon name="plus" color="blue" class="d-inline-block mx-3" />Create a New Workspace
          </a>
        </ul>
        <div class="card-body" v-if="state.loading">
          <list-placeholder :count="2" style="min-width:22em" />
        </div>
      </div>
    </div>
    <AddClient ref="addClient" @done="onAddClient" />
  </center-layout>
</template>

<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import AddClient from "./AddClient";
import { mapGetters } from "vuex";
export default {
  name: "switchClient",
  mixins: [baseMixin, dataMixin],
  components: { AddClient },
  data() {
    return {
      switching: false,
      accepting: false,
      switchingId: "",
      list: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      try {
        this.loading();
        this.list = await this.loadByAction("my", "clients");
        console.log("list", this.list);
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },
    add: function() {
      this.$refs.addClient.open();
    },
    onAddClient(profile) {
      this.$router.push("/");
      if (profile) {
        this.list.push(profile.client);
        this.switchTo(profile.client);
      }
    },
    async switchTo(client) {
      let cid = client._id;
      this.switchingId = cid;
      if (this.switching) return;
      this.switching = true;
      try {
        await this.$store.dispatch("SWITCH_CLIENT", client._id);
        this.$router.push("/");
      } catch (error) {
        console.log("error", error);
      } finally {
        this.switching = false;
      }
    },

    accept(clienUser) {
      this.accepting = true;
      this.$store
        .dispatch(JOIN, {
          action: "acceptclient",
          id: clienUser._id
        })
        .then(o => {
          this.accepting = false;
          this.$router.push("/");
        })
        .catch(e => {
          this.accepting = false;
        });
    }
  },
  computed: {
    invited() {
      return clientUser => clientUser.status == "Invited";
    },
    isActive() {
      return id => this.cid == id;
    },
    ...mapGetters(["cid"])
  }
};
</script>

<style lang="scss" scoped>
.cleint-name {
  font-weight: 500;
}

img.clint-logo {
  width: 42px;
  margin-right: 1em;
}

.btn-switch {
  min-width: 6em;
}
.bg-active {
  border: 2px #4caf50 solid;
  margin-bottom: 2px;
}
li.invited {
  // border: 1px #607d8b dashed;
  background-color: #eceff1;
  margin-bottom: 1px;
}
</style>
