<template>
  <card-layout nofooter back :state="state" class="bg-light">
    <div slot="title" class="d-flex">Progress Report</div>
    <div slot="header">
      <div class="d-flex align-items-center">
        <button-share :entity="progressReport" />
        <button-edit @edit="edit" />
      </div>
    </div>
    <div class="d-flex justify-content-between p-3">
      <div>
        <div>
          <span class="mr-2">Project:</span>
          <span class="fs-12">{{report.project.name}}</span>
        </div>
      </div>

      <div>
        <span class="text-2">Report Date:</span>
        {{ report.date |dateformat}}
      </div>
    </div>

    <div v-for="item in report.items" :key="item._id">
      <div class="form-group">
        <div class="bg-trans px-3">
          <span class="text-2 small">{{item.date | dateformat}}</span>
        </div>
        <div class="row">
          <div :class="item.attachments.length?'col-lg-8':'col-lg-12'">
            <div class="px-3">
              <div class="border-bottom">
                <div v-if="item.contact && item.contact.name">
                  <span class="text-2 mr-2">Contractor:</span>
                  {{item.contact.name}}
                </div>
                <div v-if="item.item && item.item.name">
                  <span class="text-2 mr-2">Item:</span>
                  <span>{{item.item.name}}</span>
                </div>
              </div>
              <p>{{item.note}}</p>
            </div>
          </div>
          <div :class="item.attachments.length?'col-lg-4':''"></div>
        </div>
      </div>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
const entityName = "progressReport";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
export default {
  name: "progressReportView",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: {},
  mounted() {
    this.load();
  },

  methods: {
    async load() {
      await this.loadById("progressReport", this.progressReport_id);
      this.loaded();
    },
    edit() {
      this.$router.push(`${this.report._id}/edit`);
    }
  },
  computed: {
    progressReport_id() {
      return this.entity_id(entityName);
    },
    report() {
      return (
        this.progressReports.find(o => o._id == this.progressReport_id) || {}
      );
    },
    ...mapGetters(["progressReports"])
  }
};
</script>
