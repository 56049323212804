<template>
  <div
    class="p-3 border d-flex flex-column rounded-5 shadow-sm border-success mb-1 bg-success-1"
    v-if="billable"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="fs-11">Billable Expenses</div>
      <div class="fs-12">{{ billable.total | currency0 }}</div>
    </div>
    <!-- <button class="btn btn-outline-success btn-sm border mt-3 d-flex align-items-center">
      <icon svg="svg-plus" color="gray" class="d-inline-block mr-2" />Create Invoice for This Amount
    </button>-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";

export default {
  name: "billableTotal",
  mixins: [baseMixin, dataMixin, routeMixin],
  data() {
    return { billable: "" };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      this.billable = await this.loadByAction(
        "payable",
        `project/${this.project_id}/billableTotal`
      );
      this.loaded();
    }
  }
};
</script>