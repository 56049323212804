import data from "@/mixins/dataMixin";
import routeMixin from "@/mixins/routeMixin";

import { mapGetters } from "vuex";

export default {
	mixins: [data, routeMixin],
	methods: {
		async loadBudgetingIfNot() {
			if (this.projectId && !this.budgeting) {
				await this.loadByAction("budgeting", `project/${this.projectId}`);
			}
		},
		async loadItemMasterIfNo() {
			if (!this.items || !this.items.length) {
				await this.loadPerClient("itemMaster");
			}
		},
	},
	computed: {
		budgeting() {
			return this.projectId ? this.budgetings.find((o) => o.projectId == this.projectId) : null;
		},
		...mapGetters(["items", "budgetings", "itemMaster"]),
	},
};
