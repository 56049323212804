<template>
  <card-layout nofooter noheader :state="state" class="bg-2">
    <div slot="header" class="d-flex"></div>
    <div slot="title" class="d-flex">{{ project.name }}</div>

    <div class="card-body" slot="data">
      <div class="text-center">
        <div class="row">
          <project-nav-item
            class="col-lg-6"
            title="Project Information"
            icon="icon-info"
            @open="toProjectInfo('info')"
          >
            <ul class="text-gray mb-0">
              <li>Contract Details</li>
              <li>Project Status</li>
              <!-- <li>Define project scope</li> -->
            </ul>
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Budgeting and Project Summary"
            icon="icon-equalizer"
            @open="toProjectInfo('budgetSummary')"
          >
            <BudgetSummary />
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Contract Management"
            svg="svg-contacts"
            @open="toProjectInfo('contracts')"
          >
            <SubsSummary />
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Monitor Project Progress"
            svg="svg-report"
            @open="toProjectInfo('progress')"
          >
            <ul class="text-gray mb-0">
              <li>Manage daily logs</li>
              <li>Create progress report</li>
            </ul>
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Cost Management"
            svg="svg-payable"
            @open="toProjectInfo('expenses')"
          >
            <PayableSummary />
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Project Schedule"
            svg="svg-calendar"
            @open="toProjectInfo('scheduling')"
          >
            <ul class="text-gray mb-0">
              <li>Set up task breakdown</li>
              <li>Project timeline</li>
            </ul>
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Invoices"
            svg="svg-invoice"
            @open="toProjectInfo('invoices')"
          >
            <InvocieSummary />
          </project-nav-item>
          <project-nav-item
            class="col-lg-6"
            title="Project Cash Flow"
            icon="icon-chart"
            @open="toProjectInfo('cashflow')"
          >
            <CashFlowSummary />
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Project Documents"
            icon="icon-docs"
            @open="toProjectInfo('docs')"
          >
            <ul class="text-gray mb-0">
              <li>Drawings, permits...</li>
              <li>Inspection reports...</li>
            </ul>
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Project Gallery"
            icon="icon-picture"
            @open="toProjectInfo('gallery')"
          >
            <ul class="text-gray">
              <li>Project photos</li>
              <li>Visual progress timeline</li>
            </ul>
          </project-nav-item>

          <project-nav-item
            class="col-lg-6"
            title="Timesheet"
            icon="icon-clock"
            @open="toProjectInfo('timesheet')"
          >
            <ul class="text-gray">
              <li>Collect employees timesheet</li>
              <li>Track sub contractor time</li>
            </ul>
          </project-nav-item>
        </div>
      </div>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ProjectNavItem from "./components/ProjectNavItem";
import InvocieSummary from "./components/summary/InvocieSummary";
import PayableSummary from "./components/summary/PayableSummary";
import CashFlowSummary from "./components/summary/CashFlowSummary";
import SubsSummary from "./components/summary/SubsSummary";
import BudgetSummary from "./components/summary/BudgetSummary";
export default {
  name: "projectHome",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: {
    ProjectNavItem,
    InvocieSummary,
    PayableSummary,
    CashFlowSummary,
    SubsSummary,
    BudgetSummary
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loaded();
    },
    toProjectInfo(page) {
      this.$router.push(`/project/${this.project_id}/${page}`);
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },

    ...mapGetters(["projectById"])
  }
};
</script>
