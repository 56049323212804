<template>
  <a
    v-if="!hideAction"
    class="btn d-flex align-items-center justify-content-center"
    @click="onCick"
    :class="{ disabled:disabled|| !hasAccess, 'btn-sm': small, className , 'btn':!link}"
    :disabled="!hasAccess"
  >
    <slot></slot>
  </a>
</template>

<script>
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "abutton",
  mixins: [accessMixin],

  props: {
    disabled: Boolean,
    title: {
      type: String,
      default: "Edit"
    },
    link: Boolean,
    className: String,
    small: Boolean
  },
  methods: {
    onCick() {
      if (!this.hasAccess) return;
      this.$emit("click");
    }
  }
};
</script>
