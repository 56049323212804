<template>
  <div class="p-3">
    <h5 class="font-weight-5 bg-title p-2 m-0">Categories</h5>
    <ul class="list-group list-category" v-if="categories">
      <li
        class="list-group-item list-group-item-action d-flex justify-content-between"
        :class="{ active: selected == 0 }"
        @click="select({ _id: 0 })"
      >
        All
        <small class="text-muted">({{ categories.length }})</small>
      </li>
      <li
        @click="select(c)"
        class="list-group-item text-truncate list-group-item-action d-flex justify-content-between"
        v-for="c in categories"
        v-bind:key="c._id"
        :class="{ active: selected == c._id }"
      >
        <span class="d-d-inline-block text-truncate">
          <a @click="edit(c)" class="text-primary" v-if="editable">
            <i class="icon-pencil mr-2"></i>
          </a>
          {{ c.name }}
        </span>
        <small class="text-muted"
          >({{ getItemsforCategory(c._id).length }})</small
        >
      </li>
      <button class="btn text-primary" @click="add" v-if="editable">
        <i class="la la-plus"></i>Add New Category
      </button>
    </ul>

    <b-modal ref="editModal" title="Edit Item Category" hide-footer>
      <ItemCategoryEdit
        :category="selectedForEdit"
        v-if="editModalVisible"
        @cancel="hideEditModal"
        @done="doneEdit"
        @hide="editModalVisible = false"
      />
    </b-modal>
  </div>
</template>

<script>
import ItemCategoryEdit from "./ItemCategoryEdit";

import { mapGetters } from "vuex";
export default {
  name: "itemCategories",
  components: { ItemCategoryEdit },
  props: {
    editable: Boolean,
    items: Array,
  },

  mixins: [],
  data() {
    return {
      selected: {},
      editModalVisible: false,
      selectedForEdit: "",
    };
  },
  mounted() {},
  methods: {
    select(c) {
      this.selected = c._id;
      this.$emit("select", c);
    },

    edit(category) {
      this.editModalVisible = false;
      this.selectedForEdit = { ...category };
      this.editModalVisible = true;
      this.$refs.editModal.show();
    },
    add() {
      this.edit({ name: "" });
    },
    doneEdit() {
      console.log("done");
      this.hideEditModal();
    },
    hideEditModal() {
      this.editModalVisible = false;
      this.$refs.editModal.hide();
    },
  },

  computed: {
    getItemsforCategory() {
      return (catId) =>
        _.sortBy(
          this.items.filter((o) => o.categoryId == catId),
          (o) => o.code,
          (o) => o.sequence
        ) || [];
    },
    ...mapGetters(["categories"]),
  },
};
</script>
<style lang="scss" scoped>
ul.list-category li {
  padding: 0.4rem;
  border-right-width: 0;
  border-left-width: 0;
}
</style>
