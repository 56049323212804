<template>
  <div>
    <table class="table table-hover bg-white mb-0 thead-md">
      <thead>
        <tr>
          <!-- <th>Type</th> -->
          <th scope="col" class="w-3"></th>
          <th scope="col" class="pl-3 w-8">Date</th>
          <th scope="col" class="w-8 text-center">Amount</th>
          <th scope="col" class="w-20-m">Notes</th>
          <th scope="col" class="pl-3 w-8">Type</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody v-for="g in grouped" :key="g.key">
        <tr class="bg-1">
          <td colspan="2">
            <contact-view :contact="g.contact" no-navigate />
          </td>
          <td class="fw-600 text-right">{{g.amount | currency}}</td>
          <td colspan="1">
            <span class="badge badge-info fs-1">{{percent(g.contact) |percent}}</span>
          </td>
          <td colspan="2"></td>
        </tr>
        <tr v-for="c in g.list" v-bind:key="c._id">
          <td></td>
          <td class="date no-wrap">
            <router-link :to="`cashIn/${c._id}`">{{c.date | dateformat}}</router-link>
          </td>

          <td class="text-right no-wrap">{{c.amount | currency}}</td>
          <td v-tooltip="c.note" class="w-20-m">
            <div class="text-truncate">{{c.note}}</div>
          </td>
          <td class>{{c.type.name}}</td>
          <td>
            <div class="d-flex align-items-center">
              <i
                v-if="c.sharedCustomer"
                class="icon-share small mr-2"
                v-tooltip="'Shared with client'"
              ></i>
              <attachment-icon :attachments="c.attachments" class="py-1" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";
import casheInMixin from "./data/casheInMixin";
export default {
  name: "cashInTable",
  mixins: [casheInMixin],

  props: {
    list: Array
  },
  computed: {
    grouped() {
      return _(this.list)
        .groupBy(p => p.contact._id)
        .map((value, key) => ({
          key,
          list: value,
          contact: value[0].contact,
          amount: value.map(o => o.amount).reduce((a, b) => a + b, 0)
        }))
        .orderBy(o => o.amount)
        .value();
    }
  }
};
</script>
  