export default {
    computed: {
        isActive() {
            return r => {
                return (
                    r &&
                    r.name &&
                    this.$route &&
                    this.$route.name &&
                    r.name.toLowerCase() == this.$route.name.toLowerCase()
                );
            };
        },
    },
};