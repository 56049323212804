<template>
  <section class="bg-2 form-group" v-if="data && data.length">
    <div class="row">
      <div class="col-lg-3 pr-0">
        <div class="d-flex align-items-center justify-content-between p-3 px-0">
          {{title}}
          <span class="badge badge-pill badge-light">{{data.length}}</span>
        </div>
      </div>
      <div class="col-lg-9 pl-0">
        <slot></slot>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "whereUsedLayout",
  props: {
    data: Array,
    title: String
  }
};
</script>
