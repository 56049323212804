<template>
  <side-layout>
    <span slot="title">
      <router-link to="/projects">{{ page.name }}</router-link>
      <i class="icon-arrow-right mx-2 small"></i>
      <router-link :to="`/project/${project_id}`">{{ project.name }}</router-link>
    </span>

    <div slot="side" class="side text-center" v-if="project">
      <project-image :project="project" class="pl-3" />

      <div class="form-group pl-3 pt-3">
        <h3 class="mb-0">{{ project.name }}</h3>
        <h5 class="fs-12">
          <router-link
            v-if="project.customer"
            :to="`/contact/${ project.customer._id}`"
          >{{ project.customer && project.customer.name }}</router-link>
        </h5>
        <ProjectOverview class="mt-4" />
        <ShareProjectButton :project="project" class="mt-4" />
      </div>
    </div>
  </side-layout>
</template>
<script>
import { mapGetters } from "vuex";
import pages from "@/pages";
import routeMixin from "@/mixins/routeMixin";
import dataMixin from "@/mixins/dataMixin.js";
import baseMixin from "@/mixins/baseMixin.js";
import ProjectImage from "./components/ProjectImage";
import ProjectStatus from "./components/ProjectStatus";
import ShareProjectButton from "./ShareProjectButton";
import ProjectOverview from "./components/ProjectOverview";
const entityName = "project";
export default {
  name: "projectNav",
  mixins: [routeMixin, dataMixin, baseMixin],
  components: {
    ProjectImage,
    ProjectStatus,
    ShareProjectButton,
    ProjectOverview
  },
  data() {
    return {
      page: pages.projects,
      routes: pages.projects.nav.routes
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      let force = !this.project || !this.project.contract;
      await this.loadById(entityName, this.project_id, force);
      this.loaded();
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },
    ...mapGetters(["projectById"])
  }
};
</script>
