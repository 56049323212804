<template>
  <div>
    <div class="d-flex justify-content-between" v-if="person">
      <div class="d-flex">
        <avatar :src="person.imageUrl" :username="name" :size="45" class="mr-3" />
        <div>
          <div class="small">{{ this.person.name }}</div>
          <div class="phone small">
            <phone-number v-model="person.phone" link v-if="hasphone" />
            <email-link :email="person.email" v-if="!hasphone" />
          </div>
        </div>
      </div>
      <email-link :email="person.email" v-if="hasphone && hasemail" text="@" class="px-2" />
    </div>
  </div>
</template>
<script>
import EmailLink from "@/components/form/EmailLink";
import PhoneNumber from "@/components/form/PhoneNumber";
export default {
  name: "person",
  components: { EmailLink, PhoneNumber },
  props: {
    person: Object
  },
  computed: {
    hasphone() {
      return !!this.person.phone;
    },
    hasemail() {
      return !!this.person.email;
    },
    name() {
      return this.person ? this.person.name || " " : " ";
    }
  }
};
</script>
