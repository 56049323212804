<template>
  <div>
    <svg id="gantt" />
    <TaskEdit ref="taskEditModal" :scheduling="scheduling" @update="onUpdate" v-if="showTaskEdit" />
  </div>
</template>
<script>
import scheduleMixin from "./data/scheduleMixin";
import TaskEdit from "./components/TaskEdit";
import Gantt from "@/modules/frappe-gantt";
import moment from "moment";
export default {
  name: "scheduleGantt",
  mixins: [scheduleMixin],
  components: { TaskEdit },
  props: {
    scheduling: Object
  },
  data() {
    return {
      gantt: "",
      showTaskEdit: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
      this.setSize();
    });
  },
  methods: {
    init() {
      let tasks = this.scheduling.tasks;
      // tasks[2].className = "fill-1";
      tasks.map(o => (o.custom_class = ""));
      this.gantt = new Gantt("#gantt", tasks, {
        header_height: 50,
        column_width: 20,
        step: 24,
        view_modes: ["Day", "Week", "Month"],
        bar_height: 24,
        bar_corner_radius: 5,
        arrow_curve: 5,
        padding: 18,
        view_mode: "Day",
        date_format: "YYYY-MM-DD",
        on_click: (task, e, bar) => {
          console.log("bar :", bar);
          this.edit(bar, task, bar.group);
        },
        on_date_change: (task, start, end) => {
          task.start = start;
          task.end = end;
          this.ganttUpdate(task);
        },
        on_progress_change: (task, progress) => {
          task.progress = progress;
          this.ganttUpdate(task);
        },
        on_view_change: mode => {},
        custom_popup_html: task => {
          // the task object will contain the updated
          // dates and progress value
          const end_date = task.end;
          return ` 
            <div class="details-container p-3">
            <h5>${task.name}</h5>
            <p>Expected to finish by ${task.end}</p>
            <p>${task.progress}% completed!</p>
            </div>
        `;
        }
      });
      this.gantt.change_view_mode("Week"); // Quarter Day, Half Day, Day, Week, Month
    },
    edit(bar, task, el) {
      this.showTaskEdit = true;
      this.$nextTick(() => {
        this.$refs.taskEditModal.open(el, task, bar);
      });
    },
    scale(mode) {
      this.gantt.change_view_mode(mode);
    },
    onUpdate(task) {
      this.showTaskEdit = false;
      this.refresh();

      this.$emit("update", task);
    },
    ganttUpdate(task) {
      this.$emit("ganttUpdate", task);
    },
    refresh() {
      this.$nextTick(() => {
        this.gantt.refresh(this.scheduling.tasks);
        this.gantt.render();
      });
    },
    setSize() {
      // document
      //   .getElementById("gantt")
      //   .setAttribute("height", window.innerHeight - 310);
    }
  }
};
</script>

<style lang="scss">
.gantt .bar {
  fill: #5a73ff;
}
.gantt .bar-progress {
  fill: green;
}
.gantt .bar-label {
  font-size: 0.9rem;
}
.gantt-container .popup-wrapper {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  background: rgb(238, 235, 224);
  color: #222222;
  .pointer {
    border-top-color: rgb(238, 235, 224);
    margin: 0 0 0 -3px;
    height: 6px;
  }
}
.gantt .fill-1 {
  fill: red;
}
.gantt .bar-wrapper:hover .bar {
  opacity: 0.9;
  fill: #5a73ff;
  /* fill: hsla(239, 100%, 27%, 0.378); */
}
.gantt .tick {
  stroke: #0049e3;
  stroke-width: 0.3;
}
.gantt .tick.tick {
  stroke: #8c95a7;
  stroke-width: 0.5;
}
</style>