<template>
  <b-modal ref="modal" size="lg" :title="title" no-close-on-esc hide-footer>
    <div class v-if="show && quote">
      <div class="p-3">
        <div class="row">
          <div class="col-lg-8">
            <div>
              <section class="form-group">
                <label>Subcontractor</label>
                <div>
                  <contact-selector
                    :contact="quote.contact"
                    name="company"
                    ref="contactSelctor"
                    :class="{ 'is-invalid': validated && !quote.contact._id }"
                    placeholder="Select subcontractor"
                    @change="contactChanged"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="validated && !quote.contact._id"
                  >Select a Contact</div>
                </div>
              </section>

              <section class="form-group row">
                <div class="col-lg-6">
                  <div class="float-right">
                    <label>Date Received</label>
                    <date-input ref="datePicker" @change="dateChanged" v-model="quote.date" />
                  </div>
                </div>
              </section>

              <section class="row">
                <div class="col-lg-6 form-group">
                  <label>Quote Amount</label>
                  <amount-input
                    v-model="quote.amount"
                    ref="amountInput"
                    tabindex="-1"
                    placeholder="Amount"
                    inputclass="font-weight-md px-0"
                  />
                </div>
              </section>
            </div>

            <section class="form-group">
              <label>Description</label>
              <div>
                <textarea
                  class="form-control"
                  v-model="quote.note"
                  placeholder="Scope of the work"
                  ref="noteInput"
                  rows="5"
                ></textarea>
              </div>
            </section>
          </div>
          <div class="col-lg-4">
            <entity-attachments
              :entityName="'quote'"
              :entity="quote"
              note="Upload quotation file here"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer save-footer mt-3">
        <save-cancel @save="save" @cancel="cancel"></save-cancel>
      </div>
    </div>
  </b-modal>
</template>

<script>
import data from "@/mixins/dataMixin.js";
import modal from "@/mixins/modalMixin.js";
import dataMixin from "@/mixins/dataMixin";
import routeMixin from "@/mixins/routeMixin.js";
export default {
  name: "budgetItemQuoteEdit",
  mixins: [data, modal, dataMixin, routeMixin],
  data() {
    return {
      title: "Add Quote",
      quote: "",
      validated: false
    };
  },
  props: { budgeting: Object, budgetItem: Object },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.quote = this.newQuote();
      this.validated = false;
    },
    newQuote() {
      return {
        date: new Date(),
        amount: 0,
        node: "",
        accepted: false,
        contact: { _id: "" },
        attachments: []
      };
    },
    open(quote) {
      if (!quote) this.quote = this.newQuote();
      else {
        quote = JSON.parse(JSON.stringify(quote));
        if (!quote._id) {
          this.quote = { ...newQuote(), ...quote };
        }
      }
      this.$refs.modal.show();
      this.show = true;
      setTimeout(() => {
        this.focusNext();
      }, 300);
    },
    dateChanged(val) {
      this.focusNext();
    },
    focus() {
      this.$refs.contactSelctor.show();
    },
    itemChanged() {
      this.focusNext();
    },
    contactChanged() {
      this.focusNext();
    },
    focusNext() {
      setTimeout(() => {
        if (!this.quote.contact._id) {
          this.$refs.contactSelctor.show();
          return;
        }

        if (!this.quote.amoun) {
          this.$refs.amountInput.focus();
          return;
        }
      }, 200);
    },

    async save() {
      try {
        this.validated = true;
        if (!this.valid) return;
        const data = { ...this.quote };
        console.log("data", data);
        await this.update(
          "budgeting",
          data,
          `${this.budgeting._id}/item/${this.budgetItem._id}/quote/put`
        );

        this.reset();
        this.$emit("done");
        this.close();
      } catch (error) {
        console.log("error", error);
      }
    }
  },
  computed: {
    valid() {
      return this.quote.contact && this.quote.contact._id;
    }
  }
};
</script>
