<template>
  <section class="row">
    <div class="col-lg-3 form-group">
      <label>Cost / Budget</label>
      <amount-input
        ref="firstInput"
        @change="costChange(budgetItem)"
        v-model="budgetItem.budget"
        placeholder="Cost"
        inputclass="font-weight-md px-0"
      />
    </div>
    <div class="col-lg-3 form-group">
      <label>Markup</label>
      <percent-input
        v-model="budgetItem.markup"
        @change="markupChanged(budgetItem)"
        placeholder="Markup"
        :class="{'negeive-error':markup<0}"
        inputclass="font-weight-md px-0"
      />
    </div>
    <div class="col-lg-3 form-group">
      <label>Price</label>
      <amount-input
        ref="firstInput"
        @change="priceChanged(budgetItem)"
        v-model="budgetItem.price"
        tabindex="-1"
        placeholder="Price"
        inputclass="font-weight-md px-0"
      />
    </div>
  </section>
</template>
<script>
import budgetItemMixin from "../data/budgetItemMixin";
export default {
  name: "costMarkup",
  mixins: [budgetItemMixin],
  props: {
    budgetItem: Object,
    budegting: Object
  },

  data() {
    return {
      markup: 0
    };
  }
};
</script>