<template>
  <div>
    <figure class="figure m-0" :style="containerStyle">
      <img class="img-fluid logo" v-if="url" :src="url" alt="Logo" />
      <div v-if="!url" class="logo-container text-center p-3 rounded">
        <i class="la la-building" :style="iconStyle"></i>
      </div>
    </figure>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LogoView",
  props: {
    url: String,
    size: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      containerStyle: {
        "max-width": `${this.size}px`,
        "max-height": `${this.size}px`,
        // "min-height": `${this.size}px`,
        "min-width": `${this.size}px`
      },
      //   imageStyle: {
      //     "max-width": `${this.size}px`,
      //     "max-height": `${this.size}px`
      //   },
      iconStyle: {
        "font-size": `${this.size - 2}px`
      }
    };
  },
  computed: mapGetters(["logoUrl"])
};
</script>

<style lang="scss" scoped>
.logo-container {
  background-color: #e9ecef;
  i {
    color: #bdbfc1;
  }
}
.img {
  width: 100%;
  height: auto;
}
</style>
