<template>
  <card-layout title="Contact" :state="state" noheader class="bg-1">
    <div slot="header"></div>
    <div class="card-body p-0" slot="data">
      <div class="row">
        <div class="col-lg-3 p-4 pl-5">
          <div class="list-group list-group-flush green">
            <div
              v-for="item in list"
              :key="item.code"
              :class="{ active: selected == item.code }"
              @click="select(item)"
              class="list-group-item c-pointer d-flex align-items-center list-group-item-action rounded-5 border-0 shadow-sm mb-2 justify-content-between"
            >{{ item.name }}</div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="bg-light min-vh-100">
            <ContactInfo v-if="selected==1" />
            <WhereUsed v-if="selected==2" />
          </div>
        </div>
      </div>
    </div>
    <last-modified :entity="contact" class="float-left" slot="footer" />
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import routeMixin from "@/mixins/routeMixin";
import ContactInfo from "./ContactInfo";
import WhereUsed from "./WhereUsed";
const entityName = "contact";

export default {
  name: "contactInfo",
  mixins: [dataMixin, baseMixin, routeMixin],
  components: { ContactInfo, WhereUsed },
  data() {
    return {
      list: [
        { code: 1, name: "Information" },
        { code: 2, name: "Activities" }
      ],
      selected: 1
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadById(entityName, this.contact_id);
      this.loaded();
    },
    select(item) {
      this.selected = item.code;
    },
    edit() {
      this.$router.push(`/project/${this.project_id}/info/edit`);
    }
  },
  computed: {
    contact() {
      return this.contactById(this.contact_id);
    },
    ...mapGetters(["contactById"])
  }
};
</script>
<style lang="scss" scoped>
.min-vh-100 {
  min-height: calc(100vh - 13rem) !important;
}
</style>
