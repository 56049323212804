export default {
    data() {
        return {
            show: false,
        };
    },
    methods: {
        open() {
            this.$refs.modal.show();
            this.show = true;
            setTimeout(() => {
                this.focus();
            }, 300);
        },
        close() {
            this.$refs.modal.hide();
            this.show = false;
        },
        focus() {
            if (this.$refs.firstInput) this.$refs.firstInput.focus();
        },
        onAdd() {
            this.close();
        },
        cancel() {
            this.close();
            this.$emit('cancel');
        },
    },
};