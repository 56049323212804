<template>
  <div class="pt-5">
    <ul class="list-group">
      <a
        @click="select(t)"
        class="list-group-item text-truncate list-group-item-action d-flex justify-content-between"
        v-for="t in list"
        v-bind:key="t._id"
        :class="{ active: selected == t._id }"
      >
        <span class="d-inline-block text-truncate">{{ t.name }}</span>
      </a>
    </ul>
  </div>
</template>

<script>
export default {
  name: "templateTypes",
  data() {
    return {
      selected: {},
      list: [
        { id: "S", name: "Scope of Work" },
        { id: "T", name: "Terms" },
        { id: "E", name: "Exclusions" },
        { id: "N", name: "Notes" }
      ]
    };
  },
  mounted() {
    this.selectFirst();
  },
  methods: {
    selectFirst() {
      this.select(this.list[0]);
    },
    select(type) {
      this.selected = type.id;
      this.$emit("select", type);
    }
  },

  computed: {}
};
</script>
