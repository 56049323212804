<template>
  <div>
    <div
      class="list-group-item list-group-item-action shadow-sm py-4 rounded-5 bg-1 border-0 d-flex align-items-center justify-content-between"
    >
      <div class="w-100 px-4">
        <router-link :to="`/project/${this.project_id}/projectSummary`" class="nav-link">
          <div class="fs-14">Project Summary</div>
        </router-link>
        <div class="p-3 form-group">
          <div>
            <span class="text-muted">Cost to Date / Current Budget</span>
            <strong class="ml-3">{{totalCostOverBudget | percent}}</strong>
          </div>
          <BudgetProgress :value="totalCostOverBudget" />
          <div class="d-flex justify-content-between fs-11">
            <div>
              <div>{{totalCostToDate | currency}}</div>
              <div class="small text-muted">Cost to Date</div>
            </div>
            <!-- <div>
                  <div>{{totalBudget | currency}}</div>
                  <div class="small text-muted">Total Budget</div>
            </div>-->
            <div>
              <div>{{totalBudget-totalCostToDate | currency}}</div>
              <div class="small text-muted">Cost to Complete</div>
            </div>
          </div>
        </div>

        <div class="px-3 form-group">
          <table>
            <tr>
              <td>Initial Budget:</td>
              <td class="fs-13 pl-3">{{totalInitialBudegt | currency}}</td>
            </tr>
            <tr>
              <td>Current Budget:</td>
              <td class="fs-13 pl-3">{{totalBudget | currency}}</td>
            </tr>
            <tr>
              <td>Cost to Date:</td>
              <td class="fs-11 pl-3">{{totalCostToDate | currency}}</td>
            </tr>
            <tr>
              <td>Cost to Complete:</td>
              <td class="fs-11 pl-3">{{totalBudget-totalCostToDate | currency}}</td>
            </tr>
          </table>
        </div>
      </div>

      <router-link :to="`/project/${this.project_id}/projectSummary`" class="nav-link">
        <i class="icon-arrow-right"></i>
      </router-link>
    </div>
  </div>
</template>
<script>
import budgetMixin from "../data/budgetMixin.js";
import BudgetProgress from "./BudgetProgress";
export default {
  name: "budgetSummaryCard",
  mixins: [budgetMixin],
  components: { BudgetProgress },
  props: {
    budgeting: Object
  }
};
</script>