<template>
	<div class="px-3">
		<section class="  form-group">
			<label for="itemTemplateName">Name</label>
			<input
				ref="nameInput"
				id="itemTemplateName"
				:class="{ 'is-invalid': validated && !template.name }"
				class="form-control"
				v-model="template.name"
				placeholder="A short name as a reference to this template"
			/>
			<div class="invalid-feedback " v-if="validated && !template.name">
				Please eneter a name as a reference to this template
			</div>
		</section>
		<section class="form-group">
			<label for="templateDescription">Content</label>
			<textarea
				id="templateDescription"
				ref="textInput"
				class="form-control"
				rows="5"
				v-model="template.text"
				:class="{ 'is-invalid': validated && !template.text }"
				placeholder="Template Content"
			/>
		</section>

		<div class="py-3  ">
			<delete-confirm
				v-if="!isnew"
				class="d-inline-block "
				:class="{ confirm: deleteMode }"
				@confirm="onDelModeChange"
				entityName="itemTemplate"
				:entity="template"
				confirmtext="Are you sure you want to delete this item"
			/>
			<save-cancel @save="done" @cancel="$emit('cancel')" class="float-right" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'templateEdit',
	props: {
		template: Object,
	},
	data() {
		return {
			validated: false,
			deleteMode: false,
		};
	},
	mounted() {
		this.focus();
	},
	methods: {
		focus() {
			setTimeout(() => {
				this.$refs.textInput.focus();
			}, 500);
		},
		onDelModeChange(mode) {
			this.deleteMode = mode;
		},
		done() {
			this.validated = true;
			if (!this.valid) {
				return;
			}
			let action = this.isnew ? ADD : UPDATE;
			this.$store
				.dispatch(action, {
					model: this.template,
					entityName: NAMES.template,
				})
				.then(template => {
					this.$emit('done', template);
				});
		},
	},
	computed: {
		valid() {
			return this.template.name && this.template.text;
		},
		isnew() {
			return !this.template._id || this.template._id <= 0;
		},
	},
};
</script>

<style lang="scss" scoped>
.confirm {
	position: absolute;
	left: 0;
	right: 0;
}
</style>
