<template>
  <div>
    <div
      class="fs-12 bg-2 p-2 px-3 rounded-5-top d-flex justify-content-between align-items-center"
    >
      <span>Received Quotations for {{budgetItem.name}}</span>
      <add-button link @add="add" v-if="!nodata">Add Quotation</add-button>
    </div>
    <div class="text-center p-3 bg-1" v-if="nodata">
      <h5 class="text-muted">No quotations</h5>
      <div class="row" v-if="!hideAction">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <add-button link @add="add">Add Quotation</add-button>
        </div>
      </div>
    </div>

    <ReceivedQuoteTable :list="list" @edit="edit" />
    <EditReceivedQuote ref="editReceivedQuote" :project="project" />
  </div>
</template>

<script>
import accessMixin from "@/mixins/accessMixin";
import BudegtItemQuoteEdit from "./BudegtItemQuoteEdit";
import EditReceivedQuote from "@/views/contract/EditReceivedQuote";
import ReceivedQuoteTable from "@/views/contract/components/ReceivedQuoteTable";
import ReceivedQuotes from "@/views/contract/ReceivedQuotes";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "budgetItemQuotes",
  mixins: [accessMixin, baseMixin, dataMixin, routeMixin],
  components: {
    BudegtItemQuoteEdit,
    EditReceivedQuote,
    ReceivedQuotes,
    ReceivedQuoteTable
  },
  props: {
    budgetItem: Object,
    projectId: String
  },
  data() {
    return {
      showEdit: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      this.loadByProject("receivedQuote", this.project_id);
      this.loaded();
    },
    add() {
      this.showEdit = true;
      const quote = {
        item: { _id: this.budgetItem.itemId, name: this.budgetItem.name },
        project: { _id: this.project._id, name: this.project.name }
      };

      this.$nextTick(() => {
        this.$refs.editReceivedQuote.open(quote);
      });
    },
    edit(quote) {
      this.showEdit = true;
      this.$nextTick(() => {
        this.$refs.editReceivedQuote.open(quote);
      });
    },
    onEditDone() {}
  },
  computed: {
    list() {
      return this.receivedQuotes.filter(
        o =>
          o.item &&
          o.item._id == this.budgetItem.itemId &&
          o.project &&
          o.project._id == this.projectId
      );
    },
    nodata() {
      return !this.list.length;
    },
    project() {
      return this.projectById(this.projectId);
    },

    ...mapGetters(["categories", "receivedQuotes", "projectById"])
  }
};
</script>
