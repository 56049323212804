<template>
  <div>
    <ul class="list-group list-group-flush green">
      <div
        class="list-group-item d-flex align-items-center c-pointer list-group-item-action justify-content-between"
        @click="select(c)"
        :class="{ active: selected._id == c._id }"
        v-for="c in users"
        v-bind:key="c._id"
      >
        <div class="d-flex align-items-center">
          <avatar :size="35" :username="c.name" :src="c.photoURL" />
          <div class="ml-2">
            <div class="text-truncate d-block text-default">{{ c.name |capital }}</div>
            <div class="text-muted text-truncate d-block fs-09">{{ c.email }}</div>
          </div>
        </div>

        <div>
          <div
            class="badge badge-pill"
            :class="c.role=='c'?'badge-primary':'badge-secondary'"
          >{{roleName(c.role)}}</div>
          <div
            class="badge badge-pill badge-warning d-block"
            v-if="c.pending"
          >{{c.pending?'Pending':'' }}</div>
        </div>
      </div>
    </ul>
    <Ul class="list-group">
      <a
        class="list-group-item d-flex justify-content-start text-primary align-items-center border"
        @click="invite()"
      >
        <i class="icon-user-follow fs-15"></i>
        <span class="ml-2 text-truncate fs-12">Invite User</span>
      </a>
    </Ul>
  </div>
</template>

<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import roles from "./roles";
import { mapGetters } from "vuex";

export default {
  name: "clientUsers",
  mixins: [baseMixin, dataMixin],
  props: { users: Array },

  data() {
    return {
      selected: {}
    };
  },
  methods: {
    async load() {
      // this.loading();
      // var users = await this.loadList("user");
      // this.users = users;
      this.loaded();
    },
    invite() {
      this.$router.push("/settings/invite");
    },
    select(user) {
      this.selected = user;
      this.$emit("select", user);
    },
    isMe(c) {
      return c.uid == this.me.uid;
    }
  },
  computed: {
    roleName() {
      return r => roles[r];
    },
    ...mapGetters(["me"]),
    customers() {
      return this.users.filter(o => o.role == "c");
    },
    internalUsers() {
      return this.users.filter(o => o.role != "c");
    }
  }
};
</script>
<style lang="scss" scoped>
.is-me {
  border-left: 3px solid orange !important;
}
.list-group-item:last-child {
  border-bottom: none;
}
</style>
