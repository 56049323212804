<template>
  <div class="card-layout card d-flex border-0 border-bottom mb-2" id="card-layout">
    <div class="card-header d-flex justify-content-between align-items-center" v-if="!noheader">
      <back-button v-if="back" />
      <div class="d-inline-block fs-14 m-0">
        {{ title }}
        <slot name="title"></slot>
      </div>

      <div>
        <span class="d-flex justify-content-end" v-if="!state.loading">
          <slot name="header"></slot>
        </span>
        <spinner v-else :busy="state.loading" />
      </div>
    </div>
    <div class="flex-grow-1">
      <slot name="data" v-if="!nodata && state.loaded"></slot>
      <slot></slot>
    </div>

    <div class="card-footer" v-if="!nofooter && state.loaded">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "cardlayout",

  props: {
    nodata: Boolean,
    page: Object,
    state: Object,
    title: String,
    nofooter: Boolean,
    noheader: Boolean,
    back: Boolean
  }
};
</script>
