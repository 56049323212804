<template>
  <div class="container p-5">
    <div class="row" v-show="!isAuthenticating &&!isAuthenticated">
      <div class="col-lg-3"></div>

      <div class="col-lg-6">
        <div class="login text-center">
          <div class="d-flex justify-content-around">
            <div class="d-flex align-items-center p-2">
              <img src="/img/logo/nexobuild.png" class="logo" alt="Nexobuild" />
              <span class="fs-2 pt-3">Nexobuild</span>
            </div>
          </div>

          <div class="border-0 bg-1 rounded-10 py-3">
            <h4 class="my-3">Sign in</h4>
            <div ref="authcontainer" id="firebaseui-auth-container" class="p-3"></div>
            <div>
              By proceeding you agree to our
              <br />
              <a href="https://nexobuildwebsite.azurewebsites.net/terms.html">Terms of Service</a>
              and
              <a
                href="https://nexobuildwebsite.azurewebsites.net/privacy.html"
              >Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhoneAuthProvider, GoogleAuthProvider, EmailAuthProvider, getAuth } from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      error: "",
      address: ""
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    async init() {
      let address = localStorage.getItem("address");
      if (address) {
        this.address = JSON.parse(address);

          await this.startUI();
      } else {
        try {
          await this.$store.dispatch("FIND_LOCATION_ADDRESS");
        } catch (error) {
        } finally {
          setTimeout(() => {
            this.address = this.$store.getters.address;
            this.startUI();
          }, 300);
        }
      }
    },
    async startUI() {
      const callbackURL = this.$route.query.callback;

      let code =
        this.address && this.address.country ? this.address.country.code : "";

      var uiConfig = {
        signInOptions: [
          GoogleAuthProvider.PROVIDER_ID,
          {
            provider: EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true
          },
          {
            provider: PhoneAuthProvider.PROVIDER_ID,
            requireDisplayName: true,
            defaultCountry: code,
            // Invisible reCAPTCHA with image challenge and bottom left badge.
            recaptchaParameters: {
              type: "image",
              size: "invisible",
              badge: "bottomleft"
            }
          }
        ],

        signInSuccessUrl: callbackURL || "/",
        signInFlow: "popup"
      };
      let ui = firebaseui.auth.AuthUI.getInstance();
      if (!ui) {
        ui = new firebaseui.auth.AuthUI(getAuth());
      }
      ui.start("#firebaseui-auth-container", uiConfig);
    }
  },
  computed: {
    ...mapGetters(["isAuthenticating", "isAuthenticated"])
  }
};
</script>
<style lang="scss" scoped>
.logo {
  max-width: 3rem;
  height: auto;
}
</style>
