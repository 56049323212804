<template>
  <a @click="toggle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :width="size"
      :height="size"
      viewBox="0 0 50 50"
      version="1.1"
    >
      <g :fill="fill" :class="{ hover: hover, checked: value }">
        <path
          v-if="value"
          d="M25,3C12.87,3,3,12.87,3,25s9.87,22,22,22s22-9.87,22-22S37.13,3,25,3z M35.83,16.56L24.32,33.53l-9-8.35 c-0.4-0.38-0.43-1.01-0.05-1.42c0.37-0.4,1.01-0.42,1.41-0.05l7.29,6.76l10.2-15.03c0.31-0.46,0.94-0.58,1.39-0.27 C36.02,15.48,36.14,16.1,35.83,16.56z"
        />
        <path
          v-else
          d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z "
        />
      </g>
    </svg>
  </a>
</template>
<script>
export default {
  name: "check",
  data() {
    return {
      iconStyle: {
        "font-size": `${this.size}px `
      }
    };
  },
  props: {
    value: Boolean,
    size: {
      type: [Number, String],
      default: 25
    },
    color: {
      type: String,
      default: "rgba(255, 255, 255, 0.6)"
    },
    hover: {
      type: Boolean,
      default: false
    },
    colorHover: {
      type: String,
      default: "rgba(255, 255, 255, 0.9)"
    },
    disabled: Boolean
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.$emit("change", !this.value);
      this.$emit("input", !this.value);
    }
  },
  computed: {
    fill() {
      return this.disabled ? "#999" : this.value ? "green" : "gray";
    }
  }
};
</script>
