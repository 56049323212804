<template>
  <div class="entity-view">
    <a
      ref="lookupRef"
      @click="select"
      class="d-flex justify-content-between align-items-center"
      :class="{ 'action-link': !project._id }"
      v-if="project"
    >
      <div class="d-flex align-items-center">
        <svg-icon name="folder" class="mr-3" v-if="project._id" />
        <span :class="{ 'has-value': project && project.name }">{{ project.name || placeholder }}</span>
      </div>
      <i class="icon-arrow-down mt-1 ml-2"></i>
    </a>

    <select-project
      @select="changed"
      v-if="modalShow"
      :excludeIds="excludeIds"
      ref="modalSelect"
      :container="container"
    />
  </div>
</template>

<script>
import SelectProject from "./SelectProject";
import data from "@/mixins/dataMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "ProjectSelector",
  components: { SelectProject },
  mixins: [data],
  props: {
    project: Object,
    placeholder: String,
    excludeIds: Array,
    container: [String, HTMLElement],
    showicon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    select() {
      this.show();
    },
    changed(project) {
      this.modalShow = false;
      this.project._id = project._id;
      this.project.name = project.name;
      this.project.imageUrl = project.imageUrl ? project.imageUrl : "";
      this.$emit("change", project);
    },
    show() {
      this.modalShow = true;
      this.$nextTick(() => {
        this.$refs.modalSelect.open(this.$refs.lookupRef);
      });
    }
  },
  computed: {
    project_store() {
      return this.project && this.project._id
        ? this.projectById(this.project._id)
        : {};
    },
    ...mapGetters(["projectById"])
  }
};
</script>

<style lang="scss" scoped>
.project-avatar {
  margin-top: -0.2rem;
}
.project-icon {
  font-size: 1.2rem;
}
</style>
