<template>
  <lookup-layout
    :state="state"
    :addmode="addmode"
    :isOpen="isOpen"
    :target="target"
    @close="isOpen=false"
    :height="18"
    :container="container"
  >
    <div slot="search">
      <search-input
        @change="search"
        :placeholder="'Search accounts'"
        :isfocus="true"
        v-model="keyword"
        :busy="state.loading"
        class="mr-4 ml-2"
      />
    </div>

    <div slot="add" class="text-default">
      <h6 class="px-3">Add new Account</h6>
      <NewAccount
        ref="newForm"
        @done="onAdded"
        v-if="addmode"
        @cancel="cancelAdd"
        class="px-0 pt-2 new-company"
      />
    </div>
    <div slot="list">
      <ul class="list-group list-group-flush" v-show="!addmode">
        <li
          class="list-group-item list-group-item-action"
          v-for="item in list"
          v-bind:key="item._id"
          :class="{ selected: isSelected(item._id) }"
        >
          <a @click="select(item)" class="d-flex justify-content-between">
            <div>{{ item.name }}</div>
            <div class="text-muted small">{{ item.description }}</div>
          </a>
        </li>
        <li class="list-group-account list-group-account-action border-top py-1 bg-white">
          <add-button link @add="addNew">Add New Account</add-button>
        </li>
      </ul>
    </div>
  </lookup-layout>
</template>

<script>
import NewAccount from "./NewAccount";
import lookupMixin from "@/mixins/lookupMixin";
import searchEntity from "../data/search";
import pages from "@/pages";

export default {
  name: "selectAccount",
  props: {
    selecteds: Array
  },
  data() {
    return {
      entityName: "accounts"
    };
  },
  mixins: [lookupMixin],
  components: { NewAccount },

  methods: {
    async load() {
      this.loading();
      await this.loadPerClient(this.entityName);
      this.loaded();
      this.filterAll();
    },
    filterAll() {
      let result = this.all;
      this.list = searchEntity(result, this.keyword);
    },
    onAdded(entity) {
      this.addmode = false;
      this.filterAll();
      this.select(entity);
    }
  },

  computed: {
    all() {
      return this.$store.getters.accounts.list || [];
    }
  }
};
</script>
