<template>
  <div id="app">
    <navbar class="header" />
    <Notification />
    <toast-message />
    <div class="page-wrapper" id="main-container">
      <transition-page trans="slide">
        <router-view />
      </transition-page>
    </div>
    <!-- Displayed when Service Worker says we have an update avaliable -->
    <div v-if="showUpdateNotif">
      <b-alert
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 5000"
        variant="primary"
        dismissible
        @dismissed="showUpdateNotif = false"
        :show="showUpdateNotif"
      >
        A Nexobuild update is available
        <b-button class="ml-3" variant="primary" size="sm" @click="reloadPage()"
          >Update Nexobuild</b-button
        >
      </b-alert>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/layout/Navbar";
import ToastMessage from "./components/views/ToastMessage";
import Notification from "./views/notification/Notification";
export default {
  name: "app",
  components: { Navbar, ToastMessage, Notification },

  data() {
    return {
      showUpdateNotif: false,
    };
  },
  mounted() {
    // Listen for ServiceWorker being updated
    // document.addEventListener("swUpdated", this.updateAvaliable);
  },
  beforeDestroy() {
    // document.removeEventListener("swUpdated", this.updateAvaliable);
  },
  methods: {
    updateAvaliable() {
      this.showUpdateNotif = false;
    },
    reloadPage() {
      this.showUpdateNotif = false;
      window.location.reload();
    },
  },
};
</script>
