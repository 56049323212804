<template>
  <div>
    <div class="list-group mt-4" v-if="hasAccess">
      <a class="list-group-item" @click="openShare" :class="{'text-success':isShared}">
        <i class="icon-share mr-2"></i>
        <span v-if="isShared">Shared with Clients</span>
        <span v-else>Share with Clients</span>
      </a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import accessMixin from "@/mixins/accessMixin";
import dataMixin from "@/mixins/dataMixin";

export default {
  name: "shareProjectButton",
  props: { project: Object },
  mixins: [accessMixin, dataMixin],
  mounted() {
    this.load();
  },
  methods: {
    openShare() {
      this.$router.push(`/project/${this.project._id}/shareCustomer`);
    },
    async load() {
      if (
        !this.shareCustomer &&
        this.hasAccess &&
        this.project &&
        this.project.customer
      )
        await this.loadById("shareCustomer", this.project.customer._id);
    }
  },
  watch: {
    project: {
      immediate: true,
      handler() {
        this.load();
      }
    }
  },
  computed: {
    shareCustomer() {
      return this.shareCustomers.find(o => o._id == this.project.customer._id);
    },
    isShared() {
      return this.shareCustomer
        ? this.shareCustomer.projects.map(o => o._id).includes(this.project._id)
        : false;
    },
    ...mapGetters(["shareCustomers"])
  }
};
</script>