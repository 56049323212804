 
<template>
  <div class="rounded-5 px-3 py-2 weather">
    <div class="row">
      <div class="col-lg-6">
        <label>Weather</label>
        <select
          class="form-control text-center custom-select"
          ref="select"
          @change="change($event.target.value)"
          v-model="weather.type"
        >
          <option v-for="s of options" v-bind:key="s.code" v-bind:value="s.code">{{s.name}}</option>
        </select>
      </div>
      <div class="col-lg-6">
        <label for>Temperature</label>
        <div class="row">
          <div class="col ml-auto">
            <input type="text" class="form-control" v-model="weather.minTemp" placeholder="Min. " />
          </div>
          <div class="m-auto">
            <span>to</span>
          </div>
          <div class="col mr-auto">
            <input type="text" class="form-control" v-model="weather.maxTemp" placeholder="Max. " />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "weather",

  props: {
    weather: Object
  },
  data() {
    return {
      options: [
        { code: "sunny", name: "Sunny" },
        { code: "rainy", name: "Rainy" },
        { code: "cloudy", name: "Cloudy" },
        { code: "foggy", name: "Foggy" },
        { code: "windy", name: "Windy" },
        { code: "snowey", name: "Snowey" }
      ]
    };
  },
  methods: {
    change(value) {
      const option = this.options.find(o => o.code == value);
      this.weather.description = option ? option.name : value;
      this.$emit("change", value);
    },

    focus() {
      this.$refs.select.focus();
    }
  }
};
</script>

 