<template>
  <div>
    <div>
      <div class="px-3 pb-2 rounded-5-top d-flex justify-content-between align-items-center">
        <span class="text-2">Received Quotations</span>
        <add-button @add="add" v-if="!nodata">Add Received Quotation</add-button>
      </div>
      <ReceivedQuoteTable :list="list" @edit="edit" />
      <div class="text-center p-3 bg-1" v-if="nodata">
        <h5 class="text-muted">No quotations</h5>
        <div class="row" v-if="!hideAction">
          <div class="col-lg-4"></div>
          <div class="col-lg-4">
            <add-button link @add="add">Add Quotation</add-button>
          </div>
        </div>
      </div>
    </div>

    <no-data
      v-if="nodata"
      title="No quotation has been entered"
      subtitle="Add the first quotation for this project"
      svg="svg-contacts"
    >
      <add-button @add="add">Add Received Quotation</add-button>
    </no-data>

    <EditReceivedQuote ref="addReceivedQuote" :project="project" @done="onAdd" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import accessMixin from "@/mixins/routeMixin.js";
import EditReceivedQuote from "./EditReceivedQuote";
import ReceivedQuoteTable from "./components/ReceivedQuoteTable";
const entityName = "receivedQuote";

export default {
  name: "receivedQuotes",
  mixins: [accessMixin, baseMixin, dataMixin, routeMixin],
  components: { EditReceivedQuote, ReceivedQuoteTable },
  mounted() {
    this.load();
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {
    async load() {
      this.loading();
      //this.loadByProject("contract", this.project_id);
      this.loaded();
    },

    add() {
      this.$refs.addReceivedQuote.open();
    },
    onAdd(c) {},
    edit(quote) {
      this.$refs.addReceivedQuote.open(quote);
    },
    async toContract(quote) {
      let contract = await this.update(
        entityName,
        { _id: quote._id },
        `toContract`
      );
      console.log(contract);
      this.$router.push(
        `/project/${contract.project._id}/contract/${contract._id}/edit`
      );
    },
    confirmDel(quote) {
      this.$store.commit("SET_STATUS", {
        entity: quote,
        status: "confirm-delete"
      });
    },
    async delQuote(quote) {
      await this.del(entityName, quote);
      this.$emit("done");
    },
    cancelDel(quote) {
      this.$store.commit("CLEAR_STATUS", quote);
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },
    list() {
      return this.receivedQuotes.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    nodata() {
      return this.ready && !this.list.length;
    },

    ...mapGetters(["projectById", "receivedQuotes"])
  }
};
</script>
<style lang="scss" scoped>
.vh-100-m {
  min-height: calc(100vh - 14rem);
}
</style>