<template>
  <div>
    <ItemSetup ref="budgetItemSetup" :projectId="project_id" type="budgeting" @done="onSetupDone" />
    <CopyFromProject
      ref="copyFromProject"
      :projectId="project_id"
      type="budgeting"
      @done="onSetupDone"
    />

    <div class="text-center p-5" v-if="nodata">
      <h3 class="mb-3 text-muted text-capitalize">No Budgeting</h3>
      <p class="text-center text-muted">
        You don't have budget for this project yet
        <br />You can copy from previous projects or setup a new one
      </p>
      <div class="row" v-if="!hideAction">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <div class="mt-4">
            <button class="btn btn-success w-100" @click="showSetup">
              <i class="la la-plus"></i>
              Setup Task Breakdown
            </button>
          </div>

          <div class="mt-3">
            <button class="btn btn-outline-primary w-100" @click="showCopyfrom">
              <i class="la la-plus"></i>
              Copy from Exiting Projects
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import routeMixin from "@/mixins/routeMixin.js";
import accessMixin from "@/mixins/accessMixin";
import CopyFromProject from "../CopyFromProject";
import ItemSetup from "../ItemSetup.vue";

export default {
  name: "noBudgeting",
  mixins: [routeMixin, accessMixin],
  props: {
    budgeting: Object,
    nodata: Boolean
  },
  components: { CopyFromProject, ItemSetup },
  methods: {
    showSetup() {
      this.$refs.budgetItemSetup.open(this.budgeting);
    },
    showCopyfrom() {
      this.$refs.copyFromProject.open();
    },
    onSetupDone() {}
  }
};
</script>