<template>
  <b-modal ref="modal" size="lg" :title="title" no-close-on-esc hide-footer>
    <div class="py-2" v-if="show">
      <div class="row">
        <div class="col-lg-8">
          <div class="px-4">
            <section class="form-group">
              <label>Project</label>
              <div class="form-control value">
                <project-view :project="contract.project" :navigate="false" />
              </div>
            </section>
            <section class="form-group">
              <label>Subcontractor</label>
              <div>
                <contact-selector
                  :contact="contract.contact"
                  name="company"
                  ref="contactSelctor"
                  :class="{ 'is-invalid': validated && !contract.contact._id }"
                  placeholder="Select subcontractor"
                  @change="contactChanged"
                  class="form-control"
                />
                <div
                  class="invalid-feedback"
                  v-if="validated && !contract.contact._id"
                >Select a Contact</div>
              </div>
            </section>

            <section class="form-group">
              <label>Work Item</label>
              <div>
                <item-selector
                  name="title"
                  ref="itemSelector"
                  :item="contract.item"
                  placeholder="Select work item"
                  :class="{
								'is-invalid': validated && !contract.item._id,
							}"
                  @change="itemChanged"
                  class="form-control"
                />
                <div
                  class="invalid-feedback"
                  v-if="validated && !contract.item._id"
                >Please select a item</div>
              </div>
            </section>

            <section class="form-group">
              <label>Brief Description</label>
              <div>
                <textarea
                  class="form-control"
                  v-model="contract.scope"
                  placeholder="Scope of the work"
                  ref="scopeInput"
                  rows="5"
                ></textarea>
              </div>
            </section>
          </div>
        </div>
        <div class="col-lg-4">
          <entity-attachments :entityName="'contract'" :entity="contract" />
        </div>
      </div>

      <div class="modal-footer save-footer mt-3">
        <save-cancel @save="save" @cancel="cancel"></save-cancel>
      </div>
    </div>
  </b-modal>
</template>

<script>
import data from "@/mixins/dataMixin.js";
import modal from "@/mixins/modalMixin.js";
export default {
  name: "addSubcontract",
  mixins: [data, modal],
  data() {
    return {
      title: "Add Subcontract",
      contract: {
        contact: {},
        item: {},
        project: {
          _id: this.project._id,
          name: this.project.name
        },
        attachments: [],
        date: new Date()
      },
      validated: false
    };
  },
  props: { project: Object },
  mounted() {
    this.reset();
  },
  methods: {
    reset() {
      this.contract = {
        contact: {},
        item: {},
        project: {
          _id: this.project._id,
          name: this.project.name
        },
        attachments: [],
        date: new Date()
      };
      this.validated = false;
    },
    focus() {
      this.$refs.contactSelctor.show();
    },
    itemChanged() {
      this.focusNext();
    },
    contactChanged() {
      this.focusNext();
    },
    focusNext() {
      setTimeout(() => {
        if (!this.contract.contact._id) {
          this.$refs.contactSelctor.show();
          return;
        }
        if (!this.contract.item._id) {
          this.$refs.itemSelector.show();
          return;
        }
        if (!this.contract.scope) {
          this.$refs.scopeInput.focus();
          return;
        }
      }, 200);
    },

    async save() {
      this.validated = true;
      if (!this.valid()) return;
      try {
        const contract = await this.add("contract", this.contract);
        contract.title = this.contract.item.name;
        this.reset();
        this.$emit("done", contract);
        this.close();
      } catch (error) {}
    },

    valid() {
      return (
        this.contract && this.contract.contact._id && this.contract.item._id
      );
    }
  }
};
</script>
