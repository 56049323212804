<template>
  <div class="project-image position-relative">
    <img class="card-img-top img-project img-fluid" :src="src" alt="Project Image Url" />
    <button class="btn cam-btn bg-trans" @click="handleFileUpload">
      <i class="icon-camera"></i>
    </button>
    <b-modal
      ref="uploadModal"
      size="lg"
      title="Upload Image"
      hide-footer
      @hide="showUploadModal = false"
    >
      <CropImage
        v-if="showUploadModal"
        :folder="folder"
        :isavatar="false"
        :round="false"
        @cancel="closeModal"
        @done="save"
      />
    </b-modal>
  </div>
</template>

<script>
import CropImage from "@/views/attachments/CropImage.vue";
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
export default {
  name: "projectImage",
  components: { CropImage },
  mixins: [dataMixin],
  props: {
    project: Object
  },

  data() {
    return {
      url: "/img/placeholders/blank_const_job.png",
      showUploadModal: false,
      entityName: "project"
    };
  },
  methods: {
    handleFileUpload() {
      this.showUploadModal = true;
      this.$refs.uploadModal.show();
    },
    closeModal() {
      this.$refs.uploadModal.hide();
      this.showUploadModal = false;
    },
    async save(url) {
      this.closeModal();

      let payload = {
        imageUrl: url
      };
      await this.update("project", payload, "update", this.project._id);
      this.url = url;
      this.$emit("change", url);
    }
  },
  computed: {
    folder() {
      return `/clients/${this.cid}/prj/${this.project._id}/img`;
    },
    src() {
      return this.project.imageUrl || this.url;
    },
    ...mapGetters(["cid"])
  }
};
</script>

<style lang="scss" scoped>
img.img-project {
  max-height: 18rem;
  object-fit: cover;
}
.cam-btn {
  position: absolute;
  padding: 0.25rem 0.75rem;
  right: 1rem;
  margin: auto;
  bottom: 0.5rem;
  font-size: 1.5rem;
  background-color: rgba(252, 252, 252, 0.2);
}
</style>
