<template>
  <div class="d-flex align-items-center" v-if="user">
    <div>
      <avatar :username="user.name || user.displayName" :src="src" :size="size" class="mr-2" />
    </div>

    <span v-if="!imageolnly">{{ user.name || user.displayName }}</span>
  </div>
</template>
<script>
export default {
  name: "userView",
  props: {
    user: Object,
    imageolnly: Boolean,
    size: {
      type: Number,
      default: 30
    }
  },
  computed: {
    src() {
      return this.user.imageUrl || this.user.photoURL;
    }
  }
};
</script>

<style lang="scss" scoped></style>
