<template>
  <div> 
    <label v-if="label">{{label}}</label>
    <div class="input-group mb-3 prepend">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <i class="icon-clock"></i>
        </span>
      </div>
      <vue-timepicker
        ref="timePicker"
        format="hh:mm A"
        manual-input
        auto-scroll
        v-model="time"
        @close="onClose"
        @open="$emit('open')"
        apm-label="AM/PM"
        am-text="AM"
        pm-text="PM"
        @change="onChange"
        @blur="$emit('blur')"
        @error="onError"
        :minute-interval="5"
        :input-class="{'is-invalid': invalid}"
        close-on-complete
      ></vue-timepicker>
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import { isValidTime } from "@/helpers/date";
export default {
  name: "dateInput",
  components: { VueTimepicker },
  props: {
    value: [String, Date],
    label: String,
    validated: Boolean,
    placeholder: {
      type: String,
      default: "HH:mm"
    }
  },
  data() {
    return {
      time: "",
      error: "e",
      closed: false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.time = this.value;
      }
    }
  },
  methods: {
    onChange(value) {
      console.log("value", value);
      this.$emit("input", value.displayTime);
      this.$emit("change", value.displayTime);
    },
    focus() {
      console.log("focus", this.$refs.timePicker);
      let input = this.$refs.timePicker;
      if (input) {
        input.$refs.input.focus();
      }
    },
    onClose() {
      this.closed = true;
      this.$emit("close");
    },
    onError(e) {
      this.$emit("error");
    }
  },
  computed: {
    invalid() {
      return (this.closed || this.validated) && !isValidTime(this.value);
    }
  }
};
</script>

 