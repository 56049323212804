<template>
  <span>
    <svg :width="size" :height="size" viewBox="0 0 50 50" v-if="type.path">
      <g :fill="type.color" :class="{ hover: hover }">
        <path :d="type.path" />
      </g>
    </svg>
  </span>
</template>

<script>
import paths from "./paths.json";
import { isImage, isPdf, isDoc, isExcel } from "../../helpers/file";
export default {
  name: "svgIcon",

  props: {
    name: {
      type: String,
      default: ".pdf"
    },
    size: {
      type: [Number, String],
      default: 28
    },

    hover: {
      type: Boolean,
      default: false
    },
    colorHover: {
      type: String,
      default: "rgba(255, 255, 255, 0.9)"
    }
  },
  data() {
    return {
      iconStyle: {
        "font-size": `${this.size}px `
      }
    };
  },
  computed: {
    type() {
      let p = "";
      let color = "#d84315";
      if (isPdf(this.name)) {
        color = "red";
        p = "pdf";
      } else if (isExcel(this.name)) {
        color = "green";
        p = "excel";
      } else if (isDoc(this.name)) {
        color = "blue";
        p = "word";
      } else if (isImage(this.name)) {
        color = "orange";
        p = "image";
      }
      return {
        path: paths[p],
        color: color
      };
      paths[p];
    }
  }
};
</script>
<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
path {
  stroke: none;
  fill-rule: evenodd;
  transition: ease-in 0.2s;
}
svg:hover g.hover {
  fill: rgba(255, 255, 255, 0.9);
}
</style>
