<template>
	<label class="switch ">
		<input type="checkbox" :checked="value" @change="change($event.target.checked)" />
		<span class="slider round"></span>
	</label>
</template>

<script>
export default {
	name: 'switchToggle',
	props: ['value'],
	methods: {
		change(checked) {
			this.$emit('input', checked);
			this.$emit('change', checked);
		},
	},
};
</script>

<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 48px;
	height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 22px;
	width: 22px;
	left: 2px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

input:checked + .slider {
	background-color: #8bc34a;
}

input:focus + .slider {
	box-shadow: 0 0 1px #628d32;
}

input:checked + .slider:before {
	-webkit-transform: translateX(21px);
	-ms-transform: translateX(21px);
	transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>
