import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
// Get a reference to the storage service, which is used to create references in your storage bucket

export default (fileInfo) => {
	const storageRef = ref(getStorage(), fileInfo.filePath);
	const uploadTask = uploadBytesResumable(storageRef, fileInfo.file, {
		contentType: fileInfo.file.type ?? "binary/octet-stream"
	});

	return new Promise((resolve, reject) => {
		// Listen for state changes, errors, and completion of the upload.
		uploadTask.on(
			"state_changed",
			function(snapshot) {
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				console.log("Upload is " + progress + "% done");
				fileInfo.progress = progress;
				switch (snapshot.state) {
					case "paused": // or 'paused'
						console.log("Upload is paused");
						break;
					case "running": // or 'running'
						console.log("Upload is running");
						break;
				}
			},
			function(error) {
				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				switch (error.code) {
					case "storage/unauthorized":
						// User doesn't have permission to access the object

						break;

					case "storage/canceled":
						// User canceled the upload
						break;

					case "storage/unknown":
						// Unknown error occurred, inspect error.serverResponse
						break;
				}
				reject(error.code);
			},
			function() {
				// Upload completed successfully, now we can get the download URL
				getDownloadURL(storageRef).then((downloadURL) => {
					fileInfo.uploadUrl = downloadURL;
					resolve(downloadURL);
				});
			}
		);
	});
};
