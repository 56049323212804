<template>
  <div class="list-layout">
    <div class="page-header d-flex justify-content-between no-print px-4 py-3">
      <h5 class="d-flex page-title">
        <client-name-in-header />
        <slot name="title"></slot>
      </h5>
      <spinner :busy="state.loading" />
      <div class="d-flex">
        <slot name="btns" v-if="!nodata && state.loaded"></slot>
        <slot name="top-add" v-if="state.loaded"></slot>
      </div>
    </div>

    <div class="body container over-header pt-3" v-if="!nodata && state.loaded">
      <slot></slot>
    </div>
    <div v-if="nodata && state.loaded">
      <slot name="nodata"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Containerlayout",

  props: {
    nodata: Boolean,
    state: Object
  }
};
</script>
