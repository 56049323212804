export const fileMixin = {
	methods: {
		open(file) {
			window.open(file.url, "_blank");
		},
		changeStatus(file, status) {
			this.$store.dispatch(CHANGE_FILE_STATUS, {
				file: file,
				status: status
			});
		},
		confirm(file) {
			this.changeStatus(file, "confirm-delete");
		},
		async delAttachment(file) {
			console.log("del :", file);
			if (file && file._id) {
				try {
					await this.$store.dispatch("DEL", {
						entityName: this.entityName,
						action: `attachments/del/${this.entity._id}`,
						model: file
					});
				} catch (error) {
					console.log("error :", error);
				} finally {
					file.__status = "";
				}
			}
		},
		canceldel(file) {
			this.changeStatus(file, "");
		}
	},
	computed: {
		isImage() {
			return name => isImage(name);
		}
	}
};
