export default {
	computed: {
		project_id() {
			return this.$route.params.project_id;
		},
		budgetItem_id() {
			return this.$route.params.budgetItem_id;
		},
		budgeting_id() {
			return this.$route.params.budgeting_id;
		},

		contact_id() {
			return this.$route.params.contact_id;
		},

		payable_id() {
			return this.$route.params.payable_id;
		},
		dailyLog_id() {
			return this.$route.params.dailyLog_id;
		},
		cashIn_id() {
			return this.$route.params.cashIn_id;
		},
		invoice_id() {
			return this.$route.params.invoice_id;
		},
		income_id() {
			return this.$route.params.income_id;
		},
		timesheet_id() {
			return this.$route.params.timesheet_id;
		},
		entity_id() {
			return (name) => this.$route.params[`${name}_id`];
		},
	},
};
