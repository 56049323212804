import Vue from "vue";
import moment from "moment";
import store from "../store";

let clientFormat = "";

const getFormat = () => {
	if (!clientFormat) clientFormat = store.getters.dateFormat;
	return clientFormat;
};

export const dateformat = (date, format) => {
	if (!date) return "";
	if (!format) {
		format = getFormat();
	}
	return moment(date).format(format);
};

Vue.filter("dateformat", function(date, format) {
	return dateformat(date, format);
});

Vue.filter("day", function(date, format) {
	if (!date) return "";
	if (!format) {
		format = getFormat();
	}
	return (
		moment(date)
			// .utc(date)
			// .local()
			.format("ll")
	);
});

Vue.filter("timeformat", function(date, format) {
	if (!date) return "";
	if (!format) {
		format = "YYYY-MM-DD h:mm a ";
	}
	return moment
		.utc(date)
		.local()
		.format(format);
});

Vue.filter("format_ll", function(date, format) {
	if (!date) return "";
	if (!format) {
		format = "ll";
	}
	return moment(date).format(format);
});

Vue.filter("since", function(date) {
	if (!date) return "";
	return moment
		.utc(date)
		.local()
		.fromNow();
});
Vue.filter("calendar", function(date) {
	if (!date) return "";
	return moment
		.utc(date)
		.calendar()
		.split(" at")[0];
});

Vue.filter("duration", function(value) {
	if (!value) return "";
	return `${~~(value / 60)}:${value % 60}`;
});
