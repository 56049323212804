<template>
  <lookup-layout
    :state="state"
    :isOpen="isOpen"
    :target="target"
    @close="isOpen=false"
    :container="container"
    :height="28"
  >
    <div slot="search">
      <search-input
        @change="search"
        :placeholder="'Search items'"
        :isfocus="true"
        v-model="keyword"
        :busy="state.loading"
        class="mr-4 ml-2"
      />
    </div>

    <div slot="list">
      <ul class="list-group" v-show="!addmode">
        <li
          class="list-group-item list-group-item-action"
          v-for="item in list"
          v-bind:key="item._id"
          :class="{ selected: isSelected(item._id) }"
        >
          <a @click="select(item)" class="customerListItem">
            <item-view :item="item" />
          </a>
        </li>
      </ul>
    </div>
  </lookup-layout>
</template>

<script>
import lookupMixin from "@/mixins/lookupMixin";
import searchEntity from "../data/search";
import pages from "@/pages";
import { mapGetters } from "vuex";
import budgetingMixin from "@/views/budgeting/data/budgetingMixin";

export default {
  name: "selectItem",
  props: {
    selecteds: Array,
    projectId: String
  },
  mixins: [lookupMixin, budgetingMixin],
  data() {
    return {
      entityName: "itemMaster"
    };
  },
  methods: {
    async load() {
      this.loading();
      await this.loadPerClient(this.entityName);
      await this.loadBudgetingIfNot();
      this.populate();
      this.filterAll();
      this.loaded();
    },
    populate() {
      let items = [...this.items];
      if (this.projectId) {
        const budgetItems = this.budgeting ? this.budgeting.budgetItems : null;
        if (budgetItems) {
          items.map(item => {
            item.budgetItem = budgetItems.find(o => o.itemId == item._id);
          });
        }
      }

      this.all = [
        ...items.filter(o => o.budgetItem),
        ...items.filter(o => !o.budgetItem)
      ];
    },
    filterAll() {
      let result = this.all;
      this.list = searchEntity(result, this.keyword);
    }
  },
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.load();
      }
    }
  }
};
</script>
