<template>
  <div class="shadow-sm">
    <div class="px-3 py-2 bg-trans rounded-5-top fs-11">Expenses</div>
    <highcharts :options="chartOptions" ref="hchart" />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import { subTotal } from "../data/calc";
import moment from "moment";
import { sortDate, todate } from "@/helpers/date";
export default {
  name: "clientInvoiceChart",
  components: { highcharts: Chart },
  mixins: [baseMixin],
  props: {
    list: Array
  },
  data() {
    return {
      chartOptions: {
        chart: {
          //plotBackgroundColor: "#f7f5f8",
          plotBorderWidth: null,
          plotShadow: false,
          height: 250
        },
        title: {
          text: ""
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: "%e. %b",
            year: "%b"
          }
        },
        yAxis: {
          //min: 0,
          title: {
            text: "Amount ($)"
          }
        },
        credits: {
          enabled: false
        },
        tooltip: {
          pointFormat: "{series.name}: <b>${point.y:,.0f}</b>"
        },

        series: [
          // {
          //   type: "areaspline",
          //   name: "Expensed",
          //   color: "#f3e5f5",
          //   data: []
          // },
          {
            type: "column",
            name: "Expensed",
            color: "#673ab7",
            data: []
          }
        ]
      }
    };
  },
  watch: {
    invoices: {
      immediate: true,
      handler() {
        this.updateData();
      }
    }
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      let balance = 0;
      let balanceData = [];
      let data = _(this.list)
        .groupBy(o => moment(o.date).format("YYYYMMDD"))
        .map((value, key) => ({
          key,
          date: todate(value[0].date),
          amount: value.map(subTotal).reduce((a, b) => a + b, 0)
        }))
        .orderBy(o => o.date)
        .value()
        .map(o => [o.date, o.amount]);

      this.chartOptions.series[0].data = data;
      // this.chartOptions.series[1].data = data;
    }
  },
  computed: {
    total() {
      return this.list.map(subTotal).reduce((a, b) => a + b, 0);
    }
  }
};
</script>
 
