<template>
  <div class="d-flex align-items-center" v-if="user">
    <avatar :size="size" :username="user.name" :src="user.photoURL" />
    <span class="ml-1 text-truncate d-block">{{ user.name |capital }}</span>
  </div>
</template>
<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import { mapGetters } from "vuex";
export default {
  name: "user",
  mixins: [baseMixin, dataMixin],
  props: {
    uid: String,
    size: {
      type: Number,
      default: 24
    }
  },
  mounted() {
    if (!this.user) {
      this.load();
    }
  },
  methods: {
    async load() {
      this.loading();
      await this.loadList("user");
      this.loaded();
    }
  },
  computed: {
    ...mapGetters(["users"]),
    user() {
      return this.users.find(o => o.uid == this.uid);
    }
  }
};
</script>