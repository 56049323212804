 <template>
  <card-layout
    :state="state"
    :nodata="nodata"
    title="Project Summary"
    nofooter
    back
  >
    <div slot="header" class="d-flex">
      <div v-if="!nodata">
        <div class="d-flex align-items-center">
          <export-button @click.native="exportData" />
          <print-button></print-button>
        </div>
      </div>
    </div>

    <div slot="data">
      <div class="d-flex px-3 bg-2 shadow-sm m-3 d-print-none">
        <div v-for="column in columns" :key="column.field" class="mx-2 mt-2">
          <b-checkbox v-model="column.visible" class="form-group">{{
            column.desc
          }}</b-checkbox>
        </div>
      </div>

      <div class="px-3" v-if="totals">
        <div class="alert alert-info" v-if="project.sqf">
          <div class="row">
            <div class="col-lg-4">
              Project SQFT :<strong class="ml-2"> {{ project.sqf }}</strong>
            </div>
            <div class="col-lg-4">
              Total Cost to Date / sqft:
              <strong class="ml-2">
                {{ (totals.costToDate / project.sqf) | currency }}</strong
              >
            </div>
            <div class="col-lg-4">
              Total Budget / sqft:
              <strong class="ml-2">
                {{ (totals.budget / project.sqf) | currency }}</strong
              >
            </div>
          </div>
        </div>
        <div class="alert alert-warning" v-else>
          If you need per sqft calculation, please define project total sqft on
          <router-link :to="`/project/${project._id}/info`"
            >Project Information Page</router-link
          >
        </div>
      </div>

      <table class="table table-hover thead-md pad-md mb-0">
        <thead>
          <tr>
            <th scope="col" class="w-3">No</th>
            <th scope="col" class="w-15">Title</th>
            <budget-th
              v-for="column in columns"
              :key="column.field"
              :column="column"
            />
            <th scope="col" class="w-3"></th>
          </tr>
        </thead>
        <tbody v-for="g in groups" v-bind:key="g._id">
          <tr class="bg-1">
            <td class="fw-600">{{ g.category.code }}</td>
            <td class="fw-600">
              <div>{{ g.category.name }}</div>
              <BudgetProgress :value="g.costOverBudget" />
            </td>
            <budget-td
              v-for="column in columns"
              :key="column.field"
              :column="column"
              :item="g"
              :head="true"
            />
            <td></td>
          </tr>
          <tr v-for="item in g.items" v-bind:key="item._id">
            <td>{{ item.code }}</td>
            <td class="py-1">
              <a @click="navigateTo(item)">
                <div>{{ item.name }}</div>
                <BudgetProgress :value="item.costOverBudget" />
              </a>
            </td>
            <budget-td
              v-for="column in columns"
              :key="column.field"
              :column="column"
              :item="item"
            />
            <td class="text-right">
              <button
                @click="navigateTo(item)"
                class="btn btn-sm"
                tabindex="-1"
              >
                <i class="icon-arrow-right mr-1 text-primary"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr class="bg-2">
            <td colspan="2" class="text-right">TOTAL</td>
            <BudgetTdTotal
              v-for="column in columns"
              :key="column.field"
              :column="column"
              :totals="totals"
            />
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import budgetMixin from "./data/budgetMixin.js";
import accessMixin from "@/mixins/accessMixin";
import BudgetTd from "./components/BudgetTd";
import BudgetTh from "./components/BudgetTh";
import BudgetTdTotal from "./components/BudgetTdTotal";
import BudgetProgress from "./components/BudgetProgress";
import { exportcsv } from "./data/export";
import _ from "lodash";

export default {
  name: "projectSumamry",
  mixins: [baseMixin, dataMixin, routeMixin, budgetMixin, accessMixin],
  components: { BudgetTd, BudgetTh, BudgetProgress, BudgetTdTotal },
  data() {
    return {
      columns: [
        {
          field: "initialBudget",
          desc: "Initial Budget",
          visible: true
        },
        {
          field: "budget",
          desc: "Current Budget",
          visible: true
        },
        {
          field: "costToDate",
          desc: "Cost To Date",
          visible: true
        },
        {
          field: "costOverBudget",
          desc: "Cost / Budget ",
          visible: true,
          isPercent: true
        },
        {
          field: "costToComplete",
          desc: "Cost To Complete",
          visible: true
        },
        {
          field: "costPerSqf",
          desc: "Cost / SQFT ",
          visible: false,
          isPercent: false
        },
        {
          field: "budgetPerSqf",
          desc: "Budget / SQFT ",
          visible: false,
          isPercent: false
        },
        {
          field: "note",
          desc: "Note",
          visible: false,
          isText: true,
          width: "-"
        }
      ],

      budgeting: {},
      groups: [],
      totals: {}
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    async load(force) {
      this.loading();
      if (!this.budgeting)
        await this.loadByAction("budgeting", `project/${this.project_id}`);
      await this.loadById("project", this.project_id);

      await this.loadPerClient("itemMaster");
      this.populate();
      this.loaded();
      this.select({ id: 0 });
      this.$forceUpdate();
    },
    populate() {
      this.budgeting = this.projectBudgeting;
      let itemCategories = this.categories;
      const items = this.projectBudgeting.budgetItems;
      const sqf = this.project.sqf;
      items.map(item => {
        item.budget == 0
          ? 0
          : (item.costOverBudget = item.costToDate / item.budget);
        item.costToComplete = item.budget - item.costToDate;
        if (sqf && sqf != 0) {
          item.costPerSqf = item.costToDate / sqf;
          item.budgetPerSqf = item.budget / sqf;
        }
      });

      this.groups = _(items)
        .groupBy(item => item.categoryId)
        .map((value, key) => {
          const initialBudget = value
            .map(o => o.initialBudget)
            .reduce((a, b) => a + b, 0);

          const budget = value.map(o => o.budget).reduce((a, b) => a + b, 0);

          const costToDate = value
            .map(o => o.costToDate)
            .reduce((a, b) => a + b, 0);

          return {
            _id: key,
            category: itemCategories.find(o => o._id == key) || {},
            initialBudget,
            budget,
            costToDate,
            costToComplete: budget - costToDate,
            costOverBudget: budget == 0 ? 0 : costToDate / budget,
            items: value,

            costPerSqf: sqf ? costToDate / sqf : "",
            budgetPerSqf: sqf ? budget / sqf : ""
          };
        })
        .value();

      this.totals = this.getTotals(items);
    },
    navigateTo(item) {
      const route = this.budgeting.isLockedBudget ? "revise" : "edit";
      this.$router.push(
        `/project/${this.project_id}/budgeting/${this.budgeting._id}/${route}/${item._id}`
      );
    },
    exportData() {
      exportcsv(this.budgetItems);
    }
  },
  computed: {
    nodata() {
      return this.state.loaded && !this.projectBudgeting;
    },
    visibleCount() {
      return this.columns.filter(o => o.visible).length + 2;
    },
    projectBudgeting() {
      return this.budgetings.find(o => o.projectId == this.project_id);
    },
    project() {
      return this.projects.find(o => o._id == this.project_id);
    },

    ...mapGetters(["itemMaster", "categories", "items", "projects"])
  }
};
</script>