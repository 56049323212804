
<template>
  <div class="mng-fee bg-1">
    <div class="bg-2 p-3 d-flex justify-content-between">
      <span class="fs-15">Reimbursement payments</span>
      <save-cancel savetext="Update" @save="save" @cancel="cancel" />
    </div>
    <div class="p-3" v-if="this.ready">
      <div class="d-flex justify-content-between pr-3 mb-2">
        <div>
          Selected Expenses
          <span>({{selecteds.length}})</span>
          <button class="btn ml-5 text-primary" @click="selectAll">Select All</button>
        </div>

        <div>
          Selecteds Total :
          <span class="fs-11 fw-600 no-wrap">{{total | currency0}}</span>
        </div>
      </div>

      <div class="table-container">
        <spinner :busy="state.loading" v-if="state.loading" />
        <table class="table table-hover bg-white shadow-sm" v-else>
          <thead>
            <tr>
              <th scope="col" class="w-4">Select</th>
              <th scope="col" class="w-6 text-center no-wrap">Date</th>
              <th scope="col">Payee</th>
              <th scope="col">Title</th>
              <th scope="col" class="w-7">Reference #</th>
              <th scope="col" class="text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in list" v-bind:key="p._id" :class="{checked:p.checked}">
              <td class="pr-1">
                <check v-model="p.checked" @change="onChange" />
              </td>

              <td class="date no-wrap">{{p.date | dateformat}}</td>
              <td>{{ p.payee && p.payee.name }}</td>
              <td>{{ p.title }}</td>
              <td>{{ p.refNo }}</td>
              <td class="text-right">{{ p.amount | currency }}</td>

              <td>
                <attachment-icon :attachments="p.attachments" class="py-1" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="bg-2 p-3 mt-3 border form-group">
        <section class="form-group row">
          <div class="col-lg-10">
            <label for>Line item title</label>
            <input
              type="text"
              v-model="item.description"
              class="form-control"
              placeholder="Management fee line text"
            />
          </div>
          <div class="col-lg-2">
            <div class="row d-flex">
              <div class="col">
                <label>Total Payments</label>
                <div class="form-control value fw-600">{{total | currency}}</div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <label>Notes</label>
          <div>
            <textarea
              class="form-control"
              v-model="item.note"
              rows="2"
              placeholder="Enter notes... "
            ></textarea>
          </div>
        </section>
      </div>

      <div class>
        <save-cancel savetext="Update" @save="save" @cancel="cancel" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import codes from "../data/itemcodes";
import invoiceMixin from "../data/invoiceMixin";
import taxMixin from "@/views/settings/data/taxMixin";
import PaidAmount from "@/views/payable/components/PaidAmount";
import PayableTypeName from "@/views/payable/components/PayableTypeName";

export default {
  name: "mngFeeEdit",
  mixins: [dataMixin, baseMixin, routeMixin, invoiceMixin, taxMixin],
  components: { PaidAmount, PayableTypeName },
  props: {
    invoice: Object
  },
  data() {
    return {
      list: [],
      percent: 0,
      item: {},
      payments: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      this.payments = await this.loadByAction(
        "payment",
        `project/${this.project_id}/reimbursables`,
        true
      );
      this.populate();
      this.selectExistings();
      this.loaded();
    },

    populate() {
      //filter payments that not invoiced or on this invoice
      let list = this.payments.filter(
        o => !o.invoiceId || o.invoiceId == this.invoice._id
      );

      list = JSON.parse(JSON.stringify(list));
      //this needs to be here to make it observable
      list.map(o => (o.checked = false));
      this.list = list;

      if (this.reimbItem) {
        this.item = JSON.parse(JSON.stringify(this.reimbItem));
      } else {
        this.item = this.newItem();
      }
    },

    selectExistings() {
      if (!this.item.payments) return;
      let ids = this.item.payments.map(o => o._id);
      this.list.map(o => (o.checked = ids.includes(o._id)));
    },

    newItem() {
      return {
        description: "Reimbursement payments",
        note: "",
        qty: 1,
        unitPrice: 0,
        tax1: this.newTax(1),
        tax2: this.newTax(2),
        code: codes.REIMBURSEMENT,
        payments: []
      };
    },
    selectAll() {
      if (!this.list.length) return;
      let checked = this.list[0].checked;
      this.list.filter(o => !o.noMngFee).map(o => (o.checked = !checked));
      this.onChange();
    },
    onChange() {},
    save() {
      let item = this.item;
      item.unitPrice = this.total;
      //set selected items
      item.payments = this.selecteds;

      let i = this.invoice.items.indexOf(this.reimbItem);
      if (i >= 0) {
        this.invoice.items.splice(i, 1);
        this.invoice.items[i] = item;
        //
      } else {
        this.invoice.items.push(item);
      }
      this.$emit("done", item);
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  computed: {
    selecteds() {
      return this.list.filter(o => o.checked) || [];
    },
    total() {
      return this.selecteds.map(o => o.amount).reduce((a, b) => a + b, 0);
    },

    ...mapGetters(["payables", "projectById"])
  }
};
</script>

<style lang="scss" scoped>
.table-container {
  max-height: 40vh;
  min-height: 20rem;
}
.mng-fee {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.table tr.no-mng-fee td {
  background-color: #e8e8e8;
  opacity: 0.5;
}
.table tr.checked td {
  background-color: #eff7ef !important;
}
</style>