<template>
  <b-popover
    trigger="manual"
    offset="5"
    :show.sync="isOpen"
    ref="popover"
    custom-class="modal-cont"
    placement="auto"
    :target="target"
    :auto-hide="false"
  >
    <template v-slot:title>
      <div class="p-2">Select User</div>
    </template>
    <div v-if="isOpen">
      <ul class="list-group list-group-flush green">
        <div
          class="list-group-item d-flex align-items-center c-pointer list-group-item-action justify-content-between"
          @click="select(c)"
          v-for="c in users"
          v-bind:key="c._id"
          :class="{disabled:!isInRole(c)}"
        >
          <div class="d-flex align-items-center">
            <avatar :size="35" :username="c.name" :src="c.photoURL" />
            <span class="ml-2 text-truncate d-block">{{ c.name |capital }}</span>
          </div>
          <div class="badge badge-pill badge-info">{{roleName(c.role)}}</div>
        </div>
      </ul>
    </div>
  </b-popover>
</template>

<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import roles from "@/views/settings/access/roles";
import { mapGetters } from "vuex";

export default {
  name: "clientUsers",
  mixins: [baseMixin, dataMixin],
  mounted() {
    this.init();
  },
  props: {
    roles: Array
  },
  data() {
    return {
      selected: {},
      users: [],
      isOpen: false,
      target: ""
    };
  },
  methods: {
    open(el) {
      this.target = el;
      this.isOpen = false;
      setTimeout(() => {
        this.isOpen = true;
      }, 100);
    },
    cancel() {
      this.isOpen = false;
    },
    init() {
      this.load();
    },
    async load() {
      this.loading();
      var users = await this.loadList("user");
      this.users = users;
      this.loaded();
    },
    invite() {
      this.$router.push("/settings/invite");
    },
    select(user) {
      if (!this.isInRole(user)) return;
      this.selected = user;
      this.isOpen = false;
      this.$emit("select", user);
    },
    isMe(c) {
      return c.uid == this.me.uid;
    }
  },
  computed: {
    isInRole() {
      return user =>
        !this.roles || !this.roles.length
          ? true
          : this.roles.includes(user.role);
    },
    roleName() {
      return r => roles[r];
    },
    ...mapGetters(["me"])
  }
};
</script>
<style lang="scss" scoped>
.modal-cont {
  min-width: 30rem;
}
.is-me {
  border-left: 3px solid orange !important;
}
</style>
