<template>
  <b-popover
    v-if="isOpen"
    trigger="manual"
    offset="5"
    :show.sync="isOpen"
    ref="popover"
    custom-class="address-panel"
    placement="auto"
    :target="target"
    :auto-hide="false"
    no-fade
  >
    <template v-slot:title>
      <div class="d-flex justify-content-between align-items-center pl-3 pr-0">
        {{title}}
        <button class="btn close p-2 px-3" @click="cancel" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </button>
      </div>
    </template>
    <div class="text-left" v-if="isOpen">
      <AddressInput
        :address="address"
        :types="types"
        @blur="onBlur"
        class="panel px-3 pt-3"
        @select="focusUpdate"
      />

      <div class="px-3 pt-3 d-flex justify-content-between py-2 border-top">
        <button class="btn text-gray" @click="cancel">Cancel</button>
        <button class="btn btn-outline-primary" ref="btnUpdate" @click="update">Update</button>
      </div>
    </div>
  </b-popover>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin.js";
import AddressInput from "./AddressInput";

export default {
  name: "addressInputPopup",
  mixins: [dataMixin],
  components: { AddressInput },
  props: {
    title: {
      type: String,
      default: "Update Address"
    },
    types: {
      type: String
      // default: "address" ,
    }
  },
  data() {
    return {
      isOpen: false,
      target: "",
      address: "",
      originalAddress: ""
    };
  },
  methods: {
    open(el, address) {
      this.isOpen = false;
      this.target = el;
      this.originalAddress = address;
      this.address = JSON.parse(JSON.stringify(address));
      this.$forceUpdate();
      setTimeout(() => {
        this.isOpen = true;
      }, 100);
    },
    onBlur(keyword) {},
    cancel() {
      this.isOpen = false;
    },
    focus() {
      if (!this.item.name) {
        setTimeout(() => {
          this.$refs.firstInput.focus();
        }, 500);
      }
    },
    focusUpdate() {
      setTimeout(() => {
        if (this.address.name) this.$refs.btnUpdate.focus();
      }, 300);
    },

    update() {
      this.isOpen = false;
      this.$forceUpdate();
      this.$emit("change", this.address);
    }
  }
};
</script>
<style lang="scss">
.address-panel {
  min-width: 32rem;
}
@media (max-width: 768px) {
  .address-panel {
    min-width: 100%;
  }
}

.popover-body {
  padding: 0 !important;
  background-color: rgb(247, 247, 247);
  color: #212529;
}
.popover {
  background: #f9f9f9;
  border: 1px solid rgb(224, 224, 224) !important;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss" scoped>
.confirm {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
