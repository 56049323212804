<template>
  <div class="card company-card shadow-sm bg-1 rounded border-0">
    <CompanyCard :contact="contact" />
    <!-- <PersonCard :contact="contact" v-else /> -->
  </div>
</template>

<script>
import CompanyCard from "./CompanyCard.vue";
import PersonCard from "./PersonCard";
export default {
  name: "contactCard",
  components: { CompanyCard, PersonCard },
  mixins: [],
  props: {
    contact: Object,
    navigate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isCompany() {
      return this.true;
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-avatar {
  margin-top: -0.2rem;
}
.contact-icon {
  font-size: 1.2rem;
}
</style>
