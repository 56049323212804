<template>
  <div class="alert alert-danger m-0">
    <div class="confirm">
      <div class="pb-3">{{ confirmtext }} ?</div>
      <div class="d-flex">
        <button
          class="btn btn-danger mx-0 py-0 disabled d-flex align-items-center btn-sm"
          disabled
          v-if="deleting"
        >
          <spinner :busy="true" />Deleting...
        </button>

        <button v-else class="btn btn-danger mx-3" @click="del">Delete</button>

        <button
          class="btn ml-2"
          :class="{disabled: deleting}"
          :disabled="deleting"
          @click="$emit('cancel')"
        >Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "delDoc",
  props: {
    confirmtext: {
      type: String,
      default: " Delete this file"
    },
    file: Object
  },
  methods: {
    del() {
      this.$emit("del", this.file);
    }
  },
  computed: {
    deleting() {
      return this.file.__status == "d";
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm.alert {
  margin: 0 !important;
}
.confirm.alert .btn {
  min-width: 6rem;
}
</style>
