<template>
  <ul class="text-gray mb-0" v-if="!haspayable">
    <li>Cost and payment tracking</li>
    <li>Managing tax & rebates</li>
  </ul>
  <div class="d-flex justify-content-between mt-2 pr-4" v-else>
    <div class="text-center">
      <div class="fs-14 text-2 no-wrap">{{ payableSummary.total | currency }}</div>
      <div class="text-secondary small">Total Expense</div>
    </div>
    <!-- <div class="text-center">
      <div class="fs-1 text-2 no-wrap">{{payableSummary.paid | currency}}</div>
      <div class="text-secondary small">Paid</div>
    </div>-->
    <div class="text-center">
      <div
        class="fs-14 text-2 no-wrap"
      >{{ (payableSummary.total - payableSummary.paid) | currency }}</div>
      <div class="text-secondary small">Unpaid</div>
    </div>
    <div></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "payableSummary",
  mixins: [baseMixin, dataMixin, routeMixin, accessMixin],

  mounted() {
    if (this.isUserCustomer) return;
    this.load();
  },
  methods: {
    load() {
      if (this.isUserCustomer) return;
      this.loadById("payableSummary", this.project_id, false,false);
    }
  },
  computed: {
    payableSummary() {
      return this.payableSummaries.find(o => o._id == this.project_id);
    },
    haspayable() {
      if (!this.payableSummary) return false;
      return this.payableSummary.total;
    },
    ...mapGetters(["payableSummaries"])
  }
};
</script>
