<template>
  <container-layout :nodata="nodata" :state="state" :page="page">
    <div slot="title">{{ page.name }}</div>
    <span slot="btns">
      <ImportContact class="mr-3" @done="load" />
      <!-- <export-button></export-button>
      <print-button class="mr-5"></print-button>-->
    </span>
    <span slot="top-add">
      <add-button @add="openAdd" v-if="!nodata">Add Contact</add-button>
      <add-contact ref="addContact" @done="onAdd" />
    </span>

    <div slot="side"></div>
    <div>
      <div class="pb-3 row">
        <div class="col-lg-4">
          <search-input
            placeholder="Search name, phone, person"
            @change="search"
            v-model="keyword"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-3" v-for="c in list" :key="c._id">
          <contact-card :contact="c" />
        </div>
      </div>
    </div>
    <no-data
      slot="nodata"
      :svg="page.svg"
      title="No Contact"
      subtitle="You don't have any contact! "
    >
      <add-button @add="openAdd">Add a Contact</add-button>
    </no-data>
  </container-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import pages from "@/pages";
import ContactCard from "./components/ContactCard";
import AddContact from "./AddContact";
import ImportContact from "./components/ImportContact";

const entityName = "contact";
export default {
  name: "contacts",
  mixins: [baseMixin, dataMixin],
  components: { ContactCard, AddContact, ImportContact },
  data() {
    return {
      page: pages.contacts,
      keyword: "",
      list: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadList(entityName);
      this.populate(this.contacts);
      this.loaded();
    },
    populate(list) {
      this.list = list;
    },
    search() {
      if (!this.keyword) {
        this.list = this.contacts;
        return;
      }
      const keyword = this.keyword.toLowerCase();
      const keywordClean = keyword.replace(/[-+( ).]/g, "");
      this.list = this.contacts.filter(
        o =>
          (o.name && o.name.toLowerCase().includes(keyword)) ||
          (o.description && o.description.toLowerCase().includes(keyword)) ||
          (o.phone &&
            o.phone.replace(/[-+( ).]/g, "").includes(keywordClean)) ||
          (o.people &&
            o.people.length &&
            (o.people.some(
              p => p.name && p.name.toLowerCase().includes(keyword)
            ) ||
              o.people.some(
                p => p.email && p.email.toLowerCase().startsWith(keyword)
              )))
      );
    },
    openAdd() {
      this.$refs.addContact.open();
    },
    onAdd(c) {
      this.$router.push(`contact/${c._id}`);
    }
  },
  computed: {
    nodata() {
      return this.ready && !this.contacts.length;
    },
    ...mapGetters(["contacts"])
  }
};
</script>
