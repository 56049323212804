<template>
  <div class="bg-info-1 rounded-5 form-group shadow-sm" v-if="contractType">
    <div class="fs-12 bg-trans rounded-5-top px-3 py-1">{{contractType.name}}</div>
    <div class="p-3">
      <div v-if="contractType.code">
        <div class="form-group">
          <div>
            <div class="fw-600">
              <label class="mr-3">Material Cost:</label>
              {{contractType.costByContact ?'By Client' :'By Contractor'}}
            </div>

            <div v-if="contractType.costByContact">
              <span class="fw-600">{{clientName}}</span>
              will pay all material costs
              <!-- <br />
              <span
                v-if="isProjcetContract "
                class="text-2"
              >System will calculate reimbursement if any cost paid by {{client.name}}</span>-->
            </div>

            <div v-else>
              <span class="fw-600">{{contractorName}}</span>
              will pay all material costs
              <br />
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center form-group" v-if="contractType.code=='c'">
          <span>A percentage</span>
          <div class="d-inline-block text-amount border rounded fw-600">{{contractType.percent }} %</div>
          <span>
            of all costs
            <span>as management fee</span>
          </span>
        </div>

        <div class="d-flex align-items-center form-group" v-if="contractType.code=='f'">
          <span>A fix price of</span>
          <div class="text-amount border rounded">{{contractType.fixPrice | currency}}</div>
        </div>

        <div class="d-flex align-items-center form-group" v-if="contractType.code=='t'">
          <span>A fix Rate of</span>
          <div class="text-amount text-amount border rounded">{{contractType.fixRate | currency}}</div>
          <span class="mx-2">/</span>
          <span>{{contractType.ratePer}}</span>
        </div>

        <div class="d-flex align-items-center" v-if="contractType.code=='u'">
          <span>A fix price</span>
          <div class="text-amount text-amount border rounded">{{contractType.unitPrice |currency}}</div>
          <span class="px-2">Of Unit</span>
          <span>{{contractType.uom}}</span>
        </div>

        <div>{{contractType.note}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "contractType",
  props: {
    contract: Object,
    isProjcetContract: Boolean
  },
  data() {
    return {
      // contractType: {
      //   code: "up",
      //   name: "Cost Plus",
      //   percent: 10,
      //   fixPrice: 260000.0,
      //   fixRate: 80,
      //   ratePer: "hr",
      //   unitPrice: 120,
      //   uom: "Each",
      //   note: "",
      //   contractType: false
      // },
      types: [
        { code: "", name: " Select Contract Type " },
        { code: "c", name: "Cost Plus", costByContact: true },
        { code: "f", name: "Fixed Price", costByContact: false },
        { code: "t", name: "Time & Material", costByContact: true },
        { code: "u", name: "Unit price", costByContact: false },
        { code: "o", name: "Other", costByContact: false }
      ],
      perRates: [
        { code: "hr", name: "hour" },
        { code: "day", name: "Day" },
        { code: "month", name: "Month" }
      ]
    };
  },
  methods: {
    onTypeChanged(code) {
      let type = this.types.find(o => o.code == code);
      if (type) {
        this.contractType.name = type.name;
        this.contractType.costByContact = type.costByContact;
      }
      setTimeout(() => {
        let el = this.$refs.firstInput;
        if (el) el.focus();
      }, 300);
    }
  },
  computed: {
    contractType() {
      return this.contract ? this.contract.contractType : "";
    },

    contactName() {
      return this.contract && this.contract.contact
        ? this.contract.contact.name
        : "";
    },
    contractorName() {
      return this.isProjcetContract ? this.client.name : this.contactName;
    },
    clientName() {
      return this.isProjcetContract ? this.contactName : this.client.name;
    },
    ...mapGetters(["client"])
  }
};
</script>

<style lang="scss" scoped>
.text-amount {
  // background-color: #fff176;
  padding: 0.25rem 0.75rem;
  margin: 0 0.5rem;
  font-weight: 600;
}
.w-i {
  width: 5rem !important;
}
.w-num {
  width: 10rem !important;
}
</style>