<template>
  <b-modal ref="paymentModal" size="lg" :title="title" hide-footer>
    <div class="px-2" v-if="modalShow">
      <div>
        <div>
          <div class="row">
            <div class="col col-md-7">
              <section class="form-group row">
                <label class="col-lg-4 col-form-label">Payment Date</label>
                <div class="col-lg-8">
                  <date-input ref="datePicker" v-model="payment.date" />
                </div>
              </section>

              <section class="row form-group amount">
                <label class="col-lg-4 col-form-label">{{ title }} Amount</label>
                <div class="col-lg-8">
                  <amount-input
                    ref="amountInput"
                    :readonly="!!payment.invoiceId"
                    :class="{
											'is-invalid': validated && payment.amount == 0,
										}"
                    v-model="payment.amount"
                    placeholder="Enter Amount"
                  />
                </div>
              </section>

              <section class="form-group row">
                <label
                  class="col-lg-4 col-form-label"
                >{{ payment.isRefund ? 'To' : 'From' }} Account</label>
                <div class="col-lg-8">
                  <account-selector
                    name="account"
                    ref="accountSelector"
                    :account="payment.account"
                    placeholder="Select Account"
                    class="form-control"
                  />
                </div>
              </section>

              <!-- <hr> -->

              <section class="row form-group">
                <label class="col-lg-4 col-form-label" for="refNo">Reference</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    ref="refNo"
                    id="refNo"
                    v-model="payment.refNo"
                    placeholder="e.g. Cheque Number"
                    class="form-control"
                  />
                </div>
              </section>
              <!-- <section class="row form-group">
                <label class="col-lg-4 col-form-label" for="refNo">Reimbursable</label>
                <div class="col-lg-8">
                  <div class="alert alert-success m-0" v-if="payment.invoiceId">
                    Submitted for reimbursement on this
                    <router-link
                      v-if="payment.invoiceId"
                      :to="`/project/${payable.project._id}/invoice/${payment.invoiceId}`"
                    >Invoice</router-link>
                  </div>
                  <b-checkbox
                    v-else
                    v-model="payment.isReimbursable"
                    switch
                  >Include in next invoice to client</b-checkbox>
                </div>
              </section>-->

              <section class="row form-group">
                <label for="note" class="col-lg-4 col-form-label">Notes</label>
                <div class="col-lg-8">
                  <textarea
                    class="form-control"
                    v-model="payment.note"
                    maxlength="400"
                    placeholder="Payment notes..."
                    rows="4"
                    id="note"
                  ></textarea>
                </div>
              </section>
            </div>
            <div class="col-md-5">
              <!-- <p class="alert alert-info" v-if="payment.invoiceId">
                Amount is locked!
                <br />This payment has been submitted for reimbursement.
                To change the amount you need to remove it from the invoice
              </p>-->
              <entity-attachments
                :entityName="'payable'"
                :entity="payable"
                childName="payments"
                :child="payment"
              />
            </div>
          </div>

          <div class="modal-footer save-footer mt-3 d-flex justify-content-between">
            <delete-confirm
              v-if="!isnew"
              class="d-inline-block del"
              fill
              @confirm="del"
              confirmtext="Are you sure you want to delete this Payment"
            />
            <save-cancel @save="save" @cancel="close"></save-cancel>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import newPayment from "./data/newPayment.js";
const entityName = "payment";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import calc from "./data/calc.js";
export default {
  name: "paymentEdit",
  mixins: [dataMixin, baseMixin],
  props: {
    id: Number,
    isRefund: Boolean,
    payable: Object
  },
  data() {
    return {
      payment: {},
      validated: false,
      modalShow: false
    };
  },
  methods: {
    open(payment) {
      this.modalShow = true;
      this.init(payment);
      this.$refs.paymentModal.show();
    },

    close() {
      this.modalShow = true;
      this.$refs.paymentModal.hide();
    },

    init(payment) {
      if (!payment) {
        this.payment = newPayment();
        //set amount to be payable balance
        this.payment.amount = calc.balance(this.payable);
      } else {
        this.payment = JSON.parse(JSON.stringify(payment));
      }

      setTimeout(() => {
        this.$refs.amountInput.focus();
      }, 300);
    },
    reset() {
      this.payment = newPayment();
    },

    async save() {
      this.validated = true;
      if (!this.valid) return;
      try {
        const result = await this.update(
          "payable",
          this.payment,
          `${this.payable._id}/payment/${this.isnew ? "add" : "update"}`
        );
        this.$emit("done", result);
        this.reset();
        this.close();
      } catch (error) {
        console.log("error :", error);
      }
    },
    async del() {
      try {
        await this.listDelete(
          "payable",
          this.payment,
          `${this.payable._id}/payment/del`
        );
        this.$emit("done");
        this.reset();
        this.close();
      } catch (error) {
        console.log("error :", error);
      }
    }
  },
  computed: {
    valid() {
      return this.payment && this.payment.date && this.payment.amount != 0;
    },
    isnew() {
      return !this.payment._id;
    },
    title() {
      return this.payment.isRefund ? "Refund" : "Payment";
    }
  }
};
</script>

<style lang="scss" scoped>
.col-form-label {
  padding-right: 0;
}
</style>
