import Vue from "vue";
import numeral from "numeral";

Vue.filter("tofixed", function(value) {
	if (!value) return 0;
	return numeral(value).format("0.0");
});

Vue.filter("num", function(value, decimals) {
	if (value) {
		let format = "0,0.0";
		if (decimals == 5) format = "0,0.00000";
		if (decimals == 4) format = "0,0.0000";
		if (decimals == 3) format = "0,0.000";
		if (decimals == 2) format = "0,0.00";
		if (decimals == 0) format = "0,0";

		return numeral(value).format(format);
	}
});

Vue.filter("percent", function(value) {
	if (!value) return 0;
	return (value * 100).toFixed(2) + " %";
});
