import axios from "axios";
import config from "@/config/config.json";

export default axios.create({
	baseURL:
		process.env.NODE_ENV == "production"
			? process.env.VUE_APP_API_URL
			: config.forceLocal
			? config.BASE_URL_LOCAL
			: process.env.VUE_APP_API_URL
});
