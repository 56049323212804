<template>
  <card-layout :state="state" title="Item Master" back :nodata="nodata" nofooter>
    <div slot="header">
      <div class="d-flex align-items-center">
        <button-import @onImport="importFromExcel" title="Import From Excel" />
      </div>
    </div>
    <div class="card-body p-3 h-100" slot="data">
      <div class="w-50">
        <table class="table">
          <tr v-for="row in importRows" :key="row[2]">
            <td>{{row[0]}}</td>
            <td>{{row[1]}}</td>
            <td>{{row[2]}}</td>
          </tr>
        </table>
      </div>
      <div class="row" v-if="!nodata">
        <div class="col-md-4">
          <item-categories
            ref="itemCategoriesComponent"
            :editable="hasAccess"
            @select="select"
            :items="all"
          />
        </div>
        <div class="col-md-8">
          <table class="table table-hover thead-bottom-border header-md">
            <thead>
              <tr>
                <th scope="col" class="w-3"></th>
                <th scope="col" class="w-5">Code #</th>
                <th scope="col">Item</th>
              </tr>
            </thead>
            <tbody v-for="c in selectedCategories" v-bind:key="c._id">
              <tr class="category">
                <th colspan="3">{{ c.name }}</th>
              </tr>

              <tr v-for="item in getItemsforCategory(c._id)" v-bind:key="item._id">
                <td>
                  <a @click="showEditItem(item)" v-if="hasAccess">
                    <i class="icon-pencil text-primary mr-2"></i>
                  </a>
                </td>
                <td>
                  {{ item.code }}
                  <!-- <input class="form-control" v-model="b.no" placeholder="Code" /> -->
                </td>
                <td>
                  {{ item.name }}
                  <!-- <input class="form-control" v-model="b.name" placeholder="Title" /> -->
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <button
                    class="btn text-primary"
                    @click="addCategoryItem(c)"
                    v-if="ready && hasAccess"
                  >
                    <i class="la la-plus"></i>
                    Add
                    {{ getItemsforCategory(c._id).length > 0 ? 'More' : '' }}
                    {{ c.name }} Item
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <no-data entity="BudgetItem" icon="icon-list" v-if="nodata" :custom="true">
        <p class="text-center text-muted">
          You don't have budget for this project yet
          <br />You can copy from previous projects or setup a new one
        </p>
      </no-data>

      <b-modal ref="itemMasterEditModal" title="Edit Item" hide-footer>
        <ItemLineEdit
          :item="selectedItem"
          v-if="itemModelShow"
          @cancel="hideEditItem"
          @done="onItemDone"
          @hidden="itemModelShow = false"
        />
      </b-modal>
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import ItemCategories from "./ItemCategories";
import ItemLineEdit from "./ItemLineEdit";
import pages from "@/pages";
import routeMixin from "@/mixins/routeMixin";
import dataMixin from "@/mixins/dataMixin.js";
import baseMixin from "@/mixins/baseMixin.js";
import accessMixin from "@/mixins/accessMixin.js";
const entityName = "itemMaster";
export default {
  name: "itemMasterEdit",
  components: {
    ItemCategories,
    ItemLineEdit
  },
  mixins: [baseMixin, dataMixin, accessMixin],
  data() {
    return {
      selectedItem: "",
      selectedCategories: [],
      itemModelShow: false,
      newline: {
        code: "",
        name: "",
        categoryId: ""
      },
      importRows: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading();
      await this.loadPerClient(entityName);
      this.loaded();
      this.select();
    },

    select(c) {
      this.selectedCategories = this.categories.filter(
        o => !c || c._id == 0 || o._id == c._id
      );
    },

    showEditItem(item) {
      this.itemModelShow = false;
      this.selectedItem = { ...item };
      this.itemModelShow = true;
      this.$refs.itemMasterEditModal.show();
    },

    addCategoryItem(category) {
      this.selectedItem = {
        categoryId: category._id,
        name: "",
        no: "",
        node: ""
      };
      this.itemModelShow = true;
      this.$refs.itemMasterEditModal.show();
    },

    onItemDone() {
      this.hideEditItem();
    },

    hideEditItem() {
      this.itemModelShow = false;
      this.$refs.itemMasterEditModal.hide();
    },
    async importFromExcel({ rows, append }) {
      try {
        await this.update(entityName, rows, `importFromExcel?append=${append}`);
       
        
      } catch (error) { 
       
      }
      finally{
        this.init();
        }
    }
  },
  computed: {
    getItemsforCategory() {
      return catId => this.all.filter(o => o.categoryId == catId) || [];
    },
    nodata() {
      return this.state.loaded && !this.items.length;
    },
    all() {
      return this.items;
    },
    ...mapGetters(["itemMaster", "items", "categories"])
  }
};
</script>

<style scoped>
.table tbody tr.category {
  background-color: #eeeeee;
}

.table tbody tr th,
.table tbody tr td {
  padding: 0.4em;
}

.table tbody tr th {
  font-weight: 500;
}

.table tbody + tbody {
  border-top: 2px solid #bfbfbf;
}
</style>
