<template>
  <button
    class="btn mr-3 btn-default d-flex"
    @click="$emit('click')"
    :class="{disabled:busy}"
    :disabled="busy"
  >
    <spinner :busy="busy" :size="1" v-if="busy" class="mr-2" />
    <svg-icon name="pdf" color="red" size="20" class="mr-2" v-else />Preview
    <slot></slot>
  </button>
</template> 
<script>
export default {
  name: "preview",
  props: {
    busy: Boolean
  }
};
</script>

<style scoped>
</style>
