<template>
	<router-link
		:to="item.path"
		:class="{ active: isActive(item) }"
		class="list-group-item border-0 bg-none d-flex align-items-start justify-content-between text-left "
	>
		<span>
			<i :class="item.icon" class="icon  mr-3"></i>
			<!-- <icon :svg="item.svg" :class="item.icon" /> -->
			{{ item.name }}
		</span>

		<span class="badge badge-warning badge-pill" v-if="count">{{ count }}</span>
	</router-link>
</template>
<script>
import navMixin from '@/mixins/navMixin';

export default {
	name: 'NavItem',
	mixins: [navMixin],
	props: {
		count: 0,
		item: Object,
	},
};
</script>
