<template>
  <div v-if="item">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <i class="icon-tag fs-12 mr-3 text-1"></i>
        <span class="mr-2 fw-600">{{item.code}}</span>
        <span>{{ item.name || 'unknown' }}</span>
      </div>

      <span class="bg-1 px-1 rounded" v-tooltip="'budget'" v-if="budget">{{budget | currency0}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "item-view",
  props: {
    item: Object,
    noNavigate: Boolean
  },
  computed: {
    budget() {
      return this.item.budgetItem ? this.item.budgetItem.budget : "";
    }
  }
};
</script>
