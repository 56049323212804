export default {
	computed: {
		totalInvoiced() {
			return this.list.map(o => o.invoiceTotal).reduce((a, b) => a + b, 0);
		},
		totalPaid() {
			return this.incomes.map(o => o.amount).reduce((a, b) => a + b, 0);
		},
		balance() {
			return this.totalInvoiced - this.totalPaid;
		}
	}
};
