<template>
  <div class>
    <div class>
      <div v-for="p in list" :key="p.id">
        <ProgressReportLine :report="p" @open="open(p)" />
      </div>
      <no-data :page="{ icon: 'icon-clock', name: 'Progress report' }" v-if="nodata" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const entityName = "progressReport";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ProgressReportLine from "./ProgressReportLine";
export default {
  name: "progressReports",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { ProgressReportLine },
  mounted() {
    this.load();
  },
  methods: {
    add() {
      this.$router.push(`progressReport/new/edit`);
    },
    open(item) {
      this.$router.push(`progressReport/${item._id}`);
    },
    async load() {
      this.loading();
      await this.loadByAction(entityName, `project/${this.project_id}`);
      this.loaded();
    }
  },

  computed: {
    list() {
      return this.progressReports.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    nodata() {
      return this.ready && !this.list.length;
    },
    ...mapGetters(["progressReports"])
  }
};
</script>
