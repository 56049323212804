<template>
  <div class="p-3 text-center">
    <div class="d-flex justify-content-center">
      <icon :icon="icon" :svg="svg" color="#dee2e6" :size="160" class="d-inline-block icon" />
    </div>
    <div>
      <h4 class="no-data-title mt-3">{{title}}</h4>
      <p class="subtitle">{{subtitle}}</p>
      <div class="d-flex justify-content-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "noData",
  data() {
    return {
      defaultEntity: {
        icon: "icon-doc",
        name: "Data",
        svg: ""
      },
      iconsize: 8
    };
  },
  props: {
    icon: String,
    svg: String,
    title: String,
    subtitle: String
  }
};
</script>

<style lang="scss" scoped>
.no-data-title {
  color: #bdbdbd;
  font-size: 1.8rem;
}
.subtitle {
  color: #717171;
}
.icon {
  color: #dee2e6;
}
</style>
