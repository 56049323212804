<template>
  <div v-if="budgeting">
    <div
      class="rounded-5 bg-1 border-0 d-flex align-items-center justify-content-between pl-3 py-3"
    >
      <div class="w-100">
        <router-link :to="`/project/${this.project_id}/projectSummary`" class="nav-link"></router-link>
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <span class="text-muted">Cost to date / Current Budget</span>
            <strong class="ml-3">{{totalCostOverBudget | percent}}</strong>
          </div>
          <BudgetProgress :value="totalCostOverBudget" class="my-2" />
          <div class="d-flex justify-content-between fs-11">
            <div>
              <div>{{totalCostToDate | currency}}</div>
              <div class="small text-muted">Cost to date</div>
            </div>
            <!-- <div>
                  <div>{{totalBudget | currency}}</div>
                  <div class="small text-muted">Total Budget</div>
            </div>-->
            <div>
              <div>{{totalBudget-totalCostToDate | currency}}</div>
              <div class="small text-muted">Cost to Complete</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <table>
            <!-- <tr>
              <td>Initial Budget:</td>
              <td class="fs-13 pl-3">{{totalInitialBudegt | currency}}</td>
            </tr>-->
            <tr>
              <td>Budget:</td>
              <td class="fs-13 pl-3">{{totalBudget | currency}}</td>
            </tr>
            <!-- <tr>
              <td>Cost To Date:</td>
              <td class="fs-11 pl-3">{{totalCostToDate | currency}}</td>
            </tr>
            <tr>
              <td>Cost To Complete:</td>
              <td class="fs-11 pl-3">{{totalBudget-totalCostToDate | currency}}</td>
            </tr>-->
          </table>
        </div>
      </div>

      <router-link :to="`/project/${this.project_id}/projectSummary`" class="nav-link">
        <i class="icon-arrow-right"></i>
      </router-link>
    </div>
  </div>
</template>
<script>
import BudgetProgress from "@/views/budgeting/components/BudgetProgress";
import routeMixin from "@/mixins/routeMixin.js";
import budgetMixin from "@/views/budgeting/data/budgetMixin";
import { mapGetters } from "vuex";

export default {
  name: "projectOverview",
  mixins: [routeMixin, budgetMixin],
  components: { BudgetProgress },

  computed: {
    budgeting() {
      return this.budgetings.find(o => o.projectId == this.project_id);
    },
    ...mapGetters(["items", "budgetings"])
  }
};
</script>