import Vue from "vue";
import store from "./store";
import Router from "vue-router";
import { getAnalytics, logEvent } from "firebase/analytics";
import Home from "./views/home/Home.vue";
import FirebaseLogin from "./auth/login/FirebaseLogin.vue";
import PageNotFound from "./views/PageNotFound.vue";

import Feeds from "./views/feeds/Feeds.vue";

import Projects from "./views/project/Projects.vue";
import ProjectHome from "./views/project/ProjectHome.vue";
import ProjectNav from "./views/project/ProjectNav.vue";
import ProjectInfo from "./views/project/ProjectInfo.vue";

import ContractManagement from "./views/contract/ContractManagement.vue";
import ProjectProgress from "./views/progress/ProjectProgress.vue";
import DailyLogEdit from "./views/progress/dailylog/DailyLogEdit.vue";
import ProgressReportEdit from "./views/progress/progressReport/ProgressReportEdit";
import ProgressReportView from "./views/progress/progressReport/ProgressReportView";
import ContractEdit from "./views/contract/ContractEdit";
import ShareCustomer from "./views/share/ShareCustomer";

import Budgeting from "./views/budgeting/Budgeting.vue";
import ProjectSummary from "./views/budgeting/ProjectSummary";
import BudgetSummary from "./views/budgeting/BudgetSummary";
import BudgetSetting from "./views/budgeting/BudgetSetting";

import BudgetRevise from "./views/budgeting/BudgetRevise.vue";
import BudgetEdit from "./views/budgeting/BudgetEdit.vue";
import Scheduling from "./views/scheduling/Scheduling.vue";

import CashInEdit from "./views/cashflow/CashInEdit";
import CashInView from "./views/cashflow/CashInView";

import Contacts from "./views/contact/Contacts.vue";
import ContactNav from "./views/contact/ContactNav.vue";
import ContactHome from "./views/contact/ContactHome.vue";
import ContactEdit from "./views/contact/ContactEdit.vue";

import Payables from "./views/payable/Payables.vue";
import PayableNav from "./views/payable/PayableNav.vue";
import PayableEdit from "./views/payable/PayableEdit.vue";
import PayableView from "./views/payable/PayableView.vue";
import ProjectExpenses from "./views/payable/ProjectExpenses";

import ProjectInvoices from "./views/invoice/ProjectInvoices";
import Invoices from "./views/invoice/Invoices";
import InvoiceEdit from "./views/invoice/InvoiceEdit";
import InvoiceView from "./views/invoice/InvoiceView";
import IncomeEdit from "./views/invoice/IncomeEdit";

import ProjectDocs from "./views/docs/ProjectDocs";
import ProjectGallery from "./views/docs/ProjectGallery";

import ProjectCashFlow from "./views/cashflow/ProjectCashFlow";
import SettingsNav from "./views/settings/SettingsNav.vue";

import ProjectTimesheet from "./views/timesheet/ProjectTimesheet";
import TimesheetEdit from "./views/timesheet/TimesheetEdit";

import ItemMaster from "./views/itemmaster/ItemMaster";
import Accounts from "./views/account/Accounts";
import UserProfileEdit from "./views/client/user/UserProfileEdit";

import SwitchsClient from "./views/client/SwitchsClient.vue";
import UserAccess from "./views/settings/access/UserAccess.vue";
import Invite from "./views/settings/invite/Invite";

import ClientSetting from "./views/settings/client-setting/ClientSetting.vue";

import ClientProfile from "./views/settings/client-profile/ClientProfile.vue";
import Templates from "./views/templates/Templates";

import Billing from "./views/billing/Billing.vue";
import pages from "./pages";
import { checkAccess } from "./mixins/accessMixin";
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch(err => err);
// };

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
	if (!store.getters.isAuthenticated) {
		return next();
	}
	next("/");
};

const ifAuthenticated = (to, from, next) => {
	const callback = to.fullPath;
	if (store.getters.isAuthenticated) {
		// if (store.getters.myrole == "c" && to.fullPath == "/") {
		// 	return next("/projects");
		// }
		logEvent(getAnalytics(), "page_navigation", { name: to.name });

		if (to.meta && to.meta.accessRole) {
			if (checkAccess(store.getters.myrole, to.meta.accessRole)) {
				return next();
			} else {
				return next(false);
			}
		} else {
			return next();
		}
	} else {
		let b = callback ? `?callback=${callback}` : "";
		return next(`/login${b}`);
	}
};

export default new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "home",
			component: Home,
			beforeEnter: ifAuthenticated
		},
		{
			path: "*",
			component: PageNotFound
		},
		{
			path: "/login",
			name: "login",
			component: FirebaseLogin,
			beforeEnter: ifNotAuthenticated
		},
		{
			...pages.feeds,
			component: Feeds,
			beforeEnter: ifAuthenticated
		},

		{
			path: pages.projects.path,
			name: pages.projects.name,
			component: Projects,
			beforeEnter: ifAuthenticated
		},
		{
			path: pages.projects.nav.path,
			name: pages.projects.nav.name,
			component: ProjectNav,
			beforeEnter: ifAuthenticated,
			children: [
				{
					...pages.projects.nav.projectHome,
					component: ProjectHome,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.projectInfo,
					component: ProjectInfo,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.budgeting,
					component: Budgeting,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.projectSummary,
					component: ProjectSummary,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.budgetSummary,
					component: BudgetSummary,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.budgetSetting,
					component: BudgetSetting,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.timesheet,
					component: ProjectTimesheet,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.timesheetEdit,
					component: TimesheetEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.scheduling,
					component: Scheduling,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.budegtRevise,
					component: BudgetRevise,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.editBudegt,
					component: BudgetEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.contracts,
					component: ContractManagement,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.progress,
					component: ProjectProgress,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.dailyLogEdit,
					component: DailyLogEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.expenses,
					component: ProjectExpenses,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.invoices,
					component: ProjectInvoices,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.docs,
					component: ProjectDocs,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.gallery,
					component: ProjectGallery,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.cashflow,
					component: ProjectCashFlow,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.cashInEdit,
					component: CashInEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.cashInView,
					component: CashInView,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.invoiceEdit,
					component: InvoiceEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.invoiceView,
					component: InvoiceView,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.incomeEdit,
					component: IncomeEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.payableEdit,
					component: PayableEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.progressReportEdit,
					component: ProgressReportEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.progressReport,
					component: ProgressReportView,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.contractEdit,
					component: ContractEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.projects.nav.shareCustomer,
					component: ShareCustomer,
					beforeEnter: ifAuthenticated
				}
			]
		},
		{
			path: pages.contacts.path,
			name: pages.contacts.name,
			component: Contacts,
			beforeEnter: ifAuthenticated
		},
		{
			path: pages.contacts.nav.path,
			name: pages.contacts.nav.name,
			component: ContactNav,
			beforeEnter: ifAuthenticated,
			children: [
				{
					...pages.contacts.nav.contactHome,
					component: ContactHome,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.contacts.nav.edit,
					component: ContactEdit,
					beforeEnter: ifAuthenticated
				}
			]
		},
		{
			...pages.invoices,
			component: Invoices,
			beforeEnter: ifAuthenticated
		},
		{
			path: pages.payables.path,
			name: pages.payables.name,
			component: Payables,
			beforeEnter: ifAuthenticated
		},
		{
			path: pages.payables.nav.path,
			name: pages.payables.nav.name,
			component: PayableNav,
			beforeEnter: ifAuthenticated,
			children: [
				{
					path: pages.payables.nav.view.path,
					name: pages.payables.nav.view.name,
					component: PayableView,
					beforeEnter: ifAuthenticated
				},
				{
					path: pages.payables.nav.edit.path,
					name: pages.payables.nav.edit.name,
					component: PayableEdit,
					beforeEnter: ifAuthenticated
				}
			]
		},

		{
			path: pages.settings.path,
			name: pages.settings.name,
			component: SettingsNav,
			beforeEnter: ifAuthenticated,
			children: [
				{
					path: "/",
					name: "Company Setting",
					component: ClientSetting,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.invite,
					component: Invite,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.itemMaster,
					component: ItemMaster,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.userProfile,
					component: UserProfileEdit,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.userAccess,
					component: UserAccess,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.clientprofile,
					component: ClientProfile,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.clientSetting,
					component: ClientSetting,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.accounts,
					component: Accounts,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.templates,
					component: Templates,
					beforeEnter: ifAuthenticated
				},
				{
					...pages.settings.nav.subscription,
					component: Billing,
					beforeEnter: ifAuthenticated
				}
			]
		},
		{
			path: pages.switchCLient.path,
			name: pages.switchCLient.name,
			component: SwitchsClient,
			beforeEnter: ifAuthenticated
		},
		{
			path: "/about",
			name: "about",
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import(/* webpackChunkName: "about" */ "./views/About.vue")
		}
	]
});
