Array.prototype.replaceById = function(item) {
    if (!item) return this;
    let found = this.find(o => o._id== item._id);

    if (found) {
        let index = this.indexOf(found);
        this.splice(index, 1, item);
        this[index] = item;
    }
    return this;
};