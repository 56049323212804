<template>
  <card-layout :state="state" title="Accounts" :nodata="nodata" nofooter>
    <div slot="header">
      <add-button @add="add">Add Account</add-button>
    </div>

    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-12">
          <table class="table table-hover thead-bottom-border header-md">
            <thead>
              <tr>
                <th scope="col" class="w-3"></th>
                <th scope="col">Account Name</th>
                <th scope="col">Description</th>
                <th scope="col" class="w-5"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, i) in all" v-bind:key="account._id">
                <td>{{ i + 1 }}</td>
                <td>{{ account.name }}</td>
                <td>{{ account.description }}</td>
                <td>
                  <a @click="edit(account)" class="text-primary">
                    <i class="icon-pencil"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <no-data title="Account" icon="icon-wallet" v-if="nodata" class="py-5" />

    <AccountEdit ref="accountEdit" @done="onUpdate" />
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import AccountEdit from "./AccountEdit";
import search from "./data/search.js";
import pages from "@/pages";
const entityname = "accounts";

export default {
  name: "accounts",
  mixins: [baseMixin, dataMixin],
  props: {
    selecteds: Array
  },
  components: { AccountEdit },
  data() {
    return {
      list: [],
      selected: [],
      keyword: "",
      editModalVisible: false,
      selectedForEdit: ""
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      this.loading();
      await this.loadPerClient(entityname);
      this.loaded();
    },

    edit(account) {
      this.$refs.accountEdit.open(account);
    },

    add() {
      this.$refs.accountEdit.open();
    },

    onUpdate() {
      this.list = this.all;
    }
  },

  computed: {
    nodata() {
      return this.ready && !this.all.length;
    },
    all() {
      return this.accounts.list || [];
    },
    ...mapGetters(["accounts"])
  }
};
</script>

<style lang="css" scoped></style>
