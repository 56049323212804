<template>
  <small class="badge badge-light">{{type?type.name:""}}</small>
</template>
<script>
export default {
  name: "payableTypeName",
  props: {
    type: Object
  }
};
</script> 
