<template>
  <div class="p-3" v-if="!showNodata || invites.length">
    <p class="text-center text-gray fs-12 p-3" v-if="!invites.length">No pending invitaion!</p>

    <div v-else>
      <h4 class="px-3">Invitations</h4>
      <div class="list-group list-group-flush">
        <div class="list-group-item d-flex" v-for="i in invites" :key="i._id">
          <div class="mr-4 d-flex flex-column align-items-center">
            <logo-view :url="i.joinTo.logoUrl" v-if="i.joinTo.logoUrl" :size="50" />
            <svg-icon name="contact" color="gray" :size="50" v-else />
            <div class="small">{{ i.date | dateformat }}</div>
          </div>
          <div class="flex-fill">
            <div>
              <div>{{ i.invitedBy.name }} has Invited you to join</div>
              <div>{{ i.joinTo.name }}</div>
              <div class="text-gray small">{{ i.joinTo.description }}</div>
            </div>
          </div>
          <div>
            <div v-if="i.status">
              <div>{{ i.status.title }}</div>
              <small class="text-gray">{{ i.status.date | dateformat }}</small>
            </div>

            <button
              v-else
              class="btn btn-outline-success"
              @click="accept(i)"
              :class="{ disabled: busy }"
              :disabled="busy"
            >
              <spinner :busy="busy" />Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";

export default {
  name: "myInvites",
  mixins: [dataMixin, baseMixin],
  props: {
    showNodata: Boolean
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      busy: false
    };
  },
  methods: {
    async load() {
      await this.loadList("invite", true);
    },
    async accept(invite) {
      this.busy = true;
      try {
        await this.update("invite", invite, "join");
        this.$emit("accepted", invite);
      } catch (error) {
        console.log("error", error);
      } finally {
        this.busy = false;
      }
    }
  },
  computed: {
    ...mapGetters(["invites"])
  }
};
</script>
