<template>
  <div class="bg-info-1 p-3 rounded-5 form-group shadow-sm" v-if="contractType">
    <div class="fs-12 mb-1">Contract Type</div>

    <form-select
      v-model="contractType.code"
      :options="types"
      class="form-group"
      @change="onTypeChanged"
    />
    <div class="ml-3" v-if="contractType.code">
      <div class="mb-5">
        <div>
          <b-form-group label="Material Cost" class="mb-1">
            <b-form-radio
              name="radio-radios"
              :value="true"
              v-model="contractType.costByContact"
              inline
            >
              <span class="text-0" :class="{'fw-600':contractType.costByContact}">By Client</span>
            </b-form-radio>
            <b-form-radio
              name="radio-radios"
              :value="false"
              v-model="contractType.costByContact"
              inline
            >
              <span class="text-0" :class="{'fw-600':!contractType.costByContact}">By Contractor</span>
            </b-form-radio>
          </b-form-group>

          <div v-if="contractType.costByContact">
            <span class="fw-600">{{clientName}}</span>
            will reimburse material costs to
            <span class="fw-600">{{contractorName}}</span>
            <!-- <br />
            <span
              v-if="isProjcetContract "
              class="text-2"
            >System calculates reimbursement if any cost paid by {{client.name}}</span>-->
          </div>

          <div v-else>
            <span class="fw-600">{{contractorName}}</span> pays all material costs
            <br />
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center form-group" v-if="contractType.code=='c'">
        <span>Management fee</span>
        <div class="d-inline-block w-i mx-2">
          <number-input v-model="contractType.percent" ref="firstInput" />
        </div>
        <span>%</span>
      </div>

      <div class="d-flex align-items-center form-group" v-if="contractType.code=='f'">
        <span>Fixed price contract amount</span>
        <div class="d-inline-block w-num mx-2">
          <amount-input v-model="contractType.fixPrice" placeholder="Price" ref="firstInput" />
        </div>
      </div>

      <div class="d-flex align-items-center form-group" v-if="contractType.code=='t'">
        <span>A fix Rate of</span>
        <div class="d-inline-block w-num mx-2">
          <amount-input v-model="contractType.fixRate" placeholder="Rate" ref="firstInput" />
        </div>
        <span class="mx-2">/</span>
        <span>
          <form-select v-model="contractType.ratePer" :options="perRates" />
        </span>
      </div>

      <div class="d-flex align-items-center form-group" v-if="contractType.code=='u'">
        <span>A fix price</span>
        <div class="d-inline-block w-num mx-2">
          <amount-input v-model="contractType.unitPrice" placeholder="Unit Price" ref="firstInput" />
        </div>
        <span class="px-2">Of Unit</span>
        <span>
          <input class="form-control w-5" v-model="contractType.uom" />
        </span>
      </div>

      <div class="form-group">
        <input class="form-control" v-model="contractType.note" placeholder="Notes" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "contractType",
  props: {
    contract: Object,
    isProjcetContract: Boolean
  },
  data() {
    return {
      // contractType: {
      //   code: "up",
      //   name: "Cost Plus",
      //   percent: 10,
      //   fixPrice: 260000.0,
      //   fixRate: 80,
      //   ratePer: "hr",
      //   unitPrice: 120,
      //   uom: "Each",
      //   note: "",
      //   contractType: false
      // },
      types: [
        { code: "", name: " Select contract type " },
        { code: "c", name: "Cost Plus", costByContact: true },
        { code: "f", name: "Fixed Price", costByContact: false },
        // { code: "t", name: "Time & Material", costByContact: true },
        // { code: "u", name: "Unit price", costByContact: false },
        { code: "o", name: "Other", costByContact: false }
      ],
      perRates: [
        { code: "hr", name: "hour" },
        { code: "day", name: "Day" },
        { code: "month", name: "Month" }
      ]
    };
  },
  methods: {
    onTypeChanged(code) {
      let type = this.types.find(o => o.code == code);
      if (type) {
        this.contractType.name = type.name;
        this.contractType.costByContact = type.costByContact;
      }
      setTimeout(() => {
        let el = this.$refs.firstInput;
        if (el) el.focus();
      }, 300);
    }
  },
  computed: {
    contractType() {
      return this.contract ? this.contract.contractType : "";
    },

    contactName() {
      return this.contract && this.contract.contact
        ? this.contract.contact.name
        : "";
    },
    contractorName() {
      return this.isProjcetContract ? this.client.name : this.contactName;
    },
    clientName() {
      return this.isProjcetContract ? this.contactName : this.client.name;
    },
    ...mapGetters(["client"])
  }
};
</script>

<style lang="scss" scoped>
.w-i {
  width: 5rem !important;
}
.w-num {
  width: 10rem !important;
}
</style>