<template>
  <div>
    <table class="table table-hover mb-0 thead-md">
      <thead>
        <tr>
          <th scope="col" class="w-12">Subcontractor</th>
          <th scope="col" class="w-6 text-center"># people</th>
          <th scope="col" class="w-5 no-wrap">Start/End</th>
          <th scope="col" class="w-4">By</th>
          <th scope="col">Notes</th>
          <th scope="col" class="w-3 no-wrap">Photos</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="g in grouped" :key="g.key">
        <tr class="bg-1">
          <td colspan="3">{{g.date | dateformat}}</td>
          <td colspan="4">
            <WeatherView :weather="dayWeather(g)" />
          </td>
        </tr>
        <tr v-for="c in g.list" v-bind:key="c._id">
          <td>
            <contact-view :contact="c.contact" no-navigate v-if="c.contact && c.contact.name" />
          </td>
          <td class="text-center">{{c.noOfPeople}}</td>

          <td class="text-2 small">
            <div>{{c.startTime}}</div>
            <div>{{c.endTime}}</div>
          </td>
          <td>
            <div class="small text-muted">{{c.lastChange.by.name}}</div>
          </td>
          <td class="text-2">
            <p>{{c.note}}</p>
          </td>

          <td>
            <div class="d-flex align-items-center">
              <i
                v-if="c.sharedCustomer"
                class="icon-share small mr-2"
                v-tooltip="'Shared with client'"
              ></i>
              <attachment-icon :attachments="c.attachments" class="py-1" />
            </div>
          </td>
          <td class="px-1">
            <router-link
              :to="`dailyLog/${c._id}/edit`"
              class="d-inline-block nav-link no-wrap px-2"
            >
              <i class="icon-arrow-right"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import WeatherView from "./WeatherView";
export default {
  name: "dailtLogTable",
  components: { WeatherView },
  props: {
    grouped: Array
  },
  computed: {
    dayWeather() {
      return g => {
        let dailyLog = g.list.find(o => o.weather && o.weather.description);
        return dailyLog ? dailyLog.weather : null;
      };
    }
  }
};
</script>