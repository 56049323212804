<template>
  <span>
    <a v-if="link" :href="`tel:${value}`" class="phone-link">
      <i class="icon-phone mr-1 text-secondary" v-if="showicon"></i>
      {{ formatted }}
    </a>
    <span v-else>{{ formatted }}</span>
  </span>
</template>

<script>
import format from "@/helpers/format";
import { mapGetters } from "vuex";
export default {
  name: "formInput",
  mixins: [],
  props: {
    value: String,
    readonly: Boolean,
    link: Boolean,
    showicon: { type: Boolean, default: true }
  },
  data() {
    return {};
  },
  computed: {
    formatted() {
      return this.number && this.mask
        ? format(this.number, this.mask)
        : this.value;
    },
    mask() {
      if (!this.number) return "";
      //TODO: from country
      if (this.number.length == 10) return "###-###-####";
      if (this.number.length == 11) return "(+#) ###-###-####";
      return "(+#) ###-###-####";
    },
    number() {
      return this.value ? this.value.replace(/[-+( ).]/g, "") : "";
    },
    ...mapGetters(["clientSettings"])
  }
};
</script>

<style lang="css" scoped>
.phone-link {
  color: #555;
}
</style>
