<template>
  <section class="form-group d-flex">
    <div class="px-3 pt-1 d-flex">
      <i :class="icon"></i>
      <div class="ml-2 w-10">{{label}}</div>
    </div>
    <div class="form-control value bodrer-0">
      <slot></slot>
      {{ content }}
    </div>
  </section>
</template>

<script>
export default {
  name: "iconField",
  props: {
    label: String,
    content: String,
    icon: String
  }
};
</script>
