export default (list, keyword) => {
  if (list && keyword) {
    keyword = keyword.toLowerCase();
    const items = list.filter(
      (o) =>
        (o.name && o.name.toLowerCase().includes(keyword)) ||
        (o.code && o.code.toLowerCase().startsWith(keyword)) ||
        (o.description && o.description.toLowerCase().includes(keyword))
    );
    return (
      _.sortBy(
        items.filter((o) => o.categoryId == catId),
        (o) => o.code,
        (o) => o.sequence
      ) || []
    );
  }
  return list;
};
