export default () => {
    return {
        customer: {
            id: '',
            name: '',
        },
        name: '',
        description: '',
        address: {
            text: '',
        },
    };
};