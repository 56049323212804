import { round } from "@/utils/calc";
const taxValue = (tax) => {
	if (!tax) return 0;
	return tax.amount;
};
export const subTotal = (payable) => {
	if (!payable) return 0;
	return round(payable.amount + taxValue(payable.tax1) + taxValue(payable.tax2));
};

const calcTotal = (payable) => {
	if (!payable) return 0;
	return round(payable.amount + taxValue(payable.tax1) + taxValue(payable.tax2));
};
const calcTaxPercent1 = (payable) => {
	return payable.amount == 0 ? 0 : round((taxValue(payable.tax2) / payable.amount) * 100);
};
const calcTaxPercent2 = (payable) => {
	return payable.amount == 0 ? 0 : round((taxValue(payable.tax2) / payable.amount) * 100);
};

const updateTaxes = (payable) => {
	if (payable.tax1 && payable.tax1.percent)
		payable.tax1.amount = round((payable.amount * payable.tax1.percent) / 100);
	if (payable.tax2 && payable.tax2.percent)
		payable.tax2.amount = round((payable.amount * payable.tax2.percent) / 100);
};

const calcFromTotal = (payable, total) => {
	const p1 = payable.tax1 ? payable.tax1.percent : 0;
	const p2 = payable.tax2 ? payable.tax2.percent : 0;
	const amount = parseFloat(total) / (1 + (p1 + p2) / 100);
	payable.amount = round(amount);
	updateTaxes(payable);
};
const balance = (payable) => {
	return round(payable.total - payable.paidAmount);
};

const totalPaid = (payable) => {
	return payable.payments ? payable.payments.map((o) => o.amount).reduce((a, b) => a + b, 0) : 0;
};

// const round = (amount) => {
// 	const n = 4; //from setting
// 	let p = Math.pow(10, n);
// 	return Math.round(amount * p) / p;
// };

export default {
	calcTotal,
	calcTaxPercent1,
	calcTaxPercent2,
	updateTaxes,
	calcFromTotal,
	totalPaid,
	balance,
};
