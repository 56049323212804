export default {
	data() {
		return {
			state: {
				loading: true,
				loaded: false
			},
			validated: false
		};
	},
	methods: {
		loading() {
			this.state.loading = true;
		},
		loaded() {
			this.state.loading = false;
			this.state.loaded = true;
		}
	},
	computed: {
		ready() {
			return this.state.loaded;
		}
	}
};
