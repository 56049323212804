<template>
  <card-layout nofooter back :state="state">
    <div slot="title" class="d-flex">Daily Log</div>

    <div class="card-body" slot="data">
      <div class="bg-2 px-3 py-2">
        <div class="row">
          <div class="col-lg-3">
            <label>Date</label>
            <div>
              <date-input ref="datePicker" v-model="dailyLog.date" />
              <!-- <div class="form-contorl value" v-else>{{dailyLog.date |dateformat}}</div> -->
            </div>
          </div>
          <div class="col-lg-3"></div>
          <div class="col-lg-6">
            <WeatherSelect :weather="dailyLog.weather" />
          </div>
        </div>
      </div>

      <section class="bg-1 p-3">
        <div>
          <LogEdit v-if="dailyLog" :daily-log="dailyLog" :validated="validated" />
        </div>
        <save-cancel @save="save" @cancel="cancel" class="border-top pt-3" />
      </section>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import LogEdit from "./LogEdit";
import { required, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import WeatherSelect from "./WeatherSelect";
export default {
  name: "dailyLogEdit",
  mixins: [baseMixin, dataMixin, routeMixin, validationMixin],
  components: { LogEdit, WeatherSelect },
  data() {
    return {
      validated: false,
      dailyLog: {}

      // dailyLogs: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.dailyLog_id == "new") {
        this.dailyLog = this.newDailyLog();
        this.loaded();
      } else {
        this.load();
      }
    },
    async load() {
      await this.loadById("dailyLog", this.dailyLog_id);
      let dailyLog = this.dailyLogById(this.dailyLog_id);
      this.dailyLog = JSON.parse(JSON.stringify(dailyLog));

      this.loaded();
    },
    newDailyLog() {
      const project = this.projectById(this.project_id) || {};
      return {
        date: new Date(),
        project: {
          _id: project._id,
          name: project.name
        },
        item: {},
        contact: {},
        attachments: [],
        note: "",
        startTime: "",
        endTime: "",
        noOfPeople: "",
        weather: {
          type: "",
          minTemp: "",
          maxTemp: ""
        }
      };
    },
    // addAnother() {
    //   this.logs.push(this.newLog());
    // },

    async save() {
      this.validated = true;

      if (this.invalid) return;
      try {
        let model = { ...this.dailyLog };
        if (this.isnew) {
          await this.add("dailylog", model);
        } else {
          await this.update("dailylog", model);
        }
        this.$router.go(-1);
      } catch (error) {
        console.log("error :", error);
      }
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  computed: {
    isnew() {
      return !this.dailyLog._id;
    },
    invalid() {
      return this.logs && this.logs.length && !this.logs[0].note;
    },
    hasChanges() {
      return (
        JSON.stringify(this.dailyLogById(this.dailyLog_id)) !=
        JSON.stringify(this.dailyLog)
      );
    },
    showAddAnother() {
      return this.isnew && !this.invalid;
    },
    ...mapGetters(["user", "projectById", "dailyLogById"])
  }
};
</script>
