<template>
  <card-layout nofooter :state="state" class="bg-2" back>
    <div slot="header" class="d-flex">
      <!-- <add-button @add="addIncome" class="btn-outline-primary bg-white mr-3">Receive Payment</add-button> -->
      <button class="btn d-flex align-items-center p-2 btn-outline-primary mr-3" @click="addIncome">
        <icon svg="svg-plus" color="#007bff" class="d-inline-block mr-2" />Receive Payment
      </button>
      <add-button @add="add">Add New Invoice</add-button>
    </div>
    <div slot="title">Project Invoices</div>

    <div class="card-body" slot="data">
      <div class="row form-group">
        <div class="col-lg-4">
          <InvoiceTotal :list="list" :incomes="projectIncomes" />
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <BillableTotal />
          <!--  <div
            class="d-flex flex-column rounded-5 shadow-sm bg-info-1 border border-info mb-1 mt-2"
          >
            <button class="btn d-flex align-items-center p-2" @click="addIncome">
              <icon svg="svg-plus" color="gray" class="d-inline-block mr-2" />Receive Payment
            </button>
          </div>-->
        </div>
      </div>

      <div class="shadow-sm rounded-5-top">
        <div class="px-3 py-2 bg-trans rounded-5-top fs-11">Invoices</div>
        <ClientInvoiceChart
          :invoices="list"
          :incomes="projectIncomes"
          class="bg-1 border-0 rounded-5 form-group mb-1"
        />
        <InvoiceTable :list="list" />

        <div class="px-3 py-1 bg-trans fs-11 d-flex justify-content-between align-items-center">
          Payments
          <add-button @add="addIncome" link>Receive Payment</add-button>
        </div>
        <IncomeTable :list="projectIncomes" />
      </div>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ClientInvoiceChart from "./components/ClientInvoiceChart";
import InvoiceTable from "./components/InvoiceTable";
import IncomeTable from "./components/IncomeTable";
import InvoiceTotal from "./components/InvoiceTotal";
import BillableTotal from "./BillableTotal";
const entityName = "invoice";
export default {
  name: "dailyLogs",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: {
    InvoiceTable,
    IncomeTable,
    InvoiceTotal,
    ClientInvoiceChart,
    BillableTotal
  },

  mounted() {
    this.load();
  },

  methods: {
    add() {
      this.$router.push(`invoice/new/edit`);
    },
    addIncome() {
      this.$router.push(`income/new/edit`);
    },
    async load() {
      this.loading();
      await this.loadByProject("invoice", this.project_id);
      await this.loadByProject("income", this.project_id);
      this.loaded();
    }
  },

  computed: {
    summary() {
      return {
        amount: this.list.map(o => o.amount).reduce((a, b) => a + b, 0)
      };
    },
    list() {
      return this.invoices.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    projectIncomes() {
      return this.incomes.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    nodata() {
      return this.ready && !this.list.length;
    },
    ...mapGetters(["invoices", "incomes"])
  }
};
</script>
