<template>
	<section class="row form-group-sm">
		<label class="col-form-label" :class="leftClass">
			<i :class="icon"></i>
			<slot name="label"></slot>
			{{ label }}
		</label>
		<div :class="rightClass">
			<div class="form-control value">
				<slot></slot>
				{{ content }}
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'sectionview',
	props: {
		label: String,
		content: String,
		icon: String,
		col: {
			type: Number,
			default: 3,
		},
	},
	computed: {
		leftClass() {
			return `col-lg-${this.col}`;
		},
		rightClass() {
			return `col-lg-${12 - this.col}`;
		},
	},
};
</script>
