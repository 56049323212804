import moment from "moment";
export const sortDate = (a, b) => moment(b.date).diff(moment(a.date));
// desc =>
//desc ? moment(b.date).diff(moment(a.date)) : moment(a.date).diff(moment(b.date));

export const todate = (date) => {
	return moment(date).valueOf();
};
export const dayDiff = (start, end) => moment(end).diff(moment(start), "days");

export const calcDuration = (start, end) => {
	if (!start || !end) return "";
	const format = "HH:mm a";
	var startTime = moment(start, format);
	var endTime = moment(end, format);
	const duration = moment.duration(endTime.diff(startTime));
	return duration.asMinutes();
};

export const toDuration = (value) => {
	if (!value) return "";
	return `${~~(value / 60)}:${value % 60}`;
};

export const isValidTime = (str) => {
	return str && !!str.match(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);
};
