<template>
  <div>
    <!-- <label :for="id" v-if="label">{{ label }}</label> -->
    <div class="input-group prepend">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="icon-calendar"></i>
        </span>
      </div>
      <v-date-picker
        :required="required"
        :popover="{ placement: 'bottom', visibility: 'click' }"
        mode="single"
        ref="datePicker"
        :min-date="min"
        :max-date="max"
        :formats="formats"
        :input-props="dateInputProps"
        @dayclick="change"
        v-on:input="onInput"
        v-bind:value="value"
      ></v-date-picker>
    </div>

    <small class="form-text text-muted" v-if="help">{{ help }}</small>

    <small class="form-text invalid-feedback" v-if="error">{{ error }}</small>
  </div>
</template>

<script>
import { isDate } from "@/utils/date";
import moment from "moment";
export default {
  name: "dateInput",
  props: {
    value: [String, Date],
    label: String,
    placeholder: String,
    help: String,
    error: String,
    readonly: Boolean,
    required: Boolean,
    min: [Date, String],
    max: [Date, String]
  },
  data() {
    return {
      date: null,
      dateInputProps: {
        class: "form-control date-input"
      },
      formats: {
        title: "MMMM YYYY",
        weekdays: "W",
        navMonths: "MMM",
        input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"], // Only for `v-date-picker`
        dayPopover: "L", // Only for `v-date-picker`
        data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"] // For attribute dates
      }
    };
  },
  mounted() {
    this.$nextTick(o => {
      setTimeout(() => {
        this.convetyToDate();
      }, 300);
    });
  },
  watch: {
    value(newVal) {
      this.date = newVal ? new Date(newVal) : null;
      this.convetyToDate();
    }
  },
  methods: {
    change(date) {
      this.$emit("change", date);
    },
    onInput(date) {
      this.$emit("input", date);
      this.$emit("change", date);
    },
    convetyToDate() {
      if (this.value && !isDate(this.value)) {
        this.$emit("input", moment(this.value).toDate());
      }
    },
    focus() {
      let input = this.$refs.datePicker.$el.children[0];
      if (input) input.focus();
    }
  },
  computed: {
    // min() {
    //   return this.minDate ? moment(new Date(this.minDate)).toDate() : null;
    // },
    // max() {
    //   return this.maxDate ? moment(this.maxDate).toDate() : null;
    // }
    // id() {
    // 	return 'date_input';
    // },
  }
};
</script>

<style></style>
