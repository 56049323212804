<template>
  <div>
    <div class="d-flex" v-if="current ">
      <span class="fw-600 mr-3">{{status.name}}</span>
      <span>{{status.date | dateformat}}</span>
    </div>

    <div v-else>
      <div
        class="d-flex align-items-center text-right"
        v-for="item in project.statusHistory"
        :key="item.code"
        :class="{
        current: project.statusCode == item.code,
        done: item.code < project.statusCode
      }"
      >
        <span class="text-1 small date">{{ item.date |dateformat }}</span>
        <div class="position-relative ml-2">
          <span class="line" v-if="!isLast(item)"></span>
          <div class="d-flex align-items-center">
            <span class="dot"></span>
            <span class="text-1 ml-2 status">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="d-flex pl-5 ml-5 mt-2">
        <router-link :to="`/project/${project._id}/info?status=1`" class="small" v-if="!hideAction">
          <i class="icon-pencil"></i> Edit
        </router-link>
      </div>-->
    </div>
  </div>
</template>
<script>
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "projectStatus",
  mixins: [accessMixin],
  props: {
    project: Object,
    current: Boolean
  },
  computed: {
    list() {
      return this.project.statusHistory;
    },
    status() {
      return this.project.statusHistory.find(
        o => o.code == this.project.statusCode
      );
    },
    isLast() {
      return item => item.code == this.list[this.list.length - 1].code;
    }
  }
};
</script>
<style lang="scss" scoped>
.date {
  color: #999;
}
.status {
  color: #9e9e9e;
}
.date {
  width: 5rem;
}
.dot {
  width: 1rem;
  height: 1rem;
  background-color: #cfcfcf;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.8;
  z-index: 1;
}
.line {
  border-left: 1px solid #bbbbbb;
  position: absolute;
  top: 1.2rem;
  left: 0.5rem;
  height: 0.55rem;
  margin: auto;
  z-index: 0;
}
.current {
  .dot {
    background-color: #53b2ff;
    border: 2px solid blue;
  }

  .status {
    color: blue;
  }
}

.done {
  .dot {
    background-color: #555;
  }

  .status {
    // color: #555;
  }
}
</style>
