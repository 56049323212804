export function isImage(name) {
	return /\.(jpe?g|png|gif)$/i.test(name);
}
export function isPdf(name) {
	return /\.(pdf)$/i.test(name);
}
export function isDoc(name) {
	return /\.(txt|doc|docx)$/i.test(name);
}
export function isExcel(name) {
	return /\.(exls|csv|xls|xlsx)$/i.test(name);
}
