export default {
	computed: {
		isCostPlusProject() {
			return project => {
				return project && project.contract && project.contract.contractType
					? project.contract.contractType.code == "c"
					: false;
			};
		},
		isFixedPriceProject() {
			return project =>
				project && project.contract && project.contract.contractType
					? project.contract.contractType.code == "f"
					: false;
		}
	}
};
