<template>
  <div
    class="d-flex justify-content-between px-3 form-group"
    :class="{ 'rounded-5 bg-success-1  py-2 shadow-sm': isSelected }"
  >
    <div class="d-flex w-100">
      <svg-icon name="filter" color="gray" :size="20" class="mr-3" v-if="!isSelected" />
      <item-selector
        :item="selected"
        :placeholder="placeholder"
        @change="change"
        class="w-100 pr-3"
      />
    </div>
    <button @click="clear" type="button" class="btn close" v-if="isSelected">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import data from "@/mixins/dataMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "filterItem",
  mixins: [data],
  props: {
    value: [String],
    placeholder: { type: String, default: "Filter Item" }
  },
  data() {
    return {
      selected: { id: "", name: "" }
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.selected = { ...this.itemById(this.value) };
        }
      }
    }
  },
  methods: {
    change(e) {
      this.$emit("input", e._id);
      this.selected = { ...this.itemById(e._id) };
      this.$emit("change", e._id);
    },
    clear() {
      this.selected = { id: "", name: "" };
      this.change(this.selected);
    }
  },
  computed: {
    ...mapGetters(["itemById"]),
    isSelected() {
      return !!this.selected._id;
    }
  }
};
</script>
