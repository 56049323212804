import { mapGetters } from "vuex";
import route from "@/mixins/routeMixin.js";

import {
	getforCategory,
	categoryBudget,
	categorycostToDate,
	categorycostToComplete,
} from "@/views/project/data/budgetItem.js";

export default {
	mixins: [route],
	methods: {
		select(c) {
			let items = c && c._id ? this.budgetItems.filter((o) => o.categoryId == c._id) : this.budgetItems;
			this.selectedCategories = this.categories.filter((o) => items.map((b) => b.categoryId).indexOf(o._id) >= 0);
		},
		getTotals(items) {
			const budget = items.map((o) => o.budget).reduce((a, b) => a + b, 0);
			const initialBudget = items.map((o) => o.initialBudget).reduce((a, b) => a + b, 0);
			const costToDate = items.map((o) => o.costToDate).reduce((a, b) => a + b, 0);
			return {
				initialBudget,
				budget,
				costToDate,
				costToComplete: budget - costToDate,
				costOverBudget: budget == 0 ? 0 : costToDate / budget,
			};
		},
	},
	computed: {
		contractType() {
			return this.budgeting && this.budgeting.type;
		},
		isFixedPrice() {
			return this.contractType && this.contractType == "f";
		},
		isCostPlus() {
			return this.contractType && this.contractType == "c";
		},
		budgetItems() {
			return this.budgeting ? this.budgeting.budgetItems || [] : [];
		},
		categoryBudget() {
			return (catId) => categoryBudget(catId, this.budgetItems);
		},
		projectBudget() {
			return this.totalBudget;
		},
		categoryPrice() {
			return (catId) =>
				this.budgetItems
					.filter((o) => o.categoryId == catId)
					.map((o) => o.price)
					.reduce((a, b) => a + b, 0);
		},
		categorycostToDate() {
			return (catId) => categorycostToDate(catId, this.budgetItems);
		},
		categorycostToComplete() {
			return (catId) => categorycostToComplete(catId, this.budgetItems);
		},
		getItemsforCategory() {
			return (catId) => getforCategory(catId, this.budgetItems);
		},
		totalBudget() {
			return this.budgetItems
				.filter((o) => o.budget)
				.map((o) => parseFloat(o.budget || 0))
				.reduce((a, b) => a + b, 0);
		},
		totalCostToDate() {
			return this.budgetItems
				.filter((o) => o.costToDate)
				.map((o) => parseFloat(o.costToDate || 0))
				.reduce((a, b) => a + b, 0);
		},
		totalInitialBudegt() {
			return this.budgetItems
				.filter((o) => o.initialBudget)
				.map((o) => parseFloat(o.initialBudget || 0))
				.reduce((a, b) => a + b, 0);
		},
		totalCostOverBudget() {
			return this.totalBudget ? this.totalCostToDate / this.totalBudget : 0;
		},
		totalPrice() {
			return this.budgetItems
				.filter((o) => o.price)
				.map((o) => parseFloat(o.price))
				.reduce((a, b) => a + b, 0);
		},
		selectedItems() {
			return this.budgetItems.filter((o) => o.checked);
		},
		...mapGetters(["categories", "items", "budgetings"]),
	},
};
