<template>
  <div class="card form-group" v-if="missingLogo || forceShow">
    <div class="card-header text-muted">Your Company Logo</div>
    <div>
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          <ClientLogo @change="change" />
        </div>
        <div class="col-lg-3"></div>
      </div>
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import ClientLogo from "@/views/settings/client-profile/ClientLogo";
import clientDataMixin from "@/mixins/clientDataMixin";
import { mapGetters } from "vuex";
export default {
  name: "firstProject",
  mixins: [dataMixin, baseMixin, clientDataMixin],
  components: { ClientLogo },
  data() {
    return {
      forceShow: false
    };
  },
  methods: {
    change() {
      this.forceShow = true;
    }
  },
  computed: {
    missingLogo() {
      return !this.client.logoUrl;
    },
    ...mapGetters(["client"])
  }
};
</script>