<template>
  <div class="my-4">
    <h4 class="font-weight-md mb-4 border-bottom pb-3">
      <span>Access Levels</span>
    </h4>
    <div>
      <div>
        <b-checkbox
          @change="change"
          switch
          size="md"
          v-model="value"
          value="a"
          unchecked-value
          :disabled="disabled"
        >
          <span class="text-0 fs-12">Admin</span>
        </b-checkbox>
        <p class="text-0" v-if="isAdmin">
          {{ name | capital}}
          {{list[0].desc}}
          <br />
        </p>
      </div>
      <div v-if="!isAdmin" class="mt-4">
        <b-radio-group @change="change" v-model="value" :disabled="disabled">
          <b-form-radio
            :value="item.code"
            class="d-block"
            v-for="item in list.filter(o=>o.code!='a')"
            :key="item.code"
          >
            <span :class="{ checked:value==item.code }">{{item.name}}</span>
            <p class="text-muted">{{ name | capital }} {{item.desc}}</p>
          </b-form-radio>
        </b-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "accessType",
  props: {
    name: String,
    value: String,
    disabled: Boolean
  },
  data() {
    return {
      list: [
        {
          code: "a",
          name: "Admin",
          desc: `has permission to view, add, edit and delete anything in the current
              and future projects as well as changing other users permessions`
        },
        {
          code: "f",
          name: "Full Access",
          desc: `has full access to all project`
        },
        {
          code: "r",
          name: "Read Only",
          desc: `has readonly access to all project and features`
        },
        {
          code: "c",
          name: "Client Access",
          desc: `(client) will have a readonly access to the specific entities of the project that shared with them`
        }
        // {
        //   code: "l",
        //   name: "Limited Access",
        //   desc: `has limited permissions to the following features`
        // },
        // {
        //   code: "",
        //   name: "No Access",
        //   desc: `is a user but has no access`
        // }
      ]
    };
  },

  methods: {
    change(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    }
  },
  computed: {
    isAdmin() {
      return this.value == "a";
    },
    isFull() {
      return this.value == "f";
    },
    isReadonly() {
      return this.value == "r";
    },
    isLimited() {
      return this.value == "l";
    },
    ...mapGetters(["me", "cid"])
  }
};
</script>
