<template>
  <card-layout :state="state" title="Company Setting" nofooter back>
    <div slot="header" class="d-flex"></div>

    <div class="card-body px-5" slort="data">
      <div class="row">
        <div class="col-lg-7">
          <section class="row form-group bg-1 py-2">
            <label class="col-lg-3">Taxes</label>
            <div class="col-lg-9">
              <tax-setup :tax="clientSetting.tax1" name="Tax 1" field="tax1" />
              <tax-setup :tax="clientSetting.tax2" name="Tax 2" field="tax2" />
            </div>
          </section>

          <section class="row form-group bg-1 py-2">
            <label class="col-lg-3">Date Format</label>
            <div class="col-lg-9">
              <DateFormatSetting />
            </div>
          </section>

          <section class="row form-group bg-1 py-2">
            <label class="col-lg-3">Currency</label>
            <div class="col-lg-9">
              <currency-setup />
            </div>
          </section>

          <section class="row form-group bg-1 py-2">
            <label class="col-lg-3">Last Numbers</label>
            <div class="col-lg-9">
              <LastNumbers />
            </div>
          </section>

          <section class="row form-group bg-1 py-2">
            <label class="col-lg-3">Culture Name</label>
            <div class="col-lg-9">
              <div class="form-control value">
                {{
                clientSetting.cultureName
                }}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import clientDataMixin from "@/mixins/clientDataMixin";

import TaxSetup from "@/views/settings/TaxSetup";
import CurrencySetup from "@/views/settings/CurrencySetup";
import DateFormatSetting from "./components/DateFormatSetting";
import LastNumbers from "./components/LastNumbers";

// import CurrencySettingView from "./CurrencySettingView";
// import TaxSettingView from "./TaxSettingView";
// import LastNumberSettingView from "./LastNumberSettingView";
// import FormatSettingView from "./FormatSettingView";

export default {
  name: "CompanySetting",
  mixins: [baseMixin, dataMixin, clientDataMixin],
  components: {
    TaxSetup,
    CurrencySetup,
    DateFormatSetting,
    LastNumbers
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loaded();
      //this.$store.dispatch(GET_CLIENT_SETTING);
    },
    edit() {
      this.$router.push(`/settings/clientsetting/edit`);
    }
  },
  computed: {
    ...mapGetters(["logoUrl", "clientSetting"])
  }
};
</script>
