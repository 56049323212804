<template>
  <div class="border-0 rounded-5 bg-2">
    <div
      class="card-header min-h-auto d-flex justify-content-between align-items-center"
    >
      <h5 class="m-0">Agreement With Client</h5>
      <save-cancel @save="save" @cancel="$emit('cancel')" small />
    </div>

    <div class="card-body">
      <ContractType :contract="contract" :is-projcet-contract="true" />
      <div class="form-group">
        <label for>Contract Terms</label>

        <textarea
          type="text"
          rows="2"
          class="form-control"
          v-model="contract.terms"
          placeholder="Terms and conditions of the contract"
        />
      </div>
      <div class="form-group">
        <label for>Scope of Work</label>
        <textarea
          type="text"
          rows="5"
          class="form-control"
          placeholder="Scope breakdown"
          v-model="contract.scope"
        />
      </div>
      <div class="form-group">
        <label for>Contract Exclusions</label>
        <textarea
          type="text"
          rows="3"
          class="form-control"
          placeholder="Work not included in the scope"
          v-model="contract.exclusions"
        />
      </div>

      <div class="form-group">
        <label for>Contract Documents</label>
        <div class="form-control value">
          <entity-attachments 
              :entity="project" 
              entityName="project" 
              :child="contract"
              childName="contract"
            />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <save-cancel @save="save" @cancel="$emit('cancel')" />
    </div>
  </div>
</template>

<script>
import dataMixin from "@/mixins/dataMixin.js";
import baseMixin from "@/mixins/baseMixin.js";
import ContractType from "@/views/contract/components/ContractType";
import { mapGetters } from "vuex";

export default {
  name: "projectContractEdit",
  mixins: [dataMixin, baseMixin],
  components: { ContractType },
  props: {
    proejctId: String,
  },
  data() {
    return {
      title: "Add Project",
      validated: false,
      project: {},
      contract: {},
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      let p = this.projectById(this.proejctId);
      if (p) {
        this.project = JSON.parse(JSON.stringify(p));
        this.contract = this.project.contract || {};
      }
    },

    async save() {
      this.validated = true;
      if (!this.valid()) return;
      try {
        const project = await this.update(
          "project",
          this.contract,
          "contract/update",
          this.proejctId //this.proejctId
        );
        this.$emit("done", this.project);
      } catch (error) {}
    },

    valid() {
      return this.project && this.project.customer._id && this.project.name;
    },
  },
  computed: {
    ...mapGetters(["projectById"]),
  },
};
</script>
