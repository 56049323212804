<template>
  <ul class="text-gray mb-0" v-if="!hasData">
    <li>Revenues & Financing</li>
    <li>Shareholder contributions</li>
  </ul>
  <div class="d-flex justify-content-between mt-2 pr-4" v-else>
    <div class="text-center d-flex align-items-start">
      <div>
        <div class="fs-14 text-2 no-wrap">{{ cashInSummary.total | currency }}</div>
        <div class="text-secondary small">Inflow</div>
      </div>
    </div>

    <div class="text-center">
      <div class="fs-14 text-2 no-wrap">{{ payableSummary.paid | currency }}</div>
      <div class="text-secondary small">Outflow</div>
    </div>
    <div></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import accessMixin from "@/mixins/accessMixin";
import routeMixin from "@/mixins/routeMixin.js";
export default {
  name: "cashFlowSummary",
  mixins: [baseMixin, dataMixin, routeMixin, accessMixin],
  mounted() {
    if (this.isUserCustomer) return;
    this.load();
  },
  methods: {
    load() {
      if (this.isUserCustomer) return;
      this.loadById("payableSummary", this.project_id, false,false);
      this.loadById("cashInSummary", this.project_id, false,false);
    }
  },

  computed: {
    payableSummary() {
      return this.payableSummaries.find(o => o._id == this.project_id) || {};
    },
    cashInSummary() {
      return this.cashInSummaries.find(o => o._id == this.project_id) || {};
    },
    hasData() {
      if (!this.cashInSummary) return false;
      return this.cashInSummary.total;
    },
    ...mapGetters(["payableSummaries", "cashInSummaries"])
  }
};
</script>
