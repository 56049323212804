<template>
  <card-layout nofooter :state="state" back class="bg-1" :nodata="nodata">
    <div slot="header" class="d-flex" v-if="!nodata">
      <print-button class="mx-2"></print-button>
      <export-button class="mx-2 mr-4"></export-button>
      <add-button @add="add">Record Time</add-button>
    </div>
    <div slot="title">Project Timesheet</div>
    <div class="card-body" slot="data">
      <div class="d-flex justify-content-between d-print-none">
        <div class="form-group">
          <label for>Group By</label>
          <toggle-buttons
            class="d-block"
            :items="groupByModes"
            @select="populate"
            v-model="groupByMode"
            showicon
          />
        </div>
        <div class="form-group pt-4">
          <FilterContact v-model="contactId" @change="populate" />
        </div>
        <div>
          <label for>Contact Type</label>
          <toggle-buttons
            class="d-block"
            :items="contactTypes"
            @select="populate"
            v-model="contactType"
          />
        </div>
      </div>

      <TimesheetGroup :list="list" :grouped="grouped" :groupBy="groupByMode" v-if="groupedView" />
      <TimesheetTable :list="list" v-else />
      <TimesheetChart class="mt-4" :list="list" :grouped="grouped" />
      <TimesheetPie class="mt-4" :list="list" />
    </div>
    <div v-if="nodata" class="text-center">
      <no-data slot="nodata" icon="icon-clock" title="No timesheet recorded" />
      <add-button @add="add">Record Time</add-button>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import TimesheetTable from "./components/TimesheetTable";
import TimesheetGroup from "./components/TimesheetGroup";
import FilterContact from "@/views/contact/components/FilterContact.vue";
import TimesheetChart from "./components/TimesheetChart";
import TimesheetPie from "./components/TimesheetPie";
import { GROUPBY, CONTACT_TYPE, groupByDate } from "./components/timesheet";

export default {
  name: "projectTimesheet",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: {
    TimesheetTable,
    FilterContact,
    TimesheetGroup,
    TimesheetChart,
    TimesheetPie
  },
  mounted() {
    this.init();
    this.load();
  },
  data() {
    return {
      contactId: "",
      list: [],
      groupByMode: GROUPBY.Month,
      groupByModes: [],
      viewMode: "l",
      viewModes: [
        { code: "l", icon: "icon-menu" },
        { code: "c", icon: "icon-grid" }
      ],
      contactType: CONTACT_TYPE.All,
      contactTypes: [],
      grouped: []
    };
  },
  methods: {
    init() {
      this.groupByModes = Object.keys(GROUPBY).map(key => ({
        code: GROUPBY[key],
        text: key
      }));
      this.contactTypes = Object.keys(CONTACT_TYPE).map(key => ({
        code: CONTACT_TYPE[key],
        text: key
      }));
    },
    async load() {
      this.loading();
      await this.loadByProject("timesheet", this.project_id);
      this.loaded();
      this.populate();
    },
    add() {
      this.$router.push(`timesheet/0/edit`);
    },
    populate() {
      let list = this.projectTimeSheets;
      if (this.contactId) {
        list = list.filter(o => o.contact && o.contact._id == this.contactId);
      }
      if (this.contactType != CONTACT_TYPE.All) {
        const filterInsider = this.contactType === CONTACT_TYPE.Insiders;
        list = list.filter(o => o.insider == filterInsider);
      }

      this.list = list;

      this.grouped = _(list)
        .groupBy(groupByDate(this.groupByMode))
        .map((value, key) => ({ key, list: value }))
        .value();
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },

    projectTimeSheets() {
      return (
        this.timesheets.filter(o => o.project._id == this.project_id) || []
      );
    },
    groupedView() {
      return this.groupByMode != "n";
    },

    ...mapGetters(["projectById", "timesheets"]),
    nodata() {
      return this.ready && !this.projectTimeSheets.length;
    }
  }
};
</script>