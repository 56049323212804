<template>
  <card-layout nofooter back :state="state" class="bg-1">
    <div slot="header" class="d-flex"></div>
    <div slot="title" class="d-flex">Project Documents</div>
    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-4">
          <div class="list-group list-group-flush green">
            <div
              v-for="docType in list"
              :key="docType._id"
              :class="{ active: selected._id == docType._id }"
              @click="select(docType)"
              class="list-group-item d-flex align-items-center c-pointer list-group-item-action rounded-5 border-0 shadow-sm mb-2 justify-content-between"
            >
              <div class="d-flex align-items-center">
                <i class="icon-folder mr-3 fs-15"></i>
                {{ docType.name }}
              </div>
              <count-badge :count="getCount(docType)" color="secondary" />
            </div>
          </div>
        </div>

        <div class="col-lg-8">
          <SetDefaultShare
            :projectId="project_id"
            entityName="gallery"
            v-model="sharedCustomerDefault"
          />
          <div v-if="selected._id">
            <div class="px-3 py-2 bg-trans rounded-5-top fs-12 mb-3">{{selected.name}}</div>
            <file-upload
              :projectId="project_id"
              entityName="doc"
              @uploaded="uploaded"
              :docType="selected"
              class="mb-3"
            />
            <div class="row">
              <div class="col-lg-4" v-for="file in files" :key="file._id">
                <file-thumb :file="file" class="mb-3" @del="deleteFile" :shareable="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import FileThumb from "@/views/attachments/FileThumb";
import FileUpload from "@/views/attachments/FileUpload";
import SetDefaultShare from "@/views/share/SetDefaultShare";

export default {
  name: "projectDocs",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { FileThumb, FileUpload, SetDefaultShare },
  data() {
    return {
      selected: {},
      sharedCustomerDefault: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      let result = await this.loadPerClient("docTypes");
      this.loadByProject("doc", this.project_id);
      this.loaded();
      setTimeout(() => {
        if (!this.list.length) {
          this.loadPerClient("docTypes");
        }
        this.select(this.list[0]);
      }, 300);
    },

    select(type) {
      this.selected = type;
    },

    async uploaded(docModel) {
      const { name, uploadUrl, docType, projectId } = docModel;
      let model = {
        name,
        projectId,
        url: uploadUrl,
        typeId: docType._id,
        sharedCustomer: this.sharedCustomerDefault
      };
      await this.add("doc", model);
    },
    async deleteFile(file) {
      await this.del("doc", file);
      this.$forceUpdate();
    },
    getCount(docType) {
      return this.docs.filter(
        o => o.projectId == this.project_id && o.typeId == docType._id
      ).length;
    }
  },
  computed: {
    list() {
      return this.docTypeList || []; //.sort((a, b) => a.sequence > b.sequence);
    },
    files() {
      return this.selected && this.selected._id
        ? this.docs.filter(
            o => o.projectId == this.project_id && o.typeId == this.selected._id
          )
        : [];
    },
    ...mapGetters(["docTypeList", "docs"])
  }
};
</script>
