<template>
  <span class="d-flex">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :width="size"
      :height="size"
      viewBox="0 0 50 50"
      version="1.1"
    >
      <g :fill="color" :class="{ hover: hover }">
        <path :d="path" />
      </g>
    </svg>
  </span>
</template>

<script>
import paths from "./paths.json";
export default {
  name: "svgIcon",
  data() {
    return {
      iconStyle: {
        "font-size": `${this.size}px `
      }
    };
  },
  props: {
    name: {
      type: String,
      default: "document"
    },
    size: {
      type: [Number, String],
      default: 28
    },
    color: {
      type: String,
      default: "#90a4ae"
    },
    hover: {
      type: Boolean,
      default: false
    },
    colorHover: {
      type: String,
      default: "rgba(255, 255, 255, 0.9)"
    }
  },
  computed: {
    path() {
      return paths[this.name];
    }
  }
};
</script>
<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
path {
  stroke: none;
  fill-rule: evenodd;
  transition: ease-in 0.2s;
}
svg:hover g.hover {
  fill: rgba(255, 255, 255, 0.9);
}
</style>
