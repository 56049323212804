<template>
  <div
    @click="$emit('open')"
    class="list-group-item c-pointer list-group-item-action shadow-sm border-0 rounded-5"
  >
    <div class="d-flex justify-fill p-3">
      <div class="d-flex flex-column justify-content-between">
        <div>
          <div>{{ report.date |dateformat}}</div>
          <div class="small text-1">Report Date</div>
        </div>

        <div class="text-2 no-wrap">Not Submitted</div>
      </div>
      <div class="px-3">
        <p>{{lastItem.note |truncate(200)}}</p>
      </div>
      <div class="align-self-center">
        <i class="icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const entityName = "progressReport";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
export default {
  name: "progressReportLine",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: {},
  props: {
    report: Object
  },
  mounted() {},

  computed: {
    lastItem() {
      return this.report.items[this.report.items.length - 1];
    }
  }
};
</script>
