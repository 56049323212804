<template>
  <card-layout nofooter :state="state" class="bg-2" back>
    <div slot="header" class="d-flex">
      <print-button class="mr-5"></print-button>
      <add-button @add="add" v-if="selected">Add {{toAdd.name }}</add-button>
    </div>
    <div slot="title">Project Cash Flow</div>

    <div class="card-body" slot="data">
      <div class="row form-group">
        <div class="col-lg-4">
          <div class="list-group list-group-flush green">
            <div
              v-for="item in types"
              :key="item.code"
              :class="{ active:selected && selected.key == item.key }"
              @click="select(item)"
              class="list-group-item d-flex align-items-center list-group-item-action c-pointer rounded-5 border-0 shadow-sm mb-2 justify-content-between"
            >
              <div class="d-flex align-items-center">
                <svg-icon name="cash" class="mr-3" color="#607d8b" />
                <!-- <i class="icon-arrow-down-circle mr-3 fs-15"></i> -->
                {{ item.name }} ({{ count(item.key) }})
              </div>
              {{totalType(item.key) | currency }}
            </div>
            <div
              class="list-group-item d-flex flex-column rounded-5 shadow-sm mb-1 bg-success-1 border-0"
              :class="{ active:!selected || !selected.key }"
              @click="select({})"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center fs-11">Total Cash Inflow</div>
                <div class="fs-12 fw-600">{{total | currency }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 h-100">
          <CacheInPie :list="list" v-if="!nodata" />
          <no-data :page="{name:'Cash input', icon:'icon-chart'}" v-if="nodata" />
        </div>
      </div>

      <div class="shadow-sm" v-if="!nodata">
        <div class="px-3 py-2 bg-trans rounded-5-top fs-11">Project Cash Flow</div>
        <CashflowChart :list="all" class="bg-1 border-0 rounded-5 form-group" />
      </div>
      <CashInTable :list="list" v-if="!nodata" />
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import CashflowChart from "./CashflowChart";
import CashInTable from "./CashInTable";
import types from "./data/cashInTypes";
import CacheInPie from "./CacheInPie";
import { toArray } from "@/utils/array.js";

const entityName = "chashIn";

export default {
  name: "projectCashFlow",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { CashflowChart, CashInTable, CacheInPie },
  data() {
    return {
      types: [],
      selected: {}
    };
  },
  mounted() {
    this.init();
    this.load();
  },

  methods: {
    init() {
      this.types = toArray(types);
      this.select({});
    },
    select(type) {
      this.selected = type;
    },
    add() {
      console.log("add :");
      this.$router.push(`cashin/new/edit?type=${this.toAdd.key}`);
    },
    async load() {
      this.loading();
      await this.loadByProject("cashIn", this.project_id);
      await this.loadByProject("payable", this.project_id);
      this.loaded();
    }
  },

  computed: {
    amount() {
      return code => code * 17050;
    },
    count() {
      return key => this.listType(key).length;
    },
    totalType() {
      return key =>
        this.listType(key)
          .map(o => o.amount)
          .reduce((a, b) => a + b, 0);
    },
    toAdd() {
      return this.selected && this.selected.key
        ? this.selected
        : this.types[0] || {};
    },
    list() {
      return this.listType(this.selected.key);
    },
    listType() {
      return key => this.all.filter(o => o.type.key == key || !key);
    },
    total() {
      return this.all.map(o => o.amount).reduce((a, b) => a + b, 0);
    },
    all() {
      return this.cashIns.filter(o => o.project._id == this.project_id);
    },
    nodata() {
      return this.ready && !this.all.length;
    },
    ...mapGetters(["cashIns"])
  }
};
</script>
