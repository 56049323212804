<template>
  <div class="entity-view">
    <a
      ref="lookupRef"
      @click="select"
      class="d-flex justify-content-between align-items-center"
      :class="{ 'action-link': !account._id }"
      v-if="account"
    >
      <div class="d-flex align-items-center">
        <i class="icon-wallet mr-3 text-1 fs-15" v-if="account && account._id"></i>
        <span
          class
          :class="{ 'has-value': account && account.name }"
        >{{ account.name || placeholder }}</span>
      </div>
      <i class="icon-arrow-down mt-1 ml-2"></i>
    </a>

    <select-account @select="changed" v-if="modalShow" ref="modalSelect" />
  </div>
</template>

<script>
import SelectAccount from "./SelectAccount";
import data from "@/mixins/dataMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "AccountSelector",
  components: { SelectAccount },
  mixins: [data],
  props: {
    account: Object,
    placeholder: String,
    showicon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    select() {
      this.show();
    },
    changed(account) {
      this.modalShow = false;
      this.account._id = account._id;
      this.account.name = account.name;
      this.account.imageUrl = account.imageUrl ? account.imageUrl : "";
      this.$emit("change", account);
    },
    show() {
      this.modalShow = true;
      this.$nextTick(() => {
        this.$refs.modalSelect.open(this.$refs.lookupRef);
      });
    }
  },
  computed: {
    account_store() {
      return this.account && this.account._id
        ? this.accountById(this.account._id)
        : {};
    },
    ...mapGetters(["accountById"])
  }
};
</script>

<style lang="scss" scoped>
.account-avatar {
  margin-top: -0.2rem;
}
.account-icon {
  font-size: 1.2rem;
}
</style>
