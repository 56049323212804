<template>
  <div v-if="status">
    <span class="amount d-inline-block" :class="status.value" v-b-tooltip="status.desc">
      <!-- <i class="la " :class="status.icon"></i> -->
      {{ payable.paidAmount | currency }}
    </span>
  </div>
</template>

<script>
import paymentMixin from "../data/paymentMixin.js";
export default {
  name: "paidAmount",
  mixins: [paymentMixin],
  props: {
    payable: Object
  },
  computed: {
    status() {
      return this.paymentStatus(this.payable);
    }
  }
};
</script>

<style lang="scss" scoped>
.amount {
  line-height: 1.2rem;
}

.over {
  color: red;
  // background-color: rgb(255, 225, 225);
}
.paid {
  color: green;
  // background-color: #effce0;
}
.partial {
  color: #ff9800;
  // background-color: #fff8e1;
}
.unpaid {
  color: blue;
  // background-color: #e1f5fe;
}
</style>
