<template>
  <div class="border p-3 alert alert-info">
    <div class="row">
      <div class="col-lg-3">
        <h6 class="fs-11">Select contract Type :</h6>
      </div>
      <div class="col-lg-6">
        <b-radio-group v-model="type">
          <b-radio :value="item.code" class="d-block" v-for="item in list" :key="item.code">
            <span :class="{ checked:type==item.code }">{{item.name}}</span>
            <p class="text-muted">{{item.desc}}</p>
          </b-radio>
        </b-radio-group>
      </div>
      <div class="col-lg-3">
        <div class="h-100 d-flex">
          <save-cancel @save="save" nocancel savetext="Save" class="align-self-end" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin.js";

export default {
  name: "contractTypeSet",
  mixins: [dataMixin],
  props: {
    budgeting: Object
  },

  mounted() {
    this.type = this.budgeting.type;
  },

  data() {
    return {
      type: "f",
      list: [
        {
          code: "c",
          name: "Cost Plus Budgeting",
          desc: `All material costs paid by owner.  `
        },
        {
          code: "f",
          name: "Fixed Price Budgeting",
          desc: `Calculates price based on costs and markup`
        }
      ]
    };
  },
  methods: {
    async save() {
      try {
        await this.update(
          "budgeting",
          { other: { type: this.type } },
          "update",
          this.budgeting._id
        );
        this.$emit("done");
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};
</script>