<template>
  <div v-if="address"> 
  
    <div class="d-flex justify-content-between align-items-start" v-if="hasAddress"> 
        
      <address class="m-0 fs-09"> 
        <div class="fs-11">{{address.name}}</div>
        {{address.street}} {{address.route}}
        <br />
        {{address.city}}{{address.city?",":""}} {{address.state}} {{address.state?", ":""}}{{address.postalCode}}
        <br />
        <span v-if="showCountry">{{address.country}}</span>
      </address> 
      <a class="btn  text-primary" @click="open($event.target)" v-if="!readonly  ">
        <i class="icon-pencil pr-2"></i>
        <span v-if="showBtnText">{{address.text?"Edit Address":"Add Address"}}</span> 
      </a>
      
    </div>
    <div v-else>
      
      <a class="btn text-primary" @click="open($event.target)" v-if="!readonly && hasAccess">
        <i class="icon-pencil mr-3"></i>
        {{btnText}}
      </a>
     
    </div>
    <AddressInputPopup ref="addressInputPopup" @change="update" v-if="!readonly && hasAccess" />
  </div>
</template>
<script>
import AddressInputPopup from "./AddressInputPopup";
import accessMixin from "@/mixins/accessMixin";
export default {
  name: "addressView",
  components: { AddressInputPopup },
  mixins: [accessMixin],
  props: {
    address: Object,
    readonly: Boolean,
    showBtnText: Boolean,
    btnText: { type: String, default: "Add Address" },
    showCountry: { type: Boolean, default: true },
    accessRole: { type: String, default: "f" }
  },
  methods: {
    open(el) {
      this.$refs.addressInputPopup.open(
        el,
        JSON.parse(JSON.stringify(this.address))
      );
    },
    update(address) {
      Object.assign(this.address, address);
      setTimeout(() => {
        this.$forceUpdate();
      }, 300);
      this.$emit("change", address);
    }
  },
  computed: {
    hasAddress() {
      return this.address && this.address.text ;
    }
  }
};
</script>
