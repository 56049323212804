<template>
  <div>
    <div>
      <p class="text-default" v-if="notUser">
        The client is not a Nexobuild User.
        <strong>Invite</strong>
        the client to join.
        <br />
        <!-- <span>Client will have a readonly access to the entities that shared to them.</span> -->
      </p>
      <spinner :busy="state.loading" v-if="state.loading" />
      <div v-else>
        <div v-if="customer" class="bg-1 px-3 py-2 rounded-10 shadow-sm">
          <div class="mb-2">Client :</div>
          <h5 class="pb-2">
            <avatar
              v-if="customer.logoUrl"
              :src="customer.logoUrl"
              :rounded="false"
              :username="customer.name"
              :size="35"
            />
            {{customer.name}}
          </h5>
          <div class="mb-2">Contacts :</div>
          <InvitePeople :contact="customer" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin.js";
import baseMixin from "@/mixins/baseMixin.js";
import InvitePeople from "./InvitePeople";
import { mapGetters } from "vuex";
export default {
  mixins: [dataMixin, baseMixin],
  name: "InviteCustomer",
  props: { projectId: String, notUser: Boolean },
  components: { InvitePeople },

  mounted() {
    this.load();
  },
  data() {
    return {
      contactId: ""
    };
  },
  methods: {
    async load() {
      if (!this.projectId) return;
      this.loading();
      const contact = await this.loadByAction(
        "contact",
        `customer/${this.projectId}`,
        true
      );
      this.contactId = contact._id;
      this.loaded();
    }
  },
  computed: {
    customer() {
      return this.contactId ? this.contactById(this.contactId) : "";
    },
    ...mapGetters(["contactById"])
  }
};
</script>
