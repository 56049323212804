<template>
  <div class="rounded-5 border-0 form-group bg-2" v-if="project">
    <div
      class="card-header min-h-auto d-flex justify-content-between align-items-center"
    >
      <h5 class="m-0">Project Information</h5>
      <save-cancel @save="save" @cancel="$emit('cancel')" small />
    </div>

    <div class="card-body">
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          ref="firstInput"
          v-model="project.name"
          :class="{ 'is-invalid': validated && !project.name }"
          placeholder="e.g. Adam's cabinet"
          class="form-control"
        />
        <div class="invalid-feedback" v-if="validated && !project.name">
          Please enter project name
        </div>
      </div>
      <section class="form-group">
        <label>Client</label>
        <contact-selector
          :contact="project.customer"
          name="company"
          ref="contactSelctor"
          :class="{ 'is-invalid': validated && !project.customer._id }"
          placeholder="Select Client"
          class="form-control"
        />
        <div class="invalid-feedback" v-if="validated && !project.customer._id">
          Select a Contact
        </div>
      </section>

      <section class="form-group">
        <label>Address</label>
        <address-view
          :address="project.address"
          class="bg-light px-3 py-2"
          show-btn-text
          :show-country="false"
        />

        <!-- <input
          type="text"
          ref="projectAddress"
          v-model="project.text"
          placeholder="e.g. 8281 East 16th St."
          class="form-control"
        />-->
      </section>
      <section class="form-group">
        <label
          >Gross Floor Area
          <div>
            <small class="text-secodnary"
              >This will be used to calculate Cost/sqft</small
            >
          </div></label
        >
        <div>
          <div class="row">
            <div class="col-lg-5">
              <div class="input-group mb-3 w-50">
                <input
                  v-model="project.sqf"
                  placeholder="sqft"
                  class="form-control"
                />
                <div class="input-group-append">
                  <span class="input-group-text">ft<sup>2</sup></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="form-group">
        <label>Description</label>
        <textarea
          class="form-control"
          v-model="project.description"
          ref="noteInput"
          rows="5"
        ></textarea>
      </section>
    </div>
    <div class="card-footer">
      <save-cancel @save="save" @cancel="$emit('cancel')" />
    </div>
  </div>
</template>

<script>
import dataMixin from "@/mixins/dataMixin.js";
import baseMixin from "@/mixins/baseMixin.js";
import ProjectStatus from "./ProjectStatus";
import { mapGetters } from "vuex";

export default {
  name: "projectDetailEdit",
  mixins: [dataMixin, baseMixin],
  components: { ProjectStatus },
  props: {
    id: String
  },
  data() {
    return {
      title: "Add Project",
      validated: false,
      project: {}
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      let p = this.projectById(this.id);
      if (p) {
        this.project = JSON.parse(JSON.stringify(p));
      }
    },

    async save() {
      this.validated = true;
      if (!this.valid()) return;
      try {
        const project = await this.update("project", this.project);
        this.$emit("done", this.project);
      } catch (error) {}
    },

    valid() {
      return this.project && this.project.customer._id && this.project.name;
    }
  },
  computed: {
    ...mapGetters(["projectById"])
  }
};
</script>
