<template>
  <div v-tooltip="type.name" v-if="type">
    <icon svg="svg-box" :size="26" color="#757575" v-if="type.key ==0" />
    <icon svg="svg-service" :size="26" color="#757575" v-if="type.key == 1" />
    <icon svg="svg-worker" :size="26" color="#757575" v-if="type.key == 2" />
    <icon svg="svg-cost" :size="26" color="#757575" v-if="type.key == '9'" />
  </div>
</template>
<script>
export default {
  name: "payableTypeIcon",
  props: {
    type: Object
  }
};
</script>
<style lang="scss" scoped>
img {
  max-height: 2rem;
}
</style>
