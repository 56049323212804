<template>
  <inline-edit @edit="edit" @save="save" ref="inlineEdit" :skey="skey">
    <div class="form-control value d-flex justify-content-between">
      <span class="ml-3 fw-600">{{clientSetting.dateFormat}}</span>
      <span class="w-600">{{ sampleDate }}</span>
    </div>
    <div slot="edit">
      <form-select :options="dateFormats" v-model="dateFormat" />
    </div>
  </inline-edit>
</template>
<script>
import { mapGetters } from "vuex";
import clientDataMixin from "@/mixins/clientDataMixin";
import { numeric, required, between } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import moment from "moment";
const formats = [
  "MM/DD/YYYY",
  "YYYY-MM-DD",
  "MMM DD, YYYY",
  "MMM DD, YY",
  "DD MMM, YYYY",
  "DD.MM.YYYY",
  "DD/MM/YYYY",
  "DD-MM-YYYY"
];
export default {
  name: "dateFormatSetting",
  mixins: [validationMixin, clientDataMixin],
  data() {
    return {
      model: {},
      dateFormats: [],
      validated: true,
      dateFormat: "",
      skey: "dateformet"
    };
  },
  mounted() {
    let list = formats.map(f => ({
      code: f,
      name: moment().format(f)
    }));
    this.dateFormats = list;
  },
  validations: {
    dateFormat: {
      required
    }
  },
  methods: {
    edit() {
      this.dateFormat = this.clientSetting.dateFormat;
    },
    async save() {
      if (this.$v.$invalid) return;
      try {
        await this.updateSetting({ dateFormat: this.dateFormat }, this.skey);
        this.$refs.inlineEdit.close();
        this.$emit("done", this.done);
      } catch (error) {
        console.log("error", error);
      }
    }
  },
  computed: {
    sampleDate() {
      return moment().format(this.clientSetting.dateFormat);
    },
    ...mapGetters(["clientSetting"])
  }
};
</script>
