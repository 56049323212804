<template>
  <table class="table table-hover bg-white mb-0 thead-md">
    <thead>
      <tr>
        <th scope="col" class="text-center">Description</th>
        <th scope="col" class="w-7 text-right">Unit Price</th>
        <th scope="col" class="w-4 text-center">Qty</th>
        <th scope="col" class="w-4 text-center" v-if="clientHasTax1">{{clientTaxCode1}}</th>
        <th scope="col" class="w-4 text-center" v-if="clientHasTax2">{{clientTaxCode2}}</th>
        <th scope="col" class="w-2"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in invoice.items" class="bg-light" v-bind:key="item._id||item.index">
        <td class="px-1">
          <input
            v-if="!isCustom(item)"
            type="text"
            class="form-control"
            :ref="`item${item.index||''}`"
            v-model="item.description"
            placeholder="Enter the description"
          />
          <a
            class="btn w-100 text-left btn-default text-primary"
            v-else
            @click="editCustom(item)"
          >{{item.description}}</a>
        </td>

        <td class="px-1 text-right">
          <amount-input v-model="item.unitPrice" :readonly="isCustom(item)" />
        </td>

        <td class="px-1">
          <input
            type="text"
            class="form-control"
            placeholder="qty"
            v-model="item.qty"
            v-if="!isCustom(item)"
          />
          <div class="form-control value" v-else>{{item.qty}}</div>
        </td>

        <td class="py-1 px-1" v-if="clientHasTax1">
          <TaxInput :tax="item.tax1" :amount="item.unitPrice * item.qty" small />
        </td>

        <td class="py-1 px-1" v-if="clientHasTax2">
          <TaxInput :tax="item.tax2" :amount="item.unitPrice * item.qty" small />
        </td>

        <!-- <td class="px-1 text-right">{{item.unitPrice * item.qty | currency}}</td> -->
        <td class="by-2">
          <button class="btn close text-danger p-2" tabindex="-1" @click="remove(item)">
            <span aria-hidden="true">&times;</span>
          </button>
        </td>
      </tr>

      <tr v-if="isCostPlus">
        <td :colspan="cols" v-if="!mngFeeItem">
          <a
            class="btn btn-block text-primary border-0 d-flex align-items-center"
            @click="$emit('openMngFee')"
          >
            <icon svg="svg-plus" color="#007bff" class="d-inline-block mr-2" />Add Cost Plus Management Fee
          </a>
        </td>
      </tr>
      <!-- <tr v-if="!reimbItem">
        <td>
          <a
            @click="$emit('openReimbursement')"
            class="btn btn-block text-primary border-0 d-flex align-items-center"
          >
            <icon svg="svg-plus" color="#007bff" class="d-inline-block mr-2" />Include Reimbursement
          </a>
        </td>
        <td :colspan="cols"></td>
      </tr>-->

      <tr>
        <td :colspan="cols">
          <a @click="addItem" class="btn btn-block text-primary border-0 d-flex align-items-center">
            <svg-icon name="plus" color="#007bff" class="d-inline-block mr-2" />Add Line Item
          </a>
        </td>
      </tr>
      <tr>
        <td class="border-0 bg-1"></td>
        <td :colspan="cols-3" class="text-right border-0 bg-1">Sub Total</td>
        <td colspan="2" class="text-right">{{subTotal | currency}}</td>
      </tr>

      <tr>
        <td class="border-0 bg-1"></td>
        <td :colspan="cols-3" class="text-right border-0 bg-1">Tax</td>
        <td colspan="2" class="text-right">{{totalTax | currency}}</td>
      </tr>

      <tr>
        <td class="border-0 bg-1"></td>
        <td :colspan="cols-3" class="text-right border-0 bg-1">Total</td>
        <td colspan="2" class="text-right">{{invoiceTotal | currency}}</td>
      </tr>
      <tr>
        <td class="border-0 bg-1"></td>
        <td :colspan="cols-3" class="text-right border-0 bg-1">Previous Balance</td>
        <td colspan="2" class="p-1 text-right">
          <amount-input
            ref="amountInput"
            v-model="invoice.prevBalance"
            placeholder="Prev. Balance"
            class="border-0 text-right font-weight-md"
            zero-ok
          />
        </td>
      </tr>

      <tr>
        <td class="border-0 bg-1"></td>
        <td :colspan="cols-3" class="text-right border-0 bg-1">Balance Due</td>
        <td colspan="2" class="fw-600 p-0">
          <div class="bg-success-1 px-3 py-2 border text-right">{{balanceDue|currency}}</div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import codes from "../data/itemcodes";
import invoiceMixin from "../data/invoiceMixin";
import taxMixin from "@/views/settings/data/taxMixin";
import TaxInput from "@/views/settings/tax/TaxInput";

export default {
  name: "invoiceItemTable",
  mixins: [invoiceMixin, taxMixin],
  components: { TaxInput },
  props: {
    invoice: Object,
    isCostPlus: Boolean,
    isFixedPrice: Boolean
  },
  methods: {
    addItem() {
      let item = {
        description: "",
        note: "",
        qty: 1,
        unitPrice: 0,
        tax1: this.newTax(1),
        tax2: this.newTax(2),
        index: this.invoice.items.length
      };
      this.invoice.items.push(item);

      setTimeout(() => {
        const el = this.$refs[`item${item.index}`][0];
        console.log("el", el);
        if (el) el.focus();
      }, 300);
    },
    editCustom(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      const i = this.invoice.items.indexOf(item);
      if (i >= 0) {
        this.invoice.items.splice(i, 1);
      }
    }
  },
  computed: {
    cols() {
      return 5 + (this.clientHasTax1 ? 1 : 0) + (this.clienthasTax2 ? 1 : 0);
    },
    isCustom() {
      return item => this.isMngFee(item) || this.isReimbItem(item);
    }
  }
};
</script>
<style lang="scss" scopped>
</style>