<template>
  <div></div>
</template>
<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import { collection, doc, getFirestore, onSnapshot, query, where } from "firebase/firestore"

export default {
  name: "notification",
  mixins: [dataMixin],
  data() {
    return {
      /**
       * @type {import("firebase/firestore").Unsubscribe} unsubscribe function
       */
      unsubscribeFn: null
    };
  },
  methods: {
    listenChanges() {
      if (this.unsubscribeFn) {
        this.unsubscribeFn();
      }
      let t = Date.now() / 1000 - 60;
      console.log("listening to changes...");
      let clientsCollectionRef = collection(getFirestore(), "clients");
      let clientDocRef = doc(clientsCollectionRef, this.cid);
      let changesCollectionRef = collection(clientDocRef, "changes");
      this.unsubscribeFn = onSnapshot(query(changesCollectionRef, where("ts", ">", t)), snapshot => {
        snapshot.docChanges().forEach(change => {
          const id = change.doc.id;
          let entity = change.doc.data();
          const entityName = entity.entityName;

          if (change.type === "added" || change.type === "modified") {
            console.log(`Notify ${entityName} ${id}`);
            this.loadById(entityName, id, true);
          }
          if (change.type === "removed" || entity.isDeleted) {
              //todo:
              console.log(`Notify Removed ${entityName} ${id}`);
              this.$store.commit("REMOVE_FROM", {
                entityName,
                data: { _id: id }
              });
            }
        });
      });
    }
  },
  watch: {
    cid() {
      if (this.cid) this.listenChanges();
      else if (this.unsubscribeFn) {
        this.unsubscribeFn();
        this.unsubscribeFn = null;
      }
    }
  },
  computed: {
    ...mapGetters(["cid"])
  }
};
</script>
