<template>
  <div>
    <a
      @click="$emit('open')"
      class="list-group-item list-group-item-action shadow-sm border-0 mb-2 c-pointer rounded-5 bg-white d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center w-100">
        <icon :icon="icon" :svg="svg" color="#718e9b" class="mr-4 ml-3" :size="46" />
        <div class="w-100 text-left">
          <div class="fs-14 mb-1">{{ title }}</div>
          <slot></slot>
        </div>
      </div>

      <i class="icon-arrow-right"></i>
    </a>
  </div>
</template>
<script>
export default {
  name: "projectNavItem",
  props: {
    title: String,
    icon: String,
    svg: String
  }
};
</script>
<style lang="scss" scoped>
.list-group-item {
  height: 9rem;
}
.bg-item {
  background-color: #fff;
}
</style>
