export default (name) => {
	return {
		name: name,
		street: "",
		route: "",
		city: "",
		state: "",
		postalCode: "",
		country: "",
		countryCode: "",
		text: "",
	};
};
