<template>
  <LogoUploadView
    v-model="logoUrl"
    :readonly="!isAdmin"
    :folder="`/clients/${cid}/logo`"
    @change="changeLogoUrl"
  />
</template>
<script>
import LogoUploadView from "@/views/attachments/LogoUploadView";
import clientDataMixin from "@/mixins/clientDataMixin";
import { mapGetters } from "vuex";
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "clientLogo",
  mixins: [clientDataMixin, accessMixin],
  components: { LogoUploadView },
  methods: {
    async changeLogoUrl(logoUrl) {
      await this.updateClient({ logoUrl });
      this.$emit("change");
    }
  },
  computed: {
    ...mapGetters(["cid", "logoUrl"])
  }
};
</script>