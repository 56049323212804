<template>
  <div class="home" v-if="dashVisible">
    <div v-if="ready">
      <UpdateUserInfo v-if="missingUserInfo" />
      <div v-else>
        <init-client v-if="noClient" />
        <div v-else>
          <!-- <EmailVerify v-if="notVerified" /> -->
          <Dashborad />
        </div>
      </div>
    </div>
    <div class="p-5 text-center" v-else>
      <div class="pt-5">
        <div class="nexobuild">
          <div class="d-flex justify-content-center">
            <img src="img/icons/logo-150.png" class="logo d-inline-block" alt="nexobuild" />
            <span class="name d-inline-block ml-3">Nexobuild</span>
          </div>
        </div>
        <spinner :busy="true" />
      </div>
    </div>
  </div>
</template>

<script>
import InitClient from "@/views/client/InitClient";
import UpdateUserInfo from "@/views/client/user/UpdateUserInfo.vue";
import Dashborad from "./dashboard/Dashborad";
import EmailVerify from "./EmailVerify";
import { mapGetters } from "vuex";

export default {
  name: "home",
  components: {
    Dashborad,
    InitClient,
    UpdateUserInfo,
    EmailVerify
  },
  watch: {
    dashVisible: {
      immediate: true,
      handler() {
        if (!this.dashVisible) {
          // this.$router.push("/projects");
        }
      }
    }
  },
  computed: {
    notVerified() {
      return this.user && !this.user.emailVerified;
    },
    dashVisible() {
      return (
        this.noClient ||
        (this.isAuthenticated &&
          this.ready &&
          !this.missingUserInfo &&
          this.myrole &&
          this.myrole != "c")
      );
    },
    ...mapGetters([
      "profileLoaded",
      "noClient",
      "missingUserInfo",
      "user",
      "myrole",
      "isAuthenticated"
    ]),

    ready() {
      return this.user && this.user.uid && this.profileLoaded;
    }
  }
};
</script>

<style lang="scss" scoped>
.nexobuild {
  img {
    height: 6rem;
    margin-top: -2rem;
  }
  .name {
    font-size: 2.3rem;
    font-weight: 600;
    color: rgb(0, 116, 205);
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  }
  .logo {
    max-height: 5rem;
  }
}
</style>
