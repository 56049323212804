<template>
  <div>
    <div class="card rounded-10 shadow-sm p-card border-0">
      <div class="px-4 py-2 d-flex justify-content-between align-items-start">
        <div>
          <div class="fs-15">Payables</div>
          <button class="btn small p-0 fs-09 text-2 m-0">Last 6 Months</button>
          <div></div>
        </div>
        <svg-icon name="payable" color="#798bec" :size="50" />
      </div>
      <div class="card-body dash-card-body d-flex justify-content-between p-3" v-if="payableTotal">
        <div class="text-center px-2">
          <div class="fs-15 text-normal">{{ payableTotal.total | currency0 }}</div>
          <div class="text-2">Payables</div>
        </div>
        <div class="text-center px-2">
          <div class="fs-15 text-normal">{{ (payableTotal.total - payableTotal.paid) | currency0 }}</div>
          <div class="text-2">Unpaid</div>
        </div>
      </div>
      <div class="card-footer rounded-10-bottom border-m">
        <router-link
          :to="`/payables`"
          class="text-decoration-none d-flex justify-content-between align-items-center"
        >
          <span>View Recent Payables</span>
          <i class="icon-arrow-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import clientDataMixin from "@/mixins/clientDataMixin";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "payablesCard",
  mixins: [dataMixin, baseMixin, clientDataMixin],
  mounted() {
    this.checkhasPayable();
    this.load();
  },
  methods: {
    async checkhasPayable() {
      if (!this.hasPayable) {
        const hasPayable = await this.loadByAction("payable", "any", true);
        if (hasPayable) this.reloadProfile();
      }
    },
    async load() {
      let start = moment()
        .subtract(6, "months")
        .format("YYYYMMDD");
      await this.loadByAction("payableTotal", start);
    }
  },
  computed: {
    hasPayable() {
      return this.clientSetting && this.clientSetting.hasPayable;
    },
    ...mapGetters(["clientSetting", "payableTotal"])
  }
};
</script>
