<template>
  <div class="access">
    <div>
      <div class="my-4 card-body">
        <AccessTypes :name="model.name" v-model="model.role" :disabled="isMe" />
        <div class="border-top">
          <save-cancel class="pt-3" @save="save" @cancel="cancel" v-if="!isMe && hasChanges" />
        </div>
        <div class="p-3 mt-5">
          <delete-confirm
            v-if="!isMe"
            :left="false"
            custom
            class="position-absolute"
            title="Remove User"
            @confirm="removeAccess"
            confirmtext="Are you sure you want to remove this user"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import AccessTypes from "./AccessTypes";
import { mapGetters } from "vuex";
export default {
  name: "grantAccess",
  mixins: [baseMixin, dataMixin],
  props: {
    user: [Object, String]
  },
  components: { AccessTypes },
  data() {
    return {
      model: {}
    };
  },
  mounted() {},
  watch: {
    user: {
      immediate: true,
      handler() {
        this.model = JSON.parse(JSON.stringify(this.user));
      }
    }
  },
  methods: {
    async save() {
      if (this.isMe) return;
      try {
        const data = { cid: this.cid, role: this.model.role };
        const result = await this.update(
          "user",
          data,
          `${this.user._id}/grant`
        );
        this.$emit("done", this.model);
      } catch (error) {
        console.log("error :", error);
      }
    },
    cancel() {
      this.model = JSON.parse(JSON.stringify(this.user));
    },
    async removeAccess() {
      if (this.isMe) return;
      let user = { ...this.user };
      console.log("user", user);
      try {
        const result = await this.del("user", user);
        this.$emit("onRemove", this.model);
      } catch (error) {
        console.log("error :", error);
      }
    }
  },
  computed: {
    isAdmin() {
      return this.model.role == "a";
    },
    isFull() {
      return this.model.role == "f";
    },
    isReadonly() {
      return this.model.role == "r";
    },
    isLimited() {
      return this.model.role == "l";
    },
    isMe() {
      return this.user.uid == this.me.uid;
    },
    hasChanges() {
      return this.user.role != this.model.role;
    },
    ...mapGetters(["me", "cid"])
  }
};
</script>

<style lang="scss">
.access {
  .checked {
    font-weight: 600;
  }
  font-size: 110%;
  color: #000;
}
</style>
