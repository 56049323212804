<template>
  <lookup-layout
    :state="state"
    :addmode="addmode"
    :isOpen="isOpen"
    :target="target"
    @close="isOpen=false"
  >
    <div slot="search">
      <search-input
        @change="search"
        :placeholder="'Search subcontractor'"
        :isfocus="true"
        v-model="keyword"
        :busy="state.loading"
        class="mr-4 ml-2"
      />
    </div>

    <div slot="list">
      <ul class="list-group" v-show="!addmode">
        <li
          class="list-group-item list-group-item-action"
          v-for="contract in list"
          v-bind:key="contract._id"
          :class="{ selected: isSelected(contract._id) }"
        >
          <a @click="select(contract)" class="customerListItem">
            <div class="d-flex align-items-center">
              <contact-avatar :contact="contract.contact" class="mr-3" />
              <div>
                <div class="fs-11">{{ contract.contact.name }}</div>
                <div>{{ contract.item.name }}</div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </lookup-layout>
</template>

<script>
import lookupMixin from "@/mixins/lookupMixin";
import search from "../data/search";
import pages from "@/pages";
import { mapGetters } from "vuex";

export default {
  name: "selectContract",
  props: {
    selecteds: Array,
    projectId: String
  },

  mixins: [lookupMixin],
  data() {
    return {
      entityName: "contract"
    };
  },
  methods: {
    filterAll() {
      let result = this.all;
      this.list = search(result, this.keyword);
    }
  },

  computed: {
    all() {
      return this.$store.getters.contracts.filter(
        o => o.project && o.project._id == this.projectId
      );
    }
  }
};
</script>
