<template>
  <div v-if="budgetItem" class="bg-info-2 mb-3">
    <div class="d-flex justify-content-between">
      <div class="pl-3">
        <div class="small text-muted">Cost To Date</div>
        <div>{{budgetItem.costToDate | currency}}</div>
      </div>
      <div class="pr-3 text-right">
        <div class="small text-muted">Current Budget</div>
        <div>{{budgetItem.budget | currency}}</div>
      </div>
    </div>

    <BudgetProgress :value="budgetItem.costToDate/budgetItem.budget" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BudgetProgress from "./BudgetProgress";
import budgetingMixin from "../data/budgetingMixin";
export default {
  name: "budgetItemLine",
  mixins: [budgetingMixin],
  props: {
    itemId: String,
    projectId: String
  },
  components: {
    BudgetProgress
  },
  mounted() {
    this.loadBudgetingIfNot();
  },
  computed: {
    budgetItem() {
      return this.budgeting && this.budgeting.budgetItems
        ? this.budgeting.budgetItems.find(o => o.itemId == this.itemId)
        : null;
    }
  }
};
</script>