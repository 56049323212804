<template>
  <b-modal ref="editModal" :title="isnew ? 'Add Account' : 'Edit Account'" hide-footer>
    <div class="px-3" v-if="showModal">
      <section class="form-group">
        <label>Account Name</label>
        <input
          ref="nameInput"
          :class="{ 'is-invalid': validated && !valid }"
          class="form-control"
          v-model="account.name"
          placeholder="e.g. Visa 2333"
        />
        <div class="invalid-feedback" v-if="validated && !valid">Please eneter the title</div>
      </section>
      <section class="form-group">
        <label>Description</label>
        <textarea
          class="form-control"
          v-model="account.description"
          placeholder="Account description"
        />
      </section>

      <div class="py-3">
        <delete-confirm
          v-if="!isnew"
          :left="false"
          custom
          class="position-absolute"
          @confirm="del"
          entityName="Account"
          confirmtext="Are you sure you want to delete this Account"
        />
        <save-cancel @save="done" @cancel="close" class="float-right" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
const entityName = "accounts";
import { replace } from "@/utils/array.js";
export default {
  name: "accountEdit",
  mixins: [dataMixin, baseMixin],
  data() {
    return {
      validated: false,
      showModal: false,
      account: { id: 0, name: "" }
    };
  },

  methods: {
    focus() {
      setTimeout(() => {
        this.$refs.nameInput.focus();
      }, 500);
    },
    open(account) {
      if (account) this.account = JSON.parse(JSON.stringify(account));
      else this.reset();
      this.showModal = true;
      this.$refs.editModal.show();
      this.focus();
    },
    close() {
      this.showModal = false;
      this.$refs.editModal.hide();
    },
    reset() {
      this.account = { id: 0, name: "" };
      this.validated = false;
    },

    async done() {
      this.validated = true;
      if (!this.valid) return;
      if (this.isnew) {
        await this.listAdd(entityName, this.account);
      } else {
        await this.listUpdate(entityName, this.account);
      }
      this.$emit("done");
      this.reset();
      this.close();
    },

    async del() {
      await this.listDelete(entityName, this.account);
      this.$emit("done");
      this.reset();
      this.close();
    },

    onDelete() {
      this.close();
    }
  },
  computed: {
    valid() {
      return this.account.name && this.account.name.length < 129;
    },
    isnew() {
      return !this.account._id;
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
