<template>
  <div>
    <div class="px-3 d-flex justify-content-between align-items-center form-group">
      <span class="text-2">Subcontract</span>
      <add-button @add="add" v-if="!nodata">Add Subcontract</add-button>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="list-list-group-flush green">
          <div
            @click="select(c)"
            class="list-group-item mb-2 rounded-10 shadow-sm c-pointer list-group-item-action"
            v-for="c in list"
            :key="c._id"
            :class="{active:c._id==selected}"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <contact-avatar :contact="c.contact" :size="48" class="mr-3" />
                <div>
                  <div class="fs-11">{{ c.contact.name }}</div>
                  <div>{{ c.item.name }}</div>
                </div>
              </div>
              <div class="text-right" v-if="price(c)">
                <div class="fs-11 d-block">{{price(c)}}</div>
                <small class="text-muted">{{typeName(c)}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <ContractView :id="selected" v-if="selected" class="vh-100-m shadow bg-white" />
      </div>
    </div>

    <no-data
      v-if="nodata"
      title="No subcontract has been entered"
      subtitle="Add the first subcontract for this project"
      svg="svg-contacts"
    >
      <add-button @add="add">Add Subcontract</add-button>
    </no-data>

    <AddSubContract ref="addSubContract" :project="project" @done="onAdd" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ContractView from "./ContractView";
import AddSubContract from "./AddSubContract";
import { toCurrency0 } from "@/filters/currency";
const types = {
  c: "Cost Plus",
  f: "Fixed Price",
  t: "Time & Material",
  u: "Unit price"
};
export default {
  name: "subContracts",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { AddSubContract, ContractView },
  mounted() {
    this.load();
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {
    async load() {
      this.loading();
      //this.loadByProject("contract", this.project_id);
      this.loaded();
    },
    select(c) {
      this.selected = c._id;
    },
    add() {
      this.$refs.addSubContract.open();
    },
    onAdd(c) {
      this.select(c);
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },
    list() {
      return this.contracts.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    nodata() {
      return this.ready && !this.list.length;
    },
    price() {
      return c => {
        const type = c.contractType;
        if (!type) return "";
        if (type.code == "f") return toCurrency0(type.fixPrice);
        if (type.code == "c") {
          return type.percent ? `${type.percent} %` : "";
        }
        return "";
      };
    },
    typeName() {
      return c => (c.contractType ? types[c.contractType.code] : "");
    },

    ...mapGetters(["projectById", "contracts", "contactById"])
  }
};
</script>
<style lang="scss" scoped>
.vh-100-m {
  min-height: calc(100vh - 14rem);
}
</style>