<template>
  <div class="bg-1 pb-2">
    <input
      ref="autocomplete"
      :placeholder="placeholder"
      class="form-control search form-group"
      @focus="onFocus"
      @blur="onBlur"
      type="search"
      autocomplete="false"
      v-model="keyword"
    />
    <AddressForm :address="address" @change="onChange" />
    <p class="text-2 p-2 bg-2 m-0">{{address.text}}</p>
  </div>
</template> 
 
<script>
const ADDRESS_COMPONENTS = {
  street_number: { short_name: "street" },
  route: { long_name: "route" },
  locality: { long_name: "city" },
  administrative_area_level_1: { short_name: "state" },
  postal_code: { short_name: "postalCode" },
  country: { long_name: "country", short_name: "countryCode" }
};
import AddressForm from "./AddressForm";
export default {
  name: "addressInput",

  components: { AddressForm },
  props: {
    address: Object,
    inputclass: String,
    placeholder: {
      type: String,
      default: "Start typing"
    },
    types: {
      type: String
      // default: "address"
    },
    placeholder: { type: String, default: "Search address or business name " },

    enableGeolocation: {
      type: Boolean,
      default: true
    },
    geolocationOptions: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      autocomplete: "",
      keyword: "",
      countryFilter: "",
      geolocation: {
        /**
         * Google Geocoder Objet
         * @type {Geocoder}
         * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
         */
        geocoder: null,
        /**
         * Filled after geolocate result
         * @type {Coordinates}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
         */
        loc: null,
        /**
         * Filled after geolocate result
         * @type {Position}
         * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
         */
        position: null
      }
    };
  },
  mounted() {
    const options = {};
    if (this.types) {
      options.types = [this.types];
    }
    if (this.countryFilter) {
      options.componentRestrictions = {
        country: this.countryFilter
      };
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      options
    );
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    this.focus();
  },

  watch: {
    countryFilter: function(newVal, oldVal) {
      this.autocomplete.setComponentRestrictions({
        country: this.countryFilter === null ? [] : this.countryFilter
      });
    }
  },

  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
    onFocus() {
      this.biasAutocompleteLocation();
      this.$emit("focus");
    },

    onBlur() {
      console.log("onBlur", this.keyword);
      this.$emit("blur", this.keyword);
    },

    biasAutocompleteLocation() {
      if (this.enableGeolocation) {
        this.updateGeolocation((geolocation, position) => {
          let circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          this.autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    /**
     * Update location based on navigator geolocation
     */
    geolocate() {
      this.updateGeolocation((geolocation, position) => {
        this.updateCoordinates(geolocation);
      });
    },

    /**
     * Update internal location from navigator geolocation
     * @param  {Function} (geolocation, position)
     */
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        let options = {};
        if (this.geolocationOptions)
          Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition(
          position => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.geolocation.loc = geolocation;
            this.geolocation.position = position;
            if (callback) callback(geolocation, position);
          },
          err => {
            this.$emit("error", "Cannot get Coordinates from navigator", err);
          },
          options
        );
      }
    },

    /**
     * Update internal location from navigator geolocation
     * @param  {Function} (geolocation, position)
     */
    updateGeolocation(callback = null) {
      if (navigator.geolocation) {
        let options = {};
        if (this.geolocationOptions)
          Object.assign(options, this.geolocationOptions);
        navigator.geolocation.getCurrentPosition(
          position => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.geolocation.loc = geolocation;
            this.geolocation.position = position;
            if (callback) callback(geolocation, position);
          },
          err => {
            this.$emit("error", "Cannot get Coordinates from navigator", err);
          },
          options
        );
      }
    },
    onChange() {
      this.address.text = this.format(this.address);
      this.$forceUpdate();
    },
    format() {
      const {
        street,
        route,
        city,
        state,
        postalCode,
        countryCode
      } = this.address;

      return `${street} ${route},${city}, ${state} ${postalCode}, ${countryCode}`;
    },
    onPlaceChanged() {
      let place = this.autocomplete.getPlace();
      console.log("place :", place);
      if (place.address_components !== undefined) {
        place.address_components.map(c => {
          let field = ADDRESS_COMPONENTS[c.types[0]];
          if (field) {
            if (field["short_name"])
              this.address[field["short_name"]] = c["short_name"];
            if (field["long_name"])
              this.address[field["long_name"]] = c["long_name"];
          }
        });
        this.address.location = place.geometry.location;
        this.address.text = place.formatted_address;
        if (place.name && !place.name.startsWith(this.address.street))
          this.address.name = place.name;
        else this.address.name = "";

        this.$refs.autocomplete.value = "";
        this.onChange();
        this.$emit("select", this.address);
        console.log("this.address", this.address);
        this.$forceUpdate();
      }
    }
  },
  beforeDestroy() {
    //remove extra element
    [...document.getElementsByClassName("pac-container")].map(d => {
      d.parentNode.removeChild(d);
    });
    google.maps.event.clearInstanceListeners(this.autocomplete);
  }
};
</script>
