<template>
	<div class="d-inline-block entity-view">
		<div v-if="account">
			<a class="d-block" :href="`#/account/${account._id}`" v-if="navigate">
				<!-- <account-avatar :size="30" :account="account" class="float-left mr-2 account-avatar" /> -->
				<span>{{ account.name || 'unknown' }}</span>
			</a>
			<span class="d-block" v-else>
				<!-- <account-avatar :size="30" :account="account" class="float-left mr-2 account-avatar" /> -->
				<span>{{ account.name || 'unknown' }}</span>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'account-view',
	mixins: [],
	props: {
		account: Object,
		navigate: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.account-avatar {
	margin-top: -0.2rem;
}
.account-icon {
	font-size: 1.2rem;
}
</style>
