<template>
  <div class="list-layout" id="list-layout">
    <div class="page-header d-flex justify-content-between form-group no-print p-4 pt-3">
      <h5 class="d-flex page-title">
        <client-name-in-header />
        <slot name="title"></slot>
      </h5>
      <spinner :busy="state.loading" />
      <div class="d-flex">
        <slot name="btns" v-if="!nodata && state.loaded"></slot>
        <slot name="top-add" v-if="state.loaded"></slot>
      </div>
    </div>

    <div class="body px-4 mb-1" v-if="!nodata && state.loaded">
      <div class="row">
        <div class="col-lg-3">
          <slot name="left"></slot>
        </div>
        <div class="col-lg-9">
          <div class="right-container shadow-sm over-header">
            <slot name="main"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="nodata && state.loaded">
      <slot name="nodata"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "listlayout",

  props: {
    nodata: Boolean,
    page: Object,
    state: Object
  }
};
</script>

<style lang="scss">
.list-layout {
  .right-container {
    min-height: calc(100vh - 11.5rem);
    background-color: white;
  }
}
</style>
