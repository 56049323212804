import moment from "moment";
import _ from "lodash";
export const GROUPBY = {
	Month: "m",
	Week: "w",
	Day: "d",
	None: "n",
};
export const CONTACT_TYPE = {
	Insiders: "i",
	Subcontactors: "s",
	All: "a",
};
export const groupByContact = (list) => {
	return _(list)
		.groupBy((t) => t.contact._id)
		.map((value, key) => ({
			contactId: key,
			contact: value[0].contact,
			list: value,
		}))
		.value();
};

export const totalDuration = (list) => {
	return list && list.length ? list.map((o) => o.duration || 0).reduce((a, b) => a + b) : 0;
};

export const groupByDate = (by) => (t) => {
	const date = t.date;
	if (by == GROUPBY.Week) {
		return `${moment(date)
			.startOf("week")
			.isoWeekday(1)
			.format("MMM D")}-${moment(date)
			.endOf("week")
			.isoWeekday(1)
			.format("MMM D")}
			`;
	} else if (by == GROUPBY.Month) {
		return moment(date).format("MMM YYYY");
	} else if (by == GROUPBY.Day) {
		return moment(date).format("YYYY-MM-DD");
	} else {
		return moment(date).format("YYYY-MM-DD");
	}
};

export const isValidTime = (str) => {
	return str && !!str.match(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/);
};
