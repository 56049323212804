<template>
  <card-layout :state="state" title="User Access" nofooter back>
    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-md-6 col-lg-5">
          <div class="fs-15 px-3 border-bottom pb-2">Users</div>
          <ClientUsers @select="select" :users="list" />
        </div>
        <div class="col-md-6 col-lg-7">
          <GrantAccess :user="selected" v-if="selected" @done="updated" @onRemove="onRemove" />
        </div>
      </div>
    </div>
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import ClientUsers from "./ClientUsers";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import GrantAccess from "./GrantAccess";
export default {
  name: "UserAccess",

  mixins: [baseMixin, dataMixin],
  components: { ClientUsers, GrantAccess },
  data() {
    return {
      selected: "",
      list: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      this.list = await this.loadList("user");
      this.loaded();
    },
    select(user) {
      this.selected = user;
    },
    edit() {
      this.$router.push(`/settings/useraccess/${this.selected.id}/edit`);
    },
    updated(user) {
      let list = this.list;
      let found = list.find(o => o.uid == user.uid);
      const i = list.indexOf(found);
      this.$nextTick(() => {
        list[i] = user;
        if (this.selected.uid == user.uid) {
          this.selected = user;
        }
      });

      setTimeout(() => {
        this.list = [...list];
        this.$forceUpdate();
      }, 200);
    },
    onRemove(user) {
      this.selected = "";
      this.load();
    }
  }
};
</script>
