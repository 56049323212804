<template>
  <div><highcharts :options="chartOptions" ref="hchart" /></div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";

export default {
  name: "contactPayableChart",
  components: { highcharts: Chart },
  mixins: [baseMixin],
  props: {
    list: Array
  },
  data() {
    return {
      chartOptions: {
        chart: {
          //plotBackgroundColor: "#f7f5f8",
          plotBorderWidth: null,
          plotShadow: false,
          type: "bar",
          height: 200
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: [],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: "Payment ($)"
          }
        },
        tooltip: {
          pointFormat: "{series.name}: <b>${point.y:,.0f}</b>"
        },

        series: [
          {
            name: "Payments"
          }
        ]
      }
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      //todo group by
      let labels = this.list.map(o => {
        return { name: o.payee.name, amount: o.amount };
      });
      this.chartOptions.series[0].data = labels.map(o => o.amount);
      this.chartOptions.xAxis.categories = labels.map(o => o.name);
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped></style>
