<template>
  <input
    class="form-control"
    :class="{ 'is-invalid': !validNumber && validated }"
    @change="$emit('change')"
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
    :placeholder="mask"
    type="text"
    name="number"
    ref="number"
    autocomplete="tel"
  />
</template>

<script>
import format from "@/helpers/format";
import { mapGetters } from "vuex";
export default {
  name: "formInput",
  mixins: [],
  props: {
    value: String,
    readonly: Boolean,
    validated: Boolean,
    mask: { type: String, default: "###-###-####" }
  },
  computed: {
    focus() {
      this.$refs.number.focus();
    },

    validNumber() {
      return this.value && this.value.length >= 10;
    },
    ...mapGetters(["clientSettings"])
  }
};
//const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/gm;
</script>

<style lang="css" scoped>
.phone-link {
  color: #555;
}
</style>
