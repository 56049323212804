<template>
  <div>
    <div v-if="small">
      <div class="text-right">
        <b-checkbox switch v-model="hasTax" @change="onSwitch"></b-checkbox>
      </div>
      <input
        v-tooltip="`${tax.percent} %`"
        v-if="hasTax"
        v-model="tax.amount"
        @change="taxAmountChanged"
        class="form-control small py-0 border-0 text-right"
        :class="{ overwritten: overwritten }"
      />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-sm-4">
          <label for="amount" class="d-flex mt-1">
            <span class="mr-2">{{ tax.code }}</span>
            <b-checkbox v-model="hasTax" @change="onSwitch" switch size="lg" />
          </label>
        </div>
        <div class="col-sm-8">
          <div class="input-group prepend tax">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                :class="{ overwritten: overwritten }"
              >{{ tax.percent }} %</span>
            </div>
            <amount-input v-model="tax.amount" @change="taxAmountChanged" :placeholder="tax.code" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { calcPercent, round } from "@/utils/calc";
// import taxMixin from "@/views/settings/data/taxMixin";
export default {
  name: "taxtInput",
  // mixins: [taxMixin],
  props: {
    tax: Object,
    amount: Number,
    small: Boolean
  },
  data() {
    return {
      hasTax: false
    };
  },
  mounted() {
    this.$nextTick(o => {
      this.hasTax = this.tax.amount != 0;
    });
  },
  watch: {
    amount: {
      //immediate: true,
      handler(value) {
        this.onSwitch(this.tax.amount != 0);
      }
    }
  },
  methods: {
    taxAmountChanged(amount) {
      this.tax.percent = calcPercent(this.tax.amount, this.amount);
      this.$emit("change", this.tax);
    },
    onSwitch(checked) {
      if (this.taxSetting && this.taxSetting.percent) {
        if (checked) {
          this.tax.amount = round(
            (this.taxSetting.percent * this.amount) / 100
          );
          this.tax.percent = this.taxSetting.percent;
        } else {
          this.tax.amount = 0;
          this.tax.percent = 0;
        }
      }
      this.$emit("change", this.tax);
    }
  },
  computed: {
    ...mapGetters(["clientSetting"]),
    taxSetting() {
      const tax1 = this.clientSetting.tax1;
      if (tax1 && tax1.code === this.tax.code) return tax1;
      return this.clientSetting.tax2;
    },
    overwritten() {
      return this.taxSetting && this.taxSetting.percent != this.tax.percent;
    }
  }
};
</script>

<style lang="scss" scoped></style>
