<template>
  <lookup-layout
    :state="state"
    :addmode="addmode"
    :isOpen="isOpen"
    :target="target"
    @close="isOpen=false"
    :container="container"
    :height="25.5"
  >
    <div slot="search">
      <search-input
        @change="search"
        :placeholder="'Search contacts'"
        :isfocus="true"
        v-model="keyword"
        :busy="state.loading"
        class="mr-4 ml-2"
      />
    </div>
    <div slot="add" class="text-default">
      <h6 class="px-3">Add new Contact</h6>
      <new-contact
        ref="newForm"
        @done="onAdded"
        v-if="addmode"
        @cancel="cancelAdd"
        small
        class="px-0 pt-2 new-company"
      />
    </div>

    <div slot="list">
      <ul class="list-group" v-show="!addmode">
        <a
          class="list-group-item list-group-item-action bg-white"
          v-for="contact in list"
          v-bind:key="contact._id"
          @click="select(contact)"
          :class="{ selected: isSelected(contact._id) }"
        >
          <contact-view :contact="contact" no-navigate class="customerListItem" />
        </a>
        <add-in-list text="Add new Contact" @add="addNew" />
      </ul>
    </div>
  </lookup-layout>
</template>

<script>
import lookupMixin from "@/mixins/lookupMixin";
import NewContact from "./NewContact";
import searchEntity from "../data/search";
import pages from "@/pages";
import { mapGetters } from "vuex";

export default {
  name: "selectContact",
  props: {
    selecteds: Array
  },

  mixins: [lookupMixin],
  components: { NewContact },
  data() {
    return {
      entityName: "contact"
    };
  },
  methods: {
    filterAll() {
      let result = this.all;
      this.list = searchEntity(result, this.keyword);
    }
  },

  computed: {
    ...mapGetters({ all: "contacts" })
  }
};
</script>
