<template>
  <div>
    <div class="card-header min-h-auto d-flex justify-content-between align-items-center">
      <h5 class="m-0">Project Status</h5>
      <save-cancel @save="save" @cancel="cancel" small />
    </div>

    <div class="px-3">
      <div
        class="bg-white px-3 py-1 row mb-1 d-flex align-items-center justify-content-between"
        v-for="item in model.statusHistory"
        :key="item.code"
      >
        <a @click="select(item)" class="d-flex">
          <svg-icon name="circleChecked" color="green" v-if="item.code==model.statusCode" />
          <svg-icon name="circle" v-else />
          <span class="ml-3">{{item.name}}</span>
        </a>
        <span class="mr-3" v-if="item.code==0">{{item.date ||'2015/12/25' | dateformat}}</span>

        <date-input v-model="item.date" @change="dateChanged(item)" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin";
export default {
  name: "projectStatus",
  mixins: [dataMixin],
  props: {
    project: Object
  },
  data() {
    return {
      model: {}
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.model = JSON.parse(JSON.stringify(this.project));
    },
    select(item) {
      this.model.statusCode = item.code;
      item.date = new Date();
    },
    dateChanged(item) {
      if (this.model.statusCode < item.code) this.model.statusCode = item.code;
    },
    cancel() {
      this.init();
      this.$router.go(-1);
    },
    async save() {
      try {
        await this.update("project", this.model);
        this.$emit("done", this.model);
      } catch (error) {
        console.log("error :", error);
      }
    }
  },
  computed: {
    list() {
      return this.model.statusHistory;
    },
    status() {
      return this.model.statusHistory.find(
        o => o.code == this.model.statusCode
      );
    },

    isLast() {
      return item => item.code == this.list[this.list.length - 1].code;
    }
  }
};
</script>
<style lang="scss" scoped>
.date {
  color: #999;
}
.status {
  color: #9e9e9e;
}
.date {
  width: 5rem;
}
.dot {
  width: 1rem;
  height: 1rem;
  background-color: #cfcfcf;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.8;
  z-index: 1;
}
.line {
  border-left: 1px solid #bbbbbb;
  position: absolute;
  top: 1.2rem;
  left: 0.5rem;
  height: 0.55rem;
  margin: auto;
  z-index: 0;
}
.current {
  .dot {
    background-color: #53b2ff;
    border: 2px solid blue;
  }

  .status {
    color: blue;
  }
}

.done {
  .dot {
    background-color: #555;
  }

  .status {
    // color: #555;
  }
}
</style>
