<template>
  <ul class="text-gray mb-0" v-if="!hasData">
    <li>Work breakdown sturcture</li>
    <li>Budgeting & change orders</li>
  </ul>
  <div class="d-flex justify-content-between mt-2 pr-4" v-else>
    <div class="text-center" v-if="budgeting.projectInitialBudget">
      <div class="fs-14 text-2 no-wrap">{{budgeting.projectInitialBudget | currency0}}</div>
      <div class="text-secondary small">Initial Budget</div>
    </div>
    <div class="text-center">
      <div class="fs-14 text-2 no-wrap">{{budgeting.projectBudget | currency0}}</div>
      <div class="text-secondary small">Current Budget</div>
    </div>

    <div class="text-center"></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import accessMixin from "@/mixins/accessMixin";
import routeMixin from "@/mixins/routeMixin.js";
export default {
  name: "budegtSummary",
  mixins: [baseMixin, dataMixin, routeMixin, accessMixin],
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (this.isUserCustomer) return;
      this.loadByProject("budgeting", this.project_id,false,false);
    }
  },
  computed: {
    budgeting() {
      return this.budgetings.find(o => o.projectId == this.project_id) || {};
    },
    hasData() {
      return this.budgeting && this.budgeting.projectBudget;
    },
    ...mapGetters(["budgetings"])
  }
};
</script>
 