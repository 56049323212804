<template>
  <b-modal
    v-if="!readonly"
    ref="modal"
    title="Invite User"
    size="lg"
    hide-footer
    @hide="show = false"
  >
    <Invite v-if="show" modal />
  </b-modal>
</template>
<script>
import Invite from "./Invite";

export default {
  name: "inviteModal",
  components: { Invite },
  props: {
    value: String,
    readonly: Boolean,
    folder: String
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    open() {
      this.show = true;
      this.$refs.modal.show();
    },
    close() {
      this.$refs.modal.hide();
      this.show = false;
    },
    done(url) {
      this.$emit("done");
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
img.logo {
  max-width: 10rem;
  max-height: 10rem;
}
.logo-container {
  max-width: 10rem;
  max-height: 10rem;
  background-color: #e9ecef;

  i {
    font-size: 7rem;
    color: #bdbfc1;
  }
}
</style>
