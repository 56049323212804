<template>
  <div>
    <div class="card rounded-10 shadow-sm p-card border-0">
      <div class="px-4 py-2 d-flex justify-content-between align-items-start">
        <div>
          <div class="fs-15">Invoices</div>
          <button class="btn small p-0 fs-09 text-2 m-0">last 6 Months</button>
          <div></div>
        </div>
        <svg-icon name="invoice" color="#798bec" :size="50" />
      </div>

      <div class="card-body dash-card-body d-flex justify-content-between p-3" v-if="invoiceTotal">
        <div class="text-center px-2" v-if="invoiceTotal.invoice">
          <div class="fs-15 text-normal">{{ invoiceTotal.invoice.total | currency0 }}</div>
          <div class="text-2">Invoiced</div>
        </div>
        <div class="text-center px-2" v-if="invoiceTotal.income">
          <div class="fs-15 text-normal">{{ invoiceTotal.income.total | currency0 }}</div>
          <div class="text-2">Received</div>
        </div>
      </div>
      <div class="card-footer rounded-10-bottom border-m">
        <router-link :to="`/invoices`" class="text-decoration-none d-flex justify-content-between align-items-center">
          <span>View Recent Invoices</span>
          <i class="icon-arrow-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import clientDataMixin from "@/mixins/clientDataMixin";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "invoicesCard",
  mixins: [dataMixin, baseMixin, clientDataMixin],
  mounted() {
    this.checkHasInvoice();
    this.load();
  },
  methods: {
    async checkHasInvoice() {
      if (!this.hasInvoice) {
        const hasInvoice = await this.loadByAction("invoice", "any", true);
        if (hasInvoice) this.reloadProfile();
      }
    },
    async load() {
      let start = moment()
        .subtract(6, "months")
        .format("YYYYMMDD");
      await this.loadByAction("invoiceTotal", start);
    }
  },
  computed: {
    hasInvoice() {
      return this.clientSetting && this.clientSetting.hasInvoice;
    },
    ...mapGetters(["clientSetting", "invoiceTotal"])
  }
};
</script>
<style scoped lang="scss">
.p-card {
  //   height: 12rem;
}
</style>
