<template>
  <div class="shadow-sm">
    <div class="px-3 py-2 bg-trans rounded-5-top fs-11">Cash Inflow</div>
    <div class="bg-white">
      <div class="row">
        <div class="col-lg-6">
          <table class="table mx-3">
            <thead>
              <tr>
                <th>Payee</th>
                <th class="text-center">Percent</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tr v-for="p in groupedList" :key="p.key">
              <td>{{p.contact.name}}</td>
              <td>
                <span class="badge badge-info fs-1">{{percent(p.contact) |percent}}</span>
              </td>
              <td class="text-right">{{p.amount | currency}}</td>
            </tr>
          </table>
        </div>
        <div class="col-lg-6">
          <highcharts :options="chartOptions" ref="hchart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import moment from "moment";
import casheInMixin from "./data/casheInMixin";

import _ from "lodash";
export default {
  name: "cacheInPie",
  components: { highcharts: Chart },
  mixins: [baseMixin, casheInMixin],

  props: {
    list: Array
  },
  data() {
    return {
      groupedList: [],
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 300
        },

        title: {
          text: ""
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y:,.0f}</b>"
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %"
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Total Amount",
            colorByPoint: true,
            data: []
          }
        ]
      }
    };
  },
  watch: {
    total: {
      immediate: true,
      handler(value) {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      let data = _(this.list)
        .groupBy(p => p.contact._id)
        .map((value, key) => ({
          key,
          value,
          contact: value[0].contact,
          amount: value.map(o => o.amount).reduce((a, b) => a + b, 0)
        }))
        .orderBy(o => o.amount)
        .value()
        .slice(0, 10);
      this.groupedList = data;
      this.chartOptions.series[0].data = data.map(o => {
        return { name: o.contact.name, y: o.amount };
      });
    }
  },
  computed: {
    total() {
      return this.list.map(o => o.amount).reduce((a, b) => a + b, 0);
    }
  }
};
</script>

<style lang="scss" scoped></style>
