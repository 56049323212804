<template>
	<a @click="back" class="btn ">
		<i class="icon-arrow-left mr-2"></i>
		<span v-if="!nolabel">Back</span>
	</a>
</template>

<script>
export default {
	name: 'backButton',
	props: {
		nolabel: Boolean,
	},
	methods: {
		back() {
			this.$router.go(-1);
		},
	},
};
</script>
