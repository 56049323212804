
<template>
  <Fragment>
    <th :class="{'fw-600':head}" v-if="column.visible" class="nowrap total">
      <div v-if="column.isText"></div>
      <div v-if="column.isPercent">{{totals[column.field] | percent}}</div>
      <div v-if="!column.isPercent && !column.isText">{{totals[column.field] | currency}}</div>
    </th>
  </Fragment>
</template>

<script>
import numeral from "numeral";
import { Fragment } from "vue-fragment";
export default {
  name: "budgetTd",
  components: { Fragment },
  props: {
    totals: Object,
    column: Object,
    head: Boolean
  }
};
</script>

 

 