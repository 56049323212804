<template>
  <div class="back-drop" @click="$emit('close')">
    <b-popover
      v-if="isOpen"
      triggers="hover"
      offset="5"
      :show.sync="isOpen"
      ref="popover"
      custom-class="attachment-panel"
      placement="auto"
      :target="target"
      boundary-padding="50,0,0,0"
    >
      <div class="px-3 pt-1 img-container">
        <div class="clearfix mb-2">
          <button type="button" aria-label="Close" class="btn p-2 close" @click="$emit('close')">×</button>
        </div>
        <div class="row files">
          <div
            class="col"
            :class="attachments.length>1?'col-6':'col-12'"
            v-for="file in attachments"
            v-bind:key="file._id"
          >
            <div class="card file mb-3">
              <file-thumb :file="file" />
            </div>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

      

<script>
import FileThumb from "./FileThumb";

export default {
  name: "attachmentsView",
  components: { FileThumb },

  props: {
    attachments: Array,
    width: {
      type: Number,
      default: 24
    },

    height: {
      type: Number,
      default: 25
    },
    container: [String, HTMLElement]
  },
  data() {
    return {
      target: "",
      isOpen: false
    };
  },
  methods: {
    open(el) {
      this.isOpen = false;
      this.target = el;
      this.$nextTick(() => {
        this.isOpen = true;
      });
    }
  },

  computed: {
    style() {
      let width = this.addmode ? "33rem" : `${this.width}rem`;
      let height = this.addmode ? "" : `${this.height}rem`;
      return { minWidth: width, height };
    }
  }
};
</script>

<style lang="scss" scoped>
.attachment-panel {
  width: 24rem;
  height: 24rem;
}
.files {
  overflow: auto;
  width: 24rem;
  height: 24rem;
}
</style>
<style lang="scss">
.back-drop {
  background-color: transparent;
  // opacity: 0.1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
</style>
