<template>
  <list-layout :nodata="nodata" :state="state" :page="page">
    <div slot="title">{{ page.name }}</div>
    <span slot="btns">
      <export-button @click.native="exportData" />
      <print-button class="mr-5"></print-button>
    </span>
    <span slot="top-add" v-if="!nodata">
      <add-button @add="toAddPayable">Add Payable</add-button>
    </span>

    <div slot="left">
      <ReportFilters @change="change" hasItem hasAccount hasPaid />
      <payables-total :list="list" v-if="hasFilter" class="mt-3" />
    </div>
    <div slot="main">
      <PayableTable :list="list" />
      <div class="px-3 text-center fs-11" v-if="isRecent">
        <div class="text-2">
          <strong>{{ this.recentCount }}</strong> Recent payables
        </div>
        <div class="text-1">Use filter or search for more result</div>
      </div>
    </div>
    <no-data
      slot="nodata"
      :svg="page.svg"
      title="No Payable"
      subtitle="You don't have any Payables! "
    >
      <add-button @add="toAddPayable">Add Payable</add-button>
    </no-data>
  </list-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import data from "@/mixins/dataMixin.js";
import PayablesTotal from "./components/PayablesTotal";
import ReportFilters from "@/components/filters/ReportFilters";
import pages from "@/pages";
import PayableTable from "./components/PayableTable";
import { exportcsv } from "./data/export";
const entityName = "payable";
const sortByDate = (list) => _.orderBy(list, ["date"], ["desc"]);

export default {
  name: "payables",
  mixins: [baseMixin, data],
  components: {
    PayableTable,
    PayablesTotal,
    ReportFilters,
  },
  data() {
    return {
      page: pages.payables,
      list: [],
      hasFilter: false,
      recentCount: 5,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadRecent(entityName, { max: this.recentCount });
      this.loaded();
      this.populate();
    },

    populate() {
      this.list = sortByDate(this.payables);
    },

    async change(params) {
      this.hasFilter = Object.keys(params).length;
      if (!this.hasFilter) return this.populate();
      this.loading();
      var result = await this.filterData(entityName, params, true);
      this.list = sortByDate(result);
      this.loaded();
    },

    toAddPayable() {
      this.$router.push(`/payable/new/edit`);
    },
    exportData() {
      exportcsv(this.list);
    },
  },
  computed: {
    isRecent() {
      return this.list.length <= this.recentCount && !this.hasFilter;
    },
    nodata() {
      return this.ready && !this.payables.length;
    },
    ...mapGetters(["payables"]),
  },
};
</script>
