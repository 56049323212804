<template>

<i  :class="className"></i>
    
</template>
<script>
export default {
    name: "checkCircle",
    props: {
        checked: [Boolean, String, Number]
    },
    computed: {
        className() {
            return this.checked
                ? "la la-check-circle-o text-success "
                : "la la-circle-o text-muted ";
        }
    }
};
</script>

<style lang="scss" scoped>
i {
    font-size: 1.6rem;
}
</style>


