 <template>
  <card-layout :state="state" :nodata="nodata" title="Budgeting and Project Summary" nofooter back>
    <div class="card-body p-4" slot="data" v-if="budgeting">
      <ContractTypeSet
        class="form-group"
        v-if="!contractType && ready"
        :budgeting="budgeting"
        @done="onTypeSet"
      />
    </div>

    <NoBudgeting :budgeting="budgeting" :nodata="nodata" />
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import accessMixin from "@/mixins/accessMixin";
import ContractTypeSet from "@/views/contract/components/ContractTypeSet.vue";
import budgetingMixin from "./data/budgetingMixin";
import NoBudgeting from "./components/NoBudgeting";
import _ from "lodash";

export default {
  name: "budgetSumamry",
  mixins: [baseMixin, dataMixin, routeMixin, budgetingMixin, accessMixin],
  components: {
    NoBudgeting,
    ContractTypeSet
  },

  mounted() {
    this.load();
  },

  methods: {
    async load(force) {
      this.loading();
      await this.loadBudgetingIfNot();
      this.loaded();
    },
    onTypeSet() {
      this.$router.push(`/project/${this.project_id}/budgeting`);
    }
  },
  computed: {
    nodata() {
      return this.state.loaded && !this.budgeting;
    },
    budgeting() {
      return this.budgetings.find(o => o.projectId == this.project_id);
    },
    projectId() {
      return this.project_id;
    },
    ...mapGetters(["budgetings"])
  }
};
</script>