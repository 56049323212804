export const downloadAsCSV = function({ filename, data, title }) {
	let link;

	let csv = createCSVData(data);
	if (csv == null) return;
	if (title) csv = title + " \n" + csv;
	filename = filename || "export.csv";

	if (!csv.match(/^data:text\/csv/i)) {
		csv = "data:text/csv;charset=utf-8," + csv;
	}

	let encodedUri = encodeURI(csv);

	link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", filename);

	link.click();
};

// function convertArrayOfObjectsToCSV(args) {
// 	var result, ctr, keys, columnDelimiter, lineDelimiter, data;

// 	data = args.data || null;
// 	if (data == null || !data.length) {
// 		return null;
// 	}

// 	columnDelimiter = args.columnDelimiter || ",";
// 	lineDelimiter = args.lineDelimiter || "\n";

// 	keys = Object.keys(data[0]);

// 	result = "";
// 	result += keys.join(columnDelimiter);
// 	result += lineDelimiter;

// 	data.forEach(function(item) {
// 		ctr = 0;
// 		keys.forEach(function(key) {
// 			if (ctr > 0) result += columnDelimiter;

// 			result += item[key];
// 			ctr++;
// 		});
// 		result += lineDelimiter;
// 	});

// 	return result;
// }

function getValues(data) {
	return Object.keys(data).map((key) => data[key]);
}

function getKeys(data) {
	return Object.keys(data);
}

function getEntries(data) {
	return Object.keys(data).map((key) => [key, data[key]]);
}
//Github export-from-json
export function createTableMap(data) {
	return data.map(getEntries).reduce(
		(tMap, rowKVs, rowIndex) =>
			rowKVs.reduce((map, kv) => {
				const key = kv[0];
				const value = kv[1];
				const columnValues = map[key] || Array.from({ length: data.length }).map((_) => "");
				columnValues[rowIndex] = (typeof value !== "string" ? JSON.stringify(value) : value) || "";
				map[key] = columnValues;

				return map;
			}, tMap),
		Object.create(null)
	);
}

export function createCSVData(data, delimiter = ",") {
	const tableMap = createTableMap(data);
	const head = getKeys(tableMap).join(delimiter) + "\r\n";
	const columns = getValues(tableMap).map((column) => column.map((value) => `"${value.replace(/\"/g, '""')}"`));
	const rows = columns.reduce((mergedColumn, column) =>
		mergedColumn.map((value, rowIndex) => `${value}${delimiter}${column[rowIndex]}`)
	);

	return head + rows.join("\r\n");
}
