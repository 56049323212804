<template>
  <div class="d-flex align-items-start" v-if="project">
    <svg-icon name="folder" class="mr-3" />
    <span class="d-block" v-if="noNavigate">
      <span>{{ project.name || 'unknown' }}</span>
    </span>
    <a class="d-block" :href="`#/project/${project._id}`" v-else>
      <span>{{ project.name || 'unknown' }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "project-item",
  props: {
    project: Object,
    noNavigate: Boolean
  }
};
</script>

<style lang="scss" scoped>
.project-avatar {
  margin-top: -0.2rem;
}
.project-icon {
  font-size: 1.2rem;
}
</style>
