<template>
  <!-- :style="tmbStyle" -->
  <div class="file-tmb bg-white">
    <a @click="open(file)" v-if="isImage(file.name)  && src" class="fill">
      <img
        :style="imgStyle"
        ref="img"
        :src="src"
        class="rounded img-fluid img-thumb"
        :class="{ 'in-progress': isUpload }"
        alt="Responsive image"
      />
    </a>

    <div v-else class="bg-white h-100 p-3">
      <a @click="open(file)" class="c-pointer d-flex align-items-center">
        <file-type :name="file.name" class="mr-2" />
        <div class="text-truncate mr-3">{{file.name}}</div>
      </a>
    </div>
    <div
      v-if="file.sharedCustomer"
      class="bg-trans position-absolute share-icon rounded p-1 py-1"
      v-tooltip="'Shared With Customer'"
    >
      <i class="icon-share"></i>
    </div>
    <div v-if="hasAccess && !readonly">
      <div class="btn-option" v-if="menuVisible && !delMode">
        <b-dropdown variant="link" toggle-class="text-decoration-none  " no-caret v-if="!isUpload">
          <template v-slot:button-content>
            <i class="icon-options-vertical"></i>
          </template>

          <b-dropdown-item href="#" v-if="hasNote">
            <i class="icon-speech mr-2"></i>
            Edit Note
          </b-dropdown-item>
          <b-dropdown-divider v-if="hasNote" />

          <b-dropdown-item v-if="shareable"></b-dropdown-item>
          <div class="dropdown-item" v-if="shareable">
            <button-share :entity="file" checkbox />
          </div>
          <b-dropdown-divider />
          <b-dropdown-item @click="delMode=true">
            <i class="icon-trash mr-2 text-danger"></i>
            <span class="text-danger">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <del-doc
      :file="file"
      class="del"
      @cancel="delMode=false"
      v-if="delMode"
      @del="$emit('del',file)"
    />
  </div>
</template>
<script>
import { isImage, isPdf } from "./helpers/file-helper";
import { fileMixin } from "./mixins/fileMixin.js";
import accessMixin from "@/mixins/accessMixin";
import DelDoc from "./DelDoc";
export default {
  name: "fileThumb",
  components: { DelDoc },
  mixins: [fileMixin, accessMixin],
  props: {
    file: Object,
    size: {
      type: Number
      // default: 120
    },
    shareable: Boolean,
    hasNote: Boolean,
    readonly: Boolean
  },
  data() {
    return { menuVisible: true, delMode: false };
  },
  methods: {
    open(file) {
      window.open(file.url, "_blank");
    }
  },
  computed: {
    isImage() {
      return name => isImage(name);
    },
    isPdf() {
      return isPdf(this.file.name);
    },
    src() {
      return this.file.img ? this.file.img : this.file.url;
    },
    tmbStyle() {
      return {
        // height: `${this.size}px `,
        maxHeight: `${this.size}px `
        // maxWidth: `${this.size}px `
      };
    },
    isUpload() {
      return this.file.img;
    },
    imgStyle() {
      return {
        // height: `${this.size}px `,
        // maxWidth: `${this.size}px `,
        maxHeight: `${this.size}px `
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.img-thumb {
  display: block;
  // max-width: 230px;
  // max-height: 95px;
  width: auto;
  height: auto;
}
.btn-option {
  position: absolute;
  top: 0rem;
  right: 0.25rem;
  //display: none;
}
.file-tmb:hover {
  .btn-option {
    display: inline-block;
  }
}
.del {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.share-icon {
  top: 0;
  left: 1rem;
  color: rgba(255, 255, 255, 0.858);
}
</style>
