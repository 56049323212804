import { SET_SELECTED } from "./types";
const state = {
	selecteds: {
		projectId: "",
		contactId: "",
	},
};
const getters = {
	selecteds: (state) => state.selecteds,
	selectedByKey: (state) => (key) => state.selecteds[key],
};
const actions = {};
const mutations = {
	[SET_SELECTED]: (state, { key, value }) => {
		state.selecteds[key] = value;
	},
};

export default {
	getters,
	state,
	actions,
	mutations,
};
