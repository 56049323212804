<template>
  <div>
    <div class="list-group">
      <div class="list-group list-group-flush">
        <div
          v-for="person in contact.people"
          :key="person._id"
          class="list-group-item bg-2 d-flex align-items-center rounded-10 border shadow-sm mb-2"
        >
          <div>
            <avatar :username="person.name || '-'" :src="person.imageUrl" class="mr-3" />
          </div>
          <div class="d-flex justify-content-between w-100">
            <div>
              <div class="fs-11">{{person.name}}</div>
              <div class="text-2">{{person.phone || '&nbsp;'}}</div>
              <div class="text-2">{{person.email || '&nbsp;'}}</div>
            </div>
            <div>
              <a @click="edit( $event.target,person)">
                <i class="icon icon-pencil"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="list-group-item c-pointer d-flex align-items-center list-group-item-action rounded-10 border-dash mb-3"
      >
        <i class="icon icon-user fs-2 text-primary"></i>
        <add-button @click.native="add($event.target)" link>Add Person</add-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6"></div>

      <div class="col-lg-6 contact-edit" v-if="editMode">
        <PersonEdit
          :person="selectedForEdit"
          :company="company"
          @done="hideEdit"
          @cancel="cancelEdit"
          class
        />
      </div>
    </div>
    <PersonEdit ref="personEdit" :contact="contact" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import routeMixin from "@/mixins/routeMixin";
import PersonEdit from "./PersonEdit";

export default {
  name: "people",
  mixins: [baseMixin],
  props: {
    contact: Object
  },
  components: { PersonEdit },
  data() {
    return {
      selectedForEdit: ""
    };
  },

  mounted() {},

  methods: {
    edit(el, person) {
      this.$refs.personEdit.open(el, person);
    },
    add(el) {
      this.edit(el, {
        name: "",
        email: "",
        phone: ""
      });
    },
    doneEdit() {
      this.hideEdit();
    },
    hideEdit() {
      this.selectedForEdit = "";
    },
    cancelEdit() {
      this.selectedForEdit = "";
    }
  },

  computed: {
    editMode() {
      return !!this.selectedForEdit;
    },
    nodata() {
      return !this.contact.people.length;
    }
  }
};
</script>

<style lang="css" scoped>
.contact-edit {
  margin-top: -2rem;
  margin-left: -1rem;
  padding-left: 0;
  padding-right: 0;
}
</style>
