<template>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <a class="input-group-text bg-white" @click="toggleSelect" ref="code">
        {{ value.code }}
        <i class="icon-arrow-down ml-2 text-1"></i>
      </a>
    </div>
    <input
      class="form-control"
      :class="{ 'is-invalid': !validNumber && validated }"
      @change="onChangeNumber($event.target.value)"
      v-bind:value="value.number"
      v-on:input="onNumberInput($event.target.value)"
      :placeholder="mask"
      type="text"
      name="number"
      ref="number"
      autocomplete="tel"
    />
    <CountrySelect ref="countrySelect" @change="onChangeCode" />
  </div>
</template>

<script>
import format from "@/helpers/format";
import CountrySelect from "./CountrySelect";
import dataMixin from "@/mixins/dataMixin.js";

import { mapGetters } from "vuex";
export default {
  name: "phoneCountryInput",
  mixins: [dataMixin],
  components: { CountrySelect },
  props: {
    value: Object,
    validated: Boolean,
    mask: { type: String, default: "###-###-####" },
  },

  data() {
    return {
      countryCode: "+1",
      isSelectOpen: false,
    };
  },
  mounted() {
    this.loadCountries();
  },
  methods: {
    async loadCountries() {
      await this.loadNexo("countries", "");
    },
    toggleSelect() {
      this.isSelectOpen = true;
      this.$refs.countrySelect.open(this.$refs.code);
    },
    onNumberInput(number) {
      this.onChangeNumber(number);
    },
    onCodeChange(code) {
      this.onChangeCode(code);
    },
    onChangeNumber(number) {
      const fullNumber = { ...this.value, number };
      this.$emit("input", fullNumber);
      this.$emit("change", fullNumber);
    },
    onChangeCode(code) {
      const fullNumber = { ...this.value, code: `+${code}` };
      this.$emit("input", fullNumber);
      this.$emit("change", fullNumber);
    },
    focus() {
      this.$refs.number.focus();
    },
  },
  computed: {
    validNumber() {
      return (
        this.value &&
        this.value.number &&
        this.value.number.length > 8 &&
        this.value.number.length <= 10
      );
    },
    ...mapGetters(["clientSettings", "countries"]),
  },
};
</script>

<style lang="css" scoped>
.phone-link {
  color: #555;
}
</style>
