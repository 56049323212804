import { TOAST, TOAST_ERROR, TOAST_SUCCESS } from "./types";
const state = {
	toast: {
		message: "",
		type: "error",
		title: "Some Error"
	}
};
const getters = {
	toast: state => state.toast
};
const actions = {};
const mutations = {
	[TOAST]: (state, { message, type, title }) => {
		state.toast.message = "";
		state.toast.type = "";
		state.toast.title = "";

		state.toast.message = message;
		state.toast.type = type;
		state.toast.title = title;
	},
	[TOAST_ERROR]: (state, response, { entityName, action } = {}) => {
		let message = "";
		if (!response) {
			return;
			// message = `something went wrong! with ${action || ''} ${entityName || ''}`;
		} else {
			if (response.data) {
				message = response.data.message;
			}
			if (!message) {
				message = response.data;
			}
		}

		state.toast.message = message;
		state.toast.type = "error";
		state.toast.title = "Error";
	},
	[TOAST_SUCCESS]: (state, message) => {
		state.toast.message = message;
		state.toast.type = "success";
		state.toast.title = "";
	}
};

export default {
	getters,
	state,
	actions,
	mutations
};
