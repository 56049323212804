<template>
  <card-layout nofooter back :state="state" class="bg-1">
    <div slot="title" class="d-flex">Progress Report</div>
    <div slot="header">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
    <div class="card-body" slot="data">
      <section v-if="progressReport">
        <div>
          <div class="d-flex justify-content-between form-group">
            <div>
              <label class="mr-2">Project:</label>
              <div class="fs-12">{{progressReport.project.name}}</div>
            </div>

            <div>
              <label class="text-2">Report Date:</label>
              <date-input v-model="progressReport.date" />
            </div>
          </div>

          <div v-for="item in progressReport.items" :key="item._id">
            <div class="form-group">
              <div class="bg-trans px-2">
                <span class="text-2">{{item.date | day}}</span>
              </div>
              <div class="row">
                <div :class="item.attachments.length?'col-lg-8':'col-lg-12'">
                  <div>
                    <div class="d-flex border-bottom justify-content-between">
                      <div class>
                        <div v-if="item.contact && item.contact.name">
                          <span class="text-2 mr-2">Contractor:</span>
                          {{item.contact.name}}
                        </div>
                        <div v-if="item.item && item.item.name">
                          <span class="text-2 mr-2">Item:</span>
                          <span>{{item.item.name}}</span>
                        </div>
                      </div>

                      <div>
                        <button class="btn d-flex align-items-center">
                          <i class="icon-close mr-2"></i>
                          <small>Remove</small>
                        </button>
                      </div>
                    </div>

                    <textarea rows="5" v-model="item.note" class="form-control"></textarea>
                  </div>
                </div>
                <div :class="item.attachments.length?'col-lg-4':''"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="p-3 form-group border-dash border">
          <add-button link>Add new item</add-button>
        </div>-->
        <save-cancel @save="save" @cancel="cancel" class="border-top pt-3" />
      </section>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import { required, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
const entityName = "progressReport";
export default {
  name: "progressReportEdit",
  mixins: [baseMixin, dataMixin, routeMixin, validationMixin],
  data() {
    return {
      validated: false,
      progressReport: { project: {} }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.progressReport_id == "new") {
        this.progressReport = this.newProgressReport();
        this.logs = [this.newLog()];
        this.loaded();
      } else {
        this.load();
      }
    },
    async load() {
      await this.loadById("progressReport", this.progressReport_id);
      this.progressReport = JSON.parse(
        JSON.stringify(this.progressReport_store)
      );
      this.loaded();
    },
    newProgressReport() {
      const project = this.projectById(this.project_id) || {};
      return {
        date: new Date(),
        project: {
          _id: project._id,
          name: project.name
        }
      };
    },
    newLog() {
      return {
        item: {},
        contact: {},
        attachments: [],
        note: ""
      };
    },
    addAnother() {
      this.logs.push(this.newLog());
    },

    async save() {
      this.validated = true;
      if (this.$v.$invalid) return;
      try {
        //new daily log
        let model = { ...this.progressReport };
        if (this.isnew) {
          await this.add(entityName, model);
          this.$router.go(-1);
        } else {
          await this.update(entityName, model);
          this.$router.go(-1);
        }
      } catch (error) {}
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  validations: {
    logs: {
      $each: {
        note: { required, maxLength: maxLength(500) }
      }
    }
  },
  computed: {
    isnew() {
      return !this.progressReport._id;
    },
    progressReport_id() {
      return this.entity_id(entityName);
    },
    progressReport_store() {
      return (
        this.progressReports.find(o => o._id == this.progressReport_id) || {}
      );
    },
    showAddAnother() {
      return this.isnew && !this.$v.$invalid;
    },
    ...mapGetters(["user", "projectById", "progressReports"])
  }
};
</script>
