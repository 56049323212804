<template>
  <section>
    <div class="bg-1 p-2 d-flex justify-content-between">
      <div>{{ log.date |calendar}}, {{ log.date |day}}</div>
      <button-edit @edit="edit" small />
    </div>
    <div class="p-3">
      <div class="row">
        <div :class="hasAttachment(log)?'col-lg-7':'col-lg-12'">
          <WeatherView :weather="log.weather" class="form-group" />

          <div class="d-flex justify-content-between form-group">
            <div class="fs-12 d-flex align-items-center" v-if="log.contact && log.contact.name">
              <contact-avatar :contact="log.contact" class="mr-2" :size="30" />
              {{ log.contact.name }}
            </div>
            <div v-if="log.item" class="text-truncate">{{ log.item.name }}</div>
          </div>

          <div class="form-group" v-if="log.startTime || log.endTime">
            <div class="d-flex">
              <div class="pr-3">
                <div>{{log.startTime}}</div>
                <label for class="small">Start Time</label>
              </div>
              <div class="px-3">
                <div>{{log.endTime}}</div>
                <label for class="small">End Time</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <row label="Number Of people on site" :col="6">{{log.noOfPeople}}</row>
          </div>

          <div>
            <last-modified :entity="log" simple class="mb-2" />

            <p class="form-control value mb-0 pre-wrap">{{ log.note }}</p>
          </div>
        </div>

        <div class="col-lg-5" v-if="hasAttachment(log)">
          <!-- <div class="d-flex justify-content-end">
            <button-edit @edit="edit" small />  
          </div>-->
          <entity-attachments entityName="dailyLog" :entity="log" class="px-2" readonly />
        </div>
      </div>
    </div>
    <div class="px-3 py-2 bg-light border-top">
      <div class="d-flex justify-content-between">
        <span class="text-2 small" v-if="log.inReport">Added to Progress Report</span>
        <button class="btn btn-sm text-primary" @click="addToProgress" v-else>
          <i class="icon-plus mr-1"></i>
          Add to Progress Report
        </button>
        <button-share :entity="log" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin.js";
import WeatherView from "./WeatherView";
export default {
  name: "logView",
  components: { WeatherView },
  mixins: [dataMixin],
  props: {
    log: Object
  },
  data() {
    return {
      adding: false
    };
  },
  methods: {
    edit() {
      this.$router.push(`dailyLog/${this.log._id}/edit`);
    },
    addToProgress() {
      try {
        if (this.adding) return;
        this.adding = true;
        this.update("progressReport", {}, `append/${this.log._id}`);
      } catch (error) {
        console.log("error :", error);
      } finally {
        this.adding = false;
      }
    }
  },
  computed: {
    hasAttachment() {
      return log => log.attachments && log.attachments.length;
    },
    ...mapGetters(["user"])
  }
};
</script>
