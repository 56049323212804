<template>
  <inline-edit @edit="edit" @save="save" ref="inlineEdit" :skey="skey">
    <div class="w-100">
      <section class="form-control value d-flex justify-content-between mb-1">
        <div class="mr-3 fs-11">{{title}}</div>
        <div class="fw-600">{{data.prefix}}{{data.number}}{{data.suffix}}</div>
      </section>
    </div>
    <div slot="edit">
      <section>
        <label>{{title}}</label>
        <div class="d-flex justify-content-end pl-5">
          <div class="w-50">
            <label>Prefix</label>
            <input
              class="form-control fw-600 fs-12 text-right text-primary"
              placeholder="suffix"
              type="text"
              v-model="model.prefix"
            />
          </div>
          <div class="mx-2">
            <label for>Last Number</label>
            <input
              class="form-control fw-600 fs-12 text-primary"
              type="text"
              v-model="model.number"
            />
          </div>
          <div class="w-50">
            <label>Suffix</label>
            <input
              class="form-control fw-600 fs-12 text-primary"
              placeholder="suffix"
              type="text"
              v-model="model.suffix"
            />
          </div>
        </div>
      </section>
    </div>
  </inline-edit>
</template>
<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import { numeric, required, between } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
  name: "lastNumber",
  mixins: [validationMixin, dataMixin, baseMixin],
  props: {
    data: Object,
    title: String,
    field: String
  },
  data() {
    return {
      suffix: "",
      prefix: "",
      model: {
        number: 10001,
        suffix: "",
        prefix: ""
      },
      validated: true
    };
  },
  methods: {
    edit() {
      this.model = JSON.parse(JSON.stringify(this.data));
    },
    async save() {
      try {
        const data = { [this.field]: this.model };
        await this.update(
          "lastNumber",
          data,
          "update",
          this.lastNumber._id,
          this.skey
        );
        this.$refs.inlineEdit.close();
      } catch (error) {}
    }
  },
  validations: {
    model: {
      number: {
        required
      }
    }
  },
  computed: {
    skey() {
      return `last${this.title}`;
    },
    ...mapGetters(["clientSetting", "lastNumber"])
  }
};
</script>
