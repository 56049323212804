<template>
  <Fragment>
    <th scope="col" :class="width" v-if="column.visible">{{column.desc}}</th>
  </Fragment>
</template>

<script>
import numeral from "numeral";
import { Fragment } from "vue-fragment";
export default {
  name: "budgetTh",
  components: { Fragment },
  props: {
    column: Object
  },
  computed: {
    width() {
      return !this.column.width ? "w-6" : `w-${this.column.width}`;
    }
  }
};
</script>