import moment from "moment";

import { downloadAsCSV } from "@/helpers/csv";

export const exportcsv = (items) => {
  let today = moment().format("MMM-D-YYYY");
  let data = getExportData(items);
  downloadAsCSV({
    filename: "Project Summary" + "_" + today + ".csv",
    data,
    title: "Items,Date: " + today,
  });
};

const getExportData = (items) => {
  return items.map((i) => {
    return {
      No: i.code,
      Title: i.name,
      Initial_Budget: i.initialBudget,
      Current_Budget: i.budget,
      Cost_to_Date: i.costToDate,
      Cost_Over_Budget: i.costOverBudget,
      Cost_To_Complete: i.costToComplete,
      Cost_Per_SQFT: i.costPerSqf,
      Budget_Per_SQFT: i.budgetPerSqf,
      Note: i.note,
    };
  });
};
