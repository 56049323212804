<template>
  <div class="bg-1">
    <div class="border-bottom p-3 px-4 fs-11">Billing history</div>
    <div class="p-3 pb-3">
      <table class="table table-hover thead-md pad-md">
        <thead>
          <tr>
            <th scope="col" class="w-7">Date</th>
            <th scope="col" class="w-6">Amount</th>
            <th scope="col">Notes</th>
            <th scope="col">Receipt</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in nexoAccount.history" v-bind:key="p.id">
            <td class="w-4">{{ p.date | day }}</td>
            <td>{{ p.subscription.amount | currency }}</td>
            <td>{{ p.description }}</td>
            <td>
              <a :href="p.receipt_url" target="_blank">
                <span class="d-flex align-items-center">
                  <i class="icon-doc mr-1"></i>
                  Receipt
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import moment, { months } from "moment";
export default {
  name: "Subscribe",
  mixins: [dataMixin, baseMixin],
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      this.loadPerClient("nexoAccount");
      this.loaded();
    }
  },
  computed: {
    ...mapGetters(["nexoAccount"])
  }
};
</script>