<template>
  <side-layout class="settings">
    <span slot="title">Settings</span>

    <div slot="side" class="side text-center">
      <div class="d-flex justify-content-center mt-3">
        <ClientLogo class="bg-light rounded-5" />
      </div>

      <div class="text-center form-group">
        <div class="fs-15">{{clientName }}</div>
        <div class="text-2 fs-12">{{client.description }}</div>
      </div>
      <div class="list-group ml-3 mt-4 list-group-flush light">
        <router-link
          v-for="item in navs"
          :key="item.name"
          :to="item.path"
          :class="{ active: isActive(item) }"
          class="list-group-item d-flex border-0 align-items-start justify-content-between text-left"
        >
          <div class="fs-11 d-flex align-items-center">
            <i :class="item.icon" class="icon fs-15 mr-4"></i>
            {{ item.desc }}
          </div>
        </router-link>
      </div>
    </div>
  </side-layout>
</template>

<script>
import { mapGetters } from "vuex";
import pages from "@/pages";
import navMixin from "@/mixins/navMixin";
import ClientLogo from "./client-profile/ClientLogo";
import { filterRoute } from "@/mixins/accessMixin";
export default {
  name: "settings",
  mixins: [navMixin],
  components: { ClientLogo },
  data() {
    return {
      navs: []
    };
  },
  mounted() {
    this.setRoutes();
  },
  methods: {
    setRoutes() {
      const {
        invite,
        userAccess,
        clientprofile,
        clientSetting,
        templates,
        itemMaster,
        accounts,
        subscription
      } = pages.settings.nav;

      this.navs = this.myrole
        ? [
            userAccess,
            clientprofile,
            clientSetting,
            itemMaster,
            accounts,
            subscription
          ].filter(o => filterRoute(o, this.myrole))
        : [];
    }
  },
  computed: {
    ...mapGetters([
      "clientSetting",
      "clientName",
      "clientProfile",
      "logoUrl",
      "client",
      "myrole"
    ])
  }
};
</script>
 <style lang="scss" scoped>
</style>