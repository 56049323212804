<template>
  <card-layout :state="state" :nodata="nodata" title="Scheduling" nofooter back>
    <div slot="header" class="d-flex">
      <!-- <button-preview @click="preview" :busy="creaingPdf" /> -->
      <button class="btn" v-tooltip="'Full Screen'" @click="toggleFullScreen">
        <span v-if="isFullScreen">
          <i class="icon-size-actual mr-2"></i>
          Exit Full Screen
        </span>

        <span v-else>
          <i class="icon-size-fullscreen mr-2"></i>
          Full Screen
        </span>
      </button>
      <export-button @click.native="exportData" v-if="!nodata" />
      <save-cancel @save="save" @cancel="cancel" v-if="hasChanges" />
      <div v-if="!hasChanges   && !nodata">
        <button
          class="btn btn-success ml-3"
          @click="showSetup"
          :disabled="!hasAccess"
          v-if="!hideAction"
        >
          <i class="icon-list px-2"></i>
          Setup
        </button>
      </div>
    </div>
    <div class="card-body" slot="data">
      <div class="bg-1 p-3 d-flex justify-content-between align-items-start">
        <div class="d-flex">
          <div>
            <form-select
              :options="projectSchedulings"
              v-model="selectedScheduleId"
              @change="switchSchedule"
              id="_id"
              name="title"
            />
          </div>
          <add-button link v-tooltip="'Add another schedule'" @add="addSchedule" />
          <button-share :entity="scheduling" />
        </div>

        <button @click="sortByDate()" class="btn btn-outline-primary">Sort By Date</button>
        <toggle-buttons
          :items="scales"
          @select="changeScale"
          :value="ganttScale"
          v-if="viewMode=='g'"
        />

        <toggle-buttons :items="viewModes" @select="switchView" :value="viewMode" />
      </div>
      <div>
        <ScheduleGantt
          :scheduling="scheduling"
          v-if="viewMode=='g'"
          ref="ganttChart"
          @update="onUpdate"
          @ganttUpdate="ganttUpdate"
        />
        <ScheduleList :scheduling="scheduling" v-if="viewMode=='l'" @update="onUpdate" />
        <ScheduleCalendar :scheduling="scheduling" v-if="viewMode=='c'" />
      </div>
    </div>

    <div class="text-center p-5" v-if="nodata">
      <h3 class="mb-3 text-muted text-capitalize">No Scheduling</h3>
      <p class="text-center text-muted">
        You don't have task for this project yet
        <br />You can copy from previous projects or setup a new one
      </p>
      <div class="row" v-if="!hideAction">
        <div class="col-lg-4"></div>
        <div class="col-lg-4">
          <div class="mt-4">
            <button class="btn btn-success w-100" @click="showSetup">
              <i class="la la-plus"></i>
              Create Schedule
            </button>
          </div>

          <div class="mt-3">
            <!-- <button class="btn btn-outline-primary w-100" @click="showCopyfrom">
              <i class="la la-plus"></i>
              Copy from Exiting Projects
            </button>-->
          </div>
        </div>
      </div>
    </div>

    <ItemSetup ref="taskSetup" :projectId="project_id" type="scheduling" @done="onSetupDone" />
  </card-layout>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ItemSetup from "@/views/budgeting/ItemSetup.vue";
import scheduleMixin from "./data/scheduleMixin";
import ScheduleGantt from "./ScheduleGantt";
import ScheduleList from "./ScheduleList";
import ScheduleCalendar from "./ScheduleCalendar";
import accessMixin from "@/mixins/accessMixin";
import { exportcsv } from "./data/export";

const entityName = "scheduling";
export default {
  name: "scheduletig",
  components: { ItemSetup, ScheduleGantt, ScheduleList, ScheduleCalendar },
  mixins: [baseMixin, dataMixin, routeMixin, scheduleMixin, accessMixin],
  data() {
    return {
      setupModelShow: false,
      copyFromModelShow: false,
      selectedScheduleId: "",
      scheduling: {},
      viewMode: "l",
      viewModes: [
        { text: "List View", code: "l" },
        { text: "Calendar", code: "c" },
        { text: "Gantt", code: "g" }
      ],
      ganttScale: "Week",
      scales: [
        { text: "Day", code: "Day" },
        { text: "Week", code: "Week" },
        { text: "Month", code: "Month" }
      ],
      saving: false
    };
  },
  mounted() {
    this.viewMode = this.$route.query.view || "l";

    this.load();
  },
  methods: {
    async load(force) {
      this.loading();
      await this.loadByAction(entityName, `project/${this.project_id}`);
      await this.loadPerClient("itemMaster");
      if (this.projectSchedulings.length) {
        this.selectedScheduleId = this.projectSchedulings[0]._id;
        this.init(this.selectedScheduleId);
      }

      this.loaded();
    },
    // async preview() {
    //   this.creaingPdf = true;
    //   try {
    //     await this.download("scheduling", `pdf/${this.selectedScheduleId}`);
    //   } catch (error) {
    //   } finally {
    //     this.creaingPdf = false;
    //   }
    // },
    switchSchedule(id) {
      this.init(id);
    },
    init(id) {
      if (!id) id = this.selectedScheduleId;
      let selected = this.projectSchedulings.find(o => o._id == id);
      let scheduling = JSON.parse(JSON.stringify(selected));
      let tasks = scheduling.tasks;
      tasks.map(o => ((o.id = o._id), (o.__u = "")));
      this.scheduling = scheduling;

      // this.viewMode = mode;

      if (this.viewMode == "g") {
        setTimeout(() => {
          this.$refs.ganttChart.refresh();
        }, 500);
      }
    },
    sortByDate() {
      this.sortTasksByDate();
      if (this.viewMode == "g") {
        this.$refs.ganttChart.refresh();
      }
      this.save(true);
    },

    switchView(mode) {
      this.viewMode = mode;
      this.setRouteQuery();
    },
    changeScale(scale) {
      this.ganttScale = scale;
      if (this.$refs.ganttChart) this.$refs.ganttChart.scale(this.ganttScale);
    },
    cancel() {
      this.init();
      this.$refs.ganttChart.refresh();
    },
    showSetup() {
      let scheduling = this.selectedScheduleId ? this.scheduling : "";
      this.openSetup({ scheduling });
    },
    addSchedule() {
      this.openSetup({});
    },
    openSetup({ scheduling }) {
      let title =
        scheduling && scheduling._id
          ? scheduling.title
          : `Schedule ${this.projectSchedulings.length + 1}`;
      this.$refs.taskSetup.setupScheule({
        scheduling,
        title
      });
    },
    showCopyfrom() {
      this.$refs.copyFromProject.open();
    },
    edit(el, task) {
      this.$refs.taskEdit.open(el, task);
    },
    onUpdate(task) {
      this.change(task);
      this.save();
    },
    ganttUpdate(task) {
      console.log("task.name :", task.name);
      let s = this.scheduling.tasks.find(o => o._id == task._id);
      console.log("s==task :", s == task);
      this.change(task);
    },
    change(task) {
      task.__u = this.checkChanges(task);
    },
    checkChanges(task) {
      const original = this.scheduling_store.tasks.find(o => o._id == task._id);
      return JSON.stringify(original) != JSON.stringify(task);
    },

    async save(sort) {
      if (this.saving) return;
      this.saving = true;
      // const tasks = this.scheduling.tasks.filter(o => o.__u);
      const tasks = this.scheduling.tasks.filter(
        t =>
          JSON.stringify(t) !=
          JSON.stringify(this.scheduling_store.tasks.find(o => o._id == t._id))
      );

      try {
        let q = sort ? `?sort=start` : "";

        await this.update(
          entityName,
          { tasks },
          `tasks/update/${this.scheduling._id}${q}`
        );
        this.init();
      } catch (error) {
        console.log("error", error);
      } finally {
        this.saving = false;
      }
    },
    onSetupDone(entity) {
      this.selectedScheduleId = entity._id;
      this.init();
    },
    exportData() {
      exportcsv(this.scheduling.tasks);
    },
    toggleFullScreen() {
      this.$nextTick(() => {
        this.toFullScreen(!this.isFullScreen);
      });
    },
    toFullScreen(value) {
      this.$store.commit("SET_FULL_SCREEN", {
        scheduling: value
      });
    },
    setRouteQuery() {
      this.$router.replace({ query: { view: this.viewMode } });
    }
  },
  computed: {
    hasChanges() {
      return this.scheduling && this.scheduling.tasks
        ? this.scheduling.tasks.some(o => o.__u)
        : false;
    },
    nodata() {
      return this.state.loaded && !this.projectSchedulings.length;
    },
    scheduling_store() {
      return this.schedulings.find(o => o._id == this.selectedScheduleId) || {};
    },
    projectSchedulings() {
      return this.schedulings.filter(o => o.projectId == this.project_id) || [];
    },
    isFullScreen() {
      return this.fullscreen[entityName];
    },

    ...mapGetters(["categories", "items", "schedulings", "fullscreen"])
  }
};
</script>

<style scoped>
.table tbody tr th,
.table tbody tr td {
  padding: 0.4em;
}

.table tbody tr th {
  font-weight: 500;
}

.table tbody + tbody {
  border-top: 2px solid #bfbfbf;
}
.schedule-input {
  max-width: 7rem !important;
}
.h-100- {
  min-height: calc(100vh - 18.5rem);
}
</style>
