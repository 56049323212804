import moment from "moment";

import { downloadAsCSV } from "@/helpers/csv";
import { strProgress } from "./scheduleMixin";

export const exportcsv = (list) => {
  let today = moment().format("MMM-D-YYYY");
  let data = getExportData(list);
  downloadAsCSV({
    filename: "Schedule" + "_" + today + ".csv",
    data,
    title: "Schedule,Date: " + today,
  });
};

const getExportData = (tasks) => {
  // try{ s.contacts[0].getFields(objArray, "name") } catch{ || ""
  const items = tasks.map((s) => ({
    _id: s._id,
    Task: tasks.indexOf(s) + 1,
    Name: s.name,
    Start: moment(s.start).format("YYYY-MM-DD"),
    End: moment(s.end).format("YYYY-MM-DD"),
    Dependencies: s.dependencies, // dependencyNames(s.dependencies),
    Progress: strProgress(s.progress),
    Subcontractors:
      typeof s.contacts[0] != "undefined" ? s.contacts[0].name : "",
    Note: s.note.replace(/#/g, "") || "",
  }));

  for (let item of items) {
    const ids = item.Dependencies || [];
    item.Dependencies = ids
      .map((id) => {
        const task = items.find((o) => o._id == id);
        return task ? task.Task : "";
      })
      .join(", ");
  }

  for (const item of items) {
    delete item._id;
  }

  return items;
};
