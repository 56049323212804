<template>
  <div class="px-3">
    <section class="form-group">
      <label for>Item Category</label>
      <select
        id="type"
        class="form-control"
        v-model="item.categoryId"
        :class="{ 'is-invalid': validated && !item.categoryId }"
      >
        <option v-for="item in categories" :key="item._id" :value="item._id">{{ item.name }}</option>
      </select>
      <div class="invalid-feedback" v-if="validated && !item.categoryId">Please select a category</div>
    </section>

    <section class="row form-group">
      <div class="col-lg-3">
        <label for="itemMasterCode">Item Code</label>
        <input id="itemMasterCode" class="form-control" v-model="item.code" placeholder="Code #" />
      </div>
      <div class="col-lg-9">
        <label for="itemMasterName">Item Title</label>
        <input
          ref="itemNameInput"
          id="itemMasterName"
          :class="{ 'is-invalid': validated && !item.name }"
          class="form-control"
          v-model="item.name"
          placeholder="Item Title"
        />
        <div class="invalid-feedback" v-if="validated && !item.name">Please eneter the title</div>
      </div>
    </section>
    <section class="form-group">
      <label for="itemDescription">Description</label>
      <textarea
        id="itemDescription"
        class="form-control"
        v-model="item.note"
        placeholder="Item Description"
      />
    </section>

    <div class="py-3">
      <delete-confirm
        v-if="!isnew"
        class="d-inline-block position-absolute"
        :class="{ confirm: deleteMode }"
        entityName="itemMaster"
        @confirm="del"
        :entity="item"
        confirmtext="Are you sure you want to delete this item"
      />
      <save-cancel @save="done" @cancel="$emit('cancel')" class="float-right" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin.js";
const entityName = "itemMaster";
export default {
  name: "itemLineEdit",
  mixins: [dataMixin],
  props: {
    item: Object
  },
  data() {
    return {
      validated: false,
      deleteMode: false
    };
  },

  mounted() {
    this.focus();
  },
  watch: {
    item() {
      this.focus();
    }
  },
  methods: {
    focus() {
      if (!this.item.name) {
        setTimeout(() => {
          this.$refs.itemNameInput.focus();
        }, 500);
      }
    },
    onDelModeChange(mode) {
      this.deleteMode = mode;
    },
    async del() {
      await this.listDelete(entityName, this.item);
      this.$emit("done");
    },
    async done() {
      this.validated = true;
      if (!this.valid) return;
      const item = { ...this.item };
      await (this.isnew
        ? this.listAdd(entityName, item)
        : this.listUpdate(entityName, item));
      this.$emit("done");
    }
  },
  computed: {
    ...mapGetters(["categories"]),

    valid() {
      return this.item.name && this.item.categoryId;
    },
    isnew() {
      return !this.item._id;
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
