<template>
  <b-modal ref="contactModal" size="lg" title="Add Contact" no-close-on-esc hide-footer>
    <NewContact ref="newContact" v-if="showModal" inmodal @cancel="cancel" @done="done" />
  </b-modal>
</template>

<script>
import NewContact from "./components/NewContact";
export default {
  name: "addContact",
  components: { NewContact },
  data() {
    return {
      showModal: true
    };
  },
  methods: {
    open() {
      this.showModal = true;
      this.$refs.contactModal.show();
      this.focusFirst();
    },
    focusFirst() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.newContact.focus();
        }, 300);
      });
    },
    cancel() {
      this.$emit("cancel");
      this.showModal = false;
      this.$refs.contactModal.hide();
    },
    done(contact) {
      this.showModal = false;
      this.$refs.contactModal.hide();
      this.$emit("done", contact);
    }
  }
};
</script>
