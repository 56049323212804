<template>
  <a
    v-if="!hideAction"
    class="btn btn-edit d-flex align-items-center"
    @click="$emit('edit')"
    :class="{ disabled: !hasAccess, 'btn-sm': small }"
    :disabled="!hasAccess"
  >
    <i class="icon-pencil mr-2" v-if="hasAccess"></i>
    <i class="icon-lock mr-2" v-if="!hasAccess"></i>
    {{ title }}
  </a>
</template>

<script>
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "buttonEdit",
  mixins: [accessMixin],
  props: {
    title: {
      type: String,
      default: "Edit"
    },
    small: Boolean
  },
  methods: {
    edit() {
      if (!this.hasAccess) return;
      this.$emit("edit");
    }
  }
};
</script>
