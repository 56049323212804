import calc from "../data/calc";
const paidTypes = {
	paid: {
		value: "paid",
		text: "Paid",
		desc: "Fully Paid",
		className: "success",
		icon: "icon-check ",
		bg: "bg-success-2",
	},
	over: {
		value: "over",
		text: "Overpaid",
		desc: "Overpaid",
		className: "danger",
		icon: "la-exclamation-circle ",
		bg: "bg-danger-2",
	},
	unpaid: {
		value: "unpaid",
		text: "Unpaid",
		desc: "Unpaid",
		className: "primary",
		bg: "bg-white",
		icon: " ",
	},
	partial: {
		value: "partial",
		text: "Partial",
		desc: "Partially Paid",
		className: "warning",
		icon: "la-adjust ",
		bg: "bg-warning-2",
	},
	negetive: {
		value: "negetive",
		text: "Negetive",
		desc: "Negetive",
		className: "danger",
		icon: "la-adjust ",
		bg: "bg-danger-1",
	},
	none: {
		value: "",
		text: "",
		className: "",
	},
};
export default {
	computed: {
		paymentStatus() {
			return (payable) => {
				const balance = calc.balance(payable);
				if (payable.paidAmount < 0 && balance >= 0) return paidTypes.negetive;
				if (payable.paidAmount == 0) return paidTypes.unpaid;
				if (Math.abs(parseFloat(balance)) <= 0.005) return paidTypes.paid;
				if (parseFloat(balance) > 0.01) return paidTypes.partial;
				if (parseFloat(balance) < 0.01) return paidTypes.over;
				return paidTypes.over;
			};
		},
	},
};
