import http from "@/api/http";
import { getAuth } from "firebase/auth";

export function setToken(forceRefresh = true) {
	return getAuth()
		.currentUser.getIdToken(forceRefresh)
		.then((idToken) => {
			if (process.env.NODE_ENV !== "production") {
				console.log("idToken", idToken);
			}
			return (http.defaults.headers.common["Authorization"] = "Bearer " + idToken);
		})
		.catch((error) => {
			console.log("error", error);
			throw Error(error);
		});
}

export const updateUserEmail = async (email) => {
	var user = getAuth().currentUser;
	await user.updateEmail(email);
	await setToken();
};
