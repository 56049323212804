<template>
  <div>
    <div class="card-body comp d-flex p-3 mb-0">
      <div class="mr-3">
        <avatar
          v-if="contact.logoUrl"
          :src="contact.logoUrl"
          :rounded="false"
          :username="contact.name"
          :size="75"
        />
        <icon svg="svg-company" color="#a4a4a1" :size="60" v-else />
      </div>

      <div class="text-truncate">
        <div class="font-size-11 text-truncate font-weight-5 mt-1">
          <router-link :to="`/contact/${contact._id}`">{{ contact.name }}</router-link>
        </div>

        <div class="text-gray">{{ contact.description }}</div>
        <div v-if="contact.phone">
          <phone-number v-model="contact.phone" link :showicon="false" />
        </div>
      </div>
    </div>

    <div class="footer-contact px-3 py-2 border-top">
      <Person :person="person" />
    </div>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import Person from "./Person";
export default {
  name: "companyCard",
  components: { Person },
  props: {
    contact: Object
  },
  computed: {
    logo() {
      if (this.contact.logoUrl) return this.contact.logoUrl;
      if (this.contact.isperson) return "img/blank/blank_user.png";
      else return "img/blank/blank_contact.png";
    },
    person() {
      return this.contact.person || this.contact.people
        ? this.contact.people[0]
        : { name: "" };
    }
  }
};
</script>
<style lang="scss" scoped>
.company-logo {
  color: #dbe5e6;
  font-size: 4.2rem;
}
.comp {
  height: 6.7rem;
}
</style>
