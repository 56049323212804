<template>
  <div class="shadow-sm">
    <div class="px-3 py-2 bg-trans rounded-5-top fs-11">Contact / Time</div>
    <div class="bg-white">
      <highcharts :options="chartOptions" ref="hchart" />
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import { toDuration } from "@/helpers/date";
import moment from "moment";

import _ from "lodash";
export default {
  name: "cacheInPie",
  components: { highcharts: Chart },
  mixins: [baseMixin],

  props: {
    list: Array
  },
  data() {
    return {
      groupedList: [],
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 260,
          width: 1000,
          marginLeft: 100
        },
        legend: {
          align: "left",
          verticalAlign: "top",
          layout: "vertical",
          x: 0,
          y: 10,
          itemWidth: 500,
          labelFormatter: function() {
            return `${this.name}  (${this.percentage.toFixed(2)}%) ${toDuration(
              this.y
            )}hours `;
          }
        },

        title: {
          text: ""
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y:,.0f}</b>"
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %"
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: "Total Amount",
            colorByPoint: true,
            data: []
          }
        ]
      }
    };
  },
  watch: {
    total: {
      immediate: true,
      handler(value) {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      let data = _(this.list)
        .groupBy(p => p.contact._id)
        .map((value, key) => ({
          key,
          value,
          contact: value[0].contact,
          duration: value.map(o => o.duration).reduce((a, b) => a + b, 0)
        }))
        .orderBy(o => o.duration)
        .value()
        .slice(0, 10);
      this.groupedList = data.sort((a, b) => b.duration - a.duration);
      this.chartOptions.series[0].data = data.map(o => {
        return { name: o.contact.name, y: o.duration };
      });
    }
  },
  computed: {
    total() {
      return this.list.map(o => o.duration).reduce((a, b) => a + b, 0);
    }
  }
};
</script>

<style lang="scss" scoped></style>
