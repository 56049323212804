<template>
  <div class="form-group">
    <div
      class="list-group-item list-group-item-action shadow-sm py-4 rounded-5 bg-1 border-0 d-flex align-items-start justify-content-between"
    >
      <div>
        <router-link :to="`/project/${budgeting.projectId}/budgeting`" class="nav-link">
          <div class="fs-14 mb-3 pl-3">Budget Setup</div>
        </router-link>
        <ul>
          <li>Baseline Budget</li>
          <li>Revised Budget</li>
          <li>Quotations</li>
        </ul>
      </div>

      <div class="d-flex">
        <BudgetPie :budgeting="budgeting" />
        <div class="align-self-center">
          <router-link :to="`/project/${budgeting.projectId}/budgeting`" class="nav-link">
            <i class="icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BudgetPie from "./BudgetPie";

export default {
  name: "budgetSetupCard",
  components: { BudgetPie },
  props: {
    budgeting: Object
  }
};
</script>