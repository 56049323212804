export default {
	methods: {
		updateBillingAddress(contactId, address) {
			if (!address || !contactId) return;
			this.update("contact", { address }, "update", contactId);
		},

		updateShippingAddress(contactId, address) {
			if (!address || !contactId) return;
			this.update("contact", { shippingAddress: address }, "update", contactId);
		}
	}
};
