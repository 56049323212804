<template>
      <div class="progress ">
                  <div class="progress-bar progress-bar-striped " 
                        :class="`bg-${variant}`"
                        role="progressbar" :style="`width: ${percent}%`" 
                        :aria-valuenow="percent" 
                        aria-valuemin="0" aria-valuemax="100"></div>
            </div> 
</template>

<script>
export default {
    props: {
        percent: [Number, String],
        variant: {
            type: String,
            default: "success"
        }
    }
};
</script>

<style lang="scss" scoped>
.progress {
    height: 0.3rem;
}
</style>
