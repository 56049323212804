export default {
	methods: {
		async updateClient(data, action = "update", skey = "") {
			return await this.$store.dispatch("UPDATE_CLIENT", { data, action, skey });
		},
		async updateProfile(data, skey) {
			return this.updateClient(data, "profile/update", skey);
		},
		async updateSetting(data, skey) {
			return this.updateClient(data, "setting/update", skey);
		}
	}
};
