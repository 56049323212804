<template>
  <div class="crop-image">
    <div class="d-flex justify-content-around">
      <span class="btn btn-success btn-file px-5">
        Browse Image
        <input
          type="file"
          multiple
          id="file"
          ref="file"
          @change="readFile"
          accept="image/png, image/jpeg"
          class="input-file"
        />
      </span>
    </div>
    <div class="mb-4 pl-4">
      <button
        v-if="imageUrl"
        class="btn ml-5 float-right btn-toggle my-2"
        :class="{ active: cropper }"
        @click="toggleCropper"
      >
        <i class="icon-crop la-2x"></i>
      </button>
    </div>

    <!-- Note that 'ref' is important here (value can be anything). read the description about `ref` below. -->

    <div class="image-placeholder">
      <div class="text-center" v-if="imageUrl">
        <img
          :src="imageUrl"
          class="mx-auto img-thumbnail m-3"
          alt
          width="400"
          height="400"
          v-if="!cropper"
        />
      </div>
      <div v-if="cropper && imageUrl">
        <button class="btn btn-left" @click="rotate(-90)">
          <i class="la la-rotate-left"></i>
          <!-- Rotate Left -->
        </button>
        <button class="btn btn-right" @click="rotate(-90)">
          <i class="la la-rotate-right"></i>
          <!-- Rotate Left -->
        </button>
        <vue-croppie
          ref="croppieRef"
          :enableOrientation="true"
          :mouseWheelZoom="true"
          :showZoomer="true"
          :viewport="viewport"
          @result="result"
          @update="update"
        ></vue-croppie>
      </div>
    </div>

    <div class="img-progress mx-2 px-4">
      <progress-bar
        :percent="fileModel.progress"
        :variant="'success'"
        v-if="inprogress(fileModel)"
      />
      <i class="la la-check-circle la-2x text-success" v-if="fileModel.done"></i>
      <span class="text-danger" v-if="fileModel.error">
        <i class="la la-warning la-3x text-danger"></i>
        <strong>Upload Error !</strong>
      </span>
    </div>

    <!-- the result -->
    <!-- <img v-bind:src="cropped" /> -->

    <!-- Rotate angle is Number -->
    <div class="mt-3" v-if="hasAccess">
      <save-cancel
        v-if="fileName"
        class="float-right"
        @save="upload"
        @cancel="$emit('cancel')"
        savetext="Upload"
        :busy="uploading"
        :accessRole="accessRole"
      />
    </div>
  </div>
</template>

<script>
import { b64toBlob, readImage } from "./helpers/file-helper";
import { mapGetters } from "vuex";
import firebaseUpload from "./helpers/firebaseUpload.js";
import { uuidv4, getFilExt } from "./helpers/file-helper";
import accessMixin from "@/mixins/accessMixin";
export default {
  name: "crop-image",
  mixins: [accessMixin],
  props: {
    isavatar: Boolean,
    round: Boolean,
    showCropper: {
      type: Boolean,
      default: false
    },
    folder: String
  },
  data() {
    return {
      cropper: false,
      cropped: null,
      fileModel: {},
      fileName: "",
      imageUrl: "",
      file: "",
      uploading: false,
      viewport: {
        width: this.isavatar ? 300 : 800,
        height: 300,
        type: this.round ? "circle" : ""
      },
      format: "png"
      // boundary: {  width: this.isavatar? 300 +50: 600 +50, height: 300 +50, },
    };
  },
  mounted() {
    this.cropper = this.showCropper;
  },
  methods: {
    toggleCropper() {
      this.cropper = !this.cropper;
      setTimeout(() => {
        this.bindCropper();
      }, 300);
    },
    uploadCropped() {
      if (!this.fileName) return;
      let options = {
        format: this.format,
        circle: false,
        type: "blob"
        // type:'blob', //'base64', 'html', ,'rawcanvas'
        //var croppedImage = croppedCanvas.toDataURL(); // png
      };

      this.$refs.croppieRef.result(options, output => {
        this.cropped = output;
        this.uploadFile(output);
      });
    },
    async upload() {
      if (!this.file) return;
      this.uploading = true;
      if (this.cropper) await this.uploadCropped();
      else await this.uploadFile(this.file);
      this.uploading = false;
    },

    uploadFile(file) {
      this.fileModel = this.newFileModel(file);
      firebaseUpload(this.fileModel).then(url => {
        this.$emit("done", url);
      });
    },
    result(output) {
      this.cropped = output;
    },
    update(val) {},
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },
    newFileModel(file) {
      let ext = file.name ? getFilExt(file.name) : this.format;
      return {
        file: file,
        filePath: `${this.folder}/${uuidv4()}.${ext}`,
        progress: 1,
        meta: "",
        uploadUrl: "",
        done: false,
        error: ""
      };
    },
    readFile(e) {
      let file = e.target.files[0];
      if (!file) return;
      this.file = file;
      this.imageUrl = URL.createObjectURL(file);
      this.fileName = file.name;
      setTimeout(() => {
        this.bindCropper(file);
      }, 300);
    },
    bindCropper() {
      if (!this.cropper) return;
      let croppieRef = this.$refs.croppieRef;

      if (!croppieRef) return;
      readImage(this.file).then(img => {
        croppieRef.bind({
          url: img
        });
      });
    }
  },
  computed: {
    ...mapGetters(["clientProfile", "sas", "psas"]),
    inprogress() {
      return f => f.progress > 0 && !f.done && !f.error;
    }
  }
};
</script>

<style lang="scss">
.croppie-container .cr-boundary {
  height: 24rem !important;
}
.btn-left {
  position: absolute;
  left: 2rem;
  top: 6rem;
  z-index: 10;
}
.btn-right {
  position: absolute;
  right: 2rem;
  top: 6rem;
  z-index: 10;
}
.img-progress {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 27rem;
  height: 1rem;
  margin: 0.1rem;
  z-index: 10;
}
.image-placeholder {
  min-height: 24rem;
}
.crop-image {
  .btn-toggle {
    color: #555;
    background-color: var(--light);
  }
  .btn-toggle.active {
    // background-color: var(--info);
    color: var(--primary);
  }
}
</style>
