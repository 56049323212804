<template>
  <div class="bg-1 p-1">
    <div class="fs-12 bg-2 p-2 px-3">Budget Revise History</div>

    <table class="table table-hover thead-md pad-md">
      <thead>
        <tr>
          <th scope="col" class="w-10">Date</th>
          <th scope="col" class="w-10">By</th>
          <th scope="col">Budget</th>
          <th scope="col">Notes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in budgetHistories" v-bind:key="p.id">
          <td class="w-4">{{ p.date | dateformat }}</td>
          <td class="py-1 w-5">
            <user-view :user="p.by" />
          </td>
          <td>{{ p.amount | currency }}</td>
          <td>{{ p.note }}</td>
        </tr>
      </tbody>
    </table>

    <budget-history-chart
      :list="budgetHistories"
      v-if="budgetHistories && budgetHistories.length > 1"
    />
  </div>
</template>

<script>
import BudgetHistoryChart from "./BudgetHistoryChart";

export default {
  name: "budgetHistory",
  components: { BudgetHistoryChart },
  props: {
    item: Object
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {}
  },
  computed: {
    budgetHistories() {
      return this.item.budgetHistory;
    }
  }
};
</script>
