<template>
  <container-layout :nodata="nodata" :page="page" :state="state">
    <div slot="title">{{ page.name }}</div>
    <!-- <span slot="btns">
      <export-button></export-button>
      <print-button class="mr-5"></print-button>
    </span>-->

    <span slot="top-add">
      <add-button v-if="!nodata" @add="openAdd">Add Project</add-button>
      <add-project ref="addProject" />
    </span>

    <div class="row">
      <div class="col-lg-4 mb-3" v-for="p in list" :key="p._id">
        <project-card :project="p" />
      </div>
    </div>
    <no-data
      slot="nodata"
      :svg="page.svg"
      title="No Project"
      subtitle="You don't have a project yet! "
    >
      <add-button @add="openAdd">Create Your First Project</add-button>
    </no-data>
  </container-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import data from "@/mixins/dataMixin.js";
import pages from "@/pages";
import ProjectCard from "./components/ProjectCard";
import AddProject from "./AddProject";

export default {
  name: "projects",
  mixins: [baseMixin, data],
  components: { ProjectCard, AddProject },
  data() {
    return {
      page: pages.projects,
      keyword: ""
    };
  },
  mounted() {
    this.load();
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadList("project");
      this.loaded();
    },
    populate() {},
    search() {},
    openAdd() {
      // this.$store.dispatch("GET", { entityName: "nexo", action: "initdb" });
      this.$refs.addProject.open();
    }
  },
  computed: {
    list() {
      return this.projects;
    },
    nodata() {
      return this.ready && !this.projects.length;
    },
    ...mapGetters(["projects"])
  }
};
</script>
