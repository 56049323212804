<template>
	<div class="icon-avatar rounded-circle  d-inline-block mb-3">
		<i class="icon p-2 d-inline-block" :class="icon"></i>
	</div>
</template>
<script>
export default {
	name: 'iconAvatar',
	props: {
		icon: String,
	},
};
</script>
<style lang="scss" scoped>
.icon-avatar {
	width: 8rem;
	height: 8rem;
	padding: 1.1rem;
	background-color: #eeeeee;
	background-color: #eeeeee;
	.icon {
		font-size: 4.5rem;
	}
}
</style>
