import { render, staticRenderFns } from "./PhoneCountryInput.vue?vue&type=template&id=4652e720&scoped=true"
import script from "./PhoneCountryInput.vue?vue&type=script&lang=js"
export * from "./PhoneCountryInput.vue?vue&type=script&lang=js"
import style0 from "./PhoneCountryInput.vue?vue&type=style&index=0&id=4652e720&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4652e720",
  null
  
)

export default component.exports