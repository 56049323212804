<template>
  <card-layout back title="Edit Agreement" :state="state" class="bg-1">
    <div slot="header">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-7">
          <div class="p-3 bg-1">
            <ContractType :contract="contract" />
            <section>
              <div class="row d-flex justify-content-between">
                <div class="col-lg-7">
                  <section class="form-group ">
                    <label>Subcontractor</label>
                    <div>
                      <contact-selector
                        :contact="contract.contact"
                        name="company"
                        ref="contactSelctor"
                        :class="{ 'is-invalid': validated && !contract.contact._id }"
                        placeholder="Select subcontractor"
                        @change="contactChanged"
                        class="form-control"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="validated && !contract.contact._id"
                      >Select a Contact</div>
                    </div>
                  </section>

                  <section class="form-group">
                    <label>Work Item</label>
                    <div>
                      <item-selector
                        name="title"
                        ref="itemSelector"
                        :item="contract.item"
                        placeholder="Select work item"
                        :class="{'is-invalid': validated && !contract.item._id}"
                        @change="itemChanged"
                        class="form-control"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="validated && !contract.item._id"
                      >Please select a item</div>
                    </div>
                  </section>
                </div>
                <div class="col-lg-4">
                  <!-- <div class="mb-3 d-flex justify-content-between">
                    Number:
                    <span class="fw-600">{{contract.number}}</span>
                  </div>-->
                  <div class="form-group">
                    <label>Date</label>
                    <date-input ref="dateInput" @change="focusNext" v-model="contract.date" />
                  </div>
                  <div class="form-group">
                    <label>Due date1</label>
                    <date-input ref="dateInput" @change="focusNext" v-model="contract.dueDate" />
                  </div>
                </div>
              </div>
            </section>

            <section class="form-group">
              <label>Contract terms</label>
              <div>
                <input class="form-control" v-model="contract.terms" placeholder="Terms" rows="5"/>
              </div>
            </section>
            <section class="form-group">
              <label>Scope of Work</label>
              <div>
                <textarea
                  class="form-control"
                  v-model="contract.scope"
                  placeholder="Select scope of work"
                  ref="scopeInput"
                  rows="5"
                ></textarea>
              </div>
            </section>
            <div class="form-group">
              <label for>Exclusion</label>
              <textarea
                class="form-control"
                v-model="contract.exclusions"
                placeholder="Select exclusions"
                ref="scopeInput"
                rows="5"
              ></textarea>
            </div>
            <section>
              <label>Notes</label>
              <div>
                <textarea
                  class="form-control"
                  v-model="contract.note"
                  rows="3"
                  placeholder="Add contract notes..."
                ></textarea>
              </div>
            </section>
          </div>
          <div class="alert alert-danger" v-if="error">{{error}}</div>
        </div>

        <div class="col-lg-5">
          <entity-attachments :entityName="'contract'" :entity="contract" />
        </div>
      </div>
    </div>
    <div slot="footer">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ContractType from "@/views/contract/components/ContractType";
import {
  required,
  numeric,
  minValue,
  maxValue
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
const entityName = "contract";

export default {
  name: "contractEdit",
  mixins: [dataMixin, baseMixin, routeMixin, validationMixin],
  components: { ContractType },
  data() {
    return {
      validated: true,
      error: "",
      contract: {
        attachments: [],
        contact: {},
        item: {}
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.isnew) this.newModel();
      else this.load();
    },
    async load() {
      this.loading();
      await this.loadById(entityName, this.contract_id);
      this.contract = JSON.parse(JSON.stringify(this.contract_store));
      this.loaded();
    },

    newModel() {
      let key = this.$route.query.type;
      this.contract = {
        type: { key: key, name: types[key] },
        date: new Date(),
        attachments: [],
        customer: { ...this.project.customer },
        project: { _id: this.project_id, name: this.project.name },
        amount: 0,
        interest: 0,
        note: ""
      };
      this.focusFirst();
      this.loaded();
    },

    async save() {
      this.validated = true;
      if (this.$v.invalid) return;
      let model = { ...this.contract };
      try {
        this.isnew
          ? await this.add(entityName, model)
          : await this.update(entityName, model);

        this.$router.go(-1);
      } catch (error) {
        console.log("error :", error);
      }
    },
    contactChanged() {
      this.focusNext();
    },
    itemChanged() {
      this.focusNext();
    },
    cancel() {
      this.$router.go(-1);
    },

    focusFirst() {
      setTimeout(() => {
        let input = this.$refs.dateInput;
        if (input) input.focus();
      }, 300);
    },

    focusNext() {
      setTimeout(() => {
        if (!this.contract.contact._id) {
          this.$refs.contactSelector.show();
          return;
        }
        if (this.contract.amount == 0) {
          this.$refs.amountInput.focus();
          return;
        }
      }, 200);
    }
  },
  validations: {
    contract: {
      customer: {
        _id: { required }
      },
      project: {
        _id: { required }
      },
      date: { required },
      amount: { required, numeric, minValue: minValue(1) }
    }
  },
  computed: {
    type() {
      return this.contract ? this.contract.type || {} : {};
    },
    isnew() {
      return this.contract_id == "new";
    },
    project() {
      return this.projectById(this.project_id);
    },
    contract_id() {
      return this.entity_id(entityName);
    },
    contract_store() {
      return this.contractById(this.contract_id);
    },
    ...mapGetters(["projectById", "contractById"])
  }
};
</script>
