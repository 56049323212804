export function getforCategory(catId, items) {
  return (
    _.sortBy(
      items.filter((o) => o.categoryId == catId),
      (o) => o.code,
      (o) => o.sequence
    ) || []
  );
}

export function categoryBudget(catId, budgetItems) {
  return budgetItems
    .filter((o) => o.categoryId == catId)
    .map((o) => o.budget)
    .reduce((a, b) => a + b, 0);
}
export function categoryPrice(catId, budgetItems) {
  return budgetItems
    .filter((o) => o.categoryId == catId)
    .map((o) => o.price)
    .reduce((a, b) => a + b, 0);
}

export function categorycostToDate(catId, budgetItems) {
  return budgetItems
    .filter((o) => o.categoryId == catId)
    .map((o) => o.costToDate)
    .reduce((a, b) => a + b, 0);
}
export function categorycostToComplete(catId, budgetItems) {
  return budgetItems
    .filter((o) => o.categoryId == catId)
    .map((o) => o.budget - o.costToDate)
    .reduce((a, b) => a + b, 0);
}
export function totalBudget(budgetItems) {
  return budgetItems
    .map((o) => parseFloat(o.budget))
    .reduce((a, b) => a + b, 0);
}
