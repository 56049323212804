<template>
  <div v-if="!hasProject">
    <div class="d-flex justify-content-center p-3">
      <add-button @add="openAddProject">Create Your First Project</add-button>
      <add-project ref="addProject" @done="onProjectAdd" />
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import AddProject from "@/views/project/AddProject";
import clientDataMixin from "@/mixins/clientDataMixin";
import { mapGetters } from "vuex";
export default {
  name: "firstProject",
  mixins: [dataMixin, baseMixin, clientDataMixin],
  components: { AddProject },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.hasProject) {
        const hasProject = await this.loadByAction("project", "any", true);
        if (hasProject) this.reloadProfile();
      }
    },
    openAddProject() {
      this.$refs.addProject.open();
    },
    onProjectAdd(project) {
      this.load();
      this.$router.push(`/project/${project._id}`);
    }
  },
  computed: {
    hasProject() {
      return this.clientSetting && this.clientSetting.hasProject;
    },
    ...mapGetters(["clientSetting"])
  }
};
</script>