<template>
  <div class="card border-0 shadow-sm">
    <div class="card-header min-h-auto d-flex d-flex justify-content-between align-items-center">
      <span class="fs-11">Daily Logs</span>

      <div>
        <toggle-buttons :items="viewModes" @select="changeView" v-model="viewMode" showicon />
      </div>
      <!-- <add-button @add="add">Daily Log</add-button> -->
    </div>
    <div class="card-body px-2">
      <DailyLogTable :grouped="grouped" v-if="viewMode=='l'" />
      <div v-for="log in list" :key="log.id" v-else>
        <LogView :log="log" class="border-bottom bg-light shadow-sm mb-3" />
      </div>
      <no-data :page="{ icon: 'icon-clock', name: 'Daily Log' }" v-if="nodata" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
const entityName = "dailyLog";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import DailyLogTable from "./DailyLogTable";
import LogView from "./LogView";
export default {
  name: "dailyLogs",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { LogView, DailyLogTable },
  mounted() {
    this.load();
  },
  data() {
    return {
      viewModes: [
        { code: "l", icon: "icon-menu" },
        { code: "c", icon: "icon-grid" }
      ],
      viewMode: "l"
    };
  },

  methods: {
    add() {
      this.$router.push(`dailyLog/new/edit`);
    },

    async load() {
      this.loading();
      await this.loadByAction(entityName, `project/${this.project_id}`);
      this.loaded();
    },
    changeView() {}
  },

  computed: {
    list() {
      return this.dailyLogs.filter(
        o => o.project && o.project._id == this.project_id
      );
    },
    grouped() {
      return _(this.list)
        .groupBy(p =>
          moment(p.date)
            .startOf("day")
            .format()
        )
        .map((value, key) => ({
          date: moment(key)
            .startOf("day")
            .format(),
          list: value
        }))
        .orderBy(o => o.date)
        .reverse()
        .value();
    },

    nodata() {
      return this.ready && !this.list.length;
    },
    ...mapGetters(["dailyLogs"])
  }
};
</script>
