<template>
  <div class="card">
    <div class="card-body" v-if="contract">
      <div class="pb-2 mb-1 d-flex justify-content-between align-items-start">
        <div>
          <contact-view :contact="contract.contact" class="mb-2" />
          <item-view :item="contract.item" />
          <!-- <project-view :project="contract.project" /> -->
        </div>
        <div>
          <div class="fs-2 text-1 text-uppercase">Agreement</div>
          <div class="text-2">
            <span>{{contract.number}}</span>
          </div>
          <div class="text-2 small d-flex justify-content-between">
            Date:
            <span class="ml-2">{{contract.date | day}}</span>
          </div>
          <div class="text-2 small d-flex justify-content-between">
            Due date:
            <span class="ml-2">{{contract.dueDate | day}}</span>
          </div>
        </div>
      </div>

      <div class="row form-group bg-1">
        <div class="col d-flex justify-content-between align-items-start">
          <button-share :entity="contract" />
          <button-edit @edit="edit" />
        </div>
      </div>
      <div class="form-group">
        <label for>Contract Type</label>
        <ContractTypeView :contract="contract" />
      </div>

      <div class="form-group">
        <label for>Terms</label>
        <div class="form-control value">{{ contract.terms }}</div>
      </div>
      <div class="form-group">
        <label for>Scope of Work</label>
        <p class="form-control value">{{ contract.scope }}</p>
      </div>

      <div class="form-group">
        <label for>Exclusions</label>
        <div class="form-control value">{{ contract.exclusions }}</div>
      </div>
      <div class="form-group">
        <label for>Notes</label>
        <div class="form-control value">{{ contract.note }}</div>
      </div>
      <div class="form-group">
        <label for>Contract documents /Attachements</label>
        <div class="form-control value">
          <entity-attachments :entity="contract" entityName="contract" />
        </div>
      </div>
    </div>
    <div class="card-footer">
      <last-modified :entity="contract" class="float-left" slot="footer" />
    </div>
  </div>
</template>

<script  >
const entityName = "contract";
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import ContractTypeView from "@/views/contract/components/ContractTypeView";

export default {
  name: "contractView",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { ContractTypeView },
  props: {
    id: String
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      await this.loadById(entityName, this.id);
      this.loaded();
    },
    edit() {
      this.$router.push(`contract/${this.contract._id}/edit`);
    }
  },

  computed: {
    contract() {
      return this.contractById(this.id);
    },
    ...mapGetters(["contractById"])
  }
};
</script>

<style scoped>
</style>