<template>
  <div class="p-4">
    <b-modal
      ref="copyFromModal"
      size="md"
      title="Copy From Existing Project"
      hide-footer
      @hide="showModal = false"
    >
      <div v-if="showModal">
        <section class="form-group">
          <label>Select a project to copy from</label>
          <project-selector
            name="project"
            :show-general="false"
            :project="project"
            placeholder="Select project"
            :excludeIds="[projectId]"
            @change="changeProject"
            :class="{ 'is-invalid': validated && !project._id}"
            class="form-control"
          />
          <div class="invalid-feedback" v-if="validated && !project._id">Please select a project</div>
        </section>
        <div class="px-3">
          <b-checkbox disabled v-model="copyItems" class="form-group">Copy Items</b-checkbox>

          <div v-if="isBudgeting" class="form-group">
            <b-checkbox v-model="copyAmount" switch size="md">Copy Budget Amount</b-checkbox>
          </div>

          <div v-if="isScheduling" class="form-group">
            <b-checkbox v-model="copyTaskDuration" switch size="md">Copy Task Duration</b-checkbox>
          </div>
        </div>

        <div class="modal-footer save-footer mt-3">
          <save-cancel @save="save" @cancel="close" class="float-right" savetext="Copy" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import dataMixin from "@/mixins/dataMixin.js";

export default {
  name: "copyFromProject",
  mixins: [dataMixin],
  props: {
    projectId: String,
    type: String
  },
  data() {
    return {
      project: {},
      copyItems: true,
      validated: false,
      copyAmount: true,
      copyTaskDuration: true,
      showModal: false
    };
  },
  methods: {
    open() {
      this.showModal = true;
      this.$refs.copyFromModal.show();
    },
    close() {
      this.showModal = false;
      this.$refs.copyFromModal.hide();
    },
    changeProject(p) {},
    async save() {
      this.validated = true;
      if (!this.valid) {
        return;
      }
      let model = {
        fromProjectId: this.project._id,
        copyAmount: this.copyAmount,
        copyDuration: this.CopyDuration
      };
      try {
        await this.update("budgeting", model, `${this.projectId}/copyFrom`);
        this.close();
        this.$emit("done");
      } catch (error) {
        console.log("error", error);
      }
    }
  },
  computed: {
    valid() {
      return this.project != null && this.project._id;
    },
    isBudgeting() {
      return this.type == "budgeting";
    },
    isScheduling() {
      return this.type == "scheduling";
    }
  }
};
</script> 
