<template>
  <li class="list-group-item list-group-item-action" v-if="!hideAction">
    <a
      @click="$emit('add')"
      class="action-link text-primary d-flex align-items-center"
      :class="{disabled:!hasAccess}"
      :disabled="!hasAccess"
    >
      <icon svg="svg-plus" color="#007bff" class="d-inline-block mr-2" />
      <div class="ml-2">
        <!-- <div class="text-gray small">Not listed ?</div> -->
        {{ text || 'Add New Item' }}
      </div>
    </a>
  </li>
</template>

<script>
import accessMixin from "@/mixins/accessMixin";

export default {
  name: "addInList",
  mixins: [accessMixin],
  props: {
    text: String
  }
};
</script>
