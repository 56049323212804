<template>
  <b-modal ref="modal" title="Unsaved Changes" hide-footer :visible="modalShow" @close="hide">
    <div class="p-0 container">
      <div class="alert alert-warning">You have some unsaved changes!</div>
      <div class="border-top p-3 text-right">
        <save-cancel
          savetext="Save Changes"
          class="float-right"
          @save="save"
          @cancel="hide"
          canceltext="Cancel"
        />
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "saveChangesModal",
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    hide() {
      this.modalShow = false;
      this.$emit("cancel");
    },
    open({ saveFunc, from, to }) {
      this.modalShow = true;
      this.from = from;
      this.to = to;
      this.saveFunc = saveFunc;

      this.$nextTick(() => {
        this.$refs.modal.show();
      });
    },
    cancelChanges() {
      this.$refs.modal.hide();
      this.modalShow = false;
      this.$emit("cancelChanges");
      this.next();
    },
    async save() {
      try {
        await this.saveFunc();
        setTimeout(() => {
          this.next();
        }, 200);
      } catch (error) {
        console.error(error);
      }
    },
    next() {
      this.$router.push(this.to.path);
    }
  }
};
</script>