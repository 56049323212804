import moment from "moment";
import { dayDiff } from "@/helpers/date";
export const strProgress = (progress) => {
	const value = parseFloat(progress);
	if (!value) return "Scheduled";
	if (value < 100) return "In Progress";
	if (value >= 100) return "Completed";
};

export default {
	methods: {
		adjustStartDateFromDepencies(task) {
			let scheduling = this.scheduling;
			if (task.dependencies && task.dependencies.length) {
				//tasks that this is depend of them
				let dependentTasks = scheduling.tasks.filter((t) => task.dependencies.includes(t._id));
				let maxEnds = moment.max(dependentTasks.map((o) => moment(o.end)));

				const mStart = moment(task.start);
				const mEnd = moment(task.end);
				const duration = mEnd.diff(mStart, "days");
				let diff = maxEnds.diff(mStart, "days");

				if (diff > 0) {
					task.start = maxEnds.add(1, "days").toDate();
					task.end = moment(task.start)
						.add(duration, "days")
						.toDate();
				}
			}
		},
		sortWithNext(task) {
			let tasks = this.scheduling.tasks;
			let i = tasks.indexOf(task);
			let nextTask = tasks[i + 1];
			//if this task starts after the next one swap position
			if (nextTask && moment(task.start).diff(moment(nextTask.start), "days") > 0) {
				[tasks[i], tasks[i + 1]] = [tasks[i + 1], tasks[i]];
			}
		},
		makeSureEnd(task) {
			let mStart = moment(task.start);
			let mEnd = moment(task.end);
			let diff = mEnd.diff(mStart);
			if (diff < 1) {
				task.end = mStart.add(1, "days").toDate();
			}
		},
		makeSureStart(task) {
			let mStart = moment(task.start);
			let mEnd = moment(task.end);
			let diff = mEnd.diff(mStart);
			if (diff < 1) {
				task.start = mEnd.add(-1, "days").toDate();
			}
		},
		setEndDate(task, days) {
			task.end = moment(task.start)
				.add(days, "days")
				.toDate();
		},
		sortTasksByDate() {
			this.scheduling.tasks = this.scheduling.tasks.sort((a, b) => moment(a.start).diff(moment(b.start)));
		},
	},
	computed: {
		duration() {
			return (task) => dayDiff(task.start, task.end);
		},
		progreesStatus() {
			return (progress) => {
				return strProgress(progress);
			};
		},
		progressVariant() {
			return (progress) => {
				const value = parseFloat(progress);
				if (!value) return "info";
				if (value <= 25) return "info";
				if (value < 100) return "primary";
				if (value >= 100) return "success";
			};
		},
		getTask() {
			return (id) => this.scheduling.tasks.find((o) => o._id == id);
		},
		dependenciesIndexes() {
			return (task) =>
				task.dependencies
					? task.dependencies.map((d) => this.scheduling.tasks.map((o) => o._id).indexOf(d) + 1).join()
					: "";
		},
	},
};
