<template>
  <card-layout :state="state" class="bg-1">
    <div slot="title">
      <div class="d-flex justify-content-between">
        <back-button />
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3">
          <div class="list-group list-group-flush green">
            <div
              v-for="tab in list"
              :key="tab.key"
              :class="{ active: selected == tab.key }"
              @click="select(tab)"
              class="list-group-item d-flex align-items-center c-pointer border list-group-item-action rounded-5 shadow-sm mb-2"
            >
              <div class="d-flex align-items-center">
                <!-- <i class="icon-folder mr-3 fs-15"></i> -->
                {{ tab.name }}
              </div>
            </div>
            <ProjectStatus :project="project" class="mt-5" />
          </div>
        </div>
        <div class="col-lg-9">
          <div v-if="selected == 'info'">
            <ProjectDetailEdit
              :id="project_id"
              @cancel="detailEdit = false"
              @done="detailEdit = false"
              v-if="detailEdit"
            />
            <ProjectDetail :project="project" @edit="detailEdit = true" v-else />
          </div>

          <div v-if="selected == 'contract'">
            <ProjectContractEdit
              :project="project"
              :proejctId="project_id"
              @cancel="contractEdit = false"
              @done="contractEdit = false"
              v-if="contractEdit"
            />
            <ProjectContract :project="project" @edit="contractEdit = true" v-else />
          </div>
          <div v-if="selected == 'status'">
            <ProjectStatusEdit
              :project="project"
              class="bg-2 rounded-5 shadow-sm"
              @done="selected = 'info'"
            />
            <!-- <ProjectStatus :project="project" class="bg-2 rounded-5 shadow-sm" v-else /> -->
          </div>
        </div>
      </div>
    </div>
    <entity-footer
      slot="footer"
      :entity="project"
      :confirmtext="`Are you sure you want to delete this Project `"
      @del="deleteProject"
    />
  </card-layout>
</template>

<script>
import dataMixin from "@/mixins/dataMixin.js";
import pages from "@/pages";
import baseMixin from "@/mixins/baseMixin";
import routeMixin from "@/mixins/routeMixin";
import { mapGetters } from "vuex";
import ProjectDetail from "./components/ProjectDetail";
import ProjectDetailEdit from "./components/ProjectDetailEdit";
import ProjectContract from "./components/ProjectContract";
import ProjectContractEdit from "./components/ProjectContractEdit";
import ProjectStatusEdit from "./components/ProjectStatusEdit";
import ProjectStatus from "./components/ProjectStatus";
const entityName = pages.projects.entityName;

export default {
  name: "porjectInfo",
  mixins: [dataMixin, baseMixin, routeMixin],
  components: {
    ProjectDetail,
    ProjectDetailEdit,
    ProjectContract,
    ProjectContractEdit,
    ProjectStatusEdit,
    ProjectStatus
  },
  data() {
    return {
      detailEdit: false,
      contractEdit: false,
      statusEdit: true,
      list: [
        { key: "contract", name: "Agreement With Client" },
        { key: "info", name: "Project Information" },
        { key: "status", name: "Project Status" }
      ],
      selected: "contract"
    };
  },
  mounted() {
    this.load();
    if (this.$route.query.edit == 1) {
      this.contractEdit = true;
    } else if (this.$route.query.status == 1) {
      this.selected = "status";
    }
  },
  methods: {
    edit() {
      this.$router.push(`/project/${this.project_id}/info/edit`);
    },
    async deleteProject() {
      try {
        await this.del(entityName, this.project);
        this.$router.push("/projects");
      } catch (error) {}
    },
    async load() {
      this.loading();
      await this.loadById(entityName, this.project_id, true);
      this.loaded();
    },
    select(tab) {
      this.selected = tab.key;
    }
  },
  computed: {
    status() {
      if (this.project.isDeleted) {
        return "Deleted";
      } else if (this.project.archived) {
        return "Archived";
      } else {
        return "Active";
      }
    },
    project() {
      return this.projectById(this.project_id);
    },
    ...mapGetters(["projectById"])
  }
};
</script>

<style lang="scss" scoped></style>
