 <template>
  <card-layout :state="state" :nodata="nodata" title="Budgeting and Project Summary" nofooter back>
    <div slot="header" class="d-flex">
      <button-share :entity="budgeting" />
    </div>
    <div class="card-body p-4" slot="data" v-if="budgeting">
      <ContractTypeSet
        class="form-group"
        v-if="!contractType && ready"
        :budgeting="budgeting"
        @done="onTypeSet"
      />
      <div v-else>
        <BudgetSetupCard :budgeting="budgeting" />
        <BudgetSummaryCard :budgeting="budgeting" />
      </div>
    </div>

    <NoBudgeting :budgeting="budgeting" :nodata="nodata" />
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import budgetMixin from "./data/budgetMixin.js";
import accessMixin from "@/mixins/accessMixin";
import ContractTypeSet from "@/views/contract/components/ContractTypeSet.vue";

import NoBudgeting from "./components/NoBudgeting";
import BudgetSetupCard from "./components/BudgetSetupCard";
import BudgetSummaryCard from "./components/BudgetSummaryCard";
import _ from "lodash";

export default {
  name: "budgetSumamry",
  mixins: [baseMixin, dataMixin, routeMixin, budgetMixin, accessMixin],
  components: {
    NoBudgeting,
    BudgetSetupCard,
    BudgetSummaryCard,
    ContractTypeSet
  },

  mounted() {
    this.load();
  },

  methods: {
    async load(force) {
      this.loading();
      if (!this.budgeting) {
        await this.loadByAction("budgeting", `project/${this.project_id}`);
      }
      if (!this.items) {
        await this.loadPerClient("itemMaster");
      }
      this.loaded();
    },
    onTypeSet() {
      this.$router.push(`/project/${this.project_id}/budgeting`);
    }
  },
  computed: {
    nodata() {
      return this.state.loaded && !this.budgeting;
    },
    budgeting() {
      return this.budgetings.find(o => o.projectId == this.project_id);
    },
    ...mapGetters(["items", "budgetings"])
  }
};
</script>