<template>
  <div>
    <label class="small">Payment</label>
    <div class="form-group">
      <b-form-radio-group v-model="payment.paidOption">
        <b-form-radio value="n" class="user-select-none" @change="onswitch('n')"
          >Unpaid</b-form-radio
        >
        <b-form-radio value="p" class="user-select-none" @change="onswitch('p')"
          >Partially Paid</b-form-radio
        >
        <b-form-radio value="f" class="user-select-none" @change="onswitch('f')"
          >Fully Paid</b-form-radio
        >
      </b-form-radio-group>
    </div>
    <b-collapse :visible="!unpaid" id="collapse">
      <div class="row form-group">
        <div class="col-lg-4">
          <label for>Paid Amount</label>
          <amount-input
            ref="paymentAmountInput"
            id="amount"
            v-if="payment.paidOption == 'p'"
            :class="{ 'is-invalid': payment.amount == 0 || overPied }"
            v-model="payment.amount"
            placeholder="Enter Amount"
          />
          <div class="invalid-feedback" v-if="payment.amount == 0 || overPied">
            <div v-if="!payment.amount">please enter amount</div>

            <div class="alert alert-danger py-1 mt-1" v-if="overPied">
              Overpaid!
            </div>
          </div>

          <div
            class="form-control bg-success-1 text-right"
            v-if="payment.paidOption == 'f'"
          >
            <span class="amount fs-11">{{ fullAmount | currency }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <label>Payment Date</label>
          <date-input ref="datePicker" v-model="payment.date" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-lg-4">
          <label for>Reference</label>
          <input
            type="text"
            ref="refNo"
            id="refNo"
            v-model="payment.refNo"
            placeholder="e.g. Cheque Number"
            class="form-control"
          />
        </div>
        <div class="col-lg-8">
          <label for>From Account</label>
          <account-selector
            name="title"
            ref="accountView"
            :account="payment.account"
            placeholder="Select Account"
            @change="accountChanged"
            class="form-control"
          />
        </div>
      </div>
      <!-- <div class="form-group">
        <label for>Reimbursable</label>
        <div>
          <div class="alert alert-success m-0" v-if="payment.invoiceId">
            Submitted for reimbursement on this
            <router-link
              v-if="payment.invoiceId"
              :to="`/project/${payable.project._id}/invoice/${payment.invoiceId}`"
            >Invoice</router-link>
          </div>
          <b-checkbox
            v-else
            v-model="payment.isReimbursable"
            switch
          >Include in next invoice to client</b-checkbox>
        </div>
      </div>-->
    </b-collapse>
  </div>
</template>
<script>
import AccountView from "@/views/account/components/AccountView";
import calc from "../data/calc.js";
export default {
  name: "inLinePayment",
  components: { AccountView },
  mixins: [],
  props: {
    payable: Object,
    payment: Object,
  },
  data() {
    return {
      fullypaid: true,
      amount: 0,
      paidOption: "",
    };
  },
  methods: {
    accountChanged(account) {
      this.payment.accountId = account._id;
      this.payment.accountName = account.name;
      this.$forceUpdate();
    },
    onswitch(option) {
      if (option == "n") {
        this.payment.amount = 0;
      } else if (option == "p" && this.payment.amount == 0) {
        this.focusAmount();
      } else {
        this.payment.amount = this.fullAmount;
      }
    },
    focusAmount() {
      setTimeout(() => {
        let el = this.$refs.paymentAmountInput;
        if (el) el.focus();
      }, 500);
    },
  },
  computed: {
    fullAmount() {
      return calc.calcTotal(this.payable);
    },
    unpaid() {
      return this.payment.paidOption == "n";
    },
    overPied() {
      return this.payment.amount > this.fullAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.paid-amount {
  .icon {
    font-size: 150%;
  }
}
</style>
