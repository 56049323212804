<template>
  <card-layout nofooter back :state="state" class="bg-1">
    <div slot="header" class="d-flex">
      <add-button @add="addDailyLog">Daily Log</add-button>
    </div>
    <!-- <div slot="title" class="d-flex">Project Progress</div> -->

    <div class="card-body" slot="data">
      <div class="row">
        <div class="col-lg-2">
          <div class="list-group list-group-flush green">
            <div
              v-for="item in list"
              :key="item.code"
              :class="{ active: selected == item.code }"
              @click="select(item)"
              class="list-group-item c-pointer d-flex align-items-center list-group-item-action rounded-5 border-0 shadow-sm mb-2 justify-content-between"
            >
              <div class="d-flex align-items-center">
                <i :class="item.icon" class="mr-3 fs-15"></i>
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-10">
          <DailyLogs v-if="selected=='d'" />
          <ProgressReports v-if="selected=='p'" />
        </div>
      </div>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import DailyLogs from "./dailylog/DailyLogs";
import ProgressReports from "./progressReport/ProgressReports";
export default {
  name: "projectProgress",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { DailyLogs, ProgressReports },
  data() {
    return {
      list: [
        { code: "d", name: "Daily Logs", icon: "icon-clock" },
        { code: "p", name: "Progress Reports", icon: "icon-graph" }
      ],
      selected: ""
    };
  },
  mounted() {
    this.selected = "d";
    this.loaded();
  },
  methods: {
    select(item) {
      this.selected = item.code;
    },
     addDailyLog() {
      this.$router.push(`dailyLog/new/edit`);
    },

  }
};
</script>
