<template>
  <card-layout :state="state" nofooter back title="Edit Budget">
    <div slot="header" class="d-flex">
      <save-cancel @save="save" @cancel="cancel" v-if="hasChanges" />
    </div>

    <div class="card-body" v-if="budgetItem" slot="data">
      <div class="row">
        <div class="col-lg-8">
          <div class="bg-1 rounded-5 p-3 form-group">
            <div class="mb-2 border-bottom pb-2 fs-11">{{category.name}}</div>
            <section class="row form-group">
              <div class="col-lg-3">
                <label for="itemMasterCode">Item Code</label>
                <input
                  id="itemMasterCode"
                  class="form-control"
                  v-model="budgetItem.code"
                  placeholder="Code #"
                />
              </div>
              <div class="col-lg-9">
                <label for="itemName">Item Title</label>
                <input
                  ref="itemNameInput"
                  id="itemName"
                  :class="{ 'is-invalid': validated && !budgetItem.name }"
                  class="form-control"
                  v-model="budgetItem.name"
                  placeholder="Item Title"
                />
                <div
                  class="invalid-feedback"
                  v-if="validated && !budgetItem.name"
                >Please eneter the title</div>
              </div>
            </section>

            <CostMarkup v-if="isFixedPrice" :budgeting="budgeting" :budgetItem="budgetItem" />

            <section class="row form-group" v-else>
              <div class="col-lg-3">
                <label for="itemMasterCode">Budget Amount</label>
                <amount-input
                  ref="firstInput"
                  v-model="budgetItem.budget"
                  class="font-weight-md budget-input zero-h"
                  placeholder="amount"
                />
              </div>
            </section>

            <section>
              <label for="budgetNote">Budgeting Notes</label>
              <textarea
                id="budgetNote"
                class="form-control"
                v-model="budgetItem.note"
                placeholder="Note for this budget"
              />
            </section>
          </div>
        </div>
        <div class="col-lg-4"></div>
      </div>
      <BudgetItemQuotes :projectId="projectId" :budgetItem="budgetItem" v-if="budgetItem" />
    </div>
  </card-layout>
</template>

<script>
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin";
import routeMixin from "@/mixins/routeMixin.js";
import BudgetItemQuotes from "./BudgetItemQuotes";
import budgetingMixin from "./data/budgetingMixin";
import budgetItemMixin from "./data/budgetItemMixin";
import CostMarkup from "./components/CostMarkup";
import { mapGetters } from "vuex";

export default {
  name: "editBudegt",
  mixins: [baseMixin, dataMixin, routeMixin, budgetingMixin, budgetItemMixin],
  components: { BudgetItemQuotes, CostMarkup },
  data() {
    return {
      history: {
        amount: 0,
        note: ""
      },
      budgetItem: ""
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadBudgetingIfNot();
      await this.loadItemMasterIfNo();
      this.populate();
      this.loaded();
    },
    populate() {
      const item = JSON.parse(JSON.stringify(this.budgetItem_store));
      this.calcMatrkup(item);
      this.budgetItem = item;
    },

    async save() {
      try {
        this.validated = true;
        if (!this.valid) return;
        const data = { ...this.budgetItem };
        await this.update(
          "budgeting",
          data,
          `${this.budgeting._id}/update/item`
        );
        this.$router.go(-1);
      } catch (error) {}
    },
    cancel() {
      this.populate();
    },
    toBudgets() {
      this.$router.go(-1);
    },
    async del() {
      await this.delItem(this.budgetItem);
      this.$emit("done");
    }
  },
  computed: {
    valid() {
      return this.budgetItem.name && this.budgetItem.categoryId;
    },
    category() {
      return (
        this.categories.find(o => o._id == this.budgetItem.categoryId) || {}
      );
    },
    budgetItem_store() {
      let budgeting = this.budgetingById(this.budgeting_id);
      return budgeting && budgeting.budgetItems
        ? budgeting.budgetItems.find(o => o._id == this.budgetItem_id)
        : "";
    },
    budgeting() {
      return this.budgetingById(this.budgeting_id);
    },

    hasChanges() {
      return (
        JSON.stringify(this.budgetItem_store) != JSON.stringify(this.budgetItem)
      );
    },
    isFixedPrice() {
      return this.budgeting && this.budgeting.type == "f";
    },
    projectId() {
      return this.project_id;
    },

    ...mapGetters(["budgetingById", "categories"])
  }
};
</script>

<style lang="scss" scoped>
.form-control.value {
  width: 10rem;
}
</style>
