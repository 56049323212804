export default {
	computed: {
		total() {
			return this.list.map((o) => o.amount).reduce((a, b) => a + b, 0);
		},
		percent() {
			return (contact) =>
				this.list
					.filter((o) => o.contact._id == contact._id)
					.map((o) => o.amount)
					.reduce((a, b) => a + b, 0) / this.total;
		},
	},
};
