<template>
  <div>
    <div
      class="alert alert-info py-2 d-flex justify-content-between align-items-center"
    >
      <div v-if="isLockedBudget">
        Initial Budget:
        <span class="fs-12 mx-2">{{
          budgeting.projectInitialBudget | currency
        }}</span>
      </div>
      <div>
        Total Budget:
        <span class="fs-12 mx-2">{{ projectBudget | currency }}</span>
      </div>

      <button
        class="btn btn-outline-primary float-right"
        v-if="!baseLineAlert && !isLockedBudget"
        @click="baseLineAlert = true"
        v-b-tooltip="{ delay: 200 }"
        :title="`This will lock the initial budget`"
      >
        <i class="icon-lock-open"></i>
        Set as Baseline Budget
      </button>
    </div>
    <div class="alert alert-warning" v-if="baseLineAlert">
      <p>
        Once this initial budget is locked as baseline budget, any changes will
        be saved as budget revision.
      </p>
      <div class="d-flex justify-content-end">
        <save-cancel
          savetext="Set as Baseline Budget"
          @save="setBaseline"
          @cancel="baseLineAlert = false"
          noicon
        />
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Item</th>
          <th scope="col">Notes</th>
          <th scope="col">Quotes</th>
          <th scope="col">
            <span v-if="isLockedBudget">Initial Budget</span>
          </th>
          <th scope="col">Budget</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody v-for="c in selectedCategories" v-bind:key="c._id">
        <tr class="bg-1">
          <td></td>
          <td class="fs-11">{{ c.name }}</td>
          <td colspan="2"></td>
          <td></td>
          <td class="strong fs-12" colspan="2">
            {{ categoryBudget(c._id) | currency }}
          </td>
        </tr>

        <tr v-for="b in getItemsforCategory(c._id)" v-bind:key="b._id">
          <td>{{ b.code }}</td>
          <td>{{ b.name }}</td>

          <td>
            <i
              class="icon-speech text-gray"
              v-if="b.note"
              v-tooltip="b.note"
            ></i>
          </td>
          <td>
            <i class="icon-paper-clip" v-if="b.attachments.length"></i>
          </td>
          <td class="text-1">
            <span v-if="isLockedBudget">{{ b.initialBudget | currency }}</span>
          </td>
          <td>
            <amount-input
              v-if="!isLockedBudget"
              @change="$emit('change', b)"
              v-model="b.budget"
              placeholder="Amount"
              class="font-weight-md budget-input zero-h"
            />
            <span v-else>{{ b.budget | currency }}</span>
          </td>
          <td class="w-3 pr-3">
            <!-- <button
              v-if="!isLockedBudget"
              @click="$emit('edit', $event.target,b)"
              class="btn btn-sm"
              tabindex="-1"
            >
              <i class="icon-pencil mr-1 text-primary"></i>
            </button>-->

            <button @click="$emit('edit', b)" class="btn btn-sm" tabindex="-1">
              <i class="icon-arrow-right mr-1 text-primary"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import routeMixin from "@/mixins/routeMixin.js";
import budgetMixin from "./data/budgetMixin.js";
import dataMixin from "@/mixins/dataMixin.js";

const entityName = "budgeting";
export default {
  name: "budgetTableCostPlus",
  mixins: [budgetMixin, dataMixin],
  props: {
    selectedCategories: Array,
    budgeting: Object,
  },
  data() {
    return {
      baseLineAlert: false,
    };
  },
  methods: {
    async setBaseline() {
      try {
        this.update("budgeting", {}, "setbase", this.budgeting._id);
        this.baseLineAlert = false;
        this.$emit("update");
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  computed: {
    isLockedBudget() {
      return this.budgeting && this.budgeting.isLockedBudget;
    },
  },
};
</script>

<style scoped>
.table tbody tr th,
.table tbody tr td {
  padding: 0.4em;
}

.table tbody tr th {
  font-weight: 500;
}

.table tbody + tbody {
  border-top: 2px solid #bfbfbf;
}
.budget-input {
  max-width: 7rem !important;
}
.h-100- {
  min-height: calc(100vh - 18.5rem);
}
</style>
