<template>
  <div>
    <div class="px-3 pt-3 pb-1 fw-600">Successor ({{this.list.length}})</div>
    <div class="list-group list-group-flush pl-1 px-3">
      <div
        v-for="task in list"
        :key="task._id"
        class="list-group-item text-0 border-0 c-pointer d-flex justify-content-between px-2 py-2 list-group-item-action bg-info-1 rounded-5 mb-1"
      >
        <div class="d-flex align-items-center">
          <i class="icon-arrow-left-circle mr-2 text-2 fs-12"></i>
          {{task.name}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "successores",
  props: {
    task: Object,
    scheduling: Object
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.list = this.scheduling.tasks.filter(t =>
        t.dependencies.includes(this.task._id)
      );
    }
  }
};
</script>