import Vue from 'vue';

const _debounce = (fn, delay) => {
    var timeoutID = null;
    return () => {
        clearTimeout(timeoutID);
        var args = arguments;
        var that = this;
        timeoutID = setTimeout(() => {
            fn.apply(that, args);
        }, delay);
    };
};

function debounce(el, binding) {
    if (binding.value !== binding.oldValue) {
        // change debounce only if interval has changed
        el.oninput = _debounce(function(evt) {
            el.dispatchEvent(new Event('change'));
        }, parseInt(binding.value) || 500);
    }
}

Vue.directive('debounce', debounce);