<template>
  <div class="btn-group" role="group" aria-label="Basic example">
    <button
      type="button"
      class="btn btn-toggle"
      @click="select(item)"
      v-for="item in items"
      v-bind:key="item.code"
      :class="{'active':item.code == selected}"
    >
      <i v-if="showicon && item.icon" :class="item.icon"></i>
      {{item.text}}
    </button>
  </div>
</template>

<script>
export default {
  name: "toggleButtons",
  props: {
    items: Array,
    value: [String, Number],
    showicon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  mounted() {
    this.selected = this.value;
  },
  watch: {
    value(newVal) {
      this.selected = newVal;
    }
  },

  methods: {
    select(item) {
      this.selected = item.code;
      this.$emit("input", item.code);
      this.$emit("select", item.code);
    }
  }
};
</script>

<style scoped>
.btn-toggle {
  background-color: #f8f9fa;
  border: 1px solid #999;
}
.btn-toggle.active {
  background-color: #0091ff;
  /* // #00a1b9; */
  color: white;
}
.btn-toggle:focus {
  box-shadow: inherit;
}
</style>

