import Vue from "vue";

export const truncate = (value, length) => {
	length = length || 50;
	if (!value || typeof value !== "string") return "";
	if (value.length <= length) return value;
	return value.substring(0, length) + "...";
};

Vue.filter("truncate", function(value, length) {
	return truncate(value, length);
});

Vue.filter("replace", function(value, p) {
	return p ? p.replace("{0}", value) : value;
});
Vue.filter("capital", function(str) {
	if (!str) return "";
	return str
		.split(" ")
		.map((s) => (s ? s.replace(/^./, s[0].toUpperCase()) : ""))
		.join(" ");
});
