<template>
  <div class="d-inline-block entity-view">
    <a ref="lookupRef" @click="select" class="d-block" :class="{ 'action-link': !item._id }">
      <!-- <item-avatar :size="30" :item="item" class="float-left mr-2 item-avatar" v-if="showicon" /> -->

      <span class="d-inline-block" :class="{ 'has-value': item && item.name }">
        {{
        item.name || placeholder
        }}
      </span>
      <i class="icon-arrow-down float-right align-middle mt-1 ml-2"></i>
    </a>

    <select-item @select="changed" v-if="modalShow" ref="modalSelect" :projectId="projectId" />
  </div>
</template>

<script>
import SelectItem from "./SelectItem";
import data from "@/mixins/dataMixin.js";

export default {
  name: "ItemSelector",
  components: { SelectItem },
  mixins: [data],
  props: {
    item: Object,
    placeholder: String,
    projectId: String,
    showicon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    select() {
      this.show();
    },
    changed(item) {
      this.modalShow = false;
      this.item._id = item._id;
      this.item.name = item.name;
      this.item.imageUrl = item.imageUrl ? item.imageUrl : "";
      this.$emit("change", item);
    },
    show() {
      this.modalShow = true;
      this.$nextTick(() => {
        this.$refs.modalSelect.open(this.$refs.lookupRef);
      });
    }
  },
  computed: {
    __item() {
      return this.item && this.item._id ? this.getItem(this.item._id) : {};
    }
  }
};
</script>

<style lang="scss" scoped>
.item-avatar {
  margin-top: -0.2rem;
}
.item-icon {
  font-size: 1.2rem;
}
</style>
