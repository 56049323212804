<template>
  <b-popover
    trigger="manual"
    offset="5"
    :show.sync="isOpen"
    ref="popover"
    custom-class="item-panel"
    placement="auto"
    :target="target"
    :auto-hide="false"
  >
    <template v-slot:title>
      <div class="p-2">
        <button class="btn close" @click="cancel" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </button>
        {{isnew ?'Add Contact':'Update Contact'}}
      </div>
    </template>

    <div></div>
    <div class="px-5 py-4" v-if="person">
      <div class="row">
        <div class="col-lg-4">
          <user-avatar v-model="person.imageUrl" :readonly="false" @change="changeImageUrl" />
        </div>
        <div class="col-lg-8">
          <div class="form-group">
            <label for="personEmail">Name</label>
            <input
              ref="firstNameInput"
              placeholder="Full name e.g John Smith"
              autocomplete="off"
              class="form-control"
              v-model="person.name"
              :class="{ 'is-invalid': validated && !person.name }"
            />
          </div>

          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              type="email"
              autocomplete="off"
              placeholder="Person's email address"
              v-model="person.email"
              :class="{ 'is-invalid': validated && !emailValid }"
            />
          </div>

          <div>
            <label>Phone</label>
            <input
              class="form-control"
              type="text"
              ref="phone"
              autocomplete="off"
              v-model="person.phone"
              placeholder="000-000-0000"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 px-5 border-top d-flex justify-content-end">
      <save-cancel @save="save" @cancel="cancel" />
    </div>
  </b-popover>
</template>

<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin";
import { isValidEmail } from "@/validations";
import UserAvatar from "@/views/client/user/UserAvatar.vue";

export default {
  name: "personEdit",
  mixins: [baseMixin, dataMixin],
  components: { UserAvatar },
  props: {
    contact: Object
  },
  data() {
    return {
      mask: "(+#) ###-###-####",
      showModal: true,
      original: "",
      person: { imageUrl: "", email: "" },
      isOpen: false,
      target: ""
    };
  },
  methods: {
    open(el, person) {
      this.isOpen = false;
      this.target = el;
      this.original = person;
      const copy = JSON.parse(JSON.stringify(person));
      if (copy.hasOwnProperty("imageUrl")) copy.imageUrl = "";
      this.person = copy;

      setTimeout(() => {
        this.isOpen = true;
      }, 100);
      setTimeout(() => {
        this.$refs.firstNameInput.focus();
      }, 600);
    },
    cancel() {
      this.isOpen = false;
    },
    changeImageUrl(url) {
      this.person.imageUrl = url;
      this.$forceUpdate();
    },

    save() {
      this.validated = true;
      if (!this.valid) {
        return;
      }
      try {
        this.isnew
          ? this.update("contact", this.person, `person/add`, this.contact._id)
          : this.update(
              "contact",
              this.person,
              `person/${this.person._id}/update`,
              this.contact._id
            );
        this.isOpen = false;
        this.$emit("updated", this.person);
      } catch (error) {
        console.log("error :", error);
      }
    }
  },
  computed: {
    valid() {
      return this.emailValid && this.validName;
    },
    validName() {
      return this.person.name;
    },
    emailValid() {
      return !this.person.email || isValidEmail(this.person.email);
    },
    isnew() {
      return !this.person._id;
    }
  }
};
</script>
<style lang="scss" scoped>
.first-name {
  padding-right: 0.25rem !important;
}
.last-name {
  padding-left: 0.25rem !important;
}
</style>
