<template>
  <ul class="text-gray mb-0" v-if="!hasData">
    <li>Subcontract management</li>
    <li>Contract account balances</li>
  </ul>
  <div v-else>
    <div class="d-flex py-2">
      <div
        v-for="c in list.slice(0,count)"
        :key="c._id"
        class="d-flex flex-column align-items-center mr-2"
      >
        <contact-avatar :contact="c.contact" :size="40" />
        <small class="text-truncate text-2 contact-name text-center">{{c.contact.name}}</small>
      </div>
      <avatar
        :username="more"
        :size="40"
        background-color="gray"
        color="white"
        v-if="list.length>count"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import accessMixin from "@/mixins/accessMixin";
export default {
  name: "subsSummary",
  mixins: [baseMixin, dataMixin, routeMixin, accessMixin],
  data() {
    return {
      count: 4
    };
  },
  mounted() {
    if (this.isUserCustomer) return;
    this.load();
  },
  methods: {
    load() {
      this.loadByProject("contract", this.project_id, false,false);
    }
  },
  computed: {
    list() {
      return (
        this.contracts.filter(
          o => o.project && o.project._id == this.project_id
        ) || []
      );
    },
    more() {
      return "+ " + (this.list.length - this.count); //!this.list.length  ? "" : "+ " + this.list.length - this.count;
    },
    hasData() {
      return this.list.length;
    },
    ...mapGetters(["contracts"])
  }
};
</script>
 <style lang="scss" scoped>
.contact-name {
  width: 3rem;
}
</style>