<template>
  <card-layout nofooter :state="state" back class="bg-2">
    <div slot="header"></div>
    <div slot="header" class="d-flex"></div>
    <div slot="title" class="d-flex">Share with Client</div>

    <div class="card-body" slot="data">
      <p
        class="text-2"
      >Client will have readonly access to the specific entities of the project that shared with them.</p>

      <div class="row">
        <div class="col-lg-6">
          <div class="bg-1 p-3 rounded-10 shadow-sm">
            <div class="fs-11 mb-1">Projects</div>
            <div class="list-group">
              <li
                class="list-group-item"
                :class="{current:project._id==project_id}"
                v-for="project in allCustomerProjects"
                :key="project._id"
              >
                <ShareProjectLine :project="project" />
              </li>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <InviteCustomer :projectId="project_id" />
        </div>
      </div>
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import InviteCustomer from "./InviteCustomer";
import ShareProjectLine from "./ShareProjectLine";

export default {
  name: "shareCustomer",
  mixins: [baseMixin, dataMixin, routeMixin],
  components: { InviteCustomer, ShareProjectLine },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();

      if (!this.project._id) await this.loadById("project", this.project_id);
      await this.loadById("shareCustomer", this.customerId);
      await this.loadById("contact", this.customerId);
      await this.loadByAction("project", `customer/${this.customerId}`);

      this.loaded();
    }
  },
  computed: {
    project() {
      return this.projectById(this.project_id);
    },
    customerId() {
      return this.project && this.project.customer
        ? this.project.customer._id
        : "";
    },
    customer() {
      return this.contactById(this.customerId);
    },
    allCustomerProjects() {
      return this.projects.filter(o => o.customer._id == this.customer._id);
    },

    shareCustomer() {
      return this.shareCustomers.find(o => o._id == this.customer._id);
    },
    ...mapGetters(["projectById", "contactById", "projects", "shareCustomers"])
  }
};
</script>
 
 <<style lang="scss" scoped>
 .list-group-item.current{
   border: 2px solid #a5d6a7;
 }
 </style>