<template>
  <div class="px-2 py-1 d-flex justify-content-between weather" v-if="weather">
    {{weather.description}}
    <div v-if="weather.minTemp || weather.maxTemp" class>
      <span class="text-muted mr-2">Temperature :</span>
      <span>{{weather.minTemp}}</span>
      <span class="px-1">-</span>
      <span>{{weather.maxTemp}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "weatherView",
  props: {
    weather: [Object, String]
  }
};
</script>
 