<template>
  <span>
    <span class="badge badge-pill" :class="`badge-${color}`" v-if="count">{{ count }}</span>
  </span>
</template>
<script>
export default {
  name: "countBadge",
  props: {
    count: Number,
    color: { type: String, default: "warning" }
  }
};
</script>
