<template>
  <center-layout>
    <div slot="title">Dashboard</div>
    <div slot="top-right">
      <DashIntives btn-only v-if="doneSetting" />
    </div>

    <div class="min-vh-100 pt-3">
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          <ViewDemoProject />
        </div>
      </div>
      <div class="row" v-if="hasAccess">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          <TaxCurrency />
          <ReqLogo />
          <FirstProject class="card form-group" />
          <DashIntives v-if="!doneSetting" />
        </div>
      </div>
      <div
        class="alert alert-danger text-center p-5 rounded-75 shadow-sm mx-3"
        v-if="!myrole"
      >
        <p class="fs-12">
          <i class="icon-exclamation r-2"></i>
          Missing Sufficient Permissions
        </p>
        <div>Contact your administrator</div>
      </div>
      <div class="row d-flex justify-content-around" v-if="doneSetting">
        <PayablesCard class="form-group col-lg-4" />
        <InvoiceCard class="form-group col-lg-4" />
        <ContractsCard class="form-group col-lg-4" />
      </div>
      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-6">
          <!-- <MyInvites show-nodata class="card form-group" /> -->
        </div>
      </div>
    </div>
  </center-layout>
</template>
<script>
import MyInvites from "@/views/settings/invite/MyInvites";
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import accessMixin from "@/mixins/accessMixin";
import TaxCurrency from "./TaxCurrency";
import ReqLogo from "./ReqLogo";
import FirstProject from "./FirstProject";
import DashIntives from "./DashIntives";
import PayablesCard from "./PayablesCard";
import InvoiceCard from "./InvoiceCard";
import ContractsCard from "./ContractsCard";
import ViewDemoProject from "./ViewDemoProject";

export default {
  mixins: [dataMixin, baseMixin, accessMixin],
  name: "dashboard",
  components: {
    PayablesCard,
    TaxCurrency,
    ReqLogo,
    FirstProject,
    DashIntives,
    InvoiceCard,
    ContractsCard,
    MyInvites,
    ViewDemoProject
  },
  mounted() {
    this.loaded();
  },
  methods: {},
  computed: {
    doneSetting() {
      return (
        this.clientSetting.taxVerified &&
        this.clientSetting.currencyVerified &&
        this.clientSetting.hasProject &&
        this.client.logoUrl
      );
    },

    ...mapGetters(["clientSetting", "client", "myrole", "demoCid"])
  }
};
</script>