<template>
	<div class="card-header d-flex justify-content-between align-items-center   ">
		<back-button class="pull-left" v-if="back" />
		<span>
			<h5 class="d-inline-block m-0">{{ title }}</h5>
			<slot></slot>
		</span>

		<span>
			<span v-if="state">
				<span v-if="!noEdit">
					<button-edit :allow="allowEdit" title="Edit" @edit="$emit('edit')" v-if="!state.loading" />
				</span>
				<spinner :busy="state.loading" class="float-right" />
			</span>
			<slot name="left"> </slot>
		</span>
	</div>
</template>
<script>
export default {
	name: 'header-view',
	props: {
		title: String,
		state: Object,
		allowEdit: {
			type: Boolean,
			default: true,
		},
		noEdit: Boolean,
		back: Boolean,
	},
};
</script>
