<template>
  <card-layout back title="Invoice" :state="state">
    <div slot="header" class="d-flex">
      <button-preview @click="preview" :busy="creaingPdf" />
      <!-- <button class="btn btn-default mr-3">
        <i class="icon-paper-plane mr-2"></i>
        Send
      </button>-->
      <div class="d-flex align-items-center">
        <button-share :entity="invoice" />
        <button-edit @edit="edit" />
      </div>
    </div>
    <div class="card-body" slot="data">
      <div class="row form-group">
        <div class="col-lg-9">
          <!-- company header -->
          <div class="row form-group">
            <div class="col-lg-4">
              <header-logo readonly />
            </div>
            <div class="col-lg-8">
              <address-view :address="invoice.sender.address" v-if="invoice.sender" readonly />
            </div>
          </div>
        </div>
        <!-- number -->
        <div class="col-lg-3">
          <section class="mb-2 row">
            <label class="no-wrap col">Invoice #</label>
            <div class="col fs-1 fw-600">{{invoice.number}}-R{{invoice.rev}}</div>
          </section>
          <section class="mb-2 row">
            <div class="col">Invoice Date</div>
            <div class="col">{{invoice.date | dateformat}}</div>
          </section>
          <section class="mb-2 row">
            <div class="col">Due date</div>
            <div class="col">{{invoice.dueDate | dateformat}}</div>
          </section>
          <section class="mb-0 row">
            <div class="col">Attention</div>
            <div class="col">{{invoice.attention}}</div>
          </section>
        </div>
      </div>

      <!-- contact -->
      <div class="row form-group">
        <div class="col-lg-6">
          <contact-view :contact="invoice.customer" />
        </div>
        <div class="col-lg-6"></div>
      </div>

      <!-- address -->
      <div class="row form-group">
        <div class="col-lg-9">
          <div class="row">
            <div class="col">
              <h6>Billing Address</h6>
              <address-view :address="invoice.billingAddress" class="p-3 bg-1" readonly />
            </div>
            <div class="col">
              <h6>Shipping Address</h6>
              <address-view :address="invoice.shippingAddress" class="p-3 bg-1" readonly />
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>

      <!-- address -->
      <div class="row form-group">
        <div class="col-lg-9">
          <label for>Terms</label>
          <div class="form-control value">{{invoice.terms}}</div>
        </div>
      </div>
      <!-- items -->
      <table class="table table-hover border">
        <thead>
          <tr>
            <th scope="col" class="text-center">Description</th>
            <th scope="col" class="w-7 text-right">Unit Price</th>
            <th scope="col" class="w-4 text-center">Qty</th>
            <th scope="col" class="w-5 text-right">Tax</th>
            <th scope="col" class="w-7 text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in invoice.items" v-bind:key="item._id">
            <td>
              <div class="d-flex justify-content-between text-primary">
                {{item.description}}
                <a
                  class="btn-link text-primary"
                  v-if="isCustom(item)"
                  @click="showDetail($event.target,item)"
                >
                  <i class="icon-info"></i>
                </a>
              </div>
            </td>
            <td class="text-right">{{item.unitPrice | currency}}</td>
            <td class="text-center">{{item.qty}}</td>
            <td class="py-1 text-right">{{itamTaxAmount(item)}}</td>
            <td
              class="py-1 text-right"
            >{{(item.unitPrice * item.qty + itamTaxAmount(item)) |currency}}</td>
          </tr>

          <tr>
            <td class="border-0 bg-1"></td>
            <td :colspan="cols-2" class="text-right border-0 bg-1">Sub Total</td>
            <td class="text-right">{{invoice.subTotal | currency}}</td>
          </tr>

          <tr>
            <td class="border-0 bg-1"></td>
            <td :colspan="cols-2" class="text-right border-0 bg-1">Tax</td>
            <td class="text-right">{{taxAmount(invoice.tax1)+taxAmount(invoice.tax2) | currency}}</td>
          </tr>

          <tr>
            <td class="border-0 bg-1"></td>
            <td :colspan="cols-2" class="text-right border-0 bg-1">This Invoice Total</td>
            <td class="text-right bg-success-1">{{invoice.invoiceTotal | currency}}</td>
          </tr>
          <tr>
            <td class="border-0 bg-1"></td>
            <td :colspan="cols-2" class="text-right border-0 bg-1">Previous Balance</td>
            <td class="text-right">{{invoice.prevBalance | currency}}</td>
          </tr>

          <tr>
            <td class="border-0 bg-1"></td>
            <td :colspan="cols-2" class="text-right border-0 bg-1">Balance Due</td>
            <td class="fw-600 text-right bg-warning-1">{{invoice.balanceDue | currency}}</td>
          </tr>
        </tbody>
      </table>

      <!-- note -->
      <div class="form-group">
        <label for>Notes</label>
        <div class="form-control value">{{invoice.note}}</div>
      </div>
      <div class="form-group">
        <div class>{{invoice.footer}}</div>
      </div>
    </div>
    <InvoiceExpense ref="invoiceExpense" />
    <InvoiceReimPayments ref="invoiceReimPayments" />
    <div slot="footer" class="d-flex justify-content-between">
      <last-modified :entity="invoice" class="float-left" />
      <delete-confirm
        :entity="invoice"
        fill
        @confirm="delInvoice"
        confirmtext="Are you sure you want to delete this Invoice"
      />
    </div>
  </card-layout>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import routeMixin from "@/mixins/routeMixin.js";
import invoiceMixin from "./data/invoiceMixin";
import InvoiceExpense from "./components/InvoiceExpense";
import InvoiceReimPayments from "./components/InvoiceReimPayments";

const entityName = "invoice";

export default {
  name: "invoiceEdit",
  mixins: [dataMixin, baseMixin, routeMixin, invoiceMixin],
  components: { InvoiceExpense, InvoiceReimPayments },
  data() {
    return {
      cols: 5,
      creaingPdf: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading();
      await this.loadById(entityName, this.invoice_id);
      this.loaded();
    },
    edit() {
      this.$router.push(
        `/project/${this.project_id}/invoice/${this.invoice_id}/edit`
      );
    },
    async preview() {
      this.creaingPdf = true;
      try {
        await this.download("invoice", `pdf/${this.invoice_id}`);
      } catch (error) {
      } finally {
        this.creaingPdf = false;
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    showDetail(el, item) {
      console.log("item", item);
      if (this.isMngFee(item)) {
        this.$refs.invoiceExpense.open(el, item);
      } else if (this.isReimbItem(item)) {
        this.$refs.invoiceReimPayments.open(el, item);
      }
    },
    async delInvoice() {
      await this.del(entityName, this.invoice);
      this.$router.go(-1);
    }
  },
  computed: {
    type() {
      return this.invoice ? this.invoice.type || {} : {};
    },
    isnew() {
      return this.$route.params.invoice_id == "new";
    },
    taxAmount() {
      return tax => (tax ? tax.amount : 0);
    },
    itamTaxAmount() {
      return item => this.taxAmount(item.tax1) + this.taxAmount(item.tax2);
    },
    project() {
      return this.projectById(this.project_id);
    },
    invoice() {
      return this.invoiceById(this.invoice_id);
    },
    ...mapGetters(["projectById", "invoiceById"])
  }
};
</script>
