const dashboard = {
	name: "Dashboard",
	plural: "dashboard",
	path: "/",
	icon: "icon-speedometer",
	meta: { accessRole: "r" },
};

const switchCLient = {
	name: "switchCLient",
	path: "/switchCLient",
	icon: "icon-switch",
};
const feeds = {
	path: "/feeds",
	name: "Feeds",
	icon: "icon-feed",
	count: "",
	meta: { accessRole: "f" },
};

const projects = {
	entityName: "project",
	name: "Projects",
	path: "/projects",
	svg: "svg-project",
	nodata: {
		title: "No Project",
	},

	nav: {
		name: "Project",
		path: "/project/:project_id",
		projectHome: {
			path: "",
			name: "Project",
			icon: "icon-folder",
			count: "",
		},
		projectInfo: {
			path: "info",
			name: "Project Info",
			icon: "icon-info",
			count: "",
		},
		budgeting: {
			path: "budgeting",
			name: "budgeting",
		},
		budgetSummary: {
			path: "budgetSummary",
			name: "budgetSummary",
		},
		budgetSetting: {
			path: "budgetSetting",
			name: "budgetSetting",
		},
		projectSummary: {
			path: "projectSummary",
			name: "projectSummary",
		},
		scheduling: {
			path: "scheduling",
			name: "scheduling",
		},
		contracts: {
			path: "contracts",
			name: "contracts",
			svg: "scg-contract",
		},

		budegtRevise: {
			path: "budgeting/:budgeting_id/revise/:budgetItem_id",
			name: "revisebudget",
		},
		editBudegt: {
			path: "budgeting/:budgeting_id/edit/:budgetItem_id",
			name: "editBudget",
		},
		progress: {
			path: "progress",
			name: "progress",
		},
		dailyLogEdit: {
			path: "dailyLog/:dailyLog_id/edit",
			name: "dailyLogEdit",
		},
		expenses: {
			path: "expenses",
			name: "expenses",
		},
		invoices: {
			path: "invoices",
			name: "invoices",
		},
		docs: {
			path: "docs",
			name: "docs",
		},
		gallery: {
			path: "gallery",
			name: "gallery",
		},
		cashflow: {
			path: "cashflow",
			name: "cashflow",
		},
		cashInEdit: {
			path: "cashin/:cashIn_id/edit",
			name: "cashInEdit",
		},
		cashInView: {
			path: "cashin/:cashIn_id",
			name: "cashInView",
		},
		invoiceEdit: {
			path: "invoice/:invoice_id/edit",
			name: "invoiceEdit",
		},
		invoiceView: {
			path: "invoice/:invoice_id",
			name: "invoiceView",
		},
		incomeEdit: {
			path: "income/:income_id/edit",
			name: "incomeEdit",
		},
		payableEdit: {
			path: "payable/:payable_id/edit",
			name: "projectPayableEdit",
		},
		progressReport: {
			path: "progressReport/:progressReport_id",
			name: "progressReport",
		},
		timesheet: {
			path: "timesheet",
			name: "ProjectTimesheet",
		},
		timesheetEdit: {
			path: "timesheet/:timesheet_id/edit",
			name: "timesheetEdit",
		},
		progressReportEdit: {
			path: "progressReport/:progressReport_id/edit",
			name: "progressReportEdit",
		},
		contractEdit: {
			path: "contract/:contract_id/edit",
			name: "contractEdit",
		},
		shareCustomer: {
			name: "shareCustomer",
			path: "shareCustomer",
		},
	},
};

const contacts = {
	name: "Contacts",
	path: "/contacts",
	svg: "svg-contacts",
	meta: { accessRole: "r" },

	nav: {
		name: "contact",
		path: "/contact/:contact_id",
		contactHome: {
			path: "",
			name: "contact",
			svg: "svg-contacts",
		},
		edit: {
			path: "/contact/:contact_id/edit",
			name: "contactEdit",
		},
	},
};

const payables = {
	entityName: "payable",
	name: "Payables",
	path: "/payables",
	svg: "svg-payable",
	meta: { forceShow: "c" },

	nav: {
		name: "payable",
		path: "/payable/:payable_id",
		view: {
			name: "View Payable",
			path: "/payable/:payable_id",
		},
		edit: {
			name: "Edit Payable",
			path: "/payable/:payable_id/edit",
		},
	},
};
const receivables = {
	name: "Receivable",
	path: "/receivables",
	svg: "svg-receivable",
};

const invoices = {
	name: "Invoices",
	path: "/invoices",
	svg: "svg-invoice",
	meta: { forceShow: "c" },
};

const quotes = {
	name: "Quote",
	path: "/quotes",
	svg: "svg-quote",
};

const settings = {
	entityName: "clientSetting",
	name: "Settings",
	path: "/settings",
	icon: "icon-settings",
	meta: { accessRole: "r" },

	nav: {
		invite: {
			name: "invite",
			desc: "Invite user to join",
			icon: "icon-user-follow",
			path: "/settings/invite",
			meta: { accessRole: "a" },
		},
		userAccess: {
			name: "userAccess",
			desc: "Users",
			icon: "icon-lock",
			path: "/settings/useraccess/0",
			meta: { accessRole: "a" },
		},
		userProfile: {
			name: "userProfile",
			desc: "User Profile",
			icon: "icon-user",
			path: "/settings/userProfile",
		},
		clientprofile: {
			name: "clientprofile",
			desc: "Company Profile",
			icon: "icon-settings",
			path: "/settings/clientprofile",
			meta: { accessRole: "r" },
		},
		clientSetting: {
			name: "clientSetting",
			desc: "Company Setting",
			icon: "icon-equalizer",
			path: "/settings/clientsetting",
			meta: { accessRole: "r" },
		},
		accounts: {
			name: "accounts",
			desc: "Accounts",
			icon: "icon-wallet",
			path: "/settings/accounts",
			meta: { accessRole: "r" },
			nav: false,
		},
		itemMaster: {
			name: "itemMaster",
			desc: "Item Master",
			icon: "icon-list",
			path: "/settings/itemmaster",
			meta: { accessRole: "r" },
		},
		templates: {
			name: "templates",
			desc: "Templates",
			icon: "icon-grid",
			path: "/settings/templates",
			meta: { accessRole: "r" },
		},
		subscription: {
			name: "Subscription",
			desc: "Subscription",
			icon: "icon-credit-card",
			path: "/settings/subscription",
			meta: { accessRole: "a" },
		},
	},
};

// const clientSetting = {
// 	name: "Setting",
// 	path: "/settings/clientSetting",
// 	icon: "icon-settings"
// };
export const navbars = [dashboard, projects, payables, invoices, contacts, feeds, settings];

export default {
	dashboard,
	projects,
	payables,
	receivables,
	invoices,
	quotes,
	contacts,
	settings,
	switchCLient,
	feeds,
};
