<template>
  <div :class="block? 'w-100': 'd-inline-block'" v-if="!hideAction" class="d-print-none">
    <a
      v-if="link"
      class="btn text-primary border-0 d-flex align-items-center"
      :class="{  className, disabled:!hasAccess}"
      @click="$emit('add')"
      :disabled="!hasAccess"
    >
      <i class="icon-lock mr-2" v-if="!hasAccess"></i>
      <icon svg="svg-plus" color="#007bff" class="d-inline-block mr-2" v-else />
      <slot></slot>
    </a>
    <button
      v-else
      @click="$emit('add')"
      class="btn btn-success d-flex justify-content-around"
      :class="{'sm':small, className, disabled:!hasAccess, 'w-100':block}"
      :disabled="!hasAccess"
    >
      <span class="d-flex align-items-center">
        <i class="icon-lock mr-2" v-if="!hasAccess"></i>
        <icon svg="svg-plus" color="white" class="d-inline-block mr-2" v-else />
        <slot></slot>
      </span>
    </button>
  </div>
</template> 
<script>
import accessMixin from "@/mixins/accessMixin";
export default {
  name: "btn-add",
  mixins: [accessMixin],
  props: {
    link: Boolean,
    className: String,
    small: Boolean,
    block: Boolean
  }
};
</script> 