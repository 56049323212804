import { mapGetters } from "vuex";

export default {
	methods: {
		newTax(num) {
			let tax = this.clientSetting[`tax${num}`];
			if (tax) {
				return {
					code: tax.code,
					amount: 0,
					percent: tax.percent
				};
			}
			return {
				code: "",
				amount: 0,
				percent: 0
			};
		},
		getExchange() {
			return { currency: this.clientSetting.currencyCode, rate: 1 };
		}
	},
	computed: {
		clientHasTax1() {
			return !!this.clientSetting.tax1 && this.clientSetting.tax1.code;
		},
		clientHasTax2() {
			return !!this.clientSetting.tax2 && this.clientSetting.tax2.code;
		},
		clientTaxCode1() {
			return this.clientSetting.tax1 && this.clientSetting.tax1.code;
		},
		clientTaxCode2() {
			return this.clientSetting.tax2 && this.clientSetting.tax2.code;
		},
		...mapGetters(["clientSetting"])
	}
};
