<template>
  <div class="change-logs border-left card rounded-0">
    <div class="d-flex justify-content-between bg-trans px-3 py-2">
      <div>Change Logs</div>
      <button @click="close" type="button" class="btn close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="p-0 card-body bg-white">
      <div class="list-group list-group-flush list-group-activity my-n3 py-3">
        <div class="list-group-item border-0 py-0" v-for="c in list" :key="c._id">
          <div class="row my-2">
            <div class="col-auto">
              <!-- Avatar -->
              <div class="avatar avatar-sm">
                <user-view :user="c.by" imageolnly :size="40" />
                <!-- <img :src="c.by.imageUrl" alt="..." class="avatar-img rounded-circle" /> -->
              </div>
            </div>
            <div class="col ml-n2">
              <!-- Content -->
              <div class="small">
                <strong>{{c.by.name}}</strong>
                {{c.title}}
                {{c.description}}
                <br />
                <span class="text-muted">{{c.date | since}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "changeLogs",
  props: {
    entity: Object
  },
  methods: {
    close() {
      this.$emit("close");
    },
    open() {}
  },
  computed: {
    list() {
      return this.entity.changeLogs;
    }
  }
};
</script>
<style lang="scss" scoped>
.card-body {
  height: 100%;
  overflow-y: auto;
}
.change-logs {
  position: fixed;
  padding-top: 70px;
  width: 26rem;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f3f3f3;
}

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important;
}
.list-group-activity .list-group-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  height: 100%;
  border-left: 1px solid #e3ebf6;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.83333rem;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.375rem !important;
}
</style>
 