<template>
  <div class="d-block entity-view">
    <a
      ref="lookupRef"
      @click="select"
      class="d-flex justify-content-between align-items-center"
      :class="{ 'action-link': !contact._id }"
      v-if="contact"
    >
      <div class="d-flex align-items-center">
        <contact-avatar
          :size="30"
          :contact="contact"
          class="float-left mr-2 contact-avatar"
          v-if="contact._id"
        />

        <span
          class="d-inline-block"
          :class="{ 'has-value': contact && contact.name }"
        >{{ contact.name || placeholder }}</span>
      </div>

      <i class="icon-arrow-down float-right align-middle mt-1 ml-2"></i>
    </a>

    <select-contact @select="changed" v-if="modalShow" ref="modalSelect" />
  </div>
</template>

<script>
import SelectContact from "./SelectContact";
import data from "@/mixins/dataMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "ContactSelector",
  components: { SelectContact },
  mixins: [data],
  props: {
    contact: Object,
    placeholder: String,
    showicon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    select() {
      this.show();
    },
    changed(contact) {
      this.modalShow = false;
      this.contact._id = contact._id;
      this.contact.name = contact.name;
      this.contact.imageUrl = contact.imageUrl ? contact.imageUrl : "";
      this.$emit("change", contact);
    },
    show() {
      this.modalShow = true;
      this.$nextTick(() => {
        this.$refs.modalSelect.open(this.$refs.lookupRef);
      });
    }
  },
  computed: {
    contact_store() {
      return this.contact && this.contact._id
        ? this.contactById(this.contact._id)
        : {};
    },
    ...mapGetters(["contactById"])
  }
};
</script>
