<template>
  <div class="position-relative">
    <button class="btn small py-1 ml-3 text-primary" @click="show($event.target)">
      <i class="la la-plus"></i>
      <i class="icon-arrow-down mr-2"></i>
      Import From Excel
    </button>

    <b-popover
      v-if="isOpen"
      trigger="manual"
      offset="5"
      :show.sync="isOpen"
      ref="popover"
      placement="auto"
      :target="target"
      :auto-hide="false"
      no-fade
    >
      <template v-slot:title>
        <div class="p-2">
          <button class="btn close" @click="cancel" aria-label="Close">
            <span class="d-inline-block" aria-hidden="true">&times;</span>
          </button>
          Import From Excel
        </div>
      </template>

      <div class="popup-content p-4 pt-5 bg-light">
        <div class="form-group">
          <div class="custom-file">
            <input
              type="file"
              ref="file"
              class="custom-file-input"
              id="inputGroupFile01"
              @change="handleFileUpload($event.target)"
              :class="{ disabled: !hasAccess, 'btn-sm': small, 'is-invalid': validated && !file}"
              :disabled="!hasAccess"
              accept=".xlsx"
            />

            <label
              class="custom-file-label"
              for="inputGroupFile01"
            >{{file?file.name: 'Select the Excel file'}}</label>
          </div>
          <div class="alert alert-danger mt-3" v-if="error">{{error}}</div>
        </div>

        <div class="clearfix pt-3 border-top">
          <save-cancel @save="importFile" savetext="Import" @cancel="cancel" />
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import accessMixin from "@/mixins/accessMixin";
import readXlsxFile from "read-excel-file";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
export default {
  name: "importContact",
  mixins: [accessMixin, baseMixin, dataMixin],
  props: {
    title: {
      type: String,
      default: "Import"
    },

    small: Boolean
  },
  data() {
    return {
      isOpen: true,
      file: "",
      validated: false,
      target: "",
      importing: false,
      error: ""
    };
  },
  methods: {
    show(el) {
      this.isOpen = false;
      this.target = el;
      this.$nextTick(() => {
        this.isOpen = true;
      });
    },
    hide() {
      this.isOpen = false;
      this.$refs.file.value = "";
      this.file = "";
      this.importing = false;
      this.validated = false;
    },
    select(data) {
      this.hide();
      this.$emit("select", data);
    },
    cancel() {
      this.isOpen = false;
      this.file = "";
    },
    edit() {
      if (!this.hasAccess) return;
      this.$emit("import");
    },
    async handleFileUpload(e) {
      this.file = e.files[0];
    },
    importFile() {
      this.validated = true;
      if (!this.file || this.importing) return;
      readXlsxFile(this.file).then(rows => {
        rows = rows.filter(row => row.some(cell => cell !== null));
        this.importFromExcel(rows);
      });
    },
    async importFromExcel(rows) {
      this.error = "";
      this.importing = true;
      try {
        this.importing = true;
        await this.update("contact", rows, `importFromExcel`);
        this.hide();
        this.$emit("done");
      } catch (resp) {
        this.error =
          resp.data && resp.data.message ? resp.data.message : resp.data;
      } finally {
        this.importing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-content {
  min-width: 26rem;
}
</style>
