<template>
  <div class="bg-1">
    <div class="alert alert-danger" v-if="isExpired">
      <h5 class="alert-heading">Subscription expired!</h5>Please renew your subscription to activate the account!
    </div>
    <div v-else>
      <h5 class="px-5 py-3 border-bottom">Subscription</h5>
    </div>
    <div class="px-5 pt-3 pb-3">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for>Subscription Type</label>
            <div>
              <toggle-buttons :items="cycles" @select="change" v-model="model.cycle" />
            </div>
          </div>

          <div class="form-group">
            <label for>Number of Users</label>
            <div>
              <toggle-buttons :items="priceList" @select="change" v-model="code" />
            </div>
          </div>

          <div class="form-group" v-if="code">
            <label class="fs-12 text-default" for>{{selectedCycle.text}}</label>
            <div>
              <span class="bg-info text-white py-1 px-3 rounded-5 fs-15">{{model.amount | currency}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for>Staring</label>
            <div class="form-control value text-2">{{model.starting |day}}</div>
          </div>
          <div class="form-group">
            <label for>Valid Until</label>
            <div class="form-control value text-2">{{validUntil|day}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="border-top p-3 px-5 bg-2">
      <div class="row">
        <div class="col-lg-6">
          <Pay :subscription="model" v-if="code" />
          <div class="fs-12" v-else>Contact Us...</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import baseMixin from "@/mixins/baseMixin.js";
import dataMixin from "@/mixins/dataMixin.js";
import moment, { months } from "moment";
import Pay from "./Pay";
export default {
  name: "Subscribe",
  mixins: [dataMixin, baseMixin],
  components: { Pay },
  props: {
    nexoAccount: Object
  },
  data() {
    return {
      cycles: [
        { text: "Monthly", code: "m" },
        { text: "Annual", code: "y" }
      ],
      priceList: [],
      model: {
        cycle: "m",
        maxUsers: 0,
        amount: 0.0,
        starting: ""
      },
      code: "",

      validUntil: ""
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    async load() {
      this.loading();
      let pricing = await this.loadNexo("pricing");
      pricing.list.map(o => (o.text = o.maxUsers));
      pricing.list.push({
        _id: "-1",
        text: "more...",
        m: "Contact  us",
        y: "Contact  us",
        code: ""
      });
      this.priceList = pricing.list;
      this.code = this.priceList[1].code;

      // if trial is over
      if (
        this.nexoAccount.trialEndDate &&
        moment(this.nexoAccount.trialEndDate).isAfter < moment()
      ) {
        this.model.starting = this.nexoAccount.trialEndDate || new Date();
      } else {
        this.model.starting = new Date();
      }
      this.change();
      this.loaded();
    },
    change() {
      this.model.amount = this.selectedPrice[this.model.cycle];
      this.model.maxUsers = parseFloat(this.selectedPrice.maxUsers);
      if (!this.model.starting) this.model.starting = new Date();

      this.validUntil = moment(this.model.starting).add(
        1,
        this.model.cycle == "m" ? "months" : "years"
      );
      this.$emit("change", this.model);
    }
  },
  computed: {
    selectedPrice() {
      return this.priceList
        ? this.priceList.find(o => o.code == this.code) || {}
        : {};
    },
    isExpired() {
      return (
        this.nexoAccount.validUntil &&
        moment(this.nexoAccount.validUntil).isBefore(moment(new Date()))
      );
    },
    selectedCycle() {
      return this.cycles.find(o => o.code == this.model.cycle) || {};
    },
    subscription() {
      return this.nexoAccount.subscription || {};
    },
    showContactUs() {
      return this.code;
    }
  }
};
</script>