<template>
  <div v-if="hasAccess">
    <div v-if="btnOnly">
      <button class="btn btn-outline-primary" @click="invite">
        <i class="icon-user-follow mr-2"></i>
        Invite Colleague / Partner
      </button>
    </div>
    <div v-else>
      <div class="card form-group">
        <div class="d-flex justify-content-center p-3">
          <button class="btn btn-outline-primary" @click="invite">
            <i class="icon-user-follow mr-2"></i>
            Invite Colleague / Partner
          </button>
        </div>
      </div>
    </div>
    <InviteModal ref="inviteModal" />
  </div>
</template>
<script>
import InviteModal from "@/views/settings/invite/InviteModal.vue";
import MyInvites from "@/views/settings/invite/MyInvites";
import accessMixin from "@/mixins/accessMixin";
export default {
  name: "dashInvites",
  components: { MyInvites, InviteModal },
  mixins: [accessMixin],
  props: {
    btnOnly: Boolean
  },
  methods: {
    invite() {
      this.$router.push("/settings/invite");
      this.$refs.inviteModal.open();
    }
  }
};
</script>