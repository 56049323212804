<template>
  <div v-if="isReady">
    <highcharts :options="chartOptions" ref="hchart" />
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import baseMixin from "@/mixins/baseMixin";
import budgetMixin from "../data/budgetMixin.js";
import _ from "lodash";
export default {
  name: "budgetPie",
  components: { highcharts: Chart },
  mixins: [baseMixin, budgetMixin],
  props: { budgeting: Object },
  data() {
    return {
      isReady: false,
      groupedList: [],
      chartOptions: {
        chart: {
          backgroundColor: "transparent",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 200,
          width: 460,
          marginLeft: 210
        },
        legend: {
          align: "left",
          verticalAlign: "top",
          layout: "vertical",
          x: 0,
          y: 10,
          itemWidth: 200,
          labelFormatter: function() {
            return `${this.name} ${this.percentage.toFixed(2)} %`;
          }
        },
        title: {
          text: ""
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y:,.0f}</b>"
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %"
            },
            showInLegend: true
          }
        },

        series: [
          {
            name: "Total Budget",
            colorByPoint: true,
            data: []
          }
        ]
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.updateChart();
    });
  },
  watch: {
    budgeting: {
      immediate: false,
      handler(value) {
        this.updateChart();
      }
    }
  },
  methods: {
    updateChart() {
      let itemCategories = this.categories;
      if (!this.categories || !this.budgetItems || !this.budgetItems.length)
        return;
      let data = _(this.budgetItems)
        .groupBy(item => item.categoryId)
        .map((value, key) => {
          let category = itemCategories.find(o => o._id == key) || {};
          return {
            key,
            value,
            name: category.name,
            budget: value.map(o => o.budget).reduce((a, b) => a + b, 0)
          };
        })
        .sort((a, b) => b.budget - a.budget)
        .value()
        .slice(0, 10);
      this.groupedList = data;
      this.chartOptions.series[0].data = data.map(o => {
        return { name: o.name, y: o.budget };
      });
      this.isReady = data.length && this.totalBudget;
    }
  }
};
</script>

<style lang="scss" scoped></style>
