<template>
	<div>
		<MyInvites @accepted="onAccept" />
	</div>
</template>

<script>
import MyInvites from '../../views/settings/invite/MyInvites';
export default {
	name: 'joinClient',
	components: { MyInvites },
	props: {
		invites: Array,
	},
	data() {
		return {
			clientName: '',
		};
	},
	methods: {
		async join(r) {
			await this.$store.dispatch(JOIN, { action: 'acceptclient', id: r.guid });
			this.$router.push('/');
		},
		onAccept(invite) {
			let cid = invite.cid;
			if (!cid) return;
			this.$store.dispatch('SWITCH_CLIENT', cid);
		},
	},
};
</script>
