<template>
  <div>
    <div class="list-group">
      <div class="list-group list-group-flush">
        <div
          v-for="person in contact.people"
          :key="person._id"
          class="list-group-item bg-2 d-flex align-items-center rounded-10 border shadow-sm mb-2"
        >
          <div>
            <avatar :username="person.name || '-'" :src="person.imageUrl" class="mr-3" />
          </div>
          <div class="d-flex justify-content-between w-100">
            <div>
              <div class="fs-11">{{person.name}}</div>
              <div class="text-danger" v-if="!person.name">Name is required!</div>
              <div class="text-2">{{person.phone || '&nbsp;'}}</div>
              <div class="text-2">{{person.email || '&nbsp;'}}</div>
              <div class="text-danger" v-if="!person.email">Email is required</div>
            </div>
            <div class="d-flex align-items-center" v-if="person.uid">
              <span class="badge badge-success px-3 py-2 fs-1">Joined</span>
            </div>
            <div class="d-flex align-items-center" v-else>
              <div class="border px-4 btn-sm" v-if="inviting[person._id]">
                <spinner :size="1" :busy="true" />Inviting
              </div>
              <div class="d-flex align-items-center" v-else>
                <button
                  class="btn text-primary px-3 btn-sm mr-2"
                  @click="edit( $event.target,person)"
                >
                  <i class="icon icon-pencil"></i> Edit
                </button>

                <div class="badge badge-success mx-2" v-if="person.invited">Invited</div>
                <button
                  v-else
                  class="btn btn-outline-success px-3 btn-sm fw-600"
                  @click="invite(person)"
                  :disabled="!valid(person)"
                  :class="{disabled:!valid(person)}"
                >Invite</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="list-group-item c-pointer d-flex align-items-center list-group-item-action rounded-10 border-dash mb-3"
      >
        <i class="icon icon-user fs-2 text-primary"></i>
        <add-button @click.native="add($event.target)" link>Add contact to this client</add-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6"></div>

      <div class="col-lg-6 contact-edit" v-if="editMode">
        <PersonEdit
          :person="selectedForEdit"
          :company="company"
          @updated="hideEdit"
          @cancel="cancelEdit"
          class
        />
      </div>
    </div>
    <!-- <div class="alert alert-danger">Please enter a valid email address!</div> -->
    <PersonEdit ref="personEdit" :contact="contact" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import routeMixin from "@/mixins/routeMixin";
import PersonEdit from "@/views/contact/components/PersonEdit";
import { isValidEmail } from "@/validations";

export default {
  name: "people",
  mixins: [baseMixin, dataMixin],
  props: {
    contact: Object
  },
  components: { PersonEdit },
  data() {
    return {
      selectedForEdit: "",
      inviting: {}
    };
  },

  mounted() {},

  methods: {
    edit(el, person) {
      this.$refs.personEdit.open(el, person);
    },
    add(el) {
      this.edit(el, {
        name: "",
        email: "",
        phone: ""
      });
    },
    doneEdit() {
      this.hideEdit();
    },
    hideEdit() {
      this.selectedForEdit = "";
    },
    cancelEdit() {
      this.selectedForEdit = "";
    },
    async invite(person) {
      if (!person.email || !person.name) {
        return;
      }
      try {
        this.inviting[person._id] = true;
        this.$forceUpdate();
        await this.update(
          "invite",
          { message: "", personId: person._id },
          `customer/${this.contact._id}`
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        this.inviting[person._id] = false;
        this.$forceUpdate();
      }
    }
  },

  computed: {
    editMode() {
      return !!this.selectedForEdit;
    },
    nodata() {
      return !this.contact.people.length;
    },
    isInviting() {
      return person => this.inviting[person._id];
    },
    valid() {
      return person => person.name && person.email;
    }
  }
};
</script>

<style lang="css" scoped>
.contact-edit {
  margin-top: -2rem;
  margin-left: -1rem;
  padding-left: 0;
  padding-right: 0;
}
</style>
