<template>
  <side-layout>
    <span slot="title">
      <router-link to="/contacts">{{ page.name }}</router-link>
      <i class="icon-arrow-right mx-2 small"></i>
      {{ contact.name }}
    </span>

    <div slot="side" class="side text-center">
      <div class="d-flex justify-content-center text-center mt-3">
        <contact-avatar :size="128" :contact="contact" class="bg-white rounded-5 p-3 text-center" />
      </div>
      <div class="text-center form-group">
        <div class="fs-15">{{ contact.name }}</div>
        <div class="text-2 fs-12">{{ contact.description }}</div>
      </div>
      <ContactImage :contact="contact" />
    </div>
  </side-layout>
</template>
<script>
import { mapGetters } from "vuex";
import pages from "@/pages";
import routeMixin from "@/mixins/routeMixin";
import data from "@/mixins/dataMixin.js";
import ContactImage from "./components/ContactImage";

const entityName = pages.contacts.entityName;
export default {
  name: "contactNav",
  mixins: [routeMixin, data],
  components: { ContactImage },
  data() {
    return {
      page: pages.contacts,
      routes: pages.contacts.nav.routes
    };
  },
  methods: {
    async load() {
      this.loading();
      await this.loadById(entityName, this.contact_id);
      this.loaded();
    }
  },
  computed: {
    contact() {
      return this.contactById(this.contact_id);
    },
    ...mapGetters(["contactById"])
  }
};
</script>
