<template>
  <div>
    <div class="card rounded-10 shadow-sm p-card border-0">
      <div class="px-4 py-2 d-flex justify-content-between align-items-start">
        <div>
          <div class="fs-15">Subcontracts</div>
          <button class="btn small p-0 fs-09 text-2 m-0">For Active Project</button>
          <div></div>
        </div>
        <svg-icon name="contract" color="#798bec" :size="50" />
      </div>

      <div class="card-body dash-card-body d-flex justify-content-between p-3" v-if="contractTotal">
        <div class="text-center px-2" v-if="contractTotal.projects">
          <div class="fs-15 text-normal">{{contractTotal.projects.qty}}</div>
          <div class="small text-2">Projects</div>
        </div>
        <div class="text-center px-2">
          <div class="fs-15 text-normal">{{subcontractorCount}}</div>
          <div class="small text-2">Subcontractor</div>
        </div>
      </div>
      <div class="card-footer rounded-10-bottom border-m">&nbsp;</div>
    </div>
  </div>
</template>
<script>
import dataMixin from "@/mixins/dataMixin";
import baseMixin from "@/mixins/baseMixin";
import clientDataMixin from "@/mixins/clientDataMixin";
import { mapGetters } from "vuex";
export default {
  name: "contractsCard",
  mixins: [dataMixin, baseMixin, clientDataMixin],
  mounted() {
    this.checkHasContract();
    this.load();
  },
  methods: {
    async checkHasContract() {
      if (!this.hasContract) {
        const hasContract = await this.loadByAction("contract", "any", true);
        if (hasContract) this.reloadProfile();
      }
    },
    async load() {
      await this.loadByAction("contractTotal");
    }
  },
  computed: {
    hasContract() {
      return this.clientSetting && this.clientSetting.hasContract;
    },
    subcontractorCount() {
      return this.contractTotal &&
        this.contractTotal.contracts &&
        this.contractTotal.contracts.contractors
        ? this.contractTotal.contracts.contractors.length
        : 0;
    },
    ...mapGetters(["clientSetting", "contractTotal"])
  }
};
</script>  
<style lang="scss" scoped>
.card-footer {
  height: 3.35rem;
}
</style>