export const replaceItem = (list, item) => {
	if (!item || !list) return;
	let found = list.find(o => o._id == item._id);

	if (found) {
		let index = list.indexOf(found);
		list.splice(index, 1, item);
		list[index] = found;
	}
};
export const toArray = o => {
	return Object.keys(o).map(k => {
		return { key: k, name: o[k] };
	});
};
